import { BeRubyAPI } from "../../../clients/BERubyAPI";
import { postCustomizationApi } from "./customization";
import { getVehiclePriceApi } from "./vehicle_price";
import { postSetPlansApi } from "./set_plans";
import { postOrderApi } from "./order";
import { getListVehiclePriceApi } from "./list_vehicle";
import { postInterestProduct } from "./interest_new_products";
import Jwt from "jsonwebtoken";

const getCustomizationApi = (token, subscription_id) => {
  let responseFetch = BeRubyAPI
    .get('/v2/embedded_forms/get_accessories_price?key_id=' + subscription_id,
      {
        headers: {
          'Authorization': token !== null ? 'Bearer ' + token : '',
        }
      }
    )
    .then(res => {
      let decryptData = Jwt.verify(res.data.data.payload, process.env.VUE_APP_END_TO_END_SECRET_KEY);
      return {
        data: decryptData,
        meta: res.data.meta,
      }
    }).catch((err) => {
      return err.response.data
    });

  return responseFetch;
}

export { getCustomizationApi, postCustomizationApi, getVehiclePriceApi, postSetPlansApi, postOrderApi, getListVehiclePriceApi, postInterestProduct };