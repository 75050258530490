import { BeRubyAPI } from "../../../clients/BERubyAPI";
const getListVehiclePriceApi = (token, vehicle_id) => {
  let responseFetch = BeRubyAPI
    .get('/v1/accessories/prize_by_vehicle/' + vehicle_id,
      {
        headers: {
          'Authorization': token !== null ? 'Bearer ' + token : '',
        }
      }
    )
    .then(res => {
      return res.data;
    }).catch((err) => {
      return err.response.data
    });

  return responseFetch;
}

export { getListVehiclePriceApi };