const dataDomisiliModul = {
  state: () => ({
    serviceProvince: "",
    serviceCity: "",
    serviceDistrict: "",
    serviceSubDistrict: "",
    address: "",
    serviceZip: "",
    serviceVillage: "",
    homeOwnershipStatus: "",
    lengthOfStay: "",
    sameAddress: false,
    provinceKtp: "",
    cityKtp: "",
    districtKtp: "",
    subDistrictKtp: "",
    addressKtp: "",
    postalCodeKtp: "",
  }),
  mutations: {
    setDataDomisili(state, payload) {
      state.serviceProvince = payload.serviceProvince;
      state.serviceCity = payload.serviceCity;
      state.serviceDistrict = payload.serviceDistrict;
      state.serviceSubDistrict = payload.serviceSubDistrict;
      state.serviceVillage = payload.serviceVillage,
      state.address = payload.address;
      state.serviceZip = payload.serviceZip;
      state.homeOwnershipStatus = payload.homeOwnershipStatus;
      state.lengthOfStay = payload.lengthOfStay;
      state.sameAddress = payload.sameAddress;
      state.provinceKtp = payload.provinceKtp;
      state.cityKtp = payload.cityKtp;
      state.districtKtp = payload.districtKtp;
      state.subDistrictKtp = payload.subDistrictKtp;
      state.addressKtp = payload.addressKtp;
      state.postalCodeKtp = payload.postalCodeKtp;
    },
  },
  actions: {},
  getters: {
    getDataDomisili(state) {
      return state;
    },
  }
}

export default dataDomisiliModul;