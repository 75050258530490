const invoiceModul = {
  state: () => ({
    invoice_data: {}
  }),
  mutations: {
    setInvoice(state, payload) {
      state.invoice_data = payload
    },
  },
  actions: {},
  getters: {
    getInvoice(state) {
      return state.invoice_data;
    },
  }
}

export default invoiceModul;