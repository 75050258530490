import { BeRubyAPI } from "../../../clients/BERubyAPI";
import Jwt from "jsonwebtoken";

const postResidenceInfoApi = (token, { subscription_id, pick_up, residence_info_data }) => {

  let data = {
    pick_up: pick_up,
    residence_info_data: residence_info_data,
  };

  let body = {
    body_params: data,
    timestamp: new Date()
  };

  let encryptData = Jwt.sign(body, process.env.VUE_APP_END_TO_END_SECRET_KEY);

  let responseFetch = BeRubyAPI.post('/v2/embedded_forms/residence_info',
    { key_id: subscription_id, payload: encryptData },
    {
      headers: {
        'Authorization': token !== null ? 'Bearer ' + token : '',
      }
    })
    .then((res) => { return res.data })
    .catch((err) => { return err.response.data });

  return responseFetch;
};

export { postResidenceInfoApi };