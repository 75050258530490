const byUrlModul = {
  state: () => ({
    by_url: true,
  }),
  mutations: {
    setbyUrl(state, payload) {
      state.by_url = payload;
    },
  },
  actions: {},
  getters: {
    getbyUrl(state) {
      return state.by_url;
    },
  }
}

export default byUrlModul;