<template>
  <div>
    <!-- <v-container pa-0 fluid>
      <v-sheet
        class="mx-auto"
        max-width="100%"
        tile
        :color="dark ? '#312e2e' : '#262626'"
        :dark="!dark"
        style="width: 100%; height: auto"
      >
        <v-layout
          column
          :style="windowWidth < 768 ? '' : 'margin-right:80px;margin-left:80px'"
        >
          <p v-show="$vuetify.breakpoint.lgAndUp" style="margin-top: 50px"></p>
          <p v-show="$vuetify.breakpoint.mdAndDown"></p>

          <v-col
            v-show="$vuetify.breakpoint.lgAndUp"
            style="margin-bottom: 40px"
          >
            <v-col
              cols="12"
              sm="12"
              :class="
                $vuetify.breakpoint.lgAndUp
                  ? 'mb-16 text-center d-flex justify-center'
                  : 'mb-16 text-center'
              "
            >
              <span
                class="montserrat font-weight-bold mr-3"
                style="
                  color: #fff;
                  font-size: 28px;
                  line-height: 35px;
                  letter-spacing: -0.025em;
                "
                >{{ lang[getLanguage].HOME_ICON_TITLE }}</span
              >
              <img src="/img/home/catl-logo.png" alt="" class="mt-6 mt-md-0" />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              style="
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 24px;
                text-align: center;
                letter-spacing: -0.02em;
                margin-top: -4rem;
              "
              ><a href="https://www.catl.com/en/" target="_blank">{{
                lang[getLanguage].HOME_ICON_DESCRIPTION
              }}</a>
              <v-icon color="primary" right> mdi-chevron-right </v-icon>
            </v-col>
            <v-col cols="12" sm="12">
              <v-layout pt-4 :column="windowWidth < 800" align-center>
                <v-col
                  :style="windowWidth < 800 ? 'max-width:100%' : ''"
                  cols="12"
                  sm="3"
                >
                  <v-layout column align-center>
                    <v-img
                      src="/img/home/zero_maintenance.png"
                      style="width: 80px"
                    />
                    <p class="mt-0"></p>
                    <span class="font-weight-bold">{{
                      lang[getLanguage].LOW_MAINTENANCE_TITLE
                    }}</span>
                    <p class="text-center" style="font-size: 14px">
                      {{ lang[getLanguage].LOW_MAINTENANCE_DESCRIPTION }}
                    </p>
                  </v-layout>
                </v-col>

                <v-col
                  :style="windowWidth < 800 ? 'max-width:100%' : ''"
                  cols="12"
                  sm="3"
                >
                  <v-layout column align-center>
                    <v-img
                      src="/img/home/removable-battery.png"
                      style="width: 80px"
                    />
                    <p class="mt-0"></p>
                    <span class="font-weight-bold">{{
                      lang[getLanguage].PORTABLE_BATTERIES_TITLE
                    }}</span>
                    <p
                      class="text-center"
                      style="font-size: 14px; width: 200px"
                    >
                      {{ lang[getLanguage].PORTABLE_BATTERIES_DESCRIPTION }}
                    </p>
                  </v-layout>
                </v-col>

                <v-col
                  :style="windowWidth < 800 ? 'max-width:100%' : ''"
                  cols="12"
                  sm="3"
                >
                  <v-layout column align-center>
                    <v-img
                      src="/img/home/easy_riding.png"
                      style="width: 80px"
                    />
                    <p class="mt-0"></p>
                    <span class="font-weight-bold">{{
                      lang[getLanguage].EASY_RIDING_TITLE
                    }}</span>
                    <p
                      class="text-center"
                      style="font-size: 14px; width: 240px"
                    >
                      {{ lang[getLanguage].EASY_RIDING_DESCRIPTION }}
                    </p>
                  </v-layout>
                </v-col>

                <v-col
                  :style="windowWidth < 800 ? 'max-width:100%' : ''"
                  cols="12"
                  sm="3"
                >
                  <v-layout column align-center>
                    <v-img src="/img/home/IOT-Icon.svg" style="width: 80px" />
                    <p class="mt-0"></p>
                    <span class="font-weight-bold">{{
                      lang[getLanguage].CONNECTED_TITLE
                    }}</span>
                    <p
                      class="text-center"
                      style="font-size: 14px; width: 200px"
                    >
                      {{ lang[getLanguage].CONNECTED_DESCRIPTION }}
                    </p>
                  </v-layout>
                </v-col>
              </v-layout>
            </v-col>
          </v-col>

          <v-col v-show="$vuetify.breakpoint.mdAndDown">
            <v-col
              cols="12"
              sm="12"
              :class="
                $vuetify.breakpoint.mdAndDown
                  ? 'mb-16 text-center d-flex justify-center'
                  : 'mb-16 text-center'
              "
            >
              <v-col column>
                <span
                  class="montserrat font-weight-bold mr-3"
                  style="
                    color: #fff;
                    font-size: 18px;
                    line-height: 35px;
                    letter-spacing: -0.025em;
                  "
                  >{{ lang[getLanguage].HOME_ICON_TITLE }}</span
                >
                <img
                  src="/img/home/catl-logo.png"
                  alt=""
                  class="mt-6 mt-md-0"
                />
                <br />
                <br />
                <br />
                <br />
                <br />
                <p
                  style="
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    margin-top: -4rem;
                  "
                >
                  <a href="https://www.catl.com/en/" target="_blank">{{
                    lang[getLanguage].HOME_ICON_DESCRIPTION
                  }}</a>
                  <v-icon color="primary" right> mdi-chevron-right </v-icon>
                </p>
              </v-col>
            </v-col>
            <v-col cols="12" sm="12">
              <v-layout :column="windowWidth < 800" align-center>
                <v-row>
                  <v-col
                    :style="
                      windowWidth < 800
                        ? 'max-width:100%; margin-bottom: 36px;'
                        : ''
                    "
                    cols="12"
                    sm="3"
                  >
                    <v-layout column align-center>
                      <v-img
                        src="/img/home/zero_maintenance.png"
                        style="width: 80px"
                      />
                      <p class="mt-0"></p>
                      <span class="font-weight-bold text-center">{{
                        lang[getLanguage].LOW_MAINTENANCE_TITLE
                      }}</span>
                      <p class="text-center" style="font-size: 14px">
                        {{ lang[getLanguage].LOW_MAINTENANCE_DESCRIPTION }}
                      </p>
                    </v-layout>
                  </v-col>

                  <v-col
                    :style="
                      windowWidth < 800
                        ? 'max-width:100%; margin-bottom: 36px;'
                        : ''
                    "
                    cols="12"
                    sm="3"
                  >
                    <v-layout column align-center>
                      <v-img
                        src="/img/home/removable-battery.png"
                        style="width: 80px"
                      />
                      <p class="mt-0"></p>
                      <span class="font-weight-bold text-center">{{
                        lang[getLanguage].PORTABLE_BATTERIES_TITLE
                      }}</span>
                      <p class="text-center" style="font-size: 14px">
                        {{ lang[getLanguage].PORTABLE_BATTERIES_DESCRIPTION }}
                      </p>
                    </v-layout>
                  </v-col>

                  <v-col
                    :style="
                      windowWidth < 800
                        ? 'max-width:100%; margin-bottom: 36px;'
                        : ''
                    "
                    cols="12"
                    sm="3"
                  >
                    <v-layout column align-center>
                      <v-img
                        src="/img/home/easy_riding.png"
                        style="width: 80px"
                      />
                      <p class="mt-0"></p>
                      <span class="font-weight-bold text-center">{{
                        lang[getLanguage].EASY_RIDING_TITLE
                      }}</span>
                      <p class="text-center" style="font-size: 14px">
                        {{ lang[getLanguage].EASY_RIDING_DESCRIPTION }}
                      </p>
                    </v-layout>
                  </v-col>

                  <v-col
                    :style="
                      windowWidth < 800
                        ? 'max-width:100%; margin-bottom: 36px;'
                        : ''
                    "
                    cols="12"
                    sm="3"
                  >
                    <v-layout column align-center justify-center>
                      <v-img src="/img/home/IOT-Icon.svg" style="width: 80px" />
                      <p class="mt-0"></p>
                      <span class="font-weight-bold text-center">{{
                        lang[getLanguage].CONNECTED_TITLE
                      }}</span>
                      <p class="text-center" style="font-size: 14px">
                        {{ lang[getLanguage].CONNECTED_DESCRIPTION }}
                      </p>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-layout>
            </v-col>
          </v-col>
        </v-layout>

        <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
          <v-icon color="green">mdi-check-circle-outline</v-icon>
          <span style="margin-left: 5px">{{ snackbarText }}</span>
          <v-btn text color="#388E3C" @click.native="snackbar = false"
            >Close</v-btn
          >
        </v-snackbar>
      </v-sheet>
    </v-container> -->
    <div class="w-full bg-charged-grey-100 pt-20 pl-5 pr-5 pb-4 md:pt-16">
      <div class="flex flex-wrap justify-center md:pl-14 md:pr-14 items-center">
        <div
          class="font-montserrat text-xl font-bold text-white md:text-2xl lg:text-1xl mb-3 md:mb-4 md:tracking-tight my-auto"
          style="letter-spacing: -0.7px"
        >
          {{ lang[getLanguage].HOME_ICON_TITLE }}
        </div>
        <img
          src="/img/home/catl-logo.png"
          alt=""
          class="overflow-hidden mb-3 md:mb-4"
          height="26px"
          style="height: 26px"
        />
      </div>
      <div class="text-center md:pl-14 md:pr-14">
        <a
          href="https://www.catl.com/en/"
          target="_blank"
          class="font-work-sans px-5 py-1 md:underline text-charged-purple-400"
          style="letter-spacing: -0.32px; color: #A57FB6; !important"
        >
          <span
            class="text-charged-purple-400 font-medium"
            style="letter-spacing: -0.32px"
          >
            {{ lang[getLanguage].HOME_ICON_DESCRIPTION }}
          </span>
          <span class="h-5 w-5 p-1">
            <v-icon
              class="-ml-0.5 text-xl h-5 w-5 -mt-0.5 custom-icon"
              color="primaryLight"
              right
              style="color: #A57FB6; !important;"
            >
              mdi-chevron-right
            </v-icon>
          </span>
        </a>
      </div>
      <div class="flex flex-wrap mt-16 md:pl-14 md:pr-14 justify-between">
        <div class="w-full lg:w-1/4 md:w-1/2 mb-12 sm:mb-0 px-5">
          <v-img
            src="/img/home/zero_maintenance.png"
            class="overflow-hidden w-20 mx-auto mb-6"
          />
          <p
            class="font-montserrat text-lg md:text-xl font-semibold md:font-bold text-center text-white"
          >
            {{ lang[getLanguage].LOW_MAINTENANCE_TITLE }}
          </p>
          <p
            class="font-montserrat text-base font-normal text-white text-center -mt-3"
          >
            {{ lang[getLanguage].LOW_MAINTENANCE_DESCRIPTION }}
          </p>
        </div>
        <div class="w-full lg:w-1/4 md:w-1/2 mb-12 sm:mb-0 px-5">
          <v-img
            src="/img/home/removable-battery.png"
            class="overflow-hidden w-20 mx-auto mb-6"
          />
          <p
            class="font-montserrat text-lg md:text-xl font-semibold md:font-bold text-center text-white"
          >
            {{ lang[getLanguage].PORTABLE_BATTERIES_TITLE }}
          </p>
          <p
            class="font-montserrat text-base font-normal text-white text-center -mt-3"
          >
            {{ lang[getLanguage].PORTABLE_BATTERIES_DESCRIPTION }}
          </p>
        </div>
        <div class="w-full lg:w-1/4 md:w-1/2 mb-12 sm:mb-0 px-5">
          <v-img
            src="/img/home/easy_riding.png"
            class="overflow-hidden w-20 mx-auto mb-6"
          />
          <p
            class="font-montserrat text-lg md:text-xl font-semibold md:font-bold text-center text-white"
          >
            {{ lang[getLanguage].EASY_RIDING_TITLE }}
          </p>
          <p
            class="font-montserrat text-base font-normal text-white text-center -mt-3"
          >
            {{ lang[getLanguage].EASY_RIDING_DESCRIPTION }}
          </p>
        </div>
        <div class="w-full lg:w-1/4 md:w-1/2 mb-12 sm:mb-0 px-5">
          <v-img
            src="/img/home/IOT-Icon.svg"
            class="overflow-hidden w-20 mx-auto mb-6"
          />
          <p
            class="font-montserrat text-lg md:text-xl font-semibold md:font-bold text-center text-white"
          >
            {{ lang[getLanguage].CONNECTED_TITLE }}
          </p>
          <p
            class="font-montserrat text-base font-normal text-white text-center -mt-3"
          >
            {{ lang[getLanguage].CONNECTED_DESCRIPTION }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
  props: {
    dark: Boolean,
    drawer: Boolean,
    windowWidth: Number,
    windowHeight: Number,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
  },
  data() {
    return {
      lang: this.$store.state.lang,
      model: null,
      active: true,
      snackbar: false,
      snackbarText: "",
      logoSrcDark: "",
      logoSrcLight: "",
      logoSrcWalletDark: "",
      logoSrcWalletLight: "",
      logoSrcStexDark: "",
      logoSrcStexLight: "",
      logoSrcCGDark: "",
      logoSrcCGLight: "",
      logoSrcCMCDark: "",
      logoSrcCMCLight: "",
      logoSrcBCDark: "",
      logoSrcBCLight: "",
      srcTikTok: "",
      srcTikTokWhite: "",
      donateAddress: "0x770e725359cd9A3Cf34FEeb832A16969a8D21660",
    };
  },
  components: {},
  watch: {},
  created() {
    // console.log(this.$vuetify.breakpoint.lgAndUp);
    // this.windowWidth = this.$props.windowindowWidth
    this.logoSrcWalletDark = "./img/icons/my_wallet_color_dark.png";
    this.logoSrcWalletLight = "./img/icons/my_wallet_color_light.png";
    this.logoSrcStexDark = "./img/icons/stex_contact_dark.png";
    this.logoSrcStexLight = "./img/icons/stex_contact_light.png";
    this.logoSrcCGDark = "./img/icons/coingecko-dark.png";
    this.logoSrcCGLight = "./img/icons/coingecko-light.png";
    this.logoSrcCMCDark = "./img/icons/cmc_dark.png";
    this.logoSrcCMCLight = "./img/icons/cmc_light.png";
    this.logoSrcBCDark = "./img/icons/bitcourier_dark.png";
    this.logoSrcBCLight = "./img/icons/bitcourier_light.png";
    this.srcTikTok = "./img/icons/tiktok_grey.png";
    this.srcTikTokWhite = "./img/icons/tiktok_white.png";
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    openTerms() {
      this.$emit("termsClicked");
    },
    openPrivacy() {
      this.$emit("privacyClicked");
    },
    handleSuccess(e) {
      console.log(e);
      this.snackbarText = "Contract Address copied.";
      this.snackbar = true;
    },
    handleError(e) {
      console.log(e);
    },
  },
};
</script>

<style>
.contactText {
  font-family: "Montserrat";
  font-size: 44px;
}

.wrapper {
  background: #fafafa;
  display: flex;
  justify-content: center;
}

.sectionHeader {
  font-family: "Comfortaa";
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}

.cardsH {
  background: #fff;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: grid;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}

.cardsV {
  background: #fff;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: table-column;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}

.custom-icon.v-icon.v-icon {
  font-size: 20px;
}
</style>
