const customizationModul = {
  state: () => ({
    colorVehicle: "",
    typeCharge: "",
    qtyBattery: "",
    accessories: "",
  }),
  mutations: {
    setCustomization(state, payload) {
      state.colorVehicle = payload.colorVehicle;
      state.typeCharge = payload.typeCharge;
      state.qtyBattery = payload.qtyBattery;
      state.accessories = payload.accessories;
    },
  },
  actions: {},
  getters: {
    getCustomization(state) {
      return state;
    },
  }
}

export default customizationModul;