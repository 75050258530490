const dataStepDoneModul = {
  state: () => ({
    isDone: false
  }),
  mutations: {
    setDataStepDone(state, payload) {
      state.isDone = payload;
    },
  },
  actions: {},
  getters: {
    getDataStepDone(state) {
      return state.isDone;
    },
  }
}

export default dataStepDoneModul;