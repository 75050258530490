export const REGENCIES = [
    {
        "name": "Kebayoran Baru",
        "city": "Kota Jakarta Selatan",
        "state": "regency"
    },
    {
        "name": "Kebayoran Lama",
        "city": "Kota Jakarta Selatan",
        "state": "regency"
    },
    {
        "name": "Pesanggrahan",
        "city": "Kota Jakarta Selatan",
        "state": "regency"
    },
    {
        "name": "Cilandak",
        "city": "Kota Jakarta Selatan",
        "state": "regency"
    },
    {
        "name": "Pasar Minggu",
        "city": "Kota Jakarta Selatan",
        "state": "regency"
    },
    {
        "name": "Jagakarsa",
        "city": "Kota Jakarta Selatan",
        "state": "regency"
    },
    {
        "name": "Mampang Prapatan",
        "city": "Kota Jakarta Selatan",
        "state": "regency"
    },
    {
        "name": "Pancoran",
        "city": "Kota Jakarta Selatan",
        "state": "regency"
    },
    {
        "name": "Tebet",
        "city": "Kota Jakarta Selatan",
        "state": "regency"
    },
    {
        "name": "Setia Budi",
        "city": "Kota Jakarta Selatan",
        "state": "regency"
    },
    {
        "name": "Cilincing",
        "city": "Kota Jakarta Utara",
        "state": "regency"
    },
    {
        "name": "Koja",
        "city": "Kota Jakarta Utara",
        "state": "regency"
    },
    {
        "name": "Kelapa Gading",
        "city": "Kota Jakarta Utara",
        "state": "regency"
    },
    {
        "name": "Tanjung Priok",
        "city": "Kota Jakarta Utara",
        "state": "regency"
    },
    {
        "name": "Pademangan",
        "city": "Kota Jakarta Utara",
        "state": "regency"
    },
    {
        "name": "Penjaringan",
        "city": "Kota Jakarta Utara",
        "state": "regency"
    },
    {
        "name": "Matraman",
        "city": "Kota Jakarta Timur",
        "state": "regency"
    },
    {
        "name": "Pulo Gadung",
        "city": "Kota Jakarta Timur",
        "state": "regency"
    },
    {
        "name": "Jatinegara",
        "city": "Kota Jakarta Timur",
        "state": "regency"
    },
    {
        "name": "Duren Sawit",
        "city": "Kota Jakarta Timur",
        "state": "regency"
    },
    {
        "name": "Kramat Jati",
        "city": "Kota Jakarta Timur",
        "state": "regency"
    },
    {
        "name": "Makasar",
        "city": "Kota Jakarta Timur",
        "state": "regency"
    },
    {
        "name": "Pasar Rebo",
        "city": "Kota Jakarta Timur",
        "state": "regency"
    },
    {
        "name": "Ciracas",
        "city": "Kota Jakarta Timur",
        "state": "regency"
    },
    {
        "name": "Cipayung",
        "city": "Kota Jakarta Timur",
        "state": "regency"
    },
    {
        "name": "Cakung",
        "city": "Kota Jakarta Timur",
        "state": "regency"
    },
    {
        "name": "Taman Sari",
        "city": "Kota Jakarta Barat",
        "state": "regency"
    },
    {
        "name": "Tambora",
        "city": "Kota Jakarta Barat",
        "state": "regency"
    },
    {
        "name": "Palmerah",
        "city": "Kota Jakarta Barat",
        "state": "regency"
    },
    {
        "name": "Grogol Petamburan",
        "city": "Kota Jakarta Barat",
        "state": "regency"
    },
    {
        "name": "Kebon Jeruk",
        "city": "Kota Jakarta Barat",
        "state": "regency"
    },
    {
        "name": "Kembangan",
        "city": "Kota Jakarta Barat",
        "state": "regency"
    },
    {
        "name": "Cengkareng",
        "city": "Kota Jakarta Barat",
        "state": "regency"
    },
    {
        "name": "Kalideres",
        "city": "Kota Jakarta Barat",
        "state": "regency"
    },
    {
        "name": "Gambir",
        "city": "Kota Jakarta Pusat",
        "state": "regency"
    },
    {
        "name": "Tanah Abang",
        "city": "Kota Jakarta Pusat",
        "state": "regency"
    },
    {
        "name": "Menteng",
        "city": "Kota Jakarta Pusat",
        "state": "regency"
    },
    {
        "name": "Senen",
        "city": "Kota Jakarta Pusat",
        "state": "regency"
    },
    {
        "name": "Cempaka Putih",
        "city": "Kota Jakarta Pusat",
        "state": "regency"
    },
    {
        "name": "Johar Baru",
        "city": "Kota Jakarta Pusat",
        "state": "regency"
    },
    {
        "name": "Kemayoran",
        "city": "Kota Jakarta Pusat",
        "state": "regency"
    },
    {
        "name": "Sawah Besar",
        "city": "Kota Jakarta Pusat",
        "state": "regency"
    },
    {
        "name": "Cigombong",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Bogor Barat - Kota",
        "city": "Kota Bogor",
        "state": "regency"
    },
    {
        "name": "Ciseeng",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Bogor Tengah - Kota",
        "city": "Kota Bogor",
        "state": "regency"
    },
    {
        "name": "Bogor Selatan - Kota",
        "city": "Kota Bogor",
        "state": "regency"
    },
    {
        "name": "Bogor Timur - Kota",
        "city": "Kota Bogor",
        "state": "regency"
    },
    {
        "name": "Bogor Utara - Kota",
        "city": "Kota Bogor",
        "state": "regency"
    },
    {
        "name": "Tanah Sereal",
        "city": "Kota Bogor",
        "state": "regency"
    },
    {
        "name": "Kemang",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Ranca Bungur",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Tajurhalang",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Parung",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Gunung Sindur",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Rumpin",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Parung Panjang",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Tenjo",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Tenjolaya",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Ciomas",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Tamansari",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Ciampea",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Cibungbulang",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Leuwisadeng",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Leuwiliang",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Nanggung",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Cigudeg",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Sukajaya",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Jasinga",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Dramaga",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Klapa Nunggal (Kelapa Nunggal)",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Sukaraja",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Ciawi",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Caringin",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Cijeruk",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Cisarua",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Megamendung",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Babakan Madang",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Pamijahan",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Citeureup",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Cileungsi",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Jonggol",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Sukamakmur",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Tanjungsari",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Cariu",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Cibinong",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Bojonggede",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Gunung Putri",
        "city": "Kab. Bogor",
        "state": "regency"
    },
    {
        "name": "Sukmajaya",
        "city": "Kota Depok",
        "state": "regency"
    },
    {
        "name": "Cilodong",
        "city": "Kota Depok",
        "state": "regency"
    },
    {
        "name": "Beji",
        "city": "Kota Depok",
        "state": "regency"
    },
    {
        "name": "Pancoran Mas",
        "city": "Kota Depok",
        "state": "regency"
    },
    {
        "name": "Tapos",
        "city": "Kota Depok",
        "state": "regency"
    },
    {
        "name": "Cimanggis",
        "city": "Kota Depok",
        "state": "regency"
    },
    {
        "name": "Sawangan",
        "city": "Kota Depok",
        "state": "regency"
    },
    {
        "name": "Cinere",
        "city": "Kota Depok",
        "state": "regency"
    },
    {
        "name": "Limo",
        "city": "Kota Depok",
        "state": "regency"
    },
    {
        "name": "Bojongsari",
        "city": "Kota Depok",
        "state": "regency"
    },
    {
        "name": "Cipayung",
        "city": "Kota Depok",
        "state": "regency"
    },
    {
        "name": "Tangerang",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Karawaci",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Batuceper",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Neglasari",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Benda",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Periuk",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Jatiuwung",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Cibodas",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Cipondoh",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Pinang (Penang)",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Ciledug",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Larangan",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Karang Tengah",
        "city": "Kota Tangerang",
        "state": "regency"
    },
    {
        "name": "Pagedangan",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Kosambi",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Cisauk",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Teluknaga",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Sepatan Timur",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Sepatan",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Mauk",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Sukadiri",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Kemiri",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Sindang Jaya",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Rajeg",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Kronjo",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Mekar Baru",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Pasar Kemis",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Pakuhaji",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Balaraja",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Sukamulya",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Jayanti",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Gunung Kaler",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Kresek",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Cikupa",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Panongan",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Jambe",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Tigaraksa",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Cisoka",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Solear",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Kelapa Dua",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Curug",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Legok",
        "city": "Kab. Tangerang",
        "state": "regency"
    },
    {
        "name": "Pondok Aren",
        "city": "Kota Tangerang Selatan",
        "state": "regency"
    },
    {
        "name": "Serpong",
        "city": "Kota Tangerang Selatan",
        "state": "regency"
    },
    {
        "name": "Setu",
        "city": "Kota Tangerang Selatan",
        "state": "regency"
    },
    {
        "name": "Serpong Utara",
        "city": "Kota Tangerang Selatan",
        "state": "regency"
    },
    {
        "name": "Ciputat",
        "city": "Kota Tangerang Selatan",
        "state": "regency"
    },
    {
        "name": "Ciputat Timur",
        "city": "Kota Tangerang Selatan",
        "state": "regency"
    },
    {
        "name": "Pamulang",
        "city": "Kota Tangerang Selatan",
        "state": "regency"
    },
    {
        "name": "Bekasi Timur",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Rawalumbu",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Bekasi Utara",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Medan Satria",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Bekasi Barat",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Bekasi Selatan",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Bantar Gebang",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Mustika Jaya",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Tarumajaya",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Serang Baru",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Cibarusah",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Bojongmangu",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Pondok Gede",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Pondok Melati",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Jatiasih",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Jati Sampurna",
        "city": "Kota Bekasi",
        "state": "regency"
    },
    {
        "name": "Tambun Utara",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Tambun Selatan",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Cibitung",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Cikarang Selatan",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Cikarang Pusat",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Cikarang Utara",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Cikarang Barat",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Cikarang Timur",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Karangbahagia",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Kedung Waringin",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Babelan",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Tambelang",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Sukawangi",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Sukatani",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Sukakarya",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Pebayuran",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Cabangbungin",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    {
        "name": "Muara Gembong",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    // Start Add Setu In Kab. Bekasi
    {
        "name": "Setu",
        "city": "Kab. Bekasi",
        "state": "regency"
    },
    // End Add Setu In Kab. Bekasi
    {
        "name": "Mengwi",
        "city": "Kab. Badung",
        "state": "regency"
    },
    {
        "name": "Abiansemal",
        "city": "Kab. Badung",
        "state": "regency"
    },
    {
        "name": "Petang",
        "city": "Kab. Badung",
        "state": "regency"
    },
    {
        "name": "Kuta Selatan",
        "city": "Kab. Badung",
        "state": "regency"
    },
    {
        "name": "Kuta Utara",
        "city": "Kab. Badung",
        "state": "regency"
    },
    {
        "name": "Kuta",
        "city": "Kab. Badung",
        "state": "regency"
    },
    {
        "name": "Bangli",
        "city": "Kab. Bangli",
        "state": "regency"
    },
    {
        "name": "Kintamani",
        "city": "Kab. Bangli",
        "state": "regency"
    },
    {
        "name": "Susut",
        "city": "Kab. Bangli",
        "state": "regency"
    },
    {
        "name": "Tembuku",
        "city": "Kab. Bangli",
        "state": "regency"
    },
    {
        "name": "Buleleng",
        "city": "Kab. Buleleng",
        "state": "regency"
    },
    {
        "name": "Seririt",
        "city": "Kab. Buleleng",
        "state": "regency"
    },
    {
        "name": "Busungbiu",
        "city": "Kab. Buleleng",
        "state": "regency"
    },
    {
        "name": "Gerokgak",
        "city": "Kab. Buleleng",
        "state": "regency"
    },
    {
        "name": "Sukasada",
        "city": "Kab. Buleleng",
        "state": "regency"
    },
    {
        "name": "Sawan",
        "city": "Kab. Buleleng",
        "state": "regency"
    },
    {
        "name": "Kubutambahan",
        "city": "Kab. Buleleng",
        "state": "regency"
    },
    {
        "name": "Tejakula",
        "city": "Kab. Buleleng",
        "state": "regency"
    },
    {
        "name": "Denpasar Utara",
        "city": "Kota Denpasar",
        "state": "regency"
    },
    {
        "name": "Denpasar Barat",
        "city": "Kota Denpasar",
        "state": "regency"
    },
    {
        "name": "Denpasar Selatan",
        "city": "Kota Denpasar",
        "state": "regency"
    },
    {
        "name": "Denpasar Timur",
        "city": "Kota Denpasar",
        "state": "regency"
    },
    {
        "name": "Gianyar",
        "city": "Kab. Gianyar",
        "state": "regency"
    },
    {
        "name": "Tampak Siring",
        "city": "Kab. Gianyar",
        "state": "regency"
    },
    {
        "name": "Tegallalang",
        "city": "Kab. Gianyar",
        "state": "regency"
    },
    {
        "name": "Ubud",
        "city": "Kab. Gianyar",
        "state": "regency"
    },
    {
        "name": "Payangan",
        "city": "Kab. Gianyar",
        "state": "regency"
    },
    {
        "name": "Belah Batuh (Blahbatuh)",
        "city": "Kab. Gianyar",
        "state": "regency"
    },
    {
        "name": "Sukawati",
        "city": "Kab. Gianyar",
        "state": "regency"
    },
    {
        "name": "Jembrana",
        "city": "Kab. Jembrana",
        "state": "regency"
    },
    {
        "name": "Negara",
        "city": "Kab. Jembrana",
        "state": "regency"
    },
    {
        "name": "Melaya",
        "city": "Kab. Jembrana",
        "state": "regency"
    },
    {
        "name": "Mendoyo",
        "city": "Kab. Jembrana",
        "state": "regency"
    },
    {
        "name": "Pekutatan",
        "city": "Kab. Jembrana",
        "state": "regency"
    },
    {
        "name": "Karang Asem",
        "city": "Kab. Karangasem",
        "state": "regency"
    },
    {
        "name": "Abang",
        "city": "Kab. Karangasem",
        "state": "regency"
    },
    {
        "name": "Kubu",
        "city": "Kab. Karangasem",
        "state": "regency"
    },
    {
        "name": "Bebandem",
        "city": "Kab. Karangasem",
        "state": "regency"
    },
    {
        "name": "Rendang",
        "city": "Kab. Karangasem",
        "state": "regency"
    },
    {
        "name": "Sidemen",
        "city": "Kab. Karangasem",
        "state": "regency"
    },
    {
        "name": "Manggis",
        "city": "Kab. Karangasem",
        "state": "regency"
    },
    {
        "name": "Klungkung",
        "city": "Kab. Klungkung",
        "state": "regency"
    },
    {
        "name": "Banjarangkan",
        "city": "Kab. Klungkung",
        "state": "regency"
    },
    {
        "name": "Dawan",
        "city": "Kab. Klungkung",
        "state": "regency"
    },
    {
        "name": "Nusapenida",
        "city": "Kab. Klungkung",
        "state": "regency"
    },
    {
        "name": "Tabanan",
        "city": "Kab. Tabanan",
        "state": "regency"
    },
    {
        "name": "Penebel",
        "city": "Kab. Tabanan",
        "state": "regency"
    },
    {
        "name": "Kerambitan",
        "city": "Kab. Tabanan",
        "state": "regency"
    },
    {
        "name": "Selemadeg / Salemadeg Barat",
        "city": "Kab. Tabanan",
        "state": "regency"
    },
    {
        "name": "Selemadeg",
        "city": "Kab. Tabanan",
        "state": "regency"
    },
    {
        "name": "Selemadeg / Salamadeg Timur",
        "city": "Kab. Tabanan",
        "state": "regency"
    },
    {
        "name": "Pupuan",
        "city": "Kab. Tabanan",
        "state": "regency"
    },
    {
        "name": "Marga",
        "city": "Kab. Tabanan",
        "state": "regency"
    },
    {
        "name": "Baturiti",
        "city": "Kab. Tabanan",
        "state": "regency"
    }
]
