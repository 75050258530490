export const Senta = {
  vehicle_id: process.env.VUE_APP_ID_VEHICLE_SENTA,
  name: "SENTA",
  nameLowercase: "senta",
  slug: "senta",
  folder: "vs1",
  description: "One Trip - deliver double loading of normal delivery.",
  image: "img/shared/bikes/senta/slide-1.png",
  imageNavbar: "img/navbar/senta-navbar.png",
  motor: "Electric Motor - Super Soco 4000W",
  autonomy: "Autonomy 160 Km (with L1e specification)",
  speed: "110",
  topSpeed: "110",
  maxDistance: "104",
  maximumRange: "104",
  maxDistanceUnit: "km",
  battery: 1,
  recharge:
    "Recharge 3.5 h at 60V15A (fast charge with single battery)",
  count: 12,
  price: "Rp73.000.000",
  subPrice: null,
  batterySubscription: null,
  dailyPrice: 43000,
  stock: 1,
  colorOptions: ["#377BFF"],
  colorOptionsDes: [
    {
      hex: '#377BFF', name: 'Blue'
    },
  ],
  productTagLine: {
    en: {
      title: 'Agile Precision on Every Terrain',
      desctiption: "Designed with ergonomic precision, Senta's layout enhances rider control guaranteeing safe and enjoyable riding."
    },
    id: {
      title: 'Agile Precision on Every Terrain',
      desctiption: "Designed with ergonomic precision, Senta's layout enhances rider control, guaranteeing safe and enjoyable riding."
    }
  },
  performanceAndSafetyDesc: {
    en: "<p>Senta features a die-cast aluminum frame and swingarm, providing robust structural integrity for durability on any terrain.</p> <p>Simply put, you can ride it as a powerful off-road EV or effortlessly navigate city streets with its versatile design—a true all-in-one vehicle for any terrain.</p> <p>Plus, with three dynamic riding modes—low, medium, and high speed—you can effortlessly adapt to any riding condition and preference.</p>",
    id: "<p>Senta dilengkapi dengan rangka aluminium die-cast dan swingarm, memberikan integritas struktural yang kuat untuk daya tahan di segala medan.</p> <p>Sederhananya, Anda dapat mengendarainya sebagai EV off-road yang tangguh atau dengan mudah menavigasi jalanan kota dengan desain serbaguna-kendaraan serba guna untuk segala medan.</p><p>Ditambah lagi, dengan tiga mode berkendara yang dinamis-kecepatan rendah, sedang, dan tinggi-Anda dapat dengan mudah beradaptasi dengan kondisi dan preferensi berkendara apa pun.</p>"
  },
  convenienceDesc: {
    en: "<p>Senta provides a premium and convenient suspension system, featuring:</p><ul class='list-disc'><li><p>Front Suspension: A high-travel upside-down fork with adjustable preload and rebound settings, designed to enhance control and comfort.</p></li><li><p>Rear Suspension: A fully adjustable Nitrox Monoshock with linkage, engineered for optimal performance.</p></li></ul>",
    id: "<p>Senta menyediakan sistem suspensi yang premium dan nyaman, dengan fitur:</p><ul class='list-disc'><li><p>Suspensi Depan: Garpu upside-down dengan travel tinggi dengan pengaturan preload dan rebound yang dapat disesuaikan, yang dirancang untuk meningkatkan kontrol dan kenyamanan.</p></li><li><p>Suspensi Belakang: Nitrox Monoshock yang dapat disetel sepenuhnya dengan linkage, dirancang untuk performa optimal.</p></li></ul>"
  },
  intelegencyDesc: {
    en: "<P>Senta's regenerative battery system is a game-changer. When you ease off the throttle, it kicks into action, charging the battery while providing the rider with the sensation of engine braking, delivering incredible control.</p><p>Additionally, Senta is equipped with a high-performance air cooling system, providing an eightfold improvement in thermal stability.</p>",
    id: "<P>Sistem baterai regeneratif Senta adalah pengubah permainan. Ketika Anda mengurangi gas, sistem ini akan beraksi, mengisi daya baterai sekaligus memberikan sensasi pengereman mesin kepada pengendara, memberikan kontrol yang luar biasa.</p><p>Selain itu, Senta dilengkapi dengan sistem pendingin udara berkinerja tinggi, yang memberikan peningkatan delapan kali lipat dalam stabilitas termal.</p>"
  },
  isRelease: true,
  typeProduct: "new_model",
  specificationDesc: {
    en: "*Data collected from real world testing on Indonesian roads, with a single rider of 70kg, not exceeding speeds of 50km/h. Your results may vary depending on riding conditions.",
    id: "*Data dikumpulkan dari pengujian di jalan raya di Indonesia, dengan pengendara tunggal berbobot 70kg, tidak melebihi kecepatan 50km/jam. Hasil yang Anda peroleh dapat bervariasi tergantung pada kondisi berkendara."
  },
  specification: {
    keys: {
      en: [
        {
          description: "L*W*H",
          details: "1975*720*1132 mm",
        },
        {
          description: "Wheelbase",
          details: "1365 mm",
        },
        {
          description: "Ground Clearance",
          details: "145 mm",
        },
        {
          description: "Seat Height",
          details: "760 mm",
        },
        {
          description: "Net Weight",
          details: "135 kg",
        },
        {
          description: "Max Load",
          details: "150 kg (rider excluded)",
        },
      ],
      id: [
        {
          description: "P*L*T",
          details: "1975*720*1132 mm",
        },
        {
          description: "Jarak Sumbu Roda",
          details: "1365 mm",
        },
        {
          description: "Min. Ground Clearance",
          details: "145 mm",
        },
        {
          description: "Tinggi Tempat Duduk",
          details: "760 mm",
        },
        {
          description: "Bobot Bersih",
          details: "135 kg",
        },
        {
          description: "Beban Maksimal",
          details: "150 kg (pengendara tidak termasuk)",
        },
      ],
    },
    powertrain: {
      en: [
        // {
        //   description: "Range",
        //   details: "200 km",
        // },
        {
          description: "Charge time (per battery)",
          details: "3.5 Hrs (10%-100%)",
        },
        {
          description: "Torque (peak)",
          details: "305.73 N.m",
        },
        // {
        //   description: "Top Speed",
        //   details: "90 Km/h",
        // },
      ],
      id: [
        // {
        //   description: "Jangkauan",
        //   details: "200 km",
        // },
        {
          description: "Waktu pengisian daya (per baterai)",
          details: "3.5 Jam (10%-100%)",
        },
        {
          description: "Torsi (puncaknya)",
          details: "305.73 N.m",
        },
        // {
        //   description: "Kecepatan Tertinggi",
        //   details: "90 Km/h",
        // },
      ],
    },
    other: {
      en: [
        {
          description: "Brakes (F)",
          details: "CBS, Disc, 240 mm",
        },
        {
          description: "Brakes (R)",
          details: "CBS, Disc, 180 mm",
        },
        {
          description: "Front Suspension Travel",
          details: "-",
        },
        {
          description: "Rear Suspension Travel",
          details: "-",
        },
        {
          description: "Front Tyre Size",
          details: "70/100-19",
        },
        {
          description: "Rear Tyre Size",
          details: "90/100-16",
        },
        {
          description: "Lighting",
          details: "F/R LED",
        },
        {
          description: "Battery",
          details: "Single, Permanent",
        },
      ],
      id: [
        {
          description: "Rem (Depan)",
          details: " CBS, Cakram, 240mm",
        },
        {
          description: "Rem (Belakang)",
          details: " CBS, Cakram, 180mm",
        },
        {
          description: "Travel Suspensi Depan",
          details: "-",
        },
        {
          description: "Travel Suspensi Belakang",
          details: "-",
        },
        {
          description: "Ukuran Ban Depan",
          details: "70/100-19",
        },
        {
          description: "Ukuran Ban Belakang",
          details: "90/100-16",
        },
        {
          description: "Pencahayaan",
          details: "LED (Depan, Belakang)",
        },
        {
          description: "Baterai",
          details: "Single, Tidak Dapat Dilepas Pasang",
        },
      ],
    }
  },
  priceDescription: {
    en: 'Price refer to OTR Jakarta, includes VAT, spare parts warranty and all on the road costs. Exclude government subsidies. Terms and conditions applied.',
    id: 'Harga mengacu pada OTR Jakarta, sudah termasuk PPN, garansi suku cadang dan biaya on the road. Tidak termasuk subsidi pemerintah. Syarat dan ketentuan berlaku.',
  },
  actualPrice: '',
  text: {
    otr: {
      en: 'Off The Road',
      id: 'Off The Road'
    },
    includeInOtr: "(Unit, Battery, & Charger)",
    includeInTheOtrPrice: {
      en: 'Included in the Off The Road price:',
      id: 'Sudah termasuk dalam harga Off The Road:',
    },
    includeInTheOtrDescription: {
      en: [
        {
          name: 'Unit with 1 battery',
          description: 'Senta has 1 batteries capacity'
        },
        {
          name: 'Standard Charger',
          description: 'Standard Charger, 10% to 100% in 3.5 hours time'
        },
      ],
      id: [
        {
          name: 'Unit dengan 1 baterai',
          description: 'Senta memiliki 1 kapasitas baterai'
        },
        {
          name: 'Pengisi Daya Standar',
          description: 'Pengisi Daya Standar, 10% hingga 100% dalam waktu 3,5 jam'
        },
      ]
    },
    btnInterested: {
      en: "I'm interested",
      id: "Saya tertarik"
    }
  }
}