import { BeRubyAPI } from "../../../clients/BERubyAPI";
import Jwt from "jsonwebtoken";

const postPersonalInfoApi = (token, { subscription_id, guardian_name, marital_status, employment, work_email, work_mobile_no, nik, birth_date, birth_place }) => {
  let data = '';
  if (work_email && work_mobile_no) {
    data = {
      profile: {
        guardian_name: guardian_name,
        marital_status: marital_status,
        nik,
        birth_place,
        birth_date,
      },
      employment: employment,
      users: {
        work_email,
        work_mobile_no,
      }
    };
  } else {
    data = {
      profile: {
        guardian_name: guardian_name,
        marital_status: marital_status,
        nik,
        birth_place,
        birth_date,
      },
      employment: employment,
      users: {
        work_email: '',
        work_mobile_no: '',
      }
    };
  }

  let body = {
    body_params: data,
    timestamp: new Date()
  };

  let encryptData = Jwt.sign(body, process.env.VUE_APP_END_TO_END_SECRET_KEY);

  let responseFetch = BeRubyAPI.post('/v2/embedded_forms/customer_info',
    { key_id: subscription_id, payload: encryptData },
    {
      headers: {
        'Authorization': token !== null ? 'Bearer ' + token : '',
      }
    })
    .then((res) => { return res.data })
    .catch((err) => { return err.response.data });

  return responseFetch;
};

export { postPersonalInfoApi };