<template>
  <div class="container mx-0 flex justify-center">
    <div class="w-full bg-white hidden md:table pt-10 pb-10 mx-auto">
      <div
        class="text-1xl text-center font-bold -mb-8 text-charged-grey-100"
        style="letter-spacing: -0.7px"
      >
        {{ lang[getLanguage].HOME_SUBSCRIBE_TITLE }}
      </div>

      <div class="stepper-wrapper container">
        <v-row style="margin: 0" class="gap-x-2">
          <v-col>
            <div class="stepper-item completed">
              <img src="/img/home/step/order-step.png" />
              <div class="work-sans step-counter step-counter-line white--text">
                1
              </div>
              <div
                class="work-sans step-name font-semibold mt-3 text-charged-grey-100"
                style="letter-spacing: -0.32px"
              >
                {{ lang[getLanguage].ORDER_TITLE }}
              </div>
              <p
                class="work-sans text-center mt-4 text-base font-normal text-charged-grey-300"
                style="letter-spacing: -0.32px"
              >
                {{ lang[getLanguage].ORDER_DESCRIPTION }}
              </p>
            </div>
          </v-col>
          <v-col>
            <div class="stepper-item completed">
              <img src="/img/home/step/submit-step.png" />
              <div class="work-sans step-counter step-counter-line white--text">
                2
              </div>
              <div
                class="work-sans step-name font-semibold mt-3 text-charged-grey-100"
                style="letter-spacing: -0.32px"
              >
                {{ lang[getLanguage].SUBMIT_DOCUMENTS_TITLE }}
              </div>
              <p
                class="work-sans text-center mt-4 text-base font-normal text-charged-grey-300"
                style="letter-spacing: -0.32px"
              >
                {{ lang[getLanguage].SUBMIT_DOCUMENTS_DESCRIPTION }}
              </p>
            </div>
          </v-col>
          <v-col>
            <div class="stepper-item completed">
              <img src="/img/home/step/payment-step.png" />
              <div class="work-sans step-counter white--text">3</div>
              <div
                class="work-sans step-name font-semibold mt-3 text-charged-grey-100"
                style="letter-spacing: -0.32px"
              >
                {{ lang[getLanguage].MAKE_PAYMENT_TITLE }}
              </div>
              <p
                class="work-sans text-center mt-4 text-base font-normal text-charged-grey-300"
                style="letter-spacing: -0.32px"
              >
                {{ lang[getLanguage].MAKE_PAYMENT_DESCRIPTION }}
              </p>
            </div>
          </v-col>
          <v-col>
            <div class="stepper-item completed">
              <img src="/img/home/step/collect-step.png" />
              <div
                class="work-sans step-counter step-counter-line-last white--text"
              >
                4
              </div>
              <div
                class="work-sans step-name font-semibold mt-3 text-charged-grey-100"
                style="letter-spacing: -0.32px"
              >
                {{ lang[getLanguage].COLLECT_OR_RECEIVE_YOUR_BIKE_TITLE }}
              </div>
              <p
                class="work-sans text-center mt-4 text-base font-normal text-charged-grey-300"
                style="letter-spacing: -0.32px"
              >
                {{ lang[getLanguage].COLLECT_OR_RECEIVE_YOUR_BIKE_DESCRIPTION }}
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowToSubs",
  data() {
    return {
      lang: this.$store.state.lang,
    };
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
  },
};
</script>

<style >
.step-counter-line {
  position: relative;
  z-index: 1 !important;
}
.step-counter-line::before {
  content: "";
  position: absolute;
  width: 23vw;
  height: 2px;
  left: 0;
  margin-left: 30px;
  background: #6b4693;
  z-index: -1;
}
.step-counter-line-last {
  position: relative;
  z-index: 1 !important;
}
.step-counter-line-last::before {
  content: "";
  position: absolute;
  width: 24.4vw;
  height: 2px;
  right: 0;
  margin-left: 30px;
  background: #6b4693;
  z-index: -1;
}
</style>
