<template>
  <div>
    <v-alert style="width: 100%" dense type="error" :value="alert">
      <span v-if="!SMSsent">
        {{ lang[getLanguage].SMS_ERROR }}
        <br />
        <!-- {{
          lang[getLanguage].CHECK_NR
        }} -->
        TOO_MANY_ATTEMPTS_TRY_LATER
      </span>
    </v-alert>
    <!-- section input number for send OTP -->
    <v-card
      flat
      :class="!SMSsent ? 'pa-2' : 'hidden'"
      style="height: 100%; width: 100%"
      :dark="dark"
    >
      <v-layout :class="!isMobileDevice ? 'form-signup' : 'form-signup-mobile'">
        <v-row class="mb-2">
          <v-col>
            <span v-if="!SMSsent" class="text-2xl font-bold font-montserrat">
              {{ lang[getLanguage].BUT_SIGN_IN }}
              <span class="-ml-1.5 font-montserrat">
                {{ getLanguage == "en" ? "/Register" : "/Daftar" }}
              </span>
            </span>
          </v-col>
          <v-col class="text-right pr-1" cols="2">
            <v-btn @click="signupDialogClosed" right icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div :class="!isMobileDevice ? '-mt-2' : 'phone-mobile'">
          <span
            class="login-label mb-1.5 inline-block font-semibold text-charged-grey-400"
            v-if="!SMSsent"
          >
            {{ lang[getLanguage].SIGNUP_PHONE }}
          </span>
          <VuePhoneNumberInput
            v-model="phoneNo"
            default-country-code="ID"
            @update="keyPressed"
            v-if="!SMSsent"
          />
          <!-- <v-text-field
            class="custom-combobox-d mt-4"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            style="width: 100%"
            outlined
            dense
            @click:append="showPass = !showPass"
          >
          </v-text-field> -->
        </div>
        <p v-if="!SMSsent" class="mt-4"></p>
        <p v-if="!SMSsent && getPlatform !== 'NaN'" class="mt-2"></p>
        <v-btn
          large
          style="width: 100%"
          @click="clickNext"
          :loading="loading"
          :disabled="!phoneNoValid || loading"
          class="submit-button rounded-lg"
        >
          {{ lang[getLanguage].SIGNUP_SUBMIT }}
        </v-btn>
        <span class="login-label-policy mt-5" v-if="!SMSsent">
          {{ lang[getLanguage].SIGNUP_DESCRIPTION }}
        </span>
      </v-layout>
    </v-card>

    <!-- if sms is send -->
    <v-dialog
      v-model="SMSsent"
      persistent
      max-width="500"
      :fullscreen="windowWidth < 770"
    >
      <v-alert style="width: 100%" dense type="error" :value="alert">
        <span v-if="otp.length !== 6 && SMSsent"
          >{{ lang[getLanguage].INVALID_CODE }}<br />{{
            lang[getLanguage].CODE_MUST_BE
          }}</span
        >
        <span v-else-if="SMSsent">{{ lang[getLanguage].INV_CODE }}</span>
        <span v-else-if="!SMSsent"
          >{{ lang[getLanguage].SMS_ERROR }}<br />{{
            lang[getLanguage].CHECK_NR
          }}</span
        >
      </v-alert>
      <v-card
        flat
        :class="SMSsent ? 'pa-2' : 'hidden'"
        style="height: 100%; width: 100%"
        :dark="dark"
      >
        <v-layout
          :class="!isMobileDevice ? 'form-signup' : 'form-signup-mobile'"
        >
          <v-row class="mb-2" align="center">
            <v-col cols="12" md="1" class="pl-0">
              <v-btn @click="SMSsent = false" icon>
                <v-icon v-if="windowWidth < 770" style="color: black !important"
                  >mdi-arrow-left</v-icon
                >
                <v-icon v-if="windowWidth > 770" style="color: black !important"
                  >mdi-chevron-left</v-icon
                >
              </v-btn>
            </v-col>
            <v-col>
              <span
                style="font-size: 18px"
                class="font-weight-bold"
                v-if="SMSsent"
                >{{ lang[getLanguage].THE_CODE }}</span
              >
            </v-col>
            <v-col class="text-right pr-1 hidden md:inline-block" cols="2">
              <v-btn @click="dialogVerifyClose" right icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-col cols="12" md="11" class="p-0">
            <span
              class="subheading font-work-sans text-base font-normal text-charged-grey-200"
              v-if="SMSsent"
              >{{ lang[getLanguage].SMS_SENT + this.phoneNoFormatted }}</span
            >
          </v-col>

          <v-layout column align-center pa-2>
            <!-- <v-text-field
              class="headline"
              v-if="SMSsent"
              v-model="otp"
              outlined
              type="tel"
              color="deep-purple darken-4"
              maxlength="6"
              mask="######"
              :label="lang[getLanguage].ENTER_CODE"
              append-icon="mdi-message-processing-outline"
              @input="change1"
            ></v-text-field> -->
            <span class="md:hidden my-5"></span>
            <div class="relative my-6">
              <input
                v-for="(n, i) in lengthOtp"
                v-model="otpArr[i]"
                :key="i"
                ref="otpInput"
                type="text"
                class="w-6 text-center mx-1 font-semibold focus:outline-none border-none"
                style="border-bottom: 2px solid #858688"
                maxlength="1"
                pattern="[0-9]"
                :autofocus="i == 0"
                @input="handleInputOtp(i, $event)"
                @keydown="handleKeyDownOtp(i, $event)"
              />
            </div>
            <span class="md:hidden my-5"></span>
          </v-layout>
          <span
            class="subheading text-charged-grey-200 text-base font-work-sans font-normal"
            v-if="SMSsent"
            >{{ lang[getLanguage].DIDNT_GET_CODE }}
            <a
              class="underline text-base font-work-sans font-medium"
              @click="resendOtp"
              >{{ lang[getLanguage].RE_SEND }}</a
            >
            <!-- <v-btn
              text
              v-if="SMSsent"
              @click="resendOtp"
              style="
                padding: 0 0.5rem;
                color: #2962ff;
                margin-left: -10px;
                margin-top: -4px;
                font-weight: normal;
                font-size: 16px;
                text-transform: none !important;
              "
              >{{ lang[getLanguage].RE_SEND }}
            </v-btn> -->
          </span>

          <p v-if="SMSsent" class="mt-0"></p>
          <span class="md:hidden my-4"></span>
          <v-btn
            large
            style="width: 100%"
            @click="clickNext"
            :loading="loading"
            :disabled="otp.length !== lengthOtp || loading"
            class="submit-button rounded-lg"
          >
            <!-- {{ lang[getLanguage].SIGNUP_SUBMIT }} -->
            {{ getLanguage == "en" ? "Verification Code" : "Verifikasi Kode" }}
          </v-btn>
        </v-layout>
      </v-card>
    </v-dialog>
    <div id="recaptcha-container"></div>

    <v-dialog
      v-model="profileFormDialog"
      persistent
      :fullscreen="windowWidth < 770"
      max-width="600"
    >
      <v-card
        :dark="dark"
        :style="dark ? 'background-color:#2a2a2a' : 'background-color:#FFF'"
      >
        <ProfileForm
          ref="profileForm"
          :index="index"
          :profileFormDialogClosed="profileFormDialogClosed"
          :selectedBike="selectedBike"
          :isMobileDevice="isMobileDevice"
        ></ProfileForm>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth } from "@/main";
import {
  PhoneAuthProvider,
  signInWithCredential,
  signInWithPhoneNumber,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
} from "firebase/auth";
import store from "@/store/index";
import ProfileForm from "./ProfileForm.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { LoginUserApi, getProfileApi } from "../../../services/back-end-ruby";
export default {
  name: "signup",
  props: [
    // {
    //   dark: Boolean,
    //   windowWidth: Number,
    //   windowHeight: Number,
    //   drawer: Boolean,
    //   index: Number,
    //   selectedBike: Object,
    //   isMobileDevice: Boolean,
    // },
    "dark",
    "windowWidth",
    "isMobileDevice",
    "drawer",
    "index",
    "selectedBike",
    "signupDialogClosed",
    "checkServiceAreaDialogClose",
    "btnCloseCustom",
  ],
  data() {
    return {
      showPass: false,
      lang: this.$store.state.lang,
      alert: false,
      loader: null,
      loading: false,
      phone: {
        code: "62",
        number: "",
      },
      phoneNoValid: false,
      countryCode: "+62",
      phoneNo: "",
      appVerifier: "",
      otp: "",
      otpArr: [],
      credential: "",
      verificationId: "",
      SMSsent: false,
      logoSrcDark: "",
      phoneNoFormatted: "",
      profileFormDialog: false,
      lengthOtp: 6,
    };
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language === null
        ? "id"
        : this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getPlatform() {
      return this.$store.state.device.platform;
    },
  },
  components: {
    VuePhoneNumberInput,
    ProfileForm,
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      // setTimeout(() => (this[l] = false), 3000)
    },
  },
  mounted() {
    /* this.currentUser = firebase.auth().currentUser
      if (this.currentUser && this.getUser.accType > 9) {
        console.log('####### Welcome App User #######')
        this.$router.push('/')
        return
      } else {
        this.$router.push('/')
      } */

    console.log("LOAD_SIGNUP");
  },
  created() {
    store.commit("ShowNav");
    this.initReCaptcha();
    // this.phoneNo = this.countryCode;
    this.logoSrcDark = "./img/logo/charged_logo.png";

    this.otpArr = Array(this.lengthOtp).fill("");
    this.otp = "";
  },
  methods: {
    // signupDialogClosed() {
    //   console.log(this.$refs.signup);
    // },
    dialogVerifyClose() {
      this.SMSsent = false;
      this.signupDialogClosed();
    },
    profileFormDialogClosed() {
      if (this.btnCloseCustom) {
        this.profileFormDialog = false;
        this.$router.push("/");
      } else {
        this.profileFormDialog = false;
      }
    },
    handleInputOtp(index, event) {
      const nextIndex = index < this.lengthOtp - 1 ? index + 1 : index;
      if (event.target.value.length > 0) {
        this.$refs.otpInput[nextIndex].focus();
      }
      this.alert = false;
      this.otp = this.otpArr.join("");
      // console.log("OTP_LENGTH", this.otp.length);
    },
    handleKeyDownOtp(index, event) {
      if (event.key === "Backspace" && index > 0 && this.otpArr[index] === "") {
        this.$refs.otpInput[index - 1].focus();
      } else if (event.key === "ArrowRight" && index < this.lengthOtp - 1) {
        this.$refs.otpInput[index + 1].focus();
      } else if (event.key === "ArrowLeft" && index > 0) {
        this.$refs.otpInput[index - 1].focus();
      }
    },
    keyPressed(e) {
      this.phoneNoFormatted = e.formattedNumber;
      if (e.isValid) {
        this.phoneNoValid = true;
      } else {
        this.phoneNoValid = false;
      }
      // console.log(e.formattedNumber);
      /* if (e === 'click' && this.phone.number !== '' && this.phoneNoValid) {
          this.clickNext()
          return
        } */
      // if (e.keyCode === 13 && this.phoneNoValid) {
      //   // alert('Enter was pressed')
      //   this.clickNext();
      //   return;
      // }
    },
    clickNext() {
      this.verifyOtp();
      // console.log("Platform:" + store.state.device.platform);
      // if (!this.SMSsent) {
      //   if (store.state.device.platform === "NaN") {
      //     // this.sendOtp();
      //     this.SMSsent = true;
      //     this.otp = "";
      //     this.otpArr = [];
      //   } else {
      //     this.sendOtpMobile();
      //     // this.SMSsent = true;
      //     this.otp = "";
      //     this.otpArr = [];
      //   }
      // } else {
      //   if (store.state.device.platform === "NaN") {
      //     this.verifyOtp();
      //   } else {
      //     this.verifyOtpMobile();
      //   }
      // }
    },
    async sendOtp() {
      if (!this.phoneNoValid) {
        alert("Invalid Phone Number Format !");
      } else {
        //
        // let countryCode = this.countryCode
        this.loader = "loading";
        this.phoneNoValid = true;
        this.alert = false;
        this.SMSsent = false;
        //
        let appVerifier = this.appVerifier;

        await signInWithPhoneNumber(auth, this.phoneNoFormatted, appVerifier)
          .then((confirmationResult) => {
            console.log("then");
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            //
            console.log("SMS sent");
            // hide phoneNo field, show code fields
            this.SMSsent = true;
            this.loader = null;
            this.loading = false;
          })
          .catch((error) => {
            // Error; SMS not sent
            if (error) {
              console.log("Error ! SMS not sent");
              console.log(error);
              this.alert = true;
              this.loader = null;
              this.loading = false;
            }
          });
      }
    },
    sendOtpMobile() {
      if (!this.phoneNoValid) {
        alert("Invalid Phone Number Format !");
      } else {
        this.loader = "loading";
        this.phoneNoValid = true;
        this.alert = false;
        this.SMSsent = false;
        this.signupDialogClosed();
        /* cordova.plugins.firebase.auth.verifyPhoneNumber(this.phoneNo, 0).then((verificationId) => {
            // pass verificationId to signInWithVerificationId
            console.log('sendOtpAndroid: ' + verificationId)
            // alert(credential)
            // if instant verification is true use the code that we received from the firebase endpoint,
            // otherwise ask user to input verificationCode:
            // var code = credential.instantVerification ? credential.code : inputField.value.toString();
            this.verificationId = verificationId
            // console.log('instantVerification: ' + credential.instantVerification)
            // this.credential = firebase.auth.PhoneAuthProvider.credential(verificationId, credential.code)
            // sign in with the credential
            // firebase.auth().signInWithCredential(credential);
            // OR link to an account
            // firebase.auth().currentUser.linkWithCredential(credential)
            //
            console.log('SMS sent')
            // hide phoneNo field, show code fields
            this.SMSsent = true
            this.loader = null
            this.loading = false
          /* window.FirebasePlugin.verifyPhoneNumber(this.phoneNo, 60, (credential) => {
            console.log('sendOtpAndroid: ' + credential)
            // alert(credential)
            // if instant verification is true use the code that we received from the firebase endpoint,
            // otherwise ask user to input verificationCode:
            // var code = credential.instantVerification ? credential.code : inputField.value.toString();
            this.verificationId = credential.verificationId
            console.log('instantVerification: ' + credential.instantVerification)
            // this.credential = firebase.auth.PhoneAuthProvider.credential(verificationId, credential.code)
            // sign in with the credential
            // firebase.auth().signInWithCredential(credential);
            // OR link to an account
            // firebase.auth().currentUser.linkWithCredential(credential)
            //
            console.log('SMS sent')
            // hide phoneNo field, show code fields
            this.SMSsent = true
            this.loader = null
            this.loading = false
          }, function (error) {
             console.error(error)
          }) */
      }
    },
    resendOtp() {
      this.SMSsent = false;
      this.clickNext();
    },
    //
    async verifyOtp() {
      this.signupDialogClosed();
      this.checkServiceAreaDialogClose();
      store.commit("setVerifyNumberPhone", {
        phoneNumber: this.phoneNoFormatted,
        phoneVerified: true,
      });
      await this.verifyAndLogin();
      this.profileFormDialog = true;
      // setTimeout(() => {
      // }, 1000);
      // if (this.otp.length !== 6) {
      //   console.log("Invalid SMS code format!");
      //   this.alert = true;
      // } else {
      //   //
      //   // this.loader = "loading";
      //   this.alert = false;
      //   // let vm = this;
      //   // let code = this.otp;
      //   //
      //   console.log("verify_otp");

      //   this.signupDialogClosed();
      //   this.checkServiceAreaDialogClose();
      //   store.commit("setVerifyNumberPhone", {
      //     phoneNumber: this.phoneNoFormatted,
      //     phoneVerified: true,
      //   });
      //   this.verifyAndLogin();
      //   setTimeout(() => {
      //     this.profileFormDialog = true;
      //   }, 1000);

      //   // window.confirmationResult
      //   //   .confirm(code)
      //   //   .then(() => {
      //   //     // User signed in successfully.
      //   //     // route to set Display name, email and password
      //   //     console.log("masuk ke signupnext", this.index, this.selectedBike);
      //   //     // vm.$router.push("/signupnext");
      //   //     this.signupDialogClosed();
      //   //     this.checkServiceAreaDialogClose();
      //   //     store.commit("setVerifyNumberPhone", {
      //   //       phoneNumber: this.phoneNoFormatted,
      //   //       phoneVerified: true,
      //   //     });
      //   //     this.verifyAndLogin();
      //   //     setTimeout(() => {
      //   //       this.profileFormDialog = true;
      //   //     }, 1000);
      //   //   })
      //   //   .catch((error) => {
      //   //     // User couldn't sign in (bad verification code?)
      //   //     if (error) {
      //   //       // Handle error
      //   //       console.log(error);
      //   //       this.alert = true;
      //   //       this.loader = null;
      //   //       this.loading = false;
      //   //     }
      //   //   });
      // }
    },
    async verifyOtpMobile() {
      if (this.otp.length !== 6) {
        console.log("Invalid SMS code format!");
        this.alert = true;
      } else {
        //
        this.loader = "loading";
        this.alert = false;
        // let vm = this;
        let code = this.otp;
        this.credential = PhoneAuthProvider.credential(
          this.verificationId,
          code
        );
        // this.credential = cordova.plugins.firebase.auth.signInWithVerificationId(this.verificationId, code)
        await signInWithCredential(this.credential)
          .then((result) => {
            console.log(result);
            var user = result.user;
            console.log("User: " + user);
            // User signed in successfully.
            // route to set Display name, email and password
            // vm.$router.push("/signupnext");
            /* if(this.$route.params.type === '1') {
              vm.$router.push('/signupnext')
            } else {
              vm.$router.push('/signupnexthome')
            } */

            this.signupDialogClosed();
            this.checkServiceAreaDialogClose();
            store.commit("setVerifyNumberPhone", {
              phoneNumber: this.phoneNoFormatted,
              phoneVerified: true,
            });
            this.verifyAndLogin();
            setTimeout(() => {
              this.profileFormDialog = true;
            }, 1000);
          })
          .catch((error) => {
            // User couldn't sign in (bad verification code?)
            if (error) {
              // Handle error
              console.log("########## Check Errors #############");
              console.log(error.toString());
              this.alert = true;
              this.loader = null;
              this.loading = false;
            }
          });
      }
    },
    async verifyAndLogin() {
      let getDataUser = this.getUser;
      // console.log("THIS_DATA_USERS", getDataUser);
      await LoginUserApi({ phone_number: getDataUser.phoneNumber }).then(
        (res) => {
          // console.log("RES_LOGIN", res);
          if (res.token) {
            this.$cookies.set("token_api", res.token);

            getProfileApi(res.token).then((res) => {
              // console.log("GET_PROFILE", res);
              store.commit("SetUserDetails", {
                ...getDataUser,
                customerType: res.data.user.customer_type,
              });

              this.$store.commit(
                "setCustomerType",
                res.data.user.customer_type
              );

              this.$store.commit("setIdSubs", res.data.subscription_id);
              this.$store.commit("setVehicleId", res.data?.vehicle_id ?? null);
            });

            store.commit("SetUserDetails", {
              ...getDataUser,
              displayName: res.full_name,
              fullName: res.full_name,
              email: res.email,
            });

            // store.commit("setProfile", res.user);
          }
        }
      );
    },
    async socialLogin() {
      const provider = new GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      const result = await signInWithPopup(auth, provider);
      if (result) {
        this.$router.push("/signupnext");
      }
    },
    initReCaptcha() {
      setTimeout(() => {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: () => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
            },
            "expired-callback": () => {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            },
          },
          auth
        );

        this.appVerifier = window.recaptchaVerifier;
        this.currentUser = auth.currentUser;
        if (this.currentUser && this.getUser.accType > 9) {
          console.log("####### Welcome App User #######");
          this.$router.push("/");
          return;
        }
      }, 1000);
    },
    changeLanguage() {
      // console.log(this.getLanguage)
      store.commit("SetLanguage", {
        language: this.getLanguage === "en" ? "id" : "en",
      });
      /* this.userData.language = lang
        var obj = {
          language: lang
        }
        this.saveSettingsData(obj) */
    },
    change0() {
      if (this.$refs.phoneInput.phoneNoIsValid()) {
        this.phoneNoValid = true;
      } else {
        this.phoneNoValid = false;
      }
    },
    change1() {
      this.alert = false;
    },
  }, // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAK0lEQVQYV2NkgIK3f///h7GFmRkZQWwwAZKACSDzGdElkE2iQBKvnfhcCwAOtC/AmRbyYwAAAABJRU5ErkJggg==
};
</script>
<style>
::placeholder {
  color: #656565;
}
.myDiv {
  background: url("/img/app/card_bg.jpg") no-repeat;
}
.vue-phone-number-input {
  width: 100%;
  padding: 0;
  margin: 0;
}
.charged-logo-mini {
  margin-top: -200px;
}
.login-title {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 2rem;
}
.login-title {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 2rem;
}
.login-title-mobile {
  font-weight: 700;
  font-size: 20px;
  padding: 2rem 0;
}
.phone-mobile {
  padding: 1rem 0;
}
.form-signup {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: flex-start;
  width: 100%;
}
.form-signup-mobile {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: flex-start;
  width: 100%;
}
.submit-button {
  color: #fff !important;
  background-color: #6b4693 !important;
  text-transform: none !important;
}
.login-label {
  font-weight: 400;
  font-size: 16px;
}
.login-label-policy {
  font-weight: 400;
  font-size: 14px;
  margin-top: 2rem;
}

.underline-desh:nth-child(n) {
  text-decoration: dashed;
  background: #858688;
  width: 18px;
  height: 1.2px;
  position: absolute;
  left: 0.9rem;
  bottom: -2px;
}

.underline-desh:nth-child(2) {
  text-decoration: dashed;
  background: #858688;
  width: 18px;
  height: 1.2px;
  position: absolute;
  left: 0.9rem;
  bottom: -2px;
  margin-left: 1.8rem;
}

.underline-desh:nth-child(3) {
  text-decoration: dashed;
  background: #858688;
  width: 18px;
  height: 1.2px;
  position: absolute;
  left: 0.9rem;
  bottom: -2px;
  margin-left: calc(1.84rem * 2);
}

.underline-desh:nth-child(4) {
  text-decoration: dashed;
  background: #858688;
  width: 18px;
  height: 1.2px;
  position: absolute;
  left: 0.9rem;
  bottom: -2px;
  margin-left: calc(1.85rem * 3);
}

.underline-desh:nth-child(5) {
  text-decoration: dashed;
  background: #858688;
  width: 18px;
  height: 1.2px;
  position: absolute;
  left: 0.9rem;
  bottom: -2px;
  margin-left: calc(1.85rem * 4);
}

.underline-desh:nth-child(7) {
  text-decoration: dashed;
  background: #858688;
  width: 18px;
  height: 1.2px;
  position: absolute;
  left: 0.9rem;
  bottom: -2px;
  margin-left: calc(1.88rem * 5);
}
</style>
