import Vue from "vue";
import VueRouter from "vue-router";
// import Launch from "../views/web/PressLaunch.vue";
import Home from "../views/web/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/rimba",
    name: "rimba",
    meta: {
      requiresAuth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-losaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/web/campaign/Rimba.vue"),
  },
  {
    path: "/maleoxdochi",
    name: "maleoxdochi",
    meta: {
      requiresAuth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-losaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/web/campaign/MaleoDochi.vue"),
  },
  {
    path: "/bike/:slug",
    name: "bike",

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/web/comp/Bikes/BikeComponent.vue"
      ),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/web/About.vue"),
  },
  {
    path: "/blog/:slug",
    name: "blog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/web/Blog.vue"),
  },
  {
    path: "/careers/:slug",
    name: "vacancies",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/web/Vacancies.vue"),
  },
  // {
  //   path: "/signup",
  //   name: "signup",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import("../views/auth/Signup.vue"),
  // },
  {
    path: "/component/signup",
    name: "signup_component",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/web/comp/Signup.vue"),
  },
  {
    path: "/component/profileForm",
    name: "profile_form_component",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/web/comp/ProfileForm.vue"),
  },
  {
    path: "/signupnext",
    name: "signupnext",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/auth/SignupNext.vue"),
  },
  {
    path: "/signin/:type",
    name: "signin",
    meta: {
      requiresAuth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/auth/Signin.vue"),
  },
  // {
  //   path: "/testride/:slug?",
  //   name: "testRide",
  //   meta: {
  //     requiresAuth: false,
  //   },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import("../views/web/TestRide.vue"),
  // },
  {
    path: "/contact",
    name: "contact",
    meta: {
      requiresAuth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/web/Contact.vue"),
  },
  {
    path: "/myaccount",
    name: "myaccount",
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/myaccount/MyAccount_v1.vue"),
  },
  {
    path: "/subscribe/:inv_id",
    name: "subscribe_plan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/web/comp/Subscribe_plan.vue"),
  },
  {
    path: "/payment",
    name: "payment",
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/web/Payment.vue"),
  },
  {
    path: "/booking/:inv_id",
    name: "Success",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/web/payment/Success.vue"
      ),
  },
  {
    path: "/booking/mitra_gojek/:inv_id",
    name: "SuccessGojek",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/web/payment/SuccessGojek.vue"
      ),
  },
  {
    path: "/success/:inv_id",
    name: "Success2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/web/payment/Success.vue"
      ),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
      /* webpackChunkName: "privacyPolicy" */ "../views/web/PrivacyPolicyManual.vue"
      ),
  },
  {
    path: "/term-of-use",
    name: "term-of-use",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
      /* webpackChunkName: "privacyPolicy" */ "../views/web/TermOfUseManual.vue"
      ),
  },
  {
    path: "/form-subscribe",
    name: "form-subscribe",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
      /* webpackChunkName: "privacyPolicy" */ "../views/web/FormSubscribe.vue"
      ),
  },
  {
    path: "/sign/mitra_gojek/:id",
    name: "sign-mitra-gojek",
    component: () =>
      import(
      /* webpackChunkName: "privacyPolicy" */  "../views/web/SignMitraGojek.vue"
      ),
  },
  {
    path: "/form-subscribe-mitra-gojek",
    name: "form-subscribe-mitra-gojek",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
      /* webpackChunkName: "privacyPolicy" */ "../views/web/FormSubscribeMitraGojek.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    document
      .getElementById("charged-app")
      .scrollIntoView({ behavior: "smooth" });
  },
});

export default router;
