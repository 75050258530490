<template>
  <v-container>
    <v-stepper v-model="steps" rounded>
      <v-stepper-header rounded>
        <v-stepper-step :complete="steps > 1" step="1" rounded>
          {{ lang[getLanguage].TEST_DRIVE_PERSONAL_DATA }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="steps > 2" step="2">
          {{ lang[getLanguage].TEST_DRIVE_SCHEDULE_DATA }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          {{ lang[getLanguage].TEST_DRIVE_REVIEW_DATA }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row>
            <v-col sm="12" md="12" lg="6">
              <v-img width="100%" :src="bikesImage" rounded></v-img>
            </v-col>
            <!-- align-self="center" -->
            <v-col sm="12" md="12" lg="6">
              <h2>{{ lang[getLanguage].TEST_DRIVE_PERSONAL_DATA }}</h2>
              <v-divider class="mb-6"></v-divider>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="name"
                  :label="lang[getLanguage].LABEL_FULL_NAME"
                  :rules="nameRules"
                  required
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="phone"
                  :label="lang[getLanguage].LABEL_PHONE"
                  :rules="phoneRules"
                  required
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="email"
                  :label="lang[getLanguage].LABEL_EMAIL"
                  :rules="emailRules"
                  required
                  outlined
                ></v-text-field>

                <!-- <v-checkbox
                  class="mt-2"
                  v-model="agree"
                  :rules="agreeRules"
                  label="I have agree to Charged Asia terms and conditions"
                  required
                ></v-checkbox> -->

                <v-checkbox
                  v-model="agree"
                  required
                  :rules="agreeRules"
                  class="mt-0"
                >
                  <template v-slot:label>
                    <div>
                      {{ lang[getLanguage].LABEL_AGREE }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            @click="openTC = true"
                            v-on="on"
                            class="text-decoration-none"
                          >
                            {{ lang[getLanguage].LABEL_TC }}
                          </a>
                        </template>
                        {{ lang[getLanguage].HOVER_TC }}
                      </v-tooltip>
                      {{ lang[getLanguage].LABEL_FROM_CA }}
                    </div>
                  </template>
                </v-checkbox>
                <charged-text-list-dialog
                  :title="TC[getLanguage].Title"
                  :points="TC[getLanguage].Points"
                  v-model="openTC"
                />

                <v-btn
                  block
                  color="primary"
                  class="mb-4 mt-12"
                  :disabled="disabled()"
                  @click="goNext()"
                >
                  {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
                </v-btn>
                <v-btn color="error" class="" @click="reset" block outlined>
                  {{ lang[getLanguage].BUT_CANCEL }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-row>
            <v-col sm="12" md="12" lg="6">
              <v-img width="100%" :src="bikesImage"></v-img>
            </v-col>

            <v-col sm="12" md="12" lg="6">
              <h2>{{ lang[getLanguage].TEST_DRIVE_SCHEDULE_DATA }}</h2>
              <v-divider class="mb-6"></v-divider>
              <v-dialog
                ref="dialog"
                v-model="modalDate"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    :label="lang[getLanguage].LABEL_DATE"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" :min="min" :max="max" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalDate = false">
                    {{ lang[getLanguage].BUT_CANCEL }}
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">
                    {{ lang[getLanguage].BUT_OK }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>

              <v-spacer></v-spacer>

              <v-dialog
                ref="dialog2"
                v-model="modalTime"
                :return-value.sync="time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    :label="lang[getLanguage].LABEL_TIME"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modalTime"
                  v-model="time"
                  :allowed-minutes="allowedStep"
                  full-width
                  :min="minTime"
                  :max="maxTime"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalTime = false">
                    {{ lang[getLanguage].BUT_CANCEL }}
                  </v-btn>
                  <v-btn text color="primary" @click="timing(time)">
                    {{ lang[getLanguage].BUT_OK }}
                  </v-btn>
                </v-time-picker>
              </v-dialog>
              <v-btn
                block
                color="primary"
                class="mb-4 mt-2"
                :disabled="disabled()"
                @click="goNext()"
              >
                {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
              </v-btn>
              <v-btn color="error" class="" @click="goPrev()" block outlined>
                {{ lang[getLanguage].BUT_BACK }}
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <v-col sm="12" md="12" lg="6">
              <v-img width="100%" :src="bikesImage" rounded></v-img>
            </v-col>

            <v-col sm="12" md="12" lg="6">
              <h2 class="mb-2">
                {{ lang[getLanguage].TEST_DRIVE_REVIEW_DATA }}
              </h2>
              <v-divider class="mb-6"></v-divider>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="name"
                    :label="lang[getLanguage].LABEL_FULL_NAME"
                    outlined
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-model="email"
                    :label="lang[getLanguage].LABEL_EMAIL"
                    outlined
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-model="date"
                    :label="lang[getLanguage].LABEL_DATE"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="phone"
                    :label="lang[getLanguage].LABEL_PHONE"
                    outlined
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-model="model"
                    label="Model"
                    outlined
                    readonly
                  ></v-text-field>
                  <v-text-field
                    v-model="time"
                    :label="lang[getLanguage].LABEL_TIME"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                color="primary"
                class="mb-4 mt-2"
                block
                @click="saveData()"
              >
                {{ lang[getLanguage].BUT_COMPLETE }}
              </v-btn>

              <v-btn color="error" class="" @click="goPrev()" block outlined>
                {{ lang[getLanguage].BUT_BACK }}
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
      <v-icon color="green">mdi-check-circle-outline</v-icon>
      <span>{{ snackbarText }}</span>
      <v-btn text color="#00D2D7" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { db, auth } from "@/main";
import store from "@/store/index";
import { contact, createContact } from "@/services/ContactApiCaller";
import { TERMS_AND_CONDITIONS } from "@/config/wording/conditions.js";
//import { event, createEventCalendar } from "../../../services/EventApiCaller";

export default {
  name: "TestDriveComponent",
  props: { model: String, dark: Boolean, bikesImage: String, close: Function },
  data: () => {
    return {
      //Initial
      lang: store.state.lang,
      TC: TERMS_AND_CONDITIONS,
      currentUser: null,
      steps: 1,
      min: new Date(),
      max: new Date(),
      minTime: "08:00",
      maxTime: "15:00",
      openTC: false,
      //Modal
      modalDate: false,
      modalTime: false,
      //Posted Data
      name: "",
      phone: "",
      email: "",
      agree: false,
      date: null,
      time: null,
      //Snackbars
      snackbar: false,
      snackbarText: "",
      //Validations
      valid: false,
      validSchedule: false,
      nameRules: [],
      phoneRules: [],
      emailRules: [],
      agreeRules: [],
    };
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getPlatform() {
      return this.$store.state.device.platform;
    },
    getUser() {
      return this.$store.state.user;
    },
    getCities() {
      return this.$store.state.cities;
    },
  },
  created() {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    this.min = new Date().toISOString().substr(0, 10);
    this.max = new Date(year + 1, month, day + 1).toISOString().substr(0, 10);
    this.name = "";
    this.email = "";
    this.phone = "";
    this.time = null;
    this.date = null;
    this.agree = false;
    this.steps = 1;
    this.currentUser = auth.currentUser;
    if (this.currentUser !== null) {
      this.name = this.getUser.displayName;
      this.email = this.getUser.email;
      this.phone = this.getUser.phoneNo == 0 ? null : this.getUser.phoneNo;
    }
    this.nameRules = [
      (v) => !!v || this.lang[this.getLanguage].VALIDATION_NAME_REQ,
    ];
    this.phoneRules = [
      (v) => !!v || this.lang[this.getLanguage].VALIDATION_PHONE_REQ,
    ];
    this.emailRules = [
      (v) => !!v || this.lang[this.getLanguage].VALIDATION_EMAIL_REQ,
      (v) =>
        /.+@.+\..+/.test(v) ||
        this.lang[this.getLanguage].VALIDATION_EMAIL_VALID,
    ];
    this.agreeRules = [
      (v) => !!v || this.lang[this.getLanguage].VALIDATION_TC_AGREE,
    ];
  },
  methods: {
    reset() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.time = null;
      this.date = null;
      this.agree = false;
      this.$props.close();
    },
    timing(time) {
      this.$refs.dialog2.save(time);
    },
    goNext() {
      this.steps += 1;
    },
    goPrev() {
      this.steps -= 1;
    },
    disabled() {
      if (this.steps === 1) {
        if (
          this.name === "" ||
          this.email === "" ||
          this.phone === "" ||
          this.agree === false
        ) {
          return true;
        }
      }
      if (this.steps === 2) {
        if (this.date === null || this.time === null) {
          return true;
        }
      }
      return false;
    },
    allowedStep: (m) => m % 30 === 0,
    saveData() {
      this.newPostKey = db.collection("testDrives").doc();
      let dispatchObj = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        date: this.date,
        time: this.time,
        agree: this.agree,
        created: new Date().getTime(),
      };
      db.collection("testDrives")
        .doc(this.newPostKey.id)
        .set(dispatchObj)
        .then(() => {
          //Save Contact ERP Next
          var name = this.name.split(" ");
          contact.first_name = name[0];
          if (name.length === 2) {
            contact.last_name = name[1];
          } else if (name.length === 3) {
            contact.middle_name = name[1];
            contact.last_name = name[name.length - 1];
          }
          contact.email_id = this.email;
          contact.mobile_no = this.phone;
          contact.phone = this.phone;
          contact.email_address_2 = this.email;
          contact.email_ids.push({
            parent: name[0],
            parentfield: "email_ids",
            parenttype: "Contact",
            idx: 1,
            docstatus: 0,
            email_id: this.email,
            is_primary: 1,
            doctype: "Contact Email",
          });
          contact.phone_nos.push({
            parent: name[0],
            parentfield: "phone_nos",
            parenttype: "Contact",
            idx: 1,
            docstatus: 0,
            phone: this.phone,
            is_primary_phone: 1,
            is_primary_mobile_no: 1,
            doctype: "Contact Phone",
          });
          createContact();

          // //Save Event ERP Next
          // event.subject = "Booking Test Drive";
          // event.starts_on = this.date;
          // event.links[0].link_doctype = "Contact";
          // event.links[0].link_name = this.name;
          // createEventCalendar();
        })
        .catch((error) => {
          alert(error);
        });
      this.snackbarText = this.lang[this.getLanguage].TEST_DRIVE_SAVED;
      this.snackbar = true;
      setTimeout(() => {
        let vm = this;
        vm.$router.push("/");
        return;
      }, 5000);
    },
  },
};
</script>
<style scoped>
.v-stepper {
  box-shadow: none;
}

.v-stepper__header {
  box-shadow: none;
}
</style>
