const dataInformasiPribadiModul = {
  state: () => ({
    name: "",
    nik: "",
    emailPribadi: "",
    phoneNumberPribadi: "",
    tempatLahir: "",
    dateTglLahir: "",
    motherName: "",
    companyName: "",
    yearOfExperience: "",
    salary: "",
    maritalStatus: "",
    spouseCompanyName: "",
    spousePositionLabel: "",
    spouseEmploymentStatus: "",
    spouseYearOfExperience: "",
    spouseSalary: "",
    jobPosition: "",
    employmentStatus: "",
    creditCardLimit: "",
  }),
  mutations: {
    setDataInformasiPribadi(state, payload) {
      state.name = payload.name;
      state.nik = payload.nik;
      state.emailPribadi = payload.emailPribadi;
      state.phoneNumberPribadi = payload.phoneNumberPribadi;
      state.tempatLahir = payload.tempatLahir;
      state.dateTglLahir = payload.dateTglLahir;
      state.motherName = payload.motherName;
      state.companyName = payload.companyName;
      state.yearOfExperience = payload.yearOfExperience;
      state.salary = payload.salary;
      state.maritalStatus = payload.maritalStatus;
      state.spouseCompanyName = payload.spouseCompanyName;
      state.spousePositionLabel = payload.spousePositionLabel;
      state.spouseEmploymentStatus = payload.spouseEmploymentStatus;
      state.spouseYearOfExperience = payload.spouseYearOfExperience;
      state.spouseSalary = payload.spouseSalary;
      state.jobPosition= payload.jobPosition,
      state.employmentStatus= payload.employmentStatus,
      state.creditCardLimit= payload.creditCardLimit
    },
  },
  actions: {},
  getters: {
    getDataInformasiPribadi(state) {
      return state;
    },
  },
};

export default dataInformasiPribadiModul;
