const dataKostumisasiModul = {
  state: () => ({
    bike: null,
    qtyBattery: null,
    typeCharge: null,
    accessories: null,
    color: null,
  }),
  mutations: {
    setDataKostumisasi(state, payload) {
      state.bike = payload.bike;
      state.typeCharge = payload.typeCharge;
      state.qtyBattery = payload.qtyBattery;
      state.accessories = payload.accessories;
      state.color = payload.color;
    },
  },
  actions: {},
  getters: {
    getDataKostumisasi(state) {
      return state;
    },
  },
};

export default dataKostumisasiModul;
