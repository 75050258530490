import { BeRubyAPI } from "../../../clients/BERubyAPI";
import Jwt from "jsonwebtoken";

const postCustomizationApi = async (token, { vehicle_color, charger, battery, rearbox, subscription, vehicle_id }) => {
  let data = '';

  if (!rearbox || rearbox == '') {
    data = {
      vehicle_color,
      charger,
      battery,
      vehicle_id: vehicle_id ?? '',
      subscription,
    };
  } else {
    data = {
      vehicle_color,
      charger,
      battery,
      rearbox,
      subscription,
    };
  }

  let body = {
    body_params: data,
    timestamp: new Date()
  };

  let encryptData = Jwt.sign(body, process.env.VUE_APP_END_TO_END_SECRET_KEY);

  let responseFetch = await BeRubyAPI.post('/v2/embedded_forms/customization', { payload: encryptData }, {
    headers: {
      'Authorization': token !== null ? 'Bearer ' + token : '',
    }
  })
    .then((res) => { return res.data })
    .catch((err) => { return err.response.data });

  return responseFetch;
};

export { postCustomizationApi };