import { getCustomizationApi } from "../../services/back-end-ruby";
import { getListVehiclePriceApi, getVehiclePriceApi } from "../../services/back-end-ruby/Vehicle";

const vehicleModule = {
  state: () => ({
    list_vehicle: [
      {
        vehicle_id: process.env.VUE_APP_ID_VEHICLE_ANOA,
        name: "ANOA",
        nameLowercase: "anoa",
        folder: "vs1",
        description: "One Trip - deliver double loading of normal delivery.",
        image: "img/shared/bikes/anoa/bike-anoa.webp",
        motor: "Electric Motor - Super Soco 4000W",
        autonomy: "Autonomy 160 Km (with L1e specification)",
        speed: "90",
        maxDistance: "200",
        maxDistanceUnit: "km",
        recharge:
          "Recharge 3.5 h at 60V15A (fast charge with single battery)",
        count: 12,
        price: "Rp 32.000.000",
        subPrice: "Rp 1.290.000",
        batterySubscription: "Rp 350.000",
        dailyPrice: 43000,
        stock: 1,
        // promoLebaranSvg: "img/home/lebaran/promo-lebaran-anoa.png",
      },
      {
        vehicle_id: process.env.VUE_APP_ID_VEHICLE_RIMAU,
        name: "RIMAU",
        nameLowercase: "rimau",
        folder: "cpx",
        description: "Meets the needs of the aspiring urban professional. ",
        image: "img/shared/bikes/rimau/bike-rimau.webp",
        motor: "Electric Motor - Super Soco EEC Power 4000W",
        autonomy:
          "Autonomy 140 Km (at 45 Km/h with 75 Kg driver, 2 batteries)",
        speed: "95",
        maxDistance: "200",
        maxDistanceUnit: "km",
        recharge:
          "Recharge 3.5 h at 60V15A (fast charge with single battery)",
        count: 7,
        price: "Rp 33.500.000",
        subPrice: "Rp 1.350.000",
        batterySubscription: "Rp 350.000",
        dailyPrice: 45000,
        stock: 1,
        // promoLebaranSvg: "img/home/lebaran/promo-lebaran-rimau.png",
      },
      /* {
            name: "Charged 1.6",
            folder: "cux",
            description:
              "Small size big power. Excellent safety and flexibility.",
            image: "img/bikes/cux/home.png",
            motor: "Electric Motor - BOSCH 1600W",
            autonomy: "Autonomy 75 Km (at 45 Km/h with 75 Kg driver)",
            speed: "Speed 45 Km/h",
            recharge: "Recharge 3.5 h at 60V10A (fast charge)",
            count: 7,
          }, */
      {
        vehicle_id: process.env.VUE_APP_ID_VEHICLE_MALEO,
        name: "MALEO",
        nameLowercase: "maleo",
        folder: "vs2",
        description:
          "The multi-functional delivery vehicle adding value for your business.",
        image: "img/shared/bikes/maleo/bike-maleo.webp",
        motor: "Engine Single Swingarm 3000W",
        autonomy:
          "Autonomy 75 Km (at 45 Km/h with 75 Kg driver, 2 batteries)",
        speed: "70",
        maxDistance: "125",
        maxDistanceUnit: "km",
        recharge: "Recharge 3.5 h at 60V15A (fast charge)",
        count: 8,
        price: "Rp 24.000.000",
        subPrice: "Rp 1.200.000",
        batterySubscription: "Rp 350.000",
        dailyPrice: 40000,
        stock: 1,
        // promoLebaranSvg: "img/home/lebaran/promo-lebaran-maleo.png",
      },
    ],
    list_colors: [],
    list_colors_done: false,
    list_chargers: [],
    list_chargers_done: false,
    list_batterys: [],
    list_batterys_done: false,
    list_rearbox: [],
    list_rearbox_done: false,
    list_aksesoris: [],
    list_aksesoris_done: false,
    list_vehicle_price: [],
    list_vehicle_price_done: false,
    selected_vehicle_price: {},
    total_amount: 0,
  }),
  mutations: {
    async setVehicle(state, payload) {
      if (payload.mitra_driver_gojek) {
        if (payload.refresh) {
          state.list_chargers = [];
          state.list_batterys = [];
          state.list_aksesoris = [];
          state.list_colors = [];
          state.list_chargers_done = false;
          state.list_chargers_done = false;
          state.list_batterys_done = false;
          state.list_aksesoris_done = false;
          state.list_colors_done = false;
        }
        if (!state.list_colors_done) {
          for (let i = 0; i < payload.res.data.color.length; i++) {
            const colorData = payload.res.data.color[i];
            state.list_colors.push({
              id: colorData.id,
              title: { en: colorData.name, id: colorData.name },
              color: colorData.hex_code,
              price: colorData.price,
              bikes: ["RIMAU", "MALEO", "ANOA"],
            })
          }

          state.list_colors_done = true
        }

        if (!state.list_chargers_done) {
          for (let i = 0; i < payload.res.data.accessories.Charger.length; i++) {
            const chargerData = payload.res.data.accessories.Charger[i];
            state.list_chargers.push({
              id: chargerData.id,
              title: chargerData.name,
              time: { en: chargerData.description, id: chargerData.description },
              description: { en: chargerData.name == 'Fast Charger' ? 'Charging is faster with a wattage of 1300W.' : '', id: chargerData.name == 'Fast Charger' ? 'Charging lebih cepat dengan daya watt sebesar 1300W.' : '' },
              priceLabel: "111",
              dailyPriceLabel: "3.7",
              price: chargerData.price,
              dailyPrice: chargerData.price,
            })
          }

          state.list_chargers_done = true;
        }

        if (!state.list_batterys_done) {
          for (let i = 0; i < payload.res.data.accessories.Battery.length; i++) {
            const batteryData = payload.res.data.accessories.Battery[i];
            state.list_batterys.push({
              id: batteryData.id,
              title: { en: batteryData.name, id: batteryData.name },
              conclusion: { en: batteryData.accessory_type, id: batteryData.accessory_type },
              desc: {
                en: batteryData.description,
                id: batteryData.description,
              },
              priceLabel: "0",
              dailyPriceLabel: "0",
              price: batteryData.price,
              dailyPrice: batteryData.price,
              qty: batteryData.price == 0 ? '1' : '2',
            })
          }
          state.list_batterys_done = true;
        }

        if (!state.list_aksesoris_done) {

          // if (payload.res.data.accessories.Rearbox) {
          //   for (let i = 0; i < payload.res.data.accessories.Rearbox.length; i++) {
          //     const rearboxData = payload.res.data.accessories.Rearbox[i];
          //     state.list_aksesoris.push({
          //       id: rearboxData.id,
          //       title: { en: rearboxData.name, id: rearboxData.name },
          //       conclusion: { en: rearboxData.accessory_type, id: rearboxData.accessory_type },
          //       priceLabel: "0",
          //       dailyPriceLabel: "0",
          //       price: rearboxData.price,
          //       dailyPrice: rearboxData.price,
          //       description: rearboxData.description,
          //     })
          //   }
          // }

          // if (payload.res.data.accessories.Rearseat) {
          //   for (let i = 0; i < payload.res.data.accessories?.Rearseat.length; i++) {
          //     const rearseatData = payload.res.data.accessories.Rearseat[i];
          //     state.list_aksesoris.push({
          //       id: rearseatData.id,
          //       title: { en: rearseatData.name, id: rearseatData.name },
          //       conclusion: { en: rearseatData.accessory_type, id: rearseatData.accessory_type },
          //       priceLabel: "0",
          //       dailyPriceLabel: "0",
          //       price: rearseatData.price,
          //       dailyPrice: rearseatData.price,
          //       description: rearseatData.description,
          //     })
          //   }
          // }

          state.list_aksesoris_done = true;
        }
      } else {
        await getCustomizationApi(payload.token, payload.subscription_id).then((res) => {
          if (payload.refresh) {
            state.list_chargers = [];
            state.list_batterys = [];
            state.list_aksesoris = [];
            state.list_chargers_done = false;
            state.list_chargers_done = false;
            state.list_batterys_done = false;
            state.list_aksesoris_done = false;
          }

          if (!state.list_colors_done) {
            for (let i = 0; i < res.data.color.length; i++) {
              const colorData = res.data.color[i];
              state.list_colors.push({
                id: colorData.id,
                title: { en: colorData.name, id: colorData.name },
                color: colorData.hex_code,
                price: colorData.price,
                bikes: ["RIMAU", "MALEO", "ANOA"],
              })
            }

            state.list_colors_done = true
          }

          if (!state.list_chargers_done) {
            for (let i = 0; i < res.data.accessories.Charger.length; i++) {
              const chargerData = res.data.accessories.Charger[i];
              state.list_chargers.push({
                id: chargerData.id,
                title: chargerData.name,
                time: { en: chargerData.description, id: chargerData.description },
                description: { en: chargerData.name == 'Fast Charger' ? 'Charging is faster with a wattage of 1300W.' : '', id: chargerData.name == 'Fast Charger' ? 'Charging lebih cepat dengan daya watt sebesar 1300W.' : '' },
                priceLabel: "111",
                dailyPriceLabel: "3.7",
                price: chargerData.price,
                dailyPrice: chargerData.price,
              })
            }

            state.list_chargers_done = true;
          }

          if (!state.list_batterys_done) {
            for (let i = 0; i < res.data.accessories.Battery.length; i++) {
              const batteryData = res.data.accessories.Battery[i];
              state.list_batterys.push({
                id: batteryData.id,
                title: { en: batteryData.name, id: batteryData.name },
                conclusion: { en: batteryData.accessory_type, id: batteryData.accessory_type },
                desc: {
                  en: batteryData.description,
                  id: batteryData.description,
                },
                priceLabel: "0",
                dailyPriceLabel: "0",
                price: batteryData.price,
                dailyPrice: batteryData.price,
                qty: batteryData.price == 0 ? '1' : '2',
              })
            }
            state.list_batterys_done = true;
          }

          if (!state.list_aksesoris_done) {

            // if (res.data.accessories.Rearbox) {
            //   for (let i = 0; i < res.data.accessories.Rearbox.length; i++) {
            //     const rearboxData = res.data.accessories.Rearbox[i];
            //     state.list_aksesoris.push({
            //       id: rearboxData.id,
            //       title: { en: rearboxData.name, id: rearboxData.name },
            //       conclusion: { en: rearboxData.accessory_type, id: rearboxData.accessory_type },
            //       priceLabel: "0",
            //       dailyPriceLabel: "0",
            //       price: rearboxData.price,
            //       dailyPrice: rearboxData.price,
            //       description: rearboxData.description,
            //     })
            //   }
            // }

            // if (res.data.accessories?.Rearseat) {
            //   for (let i = 0; i < res.data.accessories?.Rearseat.length; i++) {
            //     const rearseatData = res.data.accessories.Rearseat[i];
            //     state.list_aksesoris.push({
            //       id: rearseatData.id,
            //       title: { en: rearseatData.name, id: rearseatData.name },
            //       conclusion: { en: rearseatData.accessory_type, id: rearseatData.accessory_type },
            //       priceLabel: "0",
            //       dailyPriceLabel: "0",
            //       price: rearseatData.price,
            //       dailyPrice: rearseatData.price,
            //       description: rearseatData.description,
            //     })
            //   }
            // }

            state.list_aksesoris_done = true;
          }


        });
      }
    },
    async setVehiclePrice(state, payload) {
      const results = await getVehiclePriceApi(payload.token, payload.subscription_id).then((res) => {
        return res;
      });
      let conditionPriode = payload.customer_type == 'mitra' || payload.customer_type == 'mitra_driver_gojek' ? 'daily' : 'monthly';

      if (payload.reset) {
        state.list_vehicle_price = []
        state.list_vehicle_price_done = false;
      }

      if (!state.list_vehicle_price_done) {
        results.data.vehicle_prices.find((p) => {
          if (p.periode === conditionPriode) {
            state.list_vehicle_price.push({
              id: p.id,
              plan: { en: p.periode_qty + " Months Plan", id: p.periode_qty + " Bulan" },
              price: p.amount,
              dailyPrice: p.amount,
              bonus: { en: "", id: "" },
              bikes: ["RIMAU", "MALEO", "ANOA"],
            });

            state.selected_vehicle_price = {
              id: p.id,
              plan: { en: p.periode_qty + " Months Plan", id: p.periode_qty + " Bulan" },
              price: p.amount,
              dailyPrice: p.amount,
              bonus: { en: "", id: "" },
              bikes: ["RIMAU", "MALEO", "ANOA"],
            }

            state.list_vehicle_price_done = true;
          }
        });
      }
    },
    setSelectedVehiclePrice(state, payload) {
      state.selected_vehicle_price = payload;
    },
    setTotalAmount(state, payload) {
      state.total_amount = payload;
    },
  },
  actions: {
    async setVehicle({ commit }, payload) {
      if (payload.mitra_driver_gojek) {
        await getListVehiclePriceApi(payload.token, payload.vehicle_id).then((res) => {
          commit("setVehicle", {
            mitra_driver_gojek: payload.mitra_driver_gojek,
            refresh: payload.refresh,
            res: res,
          })
        })
      } else {
        // await getCustomizationApi(payload.token, payload.subscription_id).then((res) => {
        //   if (payload.refresh) {
        //     state.list_chargers = [];
        //     state.list_batterys = [];
        //     state.list_aksesoris = [];
        //     state.list_chargers_done = false;
        //     state.list_chargers_done = false;
        //     state.list_batterys_done = false;
        //     state.list_aksesoris_done = false;
        //   }

        //   if (!state.list_colors_done) {
        //     for (let i = 0; i < res.data.color.length; i++) {
        //       const colorData = res.data.color[i];
        //       state.list_colors.push({
        //         id: colorData.id,
        //         title: { en: colorData.name, id: colorData.name },
        //         color: colorData.hex_code,
        //         price: colorData.price,
        //         bikes: ["RIMAU", "MALEO", "ANOA"],
        //       })
        //     }

        //     state.list_colors_done = true
        //   }

        //   if (!state.list_chargers_done) {
        //     for (let i = 0; i < res.data.accessories.Charger.length; i++) {
        //       const chargerData = res.data.accessories.Charger[i];
        //       state.list_chargers.push({
        //         id: chargerData.id,
        //         title: chargerData.name,
        //         time: { en: chargerData.description, id: chargerData.description },
        //         description: { en: chargerData.name == 'Fast Charger' ? 'Charging is faster with a wattage of 1300W.' : '', id: chargerData.name == 'Fast Charger' ? 'Charging lebih cepat dengan daya watt sebesar 1300W.' : '' },
        //         priceLabel: "111",
        //         dailyPriceLabel: "3.7",
        //         price: chargerData.price,
        //         dailyPrice: chargerData.price,
        //       })
        //     }

        //     state.list_chargers_done = true;
        //   }

        //   if (!state.list_batterys_done) {
        //     for (let i = 0; i < res.data.accessories.Battery.length; i++) {
        //       const batteryData = res.data.accessories.Battery[i];
        //       state.list_batterys.push({
        //         id: batteryData.id,
        //         title: { en: batteryData.name, id: batteryData.name },
        //         conclusion: { en: batteryData.accessory_type, id: batteryData.accessory_type },
        //         desc: {
        //           en: batteryData.description,
        //           id: batteryData.description,
        //         },
        //         priceLabel: "0",
        //         dailyPriceLabel: "0",
        //         price: batteryData.price,
        //         dailyPrice: batteryData.price,
        //         qty: batteryData.price == 0 ? '1' : '2',
        //       })
        //     }
        //     state.list_batterys_done = true;
        //   }

        //   if (!state.list_aksesoris_done) {

        //     if (res.data.accessories.Rearbox) {
        //       for (let i = 0; i < res.data.accessories.Rearbox.length; i++) {
        //         const rearboxData = res.data.accessories.Rearbox[i];
        //         state.list_aksesoris.push({
        //           id: rearboxData.id,
        //           title: { en: rearboxData.name, id: rearboxData.name },
        //           conclusion: { en: rearboxData.accessory_type, id: rearboxData.accessory_type },
        //           priceLabel: "0",
        //           dailyPriceLabel: "0",
        //           price: rearboxData.price,
        //           dailyPrice: rearboxData.price,
        //           description: rearboxData.description,
        //         })
        //       }
        //     }

        //     if (res.data.accessories.Rearseat) {
        //       for (let i = 0; i < res.data.accessories?.Rearseat.length; i++) {
        //         const rearseatData = res.data.accessories.Rearseat[i];
        //         state.list_aksesoris.push({
        //           id: rearseatData.id,
        //           title: { en: rearseatData.name, id: rearseatData.name },
        //           conclusion: { en: rearseatData.accessory_type, id: rearseatData.accessory_type },
        //           priceLabel: "0",
        //           dailyPriceLabel: "0",
        //           price: rearseatData.price,
        //           dailyPrice: rearseatData.price,
        //           description: rearseatData.description,
        //         })
        //       }
        //     }

        //     state.list_aksesoris_done = true;
        //   }


        // });
      }
    }
  },
  getters: {
    getListVehicle(state) {
      return state.list_vehicle;
    },
    getListColorsVehicle(state) {
      return state.list_colors;
    },
    getListChargersVehicle(state) {
      return state.list_chargers;
    },
    getListBatteryVehicle(state) {
      return state.list_batterys;
    },
    getListRearboxVehicle(state) {
      return state.list_rearbox;
    },
    getListVehiclePrice(state) {
      return state.list_vehicle_price;
    },
    getListAksesorisVehicle(state) {
      return state.list_aksesoris;
    },
    getSelectedVehiclePrice(state) {
      return state.selected_vehicle_price;
    },
    getTotalAmount(state) {
      return state.total_amount;
    }
  }
}

export default vehicleModule;