export const SERVICE_CITIES =
  [
    {
      "id": "1",
      "postcode": "12110",
      "village": "Selong",
      "regency": "Kebayoran Baru",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Selong - 12110"
    },
    {
      "id": "2",
      "postcode": "12120",
      "village": "Gunung",
      "regency": "Kebayoran Baru",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Gunung - 12120"
    },
    {
      "id": "3",
      "postcode": "12130",
      "village": "Kramat Pela",
      "regency": "Kebayoran Baru",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Kramat Pela - 12130"
    },
    {
      "id": "4",
      "postcode": "12140",
      "village": "Gandaria Utara",
      "regency": "Kebayoran Baru",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Gandaria Utara - 12140"
    },
    {
      "id": "5",
      "postcode": "12150",
      "village": "Cipete Utara",
      "regency": "Kebayoran Baru",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Cipete Utara - 12150"
    },
    {
      "id": "6",
      "postcode": "12160",
      "village": "Melawai",
      "regency": "Kebayoran Baru",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Melawai - 12160"
    },
    {
      "id": "7",
      "postcode": "12160",
      "village": "Pulo",
      "regency": "Kebayoran Baru",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Pulo - 12160"
    },
    {
      "id": "8",
      "postcode": "12170",
      "village": "Petogogan",
      "regency": "Kebayoran Baru",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Petogogan - 12170"
    },
    {
      "id": "9",
      "postcode": "12180",
      "village": "Rawa Barat",
      "regency": "Kebayoran Baru",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Rawa Barat - 12180"
    },
    {
      "id": "10",
      "postcode": "12190",
      "village": "Senayan",
      "regency": "Kebayoran Baru",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Senayan - 12190"
    },
    {
      "id": "11",
      "postcode": "12210",
      "village": "Grogol Utara",
      "regency": "Kebayoran Lama",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Grogol Utara - 12210"
    },
    {
      "id": "12",
      "postcode": "12220",
      "village": "Grogol Selatan",
      "regency": "Kebayoran Lama",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Grogol Selatan - 12220"
    },
    {
      "id": "13",
      "postcode": "12230",
      "village": "Cipulir",
      "regency": "Kebayoran Lama",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Cipulir - 12230"
    },
    {
      "id": "14",
      "postcode": "12240",
      "village": "Kebayoran Lama Selatan",
      "regency": "Kebayoran Lama",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Kebayoran Lama Selatan - 12240"
    },
    {
      "id": "15",
      "postcode": "12240",
      "village": "Kebayoran Lama Utara",
      "regency": "Kebayoran Lama",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Kebayoran Lama Utara - 12240"
    },
    {
      "id": "16",
      "postcode": "12250",
      "village": "Ulujami",
      "regency": "Pesanggrahan",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Ulujami - 12250"
    },
    {
      "id": "17",
      "postcode": "12260",
      "village": "Petukangan Utara",
      "regency": "Pesanggrahan",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Petukangan Utara - 12260"
    },
    {
      "id": "18",
      "postcode": "12270",
      "village": "Petukangan Selatan",
      "regency": "Pesanggrahan",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Petukangan Selatan - 12270"
    },
    {
      "id": "19",
      "postcode": "12310",
      "village": "Pondok Pinang",
      "regency": "Kebayoran Lama",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Pondok Pinang - 12310"
    },
    {
      "id": "20",
      "postcode": "12320",
      "village": "Pesanggrahan",
      "regency": "Pesanggrahan",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Pesanggrahan - 12320"
    },
    {
      "id": "21",
      "postcode": "12330",
      "village": "Bintaro",
      "regency": "Pesanggrahan",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Bintaro - 12330"
    },
    {
      "id": "22",
      "postcode": "12410",
      "village": "Cipete Selatan",
      "regency": "Cilandak",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Cipete Selatan - 12410"
    },
    {
      "id": "23",
      "postcode": "12420",
      "village": "Gandaria Selatan",
      "regency": "Cilandak",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Gandaria Selatan - 12420"
    },
    {
      "id": "24",
      "postcode": "12430",
      "village": "Cilandak Barat",
      "regency": "Cilandak",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Cilandak Barat - 12430"
    },
    {
      "id": "25",
      "postcode": "12440",
      "village": "Lebak Bulus",
      "regency": "Cilandak",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Lebak Bulus - 12440"
    },
    {
      "id": "26",
      "postcode": "12450",
      "village": "Pondok Labu",
      "regency": "Cilandak",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Pondok Labu - 12450"
    },
    {
      "id": "27",
      "postcode": "12510",
      "village": "Pejaten Barat",
      "regency": "Pasar Minggu",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Pejaten Barat - 12510"
    },
    {
      "id": "28",
      "postcode": "12510",
      "village": "Pejaten Timur",
      "regency": "Pasar Minggu",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Pejaten Timur - 12510"
    },
    {
      "id": "29",
      "postcode": "12520",
      "village": "Kebagusan",
      "regency": "Pasar Minggu",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Kebagusan - 12520"
    },
    {
      "id": "30",
      "postcode": "12520",
      "village": "Pasar Minggu",
      "regency": "Pasar Minggu",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Pasar Minggu - 12520"
    },
    {
      "id": "31",
      "postcode": "12530",
      "village": "Tanjung Barat",
      "regency": "Jagakarsa",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Tanjung Barat - 12530"
    },
    {
      "id": "32",
      "postcode": "12540",
      "village": "Jati Padang",
      "regency": "Pasar Minggu",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Jati Padang - 12540"
    },
    {
      "id": "33",
      "postcode": "12550",
      "village": "Ragunan",
      "regency": "Pasar Minggu",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Ragunan - 12550"
    },
    {
      "id": "34",
      "postcode": "12560",
      "village": "Cilandak Timur",
      "regency": "Pasar Minggu",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Cilandak Timur - 12560"
    },
    {
      "id": "35",
      "postcode": "12620",
      "village": "Jagakarsa",
      "regency": "Jagakarsa",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Jagakarsa - 12620"
    },
    {
      "id": "36",
      "postcode": "12630",
      "village": "Ciganjur",
      "regency": "Jagakarsa",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Ciganjur - 12630"
    },
    {
      "id": "37",
      "postcode": "12630",
      "village": "Cipedak",
      "regency": "Jagakarsa",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Cipedak - 12630"
    },
    {
      "id": "38",
      "postcode": "12630",
      "village": "Lenteng Agung",
      "regency": "Jagakarsa",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Lenteng Agung - 12630"
    },
    {
      "id": "39",
      "postcode": "12640",
      "village": "Srengseng Sawah",
      "regency": "Jagakarsa",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Srengseng Sawah - 12640"
    },
    {
      "id": "40",
      "postcode": "12710",
      "village": "Kuningan Barat",
      "regency": "Mampang Prapatan",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Kuningan Barat - 12710"
    },
    {
      "id": "41",
      "postcode": "12720",
      "village": "Pela Mampang",
      "regency": "Mampang Prapatan",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Pela Mampang - 12720"
    },
    {
      "id": "42",
      "postcode": "12730",
      "village": "Bangka",
      "regency": "Mampang Prapatan",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Bangka - 12730"
    },
    {
      "id": "43",
      "postcode": "12740",
      "village": "Kalibata",
      "regency": "Pancoran",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Kalibata - 12740"
    },
    {
      "id": "44",
      "postcode": "12750",
      "village": "Rawa Jati",
      "regency": "Pancoran",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Rawa Jati - 12750"
    },
    {
      "id": "45",
      "postcode": "12760",
      "village": "Duren Tiga",
      "regency": "Pancoran",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Duren Tiga - 12760"
    },
    {
      "id": "46",
      "postcode": "12770",
      "village": "Cikoko",
      "regency": "Pancoran",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Cikoko - 12770"
    },
    {
      "id": "47",
      "postcode": "12770",
      "village": "Pengadegan",
      "regency": "Pancoran",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Pengadegan - 12770"
    },
    {
      "id": "48",
      "postcode": "12780",
      "village": "Pancoran",
      "regency": "Pancoran",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Pancoran - 12780"
    },
    {
      "id": "49",
      "postcode": "12790",
      "village": "Mampang Prapatan",
      "regency": "Mampang Prapatan",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Mampang Prapatan - 12790"
    },
    {
      "id": "50",
      "postcode": "12790",
      "village": "Tegal Parang",
      "regency": "Mampang Prapatan",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Tegal Parang - 12790"
    },
    {
      "id": "51",
      "postcode": "12810",
      "village": "Tebet Barat",
      "regency": "Tebet",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Tebet Barat - 12810"
    },
    {
      "id": "52",
      "postcode": "12820",
      "village": "Tebet Timur",
      "regency": "Tebet",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Tebet Timur - 12820"
    },
    {
      "id": "53",
      "postcode": "12830",
      "village": "Kebon Baru",
      "regency": "Tebet",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Kebon Baru - 12830"
    },
    {
      "id": "54",
      "postcode": "12840",
      "village": "Bukit Duri",
      "regency": "Tebet",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Bukit Duri - 12840"
    },
    {
      "id": "55",
      "postcode": "12850",
      "village": "Manggarai",
      "regency": "Tebet",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Manggarai - 12850"
    },
    {
      "id": "56",
      "postcode": "12860",
      "village": "Manggarai Selatan",
      "regency": "Tebet",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Manggarai Selatan - 12860"
    },
    {
      "id": "57",
      "postcode": "12870",
      "village": "Menteng Dalam",
      "regency": "Tebet",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Menteng Dalam - 12870"
    },
    {
      "id": "58",
      "postcode": "12910",
      "village": "Setiabudi",
      "regency": "Setia Budi",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Setiabudi - 12910"
    },
    {
      "id": "59",
      "postcode": "12920",
      "village": "Karet",
      "regency": "Setia Budi",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Karet - 12920"
    },
    {
      "id": "60",
      "postcode": "12930",
      "village": "Karet Semanggi",
      "regency": "Setia Budi",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Karet Semanggi - 12930"
    },
    {
      "id": "61",
      "postcode": "12940",
      "village": "Karet Kuningan",
      "regency": "Setia Budi",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Karet Kuningan - 12940"
    },
    {
      "id": "62",
      "postcode": "12950",
      "village": "Kuningan Timur",
      "regency": "Setia Budi",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Kuningan Timur - 12950"
    },
    {
      "id": "63",
      "postcode": "12960",
      "village": "Menteng Atas",
      "regency": "Setia Budi",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Menteng Atas - 12960"
    },
    {
      "id": "64",
      "postcode": "12970",
      "village": "Pasar Manggis",
      "regency": "Setia Budi",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Pasar Manggis - 12970"
    },
    {
      "id": "65",
      "postcode": "12980",
      "village": "Guntur",
      "regency": "Setia Budi",
      "state": "Kota",
      "district": "Jakarta Selatan",
      "province": "DKI Jakarta",
      "string": "Guntur - 12980"
    },
    {
      "id": "66",
      "postcode": "14110",
      "village": "Kali Baru",
      "regency": "Cilincing",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Kali Baru - 14110"
    },
    {
      "id": "67",
      "postcode": "14120",
      "village": "Cilincing",
      "regency": "Cilincing",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Cilincing - 14120"
    },
    {
      "id": "68",
      "postcode": "14130",
      "village": "Semper Barat",
      "regency": "Cilincing",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Semper Barat - 14130"
    },
    {
      "id": "69",
      "postcode": "14130",
      "village": "Semper Timur",
      "regency": "Cilincing",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Semper Timur - 14130"
    },
    {
      "id": "70",
      "postcode": "14140",
      "village": "Rorotan",
      "regency": "Cilincing",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Rorotan - 14140"
    },
    {
      "id": "71",
      "postcode": "14140",
      "village": "Sukapura",
      "regency": "Cilincing",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Sukapura - 14140"
    },
    {
      "id": "72",
      "postcode": "14150",
      "village": "Marunda",
      "regency": "Cilincing",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Marunda - 14150"
    },
    {
      "id": "73",
      "postcode": "14210",
      "village": "Koja (Utara, Selatan)",
      "regency": "Koja",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Koja (Utara, Selatan) - 14210"
    },
    {
      "id": "74",
      "postcode": "14230",
      "village": "Rawa Badak Selatan",
      "regency": "Koja",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Rawa Badak Selatan - 14230"
    },
    {
      "id": "75",
      "postcode": "14230",
      "village": "Rawa Badak Utara",
      "regency": "Koja",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Rawa Badak Utara - 14230"
    },
    {
      "id": "76",
      "postcode": "14240",
      "village": "Kelapa Gading Barat",
      "regency": "Kelapa Gading",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Kelapa Gading Barat - 14240"
    },
    {
      "id": "77",
      "postcode": "14240",
      "village": "Kelapa Gading Timur",
      "regency": "Kelapa Gading",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Kelapa Gading Timur - 14240"
    },
    {
      "id": "78",
      "postcode": "14250",
      "village": "Pegangsaan Dua",
      "regency": "Kelapa Gading",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Pegangsaan Dua - 14250"
    },
    {
      "id": "79",
      "postcode": "14260",
      "village": "Tugu Selatan",
      "regency": "Koja",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Tugu Selatan - 14260"
    },
    {
      "id": "80",
      "postcode": "14260",
      "village": "Tugu Utara",
      "regency": "Koja",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Tugu Utara - 14260"
    },
    {
      "id": "81",
      "postcode": "14270",
      "village": "Lagoa",
      "regency": "Koja",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Lagoa - 14270"
    },
    {
      "id": "82",
      "postcode": "14310",
      "village": "Tanjung Priok",
      "regency": "Tanjung Priok",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Tanjung Priok - 14310"
    },
    {
      "id": "83",
      "postcode": "14320",
      "village": "Kebon Bawang",
      "regency": "Tanjung Priok",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Kebon Bawang - 14320"
    },
    {
      "id": "84",
      "postcode": "14330",
      "village": "Sungai Bambu",
      "regency": "Tanjung Priok",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Sungai Bambu - 14330"
    },
    {
      "id": "85",
      "postcode": "14340",
      "village": "Papanggo",
      "regency": "Tanjung Priok",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Papanggo - 14340"
    },
    {
      "id": "86",
      "postcode": "14350",
      "village": "Sunter Agung",
      "regency": "Tanjung Priok",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Sunter Agung - 14350"
    },
    {
      "id": "87",
      "postcode": "14360",
      "village": "Sunter Jaya",
      "regency": "Tanjung Priok",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Sunter Jaya - 14360"
    },
    {
      "id": "88",
      "postcode": "14370",
      "village": "Warakas",
      "regency": "Tanjung Priok",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Warakas - 14370"
    },
    {
      "id": "89",
      "postcode": "14410",
      "village": "Pademangan Timur",
      "regency": "Pademangan",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Pademangan Timur - 14410"
    },
    {
      "id": "90",
      "postcode": "14420",
      "village": "Pademangan Barat",
      "regency": "Pademangan",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Pademangan Barat - 14420"
    },
    {
      "id": "91",
      "postcode": "14430",
      "village": "Ancol",
      "regency": "Pademangan",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Ancol - 14430"
    },
    {
      "id": "92",
      "postcode": "14440",
      "village": "Penjaringan",
      "regency": "Penjaringan",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Penjaringan - 14440"
    },
    {
      "id": "93",
      "postcode": "14450",
      "village": "Pejagalan",
      "regency": "Penjaringan",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Pejagalan - 14450"
    },
    {
      "id": "94",
      "postcode": "14450",
      "village": "Pluit",
      "regency": "Penjaringan",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Pluit - 14450"
    },
    {
      "id": "95",
      "postcode": "14460",
      "village": "Kapuk Muara",
      "regency": "Penjaringan",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Kapuk Muara - 14460"
    },
    {
      "id": "96",
      "postcode": "14470",
      "village": "Kamal Muara",
      "regency": "Penjaringan",
      "state": "Kota",
      "district": "Jakarta Utara",
      "province": "DKI Jakarta",
      "string": "Kamal Muara - 14470"
    },
    {
      "id": "97",
      "postcode": "13110",
      "village": "Pisangan Baru",
      "regency": "Matraman",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Pisangan Baru - 13110"
    },
    {
      "id": "98",
      "postcode": "13120",
      "village": "Utan Kayu Selatan",
      "regency": "Matraman",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Utan Kayu Selatan - 13120"
    },
    {
      "id": "99",
      "postcode": "13120",
      "village": "Utan Kayu Utara",
      "regency": "Matraman",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Utan Kayu Utara - 13120"
    },
    {
      "id": "100",
      "postcode": "13130",
      "village": "Kayu Manis",
      "regency": "Matraman",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Kayu Manis - 13130"
    },
    {
      "id": "101",
      "postcode": "13140",
      "village": "Pal Meriam",
      "regency": "Matraman",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Pal Meriam - 13140"
    },
    {
      "id": "102",
      "postcode": "13150",
      "village": "Kebon Manggis",
      "regency": "Matraman",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Kebon Manggis - 13150"
    },
    {
      "id": "103",
      "postcode": "13210",
      "village": "Kayu Putih",
      "regency": "Pulo Gadung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Kayu Putih - 13210"
    },
    {
      "id": "104",
      "postcode": "13220",
      "village": "Jati",
      "regency": "Pulo Gadung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Jati - 13220"
    },
    {
      "id": "105",
      "postcode": "13220",
      "village": "Rawamangun",
      "regency": "Pulo Gadung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Rawamangun - 13220"
    },
    {
      "id": "106",
      "postcode": "13230",
      "village": "Pisangan Timur",
      "regency": "Pulo Gadung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Pisangan Timur - 13230"
    },
    {
      "id": "107",
      "postcode": "13240",
      "village": "Cipinang",
      "regency": "Pulo Gadung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cipinang - 13240"
    },
    {
      "id": "108",
      "postcode": "13250",
      "village": "Jatinegara Kaum",
      "regency": "Pulo Gadung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Jatinegara Kaum - 13250"
    },
    {
      "id": "109",
      "postcode": "13260",
      "village": "Pulo Gadung",
      "regency": "Pulo Gadung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Pulo Gadung - 13260"
    },
    {
      "id": "110",
      "postcode": "13310",
      "village": "Bali Mester",
      "regency": "Jatinegara",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Bali Mester - 13310"
    },
    {
      "id": "111",
      "postcode": "13320",
      "village": "Kampung Melayu",
      "regency": "Jatinegara",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Kampung Melayu - 13320"
    },
    {
      "id": "112",
      "postcode": "13330",
      "village": "Bidaracina",
      "regency": "Jatinegara",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Bidaracina - 13330"
    },
    {
      "id": "113",
      "postcode": "13340",
      "village": "Cipinang Cempedak",
      "regency": "Jatinegara",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cipinang Cempedak - 13340"
    },
    {
      "id": "114",
      "postcode": "13350",
      "village": "Rawa Bunga",
      "regency": "Jatinegara",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Rawa Bunga - 13350"
    },
    {
      "id": "115",
      "postcode": "13410",
      "village": "Cipinang Besar Selatan",
      "regency": "Jatinegara",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cipinang Besar Selatan - 13410"
    },
    {
      "id": "116",
      "postcode": "13410",
      "village": "Cipinang Besar Utara",
      "regency": "Jatinegara",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cipinang Besar Utara - 13410"
    },
    {
      "id": "117",
      "postcode": "13420",
      "village": "Cipinang Muara",
      "regency": "Jatinegara",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cipinang Muara - 13420"
    },
    {
      "id": "118",
      "postcode": "13430",
      "village": "Pondok Bambu",
      "regency": "Duren Sawit",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Pondok Bambu - 13430"
    },
    {
      "id": "119",
      "postcode": "13440",
      "village": "Duren Sawit",
      "regency": "Duren Sawit",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Duren Sawit - 13440"
    },
    {
      "id": "120",
      "postcode": "13450",
      "village": "Pondok Kelapa",
      "regency": "Duren Sawit",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Pondok Kelapa - 13450"
    },
    {
      "id": "121",
      "postcode": "13460",
      "village": "Malaka Jaya",
      "regency": "Duren Sawit",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Malaka Jaya - 13460"
    },
    {
      "id": "122",
      "postcode": "13460",
      "village": "Malaka Sari",
      "regency": "Duren Sawit",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Malaka Sari - 13460"
    },
    {
      "id": "123",
      "postcode": "13460",
      "village": "Pondok Kopi",
      "regency": "Duren Sawit",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Pondok Kopi - 13460"
    },
    {
      "id": "124",
      "postcode": "13470",
      "village": "Klender",
      "regency": "Duren Sawit",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Klender - 13470"
    },
    {
      "id": "125",
      "postcode": "13510",
      "village": "Kramat Jati",
      "regency": "Kramat Jati",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Kramat Jati - 13510"
    },
    {
      "id": "126",
      "postcode": "13520",
      "village": "Batuampar",
      "regency": "Kramat Jati",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Batuampar - 13520"
    },
    {
      "id": "127",
      "postcode": "13530",
      "village": "Balekambang",
      "regency": "Kramat Jati",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Balekambang - 13530"
    },
    {
      "id": "128",
      "postcode": "13540",
      "village": "Kampung Tengah",
      "regency": "Kramat Jati",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Kampung Tengah - 13540"
    },
    {
      "id": "129",
      "postcode": "13550",
      "village": "Dukuh",
      "regency": "Kramat Jati",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Dukuh - 13550"
    },
    {
      "id": "130",
      "postcode": "13560",
      "village": "Pinang Ranti",
      "regency": "Makasar",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Pinang Ranti - 13560"
    },
    {
      "id": "131",
      "postcode": "13570",
      "village": "Makasar",
      "regency": "Makasar",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Makasar - 13570"
    },
    {
      "id": "132",
      "postcode": "13610",
      "village": "Halim Perdana Kusumah",
      "regency": "Makasar",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Halim Perdana Kusumah - 13610"
    },
    {
      "id": "133",
      "postcode": "13620",
      "village": "Cipinang Melayu",
      "regency": "Makasar",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cipinang Melayu - 13620"
    },
    {
      "id": "134",
      "postcode": "13630",
      "village": "Cawang",
      "regency": "Kramat Jati",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cawang - 13630"
    },
    {
      "id": "135",
      "postcode": "13640",
      "village": "Cililitan",
      "regency": "Kramat Jati",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cililitan - 13640"
    },
    {
      "id": "136",
      "postcode": "13650",
      "village": "Kebon Pala",
      "regency": "Makasar",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Kebon Pala - 13650"
    },
    {
      "id": "137",
      "postcode": "13710",
      "village": "Pekayon",
      "regency": "Pasar Rebo",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Pekayon - 13710"
    },
    {
      "id": "138",
      "postcode": "13720",
      "village": "Cibubur",
      "regency": "Ciracas",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cibubur - 13720"
    },
    {
      "id": "139",
      "postcode": "13730",
      "village": "Kelapa Dua Wetan",
      "regency": "Ciracas",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Kelapa Dua Wetan - 13730"
    },
    {
      "id": "140",
      "postcode": "13740",
      "village": "Ciracas",
      "regency": "Ciracas",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Ciracas - 13740"
    },
    {
      "id": "141",
      "postcode": "13750",
      "village": "Susukan",
      "regency": "Ciracas",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Susukan - 13750"
    },
    {
      "id": "142",
      "postcode": "13760",
      "village": "Gedong",
      "regency": "Pasar Rebo",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Gedong - 13760"
    },
    {
      "id": "143",
      "postcode": "13770",
      "village": "Cijantung",
      "regency": "Pasar Rebo",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cijantung - 13770"
    },
    {
      "id": "144",
      "postcode": "13780",
      "village": "Baru",
      "regency": "Pasar Rebo",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Baru - 13780"
    },
    {
      "id": "145",
      "postcode": "13790",
      "village": "Kalisari",
      "regency": "Pasar Rebo",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Kalisari - 13790"
    },
    {
      "id": "146",
      "postcode": "13810",
      "village": "Lubang Buaya",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Lubang Buaya - 13810"
    },
    {
      "id": "147",
      "postcode": "13820",
      "village": "Ceger",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Ceger - 13820"
    },
    {
      "id": "148",
      "postcode": "13830",
      "village": "Rambutan",
      "regency": "Ciracas",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Rambutan - 13830"
    },
    {
      "id": "149",
      "postcode": "13840",
      "village": "Cipayung",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cipayung - 13840"
    },
    {
      "id": "150",
      "postcode": "13850",
      "village": "Munjul",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Munjul - 13850"
    },
    {
      "id": "151",
      "postcode": "13860",
      "village": "Pondok Rangon",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Pondok Rangon - 13860"
    },
    {
      "id": "152",
      "postcode": "13870",
      "village": "Cilangkap",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cilangkap - 13870"
    },
    {
      "id": "153",
      "postcode": "13880",
      "village": "Setu",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Setu - 13880"
    },
    {
      "id": "154",
      "postcode": "13890",
      "village": "Bambu Apus",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Bambu Apus - 13890"
    },
    {
      "id": "155",
      "postcode": "13910",
      "village": "Cakung Barat",
      "regency": "Cakung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cakung Barat - 13910"
    },
    {
      "id": "156",
      "postcode": "13910",
      "village": "Cakung Timur",
      "regency": "Cakung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Cakung Timur - 13910"
    },
    {
      "id": "157",
      "postcode": "13920",
      "village": "Rawa Terate",
      "regency": "Cakung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Rawa Terate - 13920"
    },
    {
      "id": "158",
      "postcode": "13930",
      "village": "Jatinegara",
      "regency": "Cakung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Jatinegara - 13930"
    },
    {
      "id": "159",
      "postcode": "13940",
      "village": "Penggilingan",
      "regency": "Cakung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Penggilingan - 13940"
    },
    {
      "id": "160",
      "postcode": "13950",
      "village": "Pulo Gebang",
      "regency": "Cakung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Pulo Gebang - 13950"
    },
    {
      "id": "161",
      "postcode": "13960",
      "village": "Ujung Menteng",
      "regency": "Cakung",
      "state": "Kota",
      "district": "Jakarta Timur",
      "province": "DKI Jakarta",
      "string": "Ujung Menteng - 13960"
    },
    {
      "id": "162",
      "postcode": "11110",
      "village": "Pinangsia",
      "regency": "Taman Sari",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Pinangsia - 11110"
    },
    {
      "id": "163",
      "postcode": "11120",
      "village": "Glodok",
      "regency": "Taman Sari",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Glodok - 11120"
    },
    {
      "id": "164",
      "postcode": "11130",
      "village": "Keagungan",
      "regency": "Taman Sari",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Keagungan - 11130"
    },
    {
      "id": "165",
      "postcode": "11140",
      "village": "Krukut",
      "regency": "Taman Sari",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Krukut - 11140"
    },
    {
      "id": "166",
      "postcode": "11150",
      "village": "Taman Sari",
      "regency": "Taman Sari",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Taman Sari - 11150"
    },
    {
      "id": "167",
      "postcode": "11160",
      "village": "Maphar",
      "regency": "Taman Sari",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Maphar - 11160"
    },
    {
      "id": "168",
      "postcode": "11170",
      "village": "Tangki",
      "regency": "Taman Sari",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Tangki - 11170"
    },
    {
      "id": "169",
      "postcode": "11180",
      "village": "Mangga Besar",
      "regency": "Taman Sari",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Mangga Besar - 11180"
    },
    {
      "id": "170",
      "postcode": "11210",
      "village": "Tanah Sereal",
      "regency": "Tambora",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Tanah Sereal - 11210"
    },
    {
      "id": "171",
      "postcode": "11220",
      "village": "Tambora",
      "regency": "Tambora",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Tambora - 11220"
    },
    {
      "id": "172",
      "postcode": "11230",
      "village": "Roa Malaka",
      "regency": "Tambora",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Roa Malaka - 11230"
    },
    {
      "id": "173",
      "postcode": "11240",
      "village": "Pekojan",
      "regency": "Tambora",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Pekojan - 11240"
    },
    {
      "id": "174",
      "postcode": "11250",
      "village": "Jembatan Lima",
      "regency": "Tambora",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Jembatan Lima - 11250"
    },
    {
      "id": "175",
      "postcode": "11260",
      "village": "Krendang",
      "regency": "Tambora",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Krendang - 11260"
    },
    {
      "id": "176",
      "postcode": "11270",
      "village": "Duri Selatan",
      "regency": "Tambora",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Duri Selatan - 11270"
    },
    {
      "id": "177",
      "postcode": "11270",
      "village": "Duri Utara",
      "regency": "Tambora",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Duri Utara - 11270"
    },
    {
      "id": "178",
      "postcode": "11310",
      "village": "Kali Anyar",
      "regency": "Tambora",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kali Anyar - 11310"
    },
    {
      "id": "179",
      "postcode": "11320",
      "village": "Jembatan Besi",
      "regency": "Tambora",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Jembatan Besi - 11320"
    },
    {
      "id": "180",
      "postcode": "11330",
      "village": "Angke",
      "regency": "Tambora",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Angke - 11330"
    },
    {
      "id": "181",
      "postcode": "11410",
      "village": "Slipi",
      "regency": "Palmerah",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Slipi - 11410"
    },
    {
      "id": "182",
      "postcode": "11420",
      "village": "Kota Bambu Selatan",
      "regency": "Palmerah",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kota Bambu Selatan - 11420"
    },
    {
      "id": "183",
      "postcode": "11420",
      "village": "Kota Bambu Utara",
      "regency": "Palmerah",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kota Bambu Utara - 11420"
    },
    {
      "id": "184",
      "postcode": "11430",
      "village": "Jatipulo",
      "regency": "Palmerah",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Jatipulo - 11430"
    },
    {
      "id": "185",
      "postcode": "11440",
      "village": "Tomang",
      "regency": "Grogol Petamburan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Tomang - 11440"
    },
    {
      "id": "186",
      "postcode": "11450",
      "village": "Grogol",
      "regency": "Grogol Petamburan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Grogol - 11450"
    },
    {
      "id": "187",
      "postcode": "11460",
      "village": "Jelambar",
      "regency": "Grogol Petamburan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Jelambar - 11460"
    },
    {
      "id": "188",
      "postcode": "11460",
      "village": "Jelambar Baru",
      "regency": "Grogol Petamburan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Jelambar Baru - 11460"
    },
    {
      "id": "189",
      "postcode": "11460",
      "village": "Wijaya Kusuma",
      "regency": "Grogol Petamburan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Wijaya Kusuma - 11460"
    },
    {
      "id": "190",
      "postcode": "11470",
      "village": "Tanjung Duren Selatan",
      "regency": "Grogol Petamburan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Tanjung Duren Selatan - 11470"
    },
    {
      "id": "191",
      "postcode": "11470",
      "village": "Tanjung Duren Utara",
      "regency": "Grogol Petamburan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Tanjung Duren Utara - 11470"
    },
    {
      "id": "192",
      "postcode": "11480",
      "village": "Kemanggisan",
      "regency": "Palmerah",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kemanggisan - 11480"
    },
    {
      "id": "193",
      "postcode": "11480",
      "village": "Palmerah",
      "regency": "Palmerah",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Palmerah - 11480"
    },
    {
      "id": "194",
      "postcode": "11510",
      "village": "Duri Kepa",
      "regency": "Kebon Jeruk",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Duri Kepa - 11510"
    },
    {
      "id": "195",
      "postcode": "11520",
      "village": "Kedoya Selatan",
      "regency": "Kebon Jeruk",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kedoya Selatan - 11520"
    },
    {
      "id": "196",
      "postcode": "11520",
      "village": "Kedoya Utara",
      "regency": "Kebon Jeruk",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kedoya Utara - 11520"
    },
    {
      "id": "197",
      "postcode": "11530",
      "village": "Kebon Jeruk",
      "regency": "Kebon Jeruk",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kebon Jeruk - 11530"
    },
    {
      "id": "198",
      "postcode": "11540",
      "village": "Sukabumi Utara (Ilir)",
      "regency": "Kebon Jeruk",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Sukabumi Utara (Ilir) - 11540"
    },
    {
      "id": "199",
      "postcode": "11550",
      "village": "Kelapa Dua",
      "regency": "Kebon Jeruk",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kelapa Dua - 11550"
    },
    {
      "id": "200",
      "postcode": "11560",
      "village": "Sukabumi Selatan (Udik)",
      "regency": "Kebon Jeruk",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Sukabumi Selatan (Udik) - 11560"
    },
    {
      "id": "201",
      "postcode": "11610",
      "village": "Kembangan Selatan",
      "regency": "Kembangan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kembangan Selatan - 11610"
    },
    {
      "id": "202",
      "postcode": "11610",
      "village": "Kembangan Utara",
      "regency": "Kembangan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kembangan Utara - 11610"
    },
    {
      "id": "203",
      "postcode": "11620",
      "village": "Meruya Utara (Ilir)",
      "regency": "Kembangan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Meruya Utara (Ilir) - 11620"
    },
    {
      "id": "204",
      "postcode": "11630",
      "village": "Srengseng",
      "regency": "Kembangan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Srengseng - 11630"
    },
    {
      "id": "205",
      "postcode": "11640",
      "village": "Joglo",
      "regency": "Kembangan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Joglo - 11640"
    },
    {
      "id": "206",
      "postcode": "11650",
      "village": "Meruya Selatan (Udik)",
      "regency": "Kembangan",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Meruya Selatan (Udik) - 11650"
    },
    {
      "id": "207",
      "postcode": "11710",
      "village": "Kedaung Kali Angke",
      "regency": "Cengkareng",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kedaung Kali Angke - 11710"
    },
    {
      "id": "208",
      "postcode": "11720",
      "village": "Kapuk",
      "regency": "Cengkareng",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kapuk - 11720"
    },
    {
      "id": "209",
      "postcode": "11730",
      "village": "Cengkareng Barat",
      "regency": "Cengkareng",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Cengkareng Barat - 11730"
    },
    {
      "id": "210",
      "postcode": "11730",
      "village": "Cengkareng Timur",
      "regency": "Cengkareng",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Cengkareng Timur - 11730"
    },
    {
      "id": "211",
      "postcode": "11740",
      "village": "Rawa Buaya",
      "regency": "Cengkareng",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Rawa Buaya - 11740"
    },
    {
      "id": "212",
      "postcode": "11750",
      "village": "Duri Kosambi",
      "regency": "Cengkareng",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Duri Kosambi - 11750"
    },
    {
      "id": "213",
      "postcode": "11810",
      "village": "Kamal",
      "regency": "Kalideres",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kamal - 11810"
    },
    {
      "id": "214",
      "postcode": "11820",
      "village": "Tegal Alur",
      "regency": "Kalideres",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Tegal Alur - 11820"
    },
    {
      "id": "215",
      "postcode": "11830",
      "village": "Pegadungan",
      "regency": "Kalideres",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Pegadungan - 11830"
    },
    {
      "id": "216",
      "postcode": "11840",
      "village": "Kalideres",
      "regency": "Kalideres",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Kalideres - 11840"
    },
    {
      "id": "217",
      "postcode": "11850",
      "village": "Semanan",
      "regency": "Kalideres",
      "state": "Kota",
      "district": "Jakarta Barat",
      "province": "DKI Jakarta",
      "string": "Semanan - 11850"
    },
    {
      "id": "218",
      "postcode": "10110",
      "village": "Gambir",
      "regency": "Gambir",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Gambir - 10110"
    },
    {
      "id": "219",
      "postcode": "10120",
      "village": "Kebon Kelapa",
      "regency": "Gambir",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kebon Kelapa - 10120"
    },
    {
      "id": "220",
      "postcode": "10130",
      "village": "Petojo Utara",
      "regency": "Gambir",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Petojo Utara - 10130"
    },
    {
      "id": "221",
      "postcode": "10140",
      "village": "Duri Pulo",
      "regency": "Gambir",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Duri Pulo - 10140"
    },
    {
      "id": "222",
      "postcode": "10150",
      "village": "Cideng",
      "regency": "Gambir",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Cideng - 10150"
    },
    {
      "id": "223",
      "postcode": "10160",
      "village": "Petojo Selatan",
      "regency": "Gambir",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Petojo Selatan - 10160"
    },
    {
      "id": "224",
      "postcode": "10210",
      "village": "Bendungan Hilir",
      "regency": "Tanah Abang",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Bendungan Hilir - 10210"
    },
    {
      "id": "225",
      "postcode": "10220",
      "village": "Karet Tengsin",
      "regency": "Tanah Abang",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Karet Tengsin - 10220"
    },
    {
      "id": "226",
      "postcode": "10230",
      "village": "Kebon Melati",
      "regency": "Tanah Abang",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kebon Melati - 10230"
    },
    {
      "id": "227",
      "postcode": "10240",
      "village": "Kebon Kacang",
      "regency": "Tanah Abang",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kebon Kacang - 10240"
    },
    {
      "id": "228",
      "postcode": "10250",
      "village": "Kampung Bali",
      "regency": "Tanah Abang",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kampung Bali - 10250"
    },
    {
      "id": "229",
      "postcode": "10260",
      "village": "Petamburan",
      "regency": "Tanah Abang",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Petamburan - 10260"
    },
    {
      "id": "230",
      "postcode": "10270",
      "village": "Gelora",
      "regency": "Tanah Abang",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Gelora - 10270"
    },
    {
      "id": "231",
      "postcode": "10310",
      "village": "Menteng",
      "regency": "Menteng",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Menteng - 10310"
    },
    {
      "id": "232",
      "postcode": "10320",
      "village": "Pegangsaan",
      "regency": "Menteng",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Pegangsaan - 10320"
    },
    {
      "id": "233",
      "postcode": "10330",
      "village": "Cikini",
      "regency": "Menteng",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Cikini - 10330"
    },
    {
      "id": "234",
      "postcode": "10340",
      "village": "Kebon Sirih",
      "regency": "Menteng",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kebon Sirih - 10340"
    },
    {
      "id": "235",
      "postcode": "10350",
      "village": "Gondangdia",
      "regency": "Menteng",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Gondangdia - 10350"
    },
    {
      "id": "236",
      "postcode": "10410",
      "village": "Senen",
      "regency": "Senen",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Senen - 10410"
    },
    {
      "id": "237",
      "postcode": "10420",
      "village": "Kwitang",
      "regency": "Senen",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kwitang - 10420"
    },
    {
      "id": "238",
      "postcode": "10430",
      "village": "Kenari",
      "regency": "Senen",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kenari - 10430"
    },
    {
      "id": "239",
      "postcode": "10440",
      "village": "Paseban",
      "regency": "Senen",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Paseban - 10440"
    },
    {
      "id": "240",
      "postcode": "10450",
      "village": "Kramat",
      "regency": "Senen",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kramat - 10450"
    },
    {
      "id": "241",
      "postcode": "10460",
      "village": "Bungur",
      "regency": "Senen",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Bungur - 10460"
    },
    {
      "id": "242",
      "postcode": "10510",
      "village": "Cempaka Putih Timur",
      "regency": "Cempaka Putih",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Cempaka Putih Timur - 10510"
    },
    {
      "id": "243",
      "postcode": "10520",
      "village": "Cempaka Putih Barat",
      "regency": "Cempaka Putih",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Cempaka Putih Barat - 10520"
    },
    {
      "id": "244",
      "postcode": "10530",
      "village": "Galur",
      "regency": "Johar Baru",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Galur - 10530"
    },
    {
      "id": "245",
      "postcode": "10540",
      "village": "Tanah Tinggi",
      "regency": "Johar Baru",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Tanah Tinggi - 10540"
    },
    {
      "id": "246",
      "postcode": "10550",
      "village": "Kampung Rawa",
      "regency": "Johar Baru",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kampung Rawa - 10550"
    },
    {
      "id": "247",
      "postcode": "10560",
      "village": "Johar Baru",
      "regency": "Johar Baru",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Johar Baru - 10560"
    },
    {
      "id": "248",
      "postcode": "10570",
      "village": "Rawasari",
      "regency": "Cempaka Putih",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Rawasari - 10570"
    },
    {
      "id": "249",
      "postcode": "10610",
      "village": "Gunung Sahari Selatan",
      "regency": "Kemayoran",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Gunung Sahari Selatan - 10610"
    },
    {
      "id": "250",
      "postcode": "10620",
      "village": "Kemayoran",
      "regency": "Kemayoran",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kemayoran - 10620"
    },
    {
      "id": "251",
      "postcode": "10630",
      "village": "Kebon Kosong",
      "regency": "Kemayoran",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kebon Kosong - 10630"
    },
    {
      "id": "252",
      "postcode": "10640",
      "village": "Cempaka Baru",
      "regency": "Kemayoran",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Cempaka Baru - 10640"
    },
    {
      "id": "253",
      "postcode": "10640",
      "village": "Harapan Mulya",
      "regency": "Kemayoran",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Harapan Mulya - 10640"
    },
    {
      "id": "254",
      "postcode": "10640",
      "village": "Sumur Batu",
      "regency": "Kemayoran",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Sumur Batu - 10640"
    },
    {
      "id": "255",
      "postcode": "10650",
      "village": "Serdang",
      "regency": "Kemayoran",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Serdang - 10650"
    },
    {
      "id": "256",
      "postcode": "10650",
      "village": "Utan Panjang",
      "regency": "Kemayoran",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Utan Panjang - 10650"
    },
    {
      "id": "257",
      "postcode": "10710",
      "village": "Pasar Baru",
      "regency": "Sawah Besar",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Pasar Baru - 10710"
    },
    {
      "id": "258",
      "postcode": "10720",
      "village": "Gunung Sahari Utara",
      "regency": "Sawah Besar",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Gunung Sahari Utara - 10720"
    },
    {
      "id": "259",
      "postcode": "10730",
      "village": "Mangga Dua Selatan",
      "regency": "Sawah Besar",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Mangga Dua Selatan - 10730"
    },
    {
      "id": "260",
      "postcode": "10740",
      "village": "Karang Anyar",
      "regency": "Sawah Besar",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Karang Anyar - 10740"
    },
    {
      "id": "261",
      "postcode": "10750",
      "village": "Kartini",
      "regency": "Sawah Besar",
      "state": "Kota",
      "district": "Jakarta Pusat",
      "province": "DKI Jakarta",
      "string": "Kartini - 10750"
    },
    {
      "id": "262",
      "postcode": "16110",
      "village": "Ciadeg",
      "regency": "Cigombong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciadeg - 16110"
    },
    {
      "id": "263",
      "postcode": "16110",
      "village": "Ciburayut",
      "regency": "Cigombong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciburayut - 16110"
    },
    {
      "id": "264",
      "postcode": "16110",
      "village": "Ciburuy",
      "regency": "Cigombong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciburuy - 16110"
    },
    {
      "id": "265",
      "postcode": "16110",
      "village": "Cigombong",
      "regency": "Cigombong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cigombong - 16110"
    },
    {
      "id": "266",
      "postcode": "16110",
      "village": "Cisalada",
      "regency": "Cigombong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cisalada - 16110"
    },
    {
      "id": "267",
      "postcode": "16110",
      "village": "Srogol",
      "regency": "Cigombong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Srogol - 16110"
    },
    {
      "id": "268",
      "postcode": "16110",
      "village": "Tugujaya",
      "regency": "Cigombong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tugujaya - 16110"
    },
    {
      "id": "269",
      "postcode": "16110",
      "village": "Watesjaya",
      "regency": "Cigombong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Watesjaya - 16110"
    },
    {
      "id": "270",
      "postcode": "16111",
      "village": "Menteng",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Menteng - 16111"
    },
    {
      "id": "271",
      "postcode": "16112",
      "village": "Cilendek Barat",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cilendek Barat - 16112"
    },
    {
      "id": "272",
      "postcode": "16112",
      "village": "Cilendek Timur",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cilendek Timur - 16112"
    },
    {
      "id": "273",
      "postcode": "16113",
      "village": "Curug",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Curug - 16113"
    },
    {
      "id": "274",
      "postcode": "16113",
      "village": "Curug Mekar",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Curug Mekar - 16113"
    },
    {
      "id": "275",
      "postcode": "16114",
      "village": "Semplak",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Semplak - 16114"
    },
    {
      "id": "276",
      "postcode": "16115",
      "village": "Bubulak",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bubulak - 16115"
    },
    {
      "id": "277",
      "postcode": "16115",
      "village": "Situ Gede",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Situ Gede - 16115"
    },
    {
      "id": "278",
      "postcode": "16116",
      "village": "Balungbang Jaya",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Balungbang Jaya - 16116"
    },
    {
      "id": "279",
      "postcode": "16116",
      "village": "Margajaya",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Margajaya - 16116"
    },
    {
      "id": "280",
      "postcode": "16117",
      "village": "Loji",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Loji - 16117"
    },
    {
      "id": "281",
      "postcode": "16117",
      "village": "Sindangbarang",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sindangbarang - 16117"
    },
    {
      "id": "282",
      "postcode": "16118",
      "village": "Gunungbatu",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gunungbatu - 16118"
    },
    {
      "id": "283",
      "postcode": "16118",
      "village": "Pasir Mulya",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Mulya - 16118"
    },
    {
      "id": "284",
      "postcode": "16119",
      "village": "Pasir Jaya",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Jaya - 16119"
    },
    {
      "id": "285",
      "postcode": "16119",
      "village": "Pasir Jaya",
      "regency": "Cigombong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Jaya - 16119"
    },
    {
      "id": "286",
      "postcode": "16119",
      "village": "Pasir Kuda",
      "regency": "Bogor Barat - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Kuda - 16119"
    },
    {
      "id": "287",
      "postcode": "16120",
      "village": "Babakan",
      "regency": "Ciseeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Babakan - 16120"
    },
    {
      "id": "288",
      "postcode": "16120",
      "village": "Cibentang",
      "regency": "Ciseeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibentang - 16120"
    },
    {
      "id": "289",
      "postcode": "16120",
      "village": "Cibeuteung Muara",
      "regency": "Ciseeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibeuteung Muara - 16120"
    },
    {
      "id": "290",
      "postcode": "16120",
      "village": "Cibeuteung Udik",
      "regency": "Ciseeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibeuteung Udik - 16120"
    },
    {
      "id": "291",
      "postcode": "16120",
      "village": "Cihoe (Cihowe)",
      "regency": "Ciseeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cihoe (Cihowe) - 16120"
    },
    {
      "id": "292",
      "postcode": "16120",
      "village": "Ciseeng",
      "regency": "Ciseeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciseeng - 16120"
    },
    {
      "id": "293",
      "postcode": "16120",
      "village": "Karihkil",
      "regency": "Ciseeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Karihkil - 16120"
    },
    {
      "id": "294",
      "postcode": "16120",
      "village": "Kuripan",
      "regency": "Ciseeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kuripan - 16120"
    },
    {
      "id": "295",
      "postcode": "16120",
      "village": "Parigi Mekar",
      "regency": "Ciseeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Parigi Mekar - 16120"
    },
    {
      "id": "296",
      "postcode": "16120",
      "village": "Putat Nutug",
      "regency": "Ciseeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Putat Nutug - 16120"
    },
    {
      "id": "297",
      "postcode": "16121",
      "village": "Pabaton",
      "regency": "Bogor Tengah - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pabaton - 16121"
    },
    {
      "id": "298",
      "postcode": "16122",
      "village": "Paledang",
      "regency": "Bogor Tengah - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Paledang - 16122"
    },
    {
      "id": "299",
      "postcode": "16123",
      "village": "Gudang",
      "regency": "Bogor Tengah - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gudang - 16123"
    },
    {
      "id": "300",
      "postcode": "16124",
      "village": "Cibogor",
      "regency": "Bogor Tengah - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibogor - 16124"
    },
    {
      "id": "301",
      "postcode": "16124",
      "village": "Ciwaringin",
      "regency": "Bogor Tengah - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciwaringin - 16124"
    },
    {
      "id": "302",
      "postcode": "16125",
      "village": "Kebon Kelapa",
      "regency": "Bogor Tengah - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kebon Kelapa - 16125"
    },
    {
      "id": "303",
      "postcode": "16125",
      "village": "Panaragan",
      "regency": "Bogor Tengah - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Panaragan - 16125"
    },
    {
      "id": "304",
      "postcode": "16126",
      "village": "Babakan Pasar",
      "regency": "Bogor Tengah - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Babakan Pasar - 16126"
    },
    {
      "id": "305",
      "postcode": "16128",
      "village": "Babakan",
      "regency": "Bogor Tengah - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Babakan - 16128"
    },
    {
      "id": "306",
      "postcode": "16129",
      "village": "Sempur",
      "regency": "Bogor Tengah - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sempur - 16129"
    },
    {
      "id": "307",
      "postcode": "16129",
      "village": "Tegal Panjang",
      "regency": "Bogor Tengah - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tegal Panjang - 16129"
    },
    {
      "id": "308",
      "postcode": "16131",
      "village": "Bondongan",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bondongan - 16131"
    },
    {
      "id": "309",
      "postcode": "16132",
      "village": "Cikaret",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cikaret - 16132"
    },
    {
      "id": "310",
      "postcode": "16132",
      "village": "Empang",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Empang - 16132"
    },
    {
      "id": "311",
      "postcode": "16133",
      "village": "Batutulis",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Batutulis - 16133"
    },
    {
      "id": "312",
      "postcode": "16133",
      "village": "Cipaku",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cipaku - 16133"
    },
    {
      "id": "313",
      "postcode": "16134",
      "village": "Lawang Gintung",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Lawang Gintung - 16134"
    },
    {
      "id": "314",
      "postcode": "16134",
      "village": "Pakuan",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pakuan - 16134"
    },
    {
      "id": "315",
      "postcode": "16135",
      "village": "Mulyaharja",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Mulyaharja - 16135"
    },
    {
      "id": "316",
      "postcode": "16136",
      "village": "Pamoyanan",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pamoyanan - 16136"
    },
    {
      "id": "317",
      "postcode": "16136",
      "village": "Ranggamekar",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ranggamekar - 16136"
    },
    {
      "id": "318",
      "postcode": "16137",
      "village": "Genteng",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Genteng - 16137"
    },
    {
      "id": "319",
      "postcode": "16137",
      "village": "Muarasari",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Muarasari - 16137"
    },
    {
      "id": "320",
      "postcode": "16138",
      "village": "Harjasari",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Harjasari - 16138"
    },
    {
      "id": "321",
      "postcode": "16138",
      "village": "Kertamaya",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kertamaya - 16138"
    },
    {
      "id": "322",
      "postcode": "16139",
      "village": "Bojongkerta",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojongkerta - 16139"
    },
    {
      "id": "323",
      "postcode": "16139",
      "village": "Rancamaya",
      "regency": "Bogor Selatan - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Rancamaya - 16139"
    },
    {
      "id": "324",
      "postcode": "16141",
      "village": "Tajur",
      "regency": "Bogor Timur - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tajur - 16141"
    },
    {
      "id": "325",
      "postcode": "16142",
      "village": "Sukasari",
      "regency": "Bogor Timur - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukasari - 16142"
    },
    {
      "id": "326",
      "postcode": "16143",
      "village": "Baranangsiang",
      "regency": "Bogor Timur - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Baranangsiang - 16143"
    },
    {
      "id": "327",
      "postcode": "16144",
      "village": "Katulampa",
      "regency": "Bogor Timur - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Katulampa - 16144"
    },
    {
      "id": "328",
      "postcode": "16145",
      "village": "Sindangrasa",
      "regency": "Bogor Timur - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sindangrasa - 16145"
    },
    {
      "id": "329",
      "postcode": "16146",
      "village": "Sindangsari",
      "regency": "Bogor Timur - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sindangsari - 16146"
    },
    {
      "id": "330",
      "postcode": "16151",
      "village": "Cibuluh",
      "regency": "Bogor Utara - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibuluh - 16151"
    },
    {
      "id": "331",
      "postcode": "16152",
      "village": "Tegal Gundil",
      "regency": "Bogor Utara - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tegal Gundil - 16152"
    },
    {
      "id": "332",
      "postcode": "16153",
      "village": "Bantarjati",
      "regency": "Bogor Utara - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bantarjati - 16153"
    },
    {
      "id": "333",
      "postcode": "16154",
      "village": "Tanah Baru",
      "regency": "Bogor Utara - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tanah Baru - 16154"
    },
    {
      "id": "334",
      "postcode": "16155",
      "village": "Cimahpar",
      "regency": "Bogor Utara - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cimahpar - 16155"
    },
    {
      "id": "335",
      "postcode": "16156",
      "village": "Ciluar",
      "regency": "Bogor Utara - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciluar - 16156"
    },
    {
      "id": "336",
      "postcode": "16157",
      "village": "Ciparigi",
      "regency": "Bogor Utara - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciparigi - 16157"
    },
    {
      "id": "337",
      "postcode": "16158",
      "village": "Kedunghalang",
      "regency": "Bogor Utara - Kota",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kedunghalang - 16158"
    },
    {
      "id": "338",
      "postcode": "16161",
      "village": "Tanah Sareal",
      "regency": "Tanah Sereal",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tanah Sareal - 16161"
    },
    {
      "id": "339",
      "postcode": "16162",
      "village": "Kebon Pedes",
      "regency": "Tanah Sereal",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kebon Pedes - 16162"
    },
    {
      "id": "340",
      "postcode": "16164",
      "village": "Kedung Badak",
      "regency": "Tanah Sereal",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kedung Badak - 16164"
    },
    {
      "id": "341",
      "postcode": "16164",
      "village": "Kedung Jaya",
      "regency": "Tanah Sereal",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kedung Jaya - 16164"
    },
    {
      "id": "342",
      "postcode": "16164",
      "village": "Kedung Waringin",
      "regency": "Tanah Sereal",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kedung Waringin - 16164"
    },
    {
      "id": "343",
      "postcode": "16165",
      "village": "Sukadamai",
      "regency": "Tanah Sereal",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukadamai - 16165"
    },
    {
      "id": "344",
      "postcode": "16165",
      "village": "Sukaresmi",
      "regency": "Tanah Sereal",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukaresmi - 16165"
    },
    {
      "id": "345",
      "postcode": "16166",
      "village": "Cibadak",
      "regency": "Tanah Sereal",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibadak - 16166"
    },
    {
      "id": "346",
      "postcode": "16167",
      "village": "Kencana",
      "regency": "Tanah Sereal",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kencana - 16167"
    },
    {
      "id": "347",
      "postcode": "16168",
      "village": "Mekarwangi",
      "regency": "Tanah Sereal",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Mekarwangi - 16168"
    },
    {
      "id": "348",
      "postcode": "16169",
      "village": "Kayumanis",
      "regency": "Tanah Sereal",
      "state": "Kota",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kayumanis - 16169"
    },
    {
      "id": "349",
      "postcode": "16310",
      "village": "Atang Senjaya",
      "regency": "Kemang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Atang Senjaya - 16310"
    },
    {
      "id": "350",
      "postcode": "16310",
      "village": "Bantarjaya",
      "regency": "Ranca Bungur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bantarjaya - 16310"
    },
    {
      "id": "351",
      "postcode": "16310",
      "village": "Bantarsari",
      "regency": "Ranca Bungur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bantarsari - 16310"
    },
    {
      "id": "352",
      "postcode": "16310",
      "village": "Bojong",
      "regency": "Kemang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong - 16310"
    },
    {
      "id": "353",
      "postcode": "16310",
      "village": "Candali",
      "regency": "Ranca Bungur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Candali - 16310"
    },
    {
      "id": "354",
      "postcode": "16310",
      "village": "Jampang",
      "regency": "Kemang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jampang - 16310"
    },
    {
      "id": "355",
      "postcode": "16310",
      "village": "Kemang",
      "regency": "Kemang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kemang - 16310"
    },
    {
      "id": "356",
      "postcode": "16310",
      "village": "Mekarsari",
      "regency": "Ranca Bungur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Mekarsari - 16310"
    },
    {
      "id": "357",
      "postcode": "16310",
      "village": "Pabuaran",
      "regency": "Kemang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pabuaran - 16310"
    },
    {
      "id": "358",
      "postcode": "16310",
      "village": "Parakan Jaya",
      "regency": "Kemang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Parakan Jaya - 16310"
    },
    {
      "id": "359",
      "postcode": "16310",
      "village": "Pasirgaok",
      "regency": "Ranca Bungur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasirgaok - 16310"
    },
    {
      "id": "360",
      "postcode": "16310",
      "village": "Pondok Udik",
      "regency": "Kemang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pondok Udik - 16310"
    },
    {
      "id": "361",
      "postcode": "16310",
      "village": "Rancabungur",
      "regency": "Ranca Bungur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Rancabungur - 16310"
    },
    {
      "id": "362",
      "postcode": "16310",
      "village": "Semplak Barat",
      "regency": "Kemang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Semplak Barat - 16310"
    },
    {
      "id": "363",
      "postcode": "16310",
      "village": "Tegal",
      "regency": "Kemang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tegal - 16310"
    },
    {
      "id": "364",
      "postcode": "16320",
      "village": "Citayam",
      "regency": "Tajurhalang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Citayam - 16320"
    },
    {
      "id": "365",
      "postcode": "16320",
      "village": "Kalisuren",
      "regency": "Tajurhalang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kalisuren - 16320"
    },
    {
      "id": "366",
      "postcode": "16320",
      "village": "Nanggerang",
      "regency": "Tajurhalang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Nanggerang - 16320"
    },
    {
      "id": "367",
      "postcode": "16320",
      "village": "Sasak Panjang",
      "regency": "Tajurhalang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sasak Panjang - 16320"
    },
    {
      "id": "368",
      "postcode": "16320",
      "village": "Sukmajaya",
      "regency": "Tajurhalang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukmajaya - 16320"
    },
    {
      "id": "369",
      "postcode": "16320",
      "village": "Tajur Halang",
      "regency": "Tajurhalang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tajur Halang - 16320"
    },
    {
      "id": "370",
      "postcode": "16320",
      "village": "Tonjong",
      "regency": "Tajurhalang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tonjong - 16320"
    },
    {
      "id": "371",
      "postcode": "16330",
      "village": "Bojong Indah",
      "regency": "Parung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong Indah - 16330"
    },
    {
      "id": "372",
      "postcode": "16330",
      "village": "Bojong Sempu",
      "regency": "Parung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong Sempu - 16330"
    },
    {
      "id": "373",
      "postcode": "16330",
      "village": "Cogreg",
      "regency": "Parung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cogreg - 16330"
    },
    {
      "id": "374",
      "postcode": "16330",
      "village": "Iwul",
      "regency": "Parung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Iwul - 16330"
    },
    {
      "id": "375",
      "postcode": "16330",
      "village": "Jabon Mekar",
      "regency": "Parung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jabon Mekar - 16330"
    },
    {
      "id": "376",
      "postcode": "16330",
      "village": "Pamagersari",
      "regency": "Parung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pamagersari - 16330"
    },
    {
      "id": "377",
      "postcode": "16330",
      "village": "Parung",
      "regency": "Parung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Parung - 16330"
    },
    {
      "id": "378",
      "postcode": "16330",
      "village": "Waru",
      "regency": "Parung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Waru - 16330"
    },
    {
      "id": "379",
      "postcode": "16330",
      "village": "Warujaya",
      "regency": "Parung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Warujaya - 16330"
    },
    {
      "id": "380",
      "postcode": "16340",
      "village": "Cibadung",
      "regency": "Gunung Sindur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibadung - 16340"
    },
    {
      "id": "381",
      "postcode": "16340",
      "village": "Cibinong",
      "regency": "Gunung Sindur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibinong - 16340"
    },
    {
      "id": "382",
      "postcode": "16340",
      "village": "Cidokom",
      "regency": "Gunung Sindur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cidokom - 16340"
    },
    {
      "id": "383",
      "postcode": "16340",
      "village": "Curug",
      "regency": "Gunung Sindur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Curug - 16340"
    },
    {
      "id": "384",
      "postcode": "16340",
      "village": "Gunung Sindur",
      "regency": "Gunung Sindur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gunung Sindur - 16340"
    },
    {
      "id": "385",
      "postcode": "16340",
      "village": "Jampang",
      "regency": "Gunung Sindur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jampang - 16340"
    },
    {
      "id": "386",
      "postcode": "16340",
      "village": "Pabuaran",
      "regency": "Gunung Sindur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pabuaran - 16340"
    },
    {
      "id": "387",
      "postcode": "16340",
      "village": "Padurenan",
      "regency": "Gunung Sindur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Padurenan - 16340"
    },
    {
      "id": "388",
      "postcode": "16340",
      "village": "Pengasinan",
      "regency": "Gunung Sindur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pengasinan - 16340"
    },
    {
      "id": "389",
      "postcode": "16340",
      "village": "Rawakalong",
      "regency": "Gunung Sindur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Rawakalong - 16340"
    },
    {
      "id": "390",
      "postcode": "16350",
      "village": "Cibodas",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibodas - 16350"
    },
    {
      "id": "391",
      "postcode": "16350",
      "village": "Cidokom",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cidokom - 16350"
    },
    {
      "id": "392",
      "postcode": "16350",
      "village": "Cipinang",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cipinang - 16350"
    },
    {
      "id": "393",
      "postcode": "16350",
      "village": "Gobang",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gobang - 16350"
    },
    {
      "id": "394",
      "postcode": "16350",
      "village": "Kampung Sawah",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kampung Sawah - 16350"
    },
    {
      "id": "395",
      "postcode": "16350",
      "village": "Kerta Jaya",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kerta Jaya - 16350"
    },
    {
      "id": "396",
      "postcode": "16350",
      "village": "Leuwibatu",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Leuwibatu - 16350"
    },
    {
      "id": "397",
      "postcode": "16350",
      "village": "Mekar Sari",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Mekar Sari - 16350"
    },
    {
      "id": "398",
      "postcode": "16350",
      "village": "Mekarjaya",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Mekarjaya - 16350"
    },
    {
      "id": "399",
      "postcode": "16350",
      "village": "Rabak",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Rabak - 16350"
    },
    {
      "id": "400",
      "postcode": "16350",
      "village": "Rumpin",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Rumpin - 16350"
    },
    {
      "id": "401",
      "postcode": "16350",
      "village": "Sukamulya",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamulya - 16350"
    },
    {
      "id": "402",
      "postcode": "16350",
      "village": "Sukasari",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukasari - 16350"
    },
    {
      "id": "403",
      "postcode": "16350",
      "village": "Taman Sari",
      "regency": "Rumpin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Taman Sari - 16350"
    },
    {
      "id": "404",
      "postcode": "16360",
      "village": "Cibunar",
      "regency": "Parung Panjang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibunar - 16360"
    },
    {
      "id": "405",
      "postcode": "16360",
      "village": "Cikuda",
      "regency": "Parung Panjang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cikuda - 16360"
    },
    {
      "id": "406",
      "postcode": "16360",
      "village": "Dago",
      "regency": "Parung Panjang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Dago - 16360"
    },
    {
      "id": "407",
      "postcode": "16360",
      "village": "Gintung Cilejet",
      "regency": "Parung Panjang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gintung Cilejet - 16360"
    },
    {
      "id": "408",
      "postcode": "16360",
      "village": "Gorowong",
      "regency": "Parung Panjang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gorowong - 16360"
    },
    {
      "id": "409",
      "postcode": "16360",
      "village": "Jagabaya",
      "regency": "Parung Panjang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jagabaya - 16360"
    },
    {
      "id": "410",
      "postcode": "16360",
      "village": "Jagabita",
      "regency": "Parung Panjang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jagabita - 16360"
    },
    {
      "id": "411",
      "postcode": "16360",
      "village": "Kabasiran",
      "regency": "Parung Panjang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kabasiran - 16360"
    },
    {
      "id": "412",
      "postcode": "16360",
      "village": "Lumpang",
      "regency": "Parung Panjang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Lumpang - 16360"
    },
    {
      "id": "413",
      "postcode": "16360",
      "village": "Parungpanjang",
      "regency": "Parung Panjang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Parungpanjang - 16360"
    },
    {
      "id": "414",
      "postcode": "16360",
      "village": "Pingku",
      "regency": "Parung Panjang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pingku - 16360"
    },
    {
      "id": "415",
      "postcode": "16370",
      "village": "Babakan",
      "regency": "Tenjo",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Babakan - 16370"
    },
    {
      "id": "416",
      "postcode": "16370",
      "village": "Batok",
      "regency": "Tenjo",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Batok - 16370"
    },
    {
      "id": "417",
      "postcode": "16370",
      "village": "Bojong",
      "regency": "Tenjo",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong - 16370"
    },
    {
      "id": "418",
      "postcode": "16370",
      "village": "Cibitung Tengah",
      "regency": "Tenjolaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibitung Tengah - 16370"
    },
    {
      "id": "419",
      "postcode": "16370",
      "village": "Cilaku",
      "regency": "Tenjo",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cilaku - 16370"
    },
    {
      "id": "420",
      "postcode": "16370",
      "village": "Cinangneng",
      "regency": "Tenjolaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cinangneng - 16370"
    },
    {
      "id": "421",
      "postcode": "16370",
      "village": "Ciomas",
      "regency": "Tenjo",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciomas - 16370"
    },
    {
      "id": "422",
      "postcode": "16370",
      "village": "Gunung Malang",
      "regency": "Tenjolaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gunung Malang - 16370"
    },
    {
      "id": "423",
      "postcode": "16370",
      "village": "Singabangsa",
      "regency": "Tenjo",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Singabangsa - 16370"
    },
    {
      "id": "424",
      "postcode": "16370",
      "village": "Singabraja",
      "regency": "Tenjo",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Singabraja - 16370"
    },
    {
      "id": "425",
      "postcode": "16370",
      "village": "Situ Daun",
      "regency": "Tenjolaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Situ Daun - 16370"
    },
    {
      "id": "426",
      "postcode": "16370",
      "village": "Tapos",
      "regency": "Tenjo",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tapos - 16370"
    },
    {
      "id": "427",
      "postcode": "16370",
      "village": "Tapos 1",
      "regency": "Tenjolaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tapos 1 - 16370"
    },
    {
      "id": "428",
      "postcode": "16370",
      "village": "Tapos 2",
      "regency": "Tenjolaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tapos 2 - 16370"
    },
    {
      "id": "429",
      "postcode": "16370",
      "village": "Tenjo",
      "regency": "Tenjo",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tenjo - 16370"
    },
    {
      "id": "430",
      "postcode": "16610",
      "village": "Ciapus",
      "regency": "Ciomas",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciapus - 16610"
    },
    {
      "id": "431",
      "postcode": "16610",
      "village": "Ciomas",
      "regency": "Ciomas",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciomas - 16610"
    },
    {
      "id": "432",
      "postcode": "16610",
      "village": "Ciomas Rahayu",
      "regency": "Ciomas",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciomas Rahayu - 16610"
    },
    {
      "id": "433",
      "postcode": "16610",
      "village": "Kota Batu",
      "regency": "Ciomas",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kota Batu - 16610"
    },
    {
      "id": "434",
      "postcode": "16610",
      "village": "Laladon",
      "regency": "Ciomas",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Laladon - 16610"
    },
    {
      "id": "435",
      "postcode": "16610",
      "village": "Mekarjaya",
      "regency": "Ciomas",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Mekarjaya - 16610"
    },
    {
      "id": "436",
      "postcode": "16610",
      "village": "Padasuka",
      "regency": "Ciomas",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Padasuka - 16610"
    },
    {
      "id": "437",
      "postcode": "16610",
      "village": "Pagelaran",
      "regency": "Ciomas",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pagelaran - 16610"
    },
    {
      "id": "438",
      "postcode": "16610",
      "village": "Parakan",
      "regency": "Ciomas",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Parakan - 16610"
    },
    {
      "id": "439",
      "postcode": "16610",
      "village": "Pasireurih",
      "regency": "Tamansari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasireurih - 16610"
    },
    {
      "id": "440",
      "postcode": "16610",
      "village": "Sirnagalih",
      "regency": "Tamansari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sirnagalih - 16610"
    },
    {
      "id": "441",
      "postcode": "16610",
      "village": "Sukaharja",
      "regency": "Ciomas",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukaharja - 16610"
    },
    {
      "id": "442",
      "postcode": "16610",
      "village": "Sukajadi",
      "regency": "Tamansari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukajadi - 16610"
    },
    {
      "id": "443",
      "postcode": "16610",
      "village": "Sukajaya",
      "regency": "Tamansari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukajaya - 16610"
    },
    {
      "id": "444",
      "postcode": "16610",
      "village": "Sukaluyu",
      "regency": "Tamansari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukaluyu - 16610"
    },
    {
      "id": "445",
      "postcode": "16610",
      "village": "Sukamakmur",
      "regency": "Ciomas",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamakmur - 16610"
    },
    {
      "id": "446",
      "postcode": "16610",
      "village": "Sukamantri",
      "regency": "Tamansari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamantri - 16610"
    },
    {
      "id": "447",
      "postcode": "16610",
      "village": "Sukaresmi",
      "regency": "Tamansari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukaresmi - 16610"
    },
    {
      "id": "448",
      "postcode": "16610",
      "village": "Tamansari",
      "regency": "Tamansari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tamansari - 16610"
    },
    {
      "id": "449",
      "postcode": "16620",
      "village": "Benteng",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Benteng - 16620"
    },
    {
      "id": "450",
      "postcode": "16620",
      "village": "Bojong Jengkol",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong Jengkol - 16620"
    },
    {
      "id": "451",
      "postcode": "16620",
      "village": "Bojong Rangkas",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong Rangkas - 16620"
    },
    {
      "id": "452",
      "postcode": "16620",
      "village": "Ciampea",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciampea - 16620"
    },
    {
      "id": "453",
      "postcode": "16620",
      "village": "Ciampea Udik",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciampea Udik - 16620"
    },
    {
      "id": "454",
      "postcode": "16620",
      "village": "Cibadak",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibadak - 16620"
    },
    {
      "id": "455",
      "postcode": "16620",
      "village": "Cibanteng",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibanteng - 16620"
    },
    {
      "id": "456",
      "postcode": "16620",
      "village": "Cibuntu",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibuntu - 16620"
    },
    {
      "id": "457",
      "postcode": "16620",
      "village": "Cicadas",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cicadas - 16620"
    },
    {
      "id": "458",
      "postcode": "16620",
      "village": "Cihideung Ilir",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cihideung Ilir - 16620"
    },
    {
      "id": "459",
      "postcode": "16620",
      "village": "Cihideung Udik",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cihideung Udik - 16620"
    },
    {
      "id": "460",
      "postcode": "16620",
      "village": "Cinangka",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cinangka - 16620"
    },
    {
      "id": "461",
      "postcode": "16620",
      "village": "Tegal Waru",
      "regency": "Ciampea",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tegal Waru - 16620"
    },
    {
      "id": "462",
      "postcode": "16630",
      "village": "Cemplang",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cemplang - 16630"
    },
    {
      "id": "463",
      "postcode": "16630",
      "village": "Ciaruteun Ilir",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciaruteun Ilir - 16630"
    },
    {
      "id": "464",
      "postcode": "16630",
      "village": "Ciaruteun Udik",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciaruteun Udik - 16630"
    },
    {
      "id": "465",
      "postcode": "16630",
      "village": "Cibatok 1",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibatok 1 - 16630"
    },
    {
      "id": "466",
      "postcode": "16630",
      "village": "Cibatok 2",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibatok 2 - 16630"
    },
    {
      "id": "467",
      "postcode": "16630",
      "village": "Cijujung",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cijujung - 16630"
    },
    {
      "id": "468",
      "postcode": "16630",
      "village": "Cimanggu 1",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cimanggu 1 - 16630"
    },
    {
      "id": "469",
      "postcode": "16630",
      "village": "Cimanggu 2",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cimanggu 2 - 16630"
    },
    {
      "id": "470",
      "postcode": "16630",
      "village": "Dukuh",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Dukuh - 16630"
    },
    {
      "id": "471",
      "postcode": "16630",
      "village": "Galuga",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Galuga - 16630"
    },
    {
      "id": "472",
      "postcode": "16630",
      "village": "Girimulya",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Girimulya - 16630"
    },
    {
      "id": "473",
      "postcode": "16630",
      "village": "Leuweung Kolot",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Leuweung Kolot - 16630"
    },
    {
      "id": "474",
      "postcode": "16630",
      "village": "Situ Ilir",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Situ Ilir - 16630"
    },
    {
      "id": "475",
      "postcode": "16630",
      "village": "Situ Udik",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Situ Udik - 16630"
    },
    {
      "id": "476",
      "postcode": "16630",
      "village": "Sukamaju",
      "regency": "Cibungbulang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamaju - 16630"
    },
    {
      "id": "477",
      "postcode": "16640",
      "village": "Babakan Sadeng",
      "regency": "Leuwisadeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Babakan Sadeng - 16640"
    },
    {
      "id": "478",
      "postcode": "16640",
      "village": "Barengkok",
      "regency": "Leuwiliang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Barengkok - 16640"
    },
    {
      "id": "479",
      "postcode": "16640",
      "village": "Cibeber 1",
      "regency": "Leuwiliang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibeber 1 - 16640"
    },
    {
      "id": "480",
      "postcode": "16640",
      "village": "Cibeber 2",
      "regency": "Leuwiliang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibeber 2 - 16640"
    },
    {
      "id": "481",
      "postcode": "16640",
      "village": "Kalong 1",
      "regency": "Leuwisadeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kalong 1 - 16640"
    },
    {
      "id": "482",
      "postcode": "16640",
      "village": "Kalong 2",
      "regency": "Leuwisadeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kalong 2 - 16640"
    },
    {
      "id": "483",
      "postcode": "16640",
      "village": "Karacak",
      "regency": "Leuwiliang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Karacak - 16640"
    },
    {
      "id": "484",
      "postcode": "16640",
      "village": "Karehkel",
      "regency": "Leuwiliang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Karehkel - 16640"
    },
    {
      "id": "485",
      "postcode": "16640",
      "village": "Karyasari",
      "regency": "Leuwiliang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Karyasari - 16640"
    },
    {
      "id": "486",
      "postcode": "16640",
      "village": "Leuwiliang",
      "regency": "Leuwiliang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Leuwiliang - 16640"
    },
    {
      "id": "487",
      "postcode": "16640",
      "village": "Leuwimekar",
      "regency": "Leuwiliang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Leuwimekar - 16640"
    },
    {
      "id": "488",
      "postcode": "16640",
      "village": "Leuwisadeng",
      "regency": "Leuwisadeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Leuwisadeng - 16640"
    },
    {
      "id": "489",
      "postcode": "16640",
      "village": "Pabangbon",
      "regency": "Leuwiliang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pabangbon - 16640"
    },
    {
      "id": "490",
      "postcode": "16640",
      "village": "Purasari",
      "regency": "Leuwiliang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Purasari - 16640"
    },
    {
      "id": "491",
      "postcode": "16640",
      "village": "Puraseda",
      "regency": "Leuwiliang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Puraseda - 16640"
    },
    {
      "id": "492",
      "postcode": "16640",
      "village": "Sadeng",
      "regency": "Leuwisadeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sadeng - 16640"
    },
    {
      "id": "493",
      "postcode": "16640",
      "village": "Sadengkolot",
      "regency": "Leuwisadeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sadengkolot - 16640"
    },
    {
      "id": "494",
      "postcode": "16640",
      "village": "Sibanteng",
      "regency": "Leuwisadeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sibanteng - 16640"
    },
    {
      "id": "495",
      "postcode": "16640",
      "village": "Wangun Jaya",
      "regency": "Leuwisadeng",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Wangun Jaya - 16640"
    },
    {
      "id": "496",
      "postcode": "16650",
      "village": "Bantar Karet",
      "regency": "Nanggung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bantar Karet - 16650"
    },
    {
      "id": "497",
      "postcode": "16650",
      "village": "Cisarua",
      "regency": "Nanggung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cisarua - 16650"
    },
    {
      "id": "498",
      "postcode": "16650",
      "village": "Curug Bitung",
      "regency": "Nanggung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Curug Bitung - 16650"
    },
    {
      "id": "499",
      "postcode": "16650",
      "village": "Hambaro",
      "regency": "Nanggung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Hambaro - 16650"
    },
    {
      "id": "500",
      "postcode": "16650",
      "village": "Kalong Liud",
      "regency": "Nanggung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kalong Liud - 16650"
    },
    {
      "id": "501",
      "postcode": "16650",
      "village": "Malasari",
      "regency": "Nanggung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Malasari - 16650"
    },
    {
      "id": "502",
      "postcode": "16650",
      "village": "Nanggung",
      "regency": "Nanggung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Nanggung - 16650"
    },
    {
      "id": "503",
      "postcode": "16650",
      "village": "Pangkal Jaya",
      "regency": "Nanggung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pangkal Jaya - 16650"
    },
    {
      "id": "504",
      "postcode": "16650",
      "village": "Parakan Muncang",
      "regency": "Nanggung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Parakan Muncang - 16650"
    },
    {
      "id": "505",
      "postcode": "16650",
      "village": "Sukaluyu",
      "regency": "Nanggung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukaluyu - 16650"
    },
    {
      "id": "506",
      "postcode": "16660",
      "village": "Argapura",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Argapura - 16660"
    },
    {
      "id": "507",
      "postcode": "16660",
      "village": "Bangunjaya",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bangunjaya - 16660"
    },
    {
      "id": "508",
      "postcode": "16660",
      "village": "Banyu Asih",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Banyu Asih - 16660"
    },
    {
      "id": "509",
      "postcode": "16660",
      "village": "Banyu Resmi",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Banyu Resmi - 16660"
    },
    {
      "id": "510",
      "postcode": "16660",
      "village": "Banyu Wangi",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Banyu Wangi - 16660"
    },
    {
      "id": "511",
      "postcode": "16660",
      "village": "Batu Jajar",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Batu Jajar - 16660"
    },
    {
      "id": "512",
      "postcode": "16660",
      "village": "Bunar",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bunar - 16660"
    },
    {
      "id": "513",
      "postcode": "16660",
      "village": "Cigudeg",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cigudeg - 16660"
    },
    {
      "id": "514",
      "postcode": "16660",
      "village": "Cileuksa",
      "regency": "Sukajaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cileuksa - 16660"
    },
    {
      "id": "515",
      "postcode": "16660",
      "village": "Cintamanik",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cintamanik - 16660"
    },
    {
      "id": "516",
      "postcode": "16660",
      "village": "Cisarua",
      "regency": "Sukajaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cisarua - 16660"
    },
    {
      "id": "517",
      "postcode": "16660",
      "village": "Harkatjaya",
      "regency": "Sukajaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Harkatjaya - 16660"
    },
    {
      "id": "518",
      "postcode": "16660",
      "village": "Jayaraharja",
      "regency": "Sukajaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jayaraharja - 16660"
    },
    {
      "id": "519",
      "postcode": "16660",
      "village": "Kiara Pandak",
      "regency": "Sukajaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kiara Pandak - 16660"
    },
    {
      "id": "520",
      "postcode": "16660",
      "village": "Kiarasari",
      "regency": "Sukajaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kiarasari - 16660"
    },
    {
      "id": "521",
      "postcode": "16660",
      "village": "Mekarjaya",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Mekarjaya - 16660"
    },
    {
      "id": "522",
      "postcode": "16660",
      "village": "Pasir Madang",
      "regency": "Sukajaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Madang - 16660"
    },
    {
      "id": "523",
      "postcode": "16660",
      "village": "Rengasjajar",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Rengasjajar - 16660"
    },
    {
      "id": "524",
      "postcode": "16660",
      "village": "Sipayung",
      "regency": "Sukajaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sipayung - 16660"
    },
    {
      "id": "525",
      "postcode": "16660",
      "village": "Sukajaya",
      "regency": "Sukajaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukajaya - 16660"
    },
    {
      "id": "526",
      "postcode": "16660",
      "village": "Sukamaju",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamaju - 16660"
    },
    {
      "id": "527",
      "postcode": "16660",
      "village": "Sukamulih",
      "regency": "Sukajaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamulih - 16660"
    },
    {
      "id": "528",
      "postcode": "16660",
      "village": "Sukaraksa",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukaraksa - 16660"
    },
    {
      "id": "529",
      "postcode": "16660",
      "village": "Tegallega",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tegallega - 16660"
    },
    {
      "id": "530",
      "postcode": "16660",
      "village": "Urug",
      "regency": "Sukajaya",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Urug - 16660"
    },
    {
      "id": "531",
      "postcode": "16660",
      "village": "Wargajaya",
      "regency": "Cigudeg",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Wargajaya - 16660"
    },
    {
      "id": "532",
      "postcode": "16670",
      "village": "Bagoang",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bagoang - 16670"
    },
    {
      "id": "533",
      "postcode": "16670",
      "village": "Barengkok",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Barengkok - 16670"
    },
    {
      "id": "534",
      "postcode": "16670",
      "village": "Cikopomayak",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cikopomayak - 16670"
    },
    {
      "id": "535",
      "postcode": "16670",
      "village": "Curug",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Curug - 16670"
    },
    {
      "id": "536",
      "postcode": "16670",
      "village": "Jasinga",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jasinga - 16670"
    },
    {
      "id": "537",
      "postcode": "16670",
      "village": "Jugala Jaya",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jugala Jaya - 16670"
    },
    {
      "id": "538",
      "postcode": "16670",
      "village": "Kalongsawah",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kalongsawah - 16670"
    },
    {
      "id": "539",
      "postcode": "16670",
      "village": "Koleang",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Koleang - 16670"
    },
    {
      "id": "540",
      "postcode": "16670",
      "village": "Neglasari",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Neglasari - 16670"
    },
    {
      "id": "541",
      "postcode": "16670",
      "village": "Pamagersari",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pamagersari - 16670"
    },
    {
      "id": "542",
      "postcode": "16670",
      "village": "Pangaur",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pangaur - 16670"
    },
    {
      "id": "543",
      "postcode": "16670",
      "village": "Pangradin",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pangradin - 16670"
    },
    {
      "id": "544",
      "postcode": "16670",
      "village": "Setu",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Setu - 16670"
    },
    {
      "id": "545",
      "postcode": "16670",
      "village": "Sipak",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sipak - 16670"
    },
    {
      "id": "546",
      "postcode": "16670",
      "village": "Tegal Wangi",
      "regency": "Jasinga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tegal Wangi - 16670"
    },
    {
      "id": "547",
      "postcode": "16680",
      "village": "Babakan",
      "regency": "Dramaga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Babakan - 16680"
    },
    {
      "id": "548",
      "postcode": "16680",
      "village": "Ciherang",
      "regency": "Dramaga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciherang - 16680"
    },
    {
      "id": "549",
      "postcode": "16680",
      "village": "Cikarawang",
      "regency": "Dramaga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cikarawang - 16680"
    },
    {
      "id": "550",
      "postcode": "16680",
      "village": "Dramaga",
      "regency": "Dramaga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Dramaga - 16680"
    },
    {
      "id": "551",
      "postcode": "16680",
      "village": "Neglasari",
      "regency": "Dramaga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Neglasari - 16680"
    },
    {
      "id": "552",
      "postcode": "16680",
      "village": "Petir",
      "regency": "Dramaga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Petir - 16680"
    },
    {
      "id": "553",
      "postcode": "16680",
      "village": "Purwasari",
      "regency": "Dramaga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Purwasari - 16680"
    },
    {
      "id": "554",
      "postcode": "16680",
      "village": "Sinar Sari",
      "regency": "Dramaga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sinar Sari - 16680"
    },
    {
      "id": "555",
      "postcode": "16680",
      "village": "Sukadamai",
      "regency": "Dramaga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukadamai - 16680"
    },
    {
      "id": "556",
      "postcode": "16680",
      "village": "Sukawening",
      "regency": "Dramaga",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukawening - 16680"
    },
    {
      "id": "557",
      "postcode": "16710",
      "village": "Bantar Jati",
      "regency": "Klapa Nunggal (Kelapa Nunggal)",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bantar Jati - 16710"
    },
    {
      "id": "558",
      "postcode": "16710",
      "village": "Bojong",
      "regency": "Klapa Nunggal (Kelapa Nunggal)",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong - 16710"
    },
    {
      "id": "559",
      "postcode": "16710",
      "village": "Cadas Ngampar",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cadas Ngampar - 16710"
    },
    {
      "id": "560",
      "postcode": "16710",
      "village": "Cibanon",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibanon - 16710"
    },
    {
      "id": "561",
      "postcode": "16710",
      "village": "Cijujung",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cijujung - 16710"
    },
    {
      "id": "562",
      "postcode": "16710",
      "village": "Cikahuripan",
      "regency": "Klapa Nunggal (Kelapa Nunggal)",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cikahuripan - 16710"
    },
    {
      "id": "563",
      "postcode": "16710",
      "village": "Cikeas",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cikeas - 16710"
    },
    {
      "id": "564",
      "postcode": "16710",
      "village": "Cilebut Barat",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cilebut Barat - 16710"
    },
    {
      "id": "565",
      "postcode": "16710",
      "village": "Cilebut Timur",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cilebut Timur - 16710"
    },
    {
      "id": "566",
      "postcode": "16710",
      "village": "Cimandala",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cimandala - 16710"
    },
    {
      "id": "567",
      "postcode": "16710",
      "village": "Gunung Geulis",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gunung Geulis - 16710"
    },
    {
      "id": "568",
      "postcode": "16710",
      "village": "Kembang Kuning",
      "regency": "Klapa Nunggal (Kelapa Nunggal)",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kembang Kuning - 16710"
    },
    {
      "id": "569",
      "postcode": "16710",
      "village": "Klapanunggal",
      "regency": "Klapa Nunggal (Kelapa Nunggal)",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Klapanunggal - 16710"
    },
    {
      "id": "570",
      "postcode": "16710",
      "village": "Leuwikaret",
      "regency": "Klapa Nunggal (Kelapa Nunggal)",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Leuwikaret - 16710"
    },
    {
      "id": "571",
      "postcode": "16710",
      "village": "Ligarmukti",
      "regency": "Klapa Nunggal (Kelapa Nunggal)",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ligarmukti - 16710"
    },
    {
      "id": "572",
      "postcode": "16710",
      "village": "Lulut",
      "regency": "Klapa Nunggal (Kelapa Nunggal)",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Lulut - 16710"
    },
    {
      "id": "573",
      "postcode": "16710",
      "village": "Nagrak",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Nagrak - 16710"
    },
    {
      "id": "574",
      "postcode": "16710",
      "village": "Nambo",
      "regency": "Klapa Nunggal (Kelapa Nunggal)",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Nambo - 16710"
    },
    {
      "id": "575",
      "postcode": "16710",
      "village": "Pasir Jambu",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Jambu - 16710"
    },
    {
      "id": "576",
      "postcode": "16710",
      "village": "Pasirlaja",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasirlaja - 16710"
    },
    {
      "id": "577",
      "postcode": "16710",
      "village": "Sukaraja",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukaraja - 16710"
    },
    {
      "id": "578",
      "postcode": "16710",
      "village": "Sukatani",
      "regency": "Sukaraja",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukatani - 16710"
    },
    {
      "id": "579",
      "postcode": "16720",
      "village": "Banjar Sari",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Banjar Sari - 16720"
    },
    {
      "id": "580",
      "postcode": "16720",
      "village": "Banjar Wangi",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Banjar Wangi - 16720"
    },
    {
      "id": "581",
      "postcode": "16720",
      "village": "Banjar Waru",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Banjar Waru - 16720"
    },
    {
      "id": "582",
      "postcode": "16720",
      "village": "Bendungan",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bendungan - 16720"
    },
    {
      "id": "583",
      "postcode": "16720",
      "village": "Bitung Sari",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bitung Sari - 16720"
    },
    {
      "id": "584",
      "postcode": "16720",
      "village": "Bojong Murni",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong Murni - 16720"
    },
    {
      "id": "585",
      "postcode": "16720",
      "village": "Ciawi",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciawi - 16720"
    },
    {
      "id": "586",
      "postcode": "16720",
      "village": "Cibedug",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibedug - 16720"
    },
    {
      "id": "587",
      "postcode": "16720",
      "village": "Cileungsi",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cileungsi - 16720"
    },
    {
      "id": "588",
      "postcode": "16720",
      "village": "Citapen",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Citapen - 16720"
    },
    {
      "id": "589",
      "postcode": "16720",
      "village": "Jambu Luwuk",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jambu Luwuk - 16720"
    },
    {
      "id": "590",
      "postcode": "16720",
      "village": "Pandansari",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pandansari - 16720"
    },
    {
      "id": "591",
      "postcode": "16720",
      "village": "Teluk Pinang",
      "regency": "Ciawi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Teluk Pinang - 16720"
    },
    {
      "id": "592",
      "postcode": "16730",
      "village": "Caringin",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Caringin - 16730"
    },
    {
      "id": "593",
      "postcode": "16730",
      "village": "Ciderum",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciderum - 16730"
    },
    {
      "id": "594",
      "postcode": "16730",
      "village": "Ciherang Pondok",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciherang Pondok - 16730"
    },
    {
      "id": "595",
      "postcode": "16730",
      "village": "Cimande",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cimande - 16730"
    },
    {
      "id": "596",
      "postcode": "16730",
      "village": "Cimande Hilir",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cimande Hilir - 16730"
    },
    {
      "id": "597",
      "postcode": "16730",
      "village": "Cinagara",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cinagara - 16730"
    },
    {
      "id": "598",
      "postcode": "16730",
      "village": "Lemah Duhur",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Lemah Duhur - 16730"
    },
    {
      "id": "599",
      "postcode": "16730",
      "village": "Muarajaya",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Muarajaya - 16730"
    },
    {
      "id": "600",
      "postcode": "16730",
      "village": "Pancawati",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pancawati - 16730"
    },
    {
      "id": "601",
      "postcode": "16730",
      "village": "Pasir Buncir",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Buncir - 16730"
    },
    {
      "id": "602",
      "postcode": "16730",
      "village": "Pasir Muncang",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Muncang - 16730"
    },
    {
      "id": "603",
      "postcode": "16730",
      "village": "Tangkil",
      "regency": "Caringin",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tangkil - 16730"
    },
    {
      "id": "604",
      "postcode": "16740",
      "village": "Cibalung",
      "regency": "Cijeruk",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibalung - 16740"
    },
    {
      "id": "605",
      "postcode": "16740",
      "village": "Cijeruk",
      "regency": "Cijeruk",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cijeruk - 16740"
    },
    {
      "id": "606",
      "postcode": "16740",
      "village": "Cipelang",
      "regency": "Cijeruk",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cipelang - 16740"
    },
    {
      "id": "607",
      "postcode": "16740",
      "village": "Cipicung",
      "regency": "Cijeruk",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cipicung - 16740"
    },
    {
      "id": "608",
      "postcode": "16740",
      "village": "Palasari",
      "regency": "Cijeruk",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Palasari - 16740"
    },
    {
      "id": "609",
      "postcode": "16740",
      "village": "Sukaharja",
      "regency": "Cijeruk",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukaharja - 16740"
    },
    {
      "id": "610",
      "postcode": "16740",
      "village": "Tajur Halang",
      "regency": "Cijeruk",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tajur Halang - 16740"
    },
    {
      "id": "611",
      "postcode": "16740",
      "village": "Tanjung Sari",
      "regency": "Cijeruk",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tanjung Sari - 16740"
    },
    {
      "id": "612",
      "postcode": "16740",
      "village": "Warung Menteng",
      "regency": "Cijeruk",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Warung Menteng - 16740"
    },
    {
      "id": "613",
      "postcode": "16750",
      "village": "Batu Layang",
      "regency": "Cisarua",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Batu Layang - 16750"
    },
    {
      "id": "614",
      "postcode": "16750",
      "village": "Cibeureum",
      "regency": "Cisarua",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibeureum - 16750"
    },
    {
      "id": "615",
      "postcode": "16750",
      "village": "Cilember",
      "regency": "Cisarua",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cilember - 16750"
    },
    {
      "id": "616",
      "postcode": "16750",
      "village": "Cisarua",
      "regency": "Cisarua",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cisarua - 16750"
    },
    {
      "id": "617",
      "postcode": "16750",
      "village": "Citeko",
      "regency": "Cisarua",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Citeko - 16750"
    },
    {
      "id": "618",
      "postcode": "16750",
      "village": "Jogjogan",
      "regency": "Cisarua",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jogjogan - 16750"
    },
    {
      "id": "619",
      "postcode": "16750",
      "village": "Kopo",
      "regency": "Cisarua",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kopo - 16750"
    },
    {
      "id": "620",
      "postcode": "16750",
      "village": "Leuwimalang",
      "regency": "Cisarua",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Leuwimalang - 16750"
    },
    {
      "id": "621",
      "postcode": "16750",
      "village": "Tugu Selatan",
      "regency": "Cisarua",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tugu Selatan - 16750"
    },
    {
      "id": "622",
      "postcode": "16750",
      "village": "Tugu Utara",
      "regency": "Cisarua",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tugu Utara - 16750"
    },
    {
      "id": "623",
      "postcode": "16770",
      "village": "Cipayung (Cipayung Datar)",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cipayung (Cipayung Datar) - 16770"
    },
    {
      "id": "624",
      "postcode": "16770",
      "village": "Cipayung Girang",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cipayung Girang - 16770"
    },
    {
      "id": "625",
      "postcode": "16770",
      "village": "Gadog",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gadog - 16770"
    },
    {
      "id": "626",
      "postcode": "16770",
      "village": "Kuta",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kuta - 16770"
    },
    {
      "id": "627",
      "postcode": "16770",
      "village": "Megamendung",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Megamendung - 16770"
    },
    {
      "id": "628",
      "postcode": "16770",
      "village": "Pasir Angin",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Angin - 16770"
    },
    {
      "id": "629",
      "postcode": "16770",
      "village": "Sukagalih",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukagalih - 16770"
    },
    {
      "id": "630",
      "postcode": "16770",
      "village": "Sukakarya",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukakarya - 16770"
    },
    {
      "id": "631",
      "postcode": "16770",
      "village": "Sukamahi",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamahi - 16770"
    },
    {
      "id": "632",
      "postcode": "16770",
      "village": "Sukamaju",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamaju - 16770"
    },
    {
      "id": "633",
      "postcode": "16770",
      "village": "Sukamanah",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamanah - 16770"
    },
    {
      "id": "634",
      "postcode": "16770",
      "village": "Sukaresmi",
      "regency": "Megamendung",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukaresmi - 16770"
    },
    {
      "id": "635",
      "postcode": "16810",
      "village": "Babakan Madang",
      "regency": "Babakan Madang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Babakan Madang - 16810"
    },
    {
      "id": "636",
      "postcode": "16810",
      "village": "Bojong Koneng",
      "regency": "Babakan Madang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong Koneng - 16810"
    },
    {
      "id": "637",
      "postcode": "16810",
      "village": "Ciasihan",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciasihan - 16810"
    },
    {
      "id": "638",
      "postcode": "16810",
      "village": "Ciasmara",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciasmara - 16810"
    },
    {
      "id": "639",
      "postcode": "16810",
      "village": "Cibening",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibening - 16810"
    },
    {
      "id": "640",
      "postcode": "16810",
      "village": "Cibitung Kulon",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibitung Kulon - 16810"
    },
    {
      "id": "641",
      "postcode": "16810",
      "village": "Cibitung Wetan",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibitung Wetan - 16810"
    },
    {
      "id": "642",
      "postcode": "16810",
      "village": "Cibunian",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibunian - 16810"
    },
    {
      "id": "643",
      "postcode": "16810",
      "village": "Cijayanti",
      "regency": "Babakan Madang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cijayanti - 16810"
    },
    {
      "id": "644",
      "postcode": "16810",
      "village": "Cimayang",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cimayang - 16810"
    },
    {
      "id": "645",
      "postcode": "16810",
      "village": "Cipambuan",
      "regency": "Babakan Madang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cipambuan - 16810"
    },
    {
      "id": "646",
      "postcode": "16810",
      "village": "Citaringgul",
      "regency": "Babakan Madang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Citaringgul - 16810"
    },
    {
      "id": "647",
      "postcode": "16810",
      "village": "Citeureup",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Citeureup - 16810"
    },
    {
      "id": "648",
      "postcode": "16810",
      "village": "Gunung Bunder 1",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gunung Bunder 1 - 16810"
    },
    {
      "id": "649",
      "postcode": "16810",
      "village": "Gunung Bunder 2",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gunung Bunder 2 - 16810"
    },
    {
      "id": "650",
      "postcode": "16810",
      "village": "Gunung Menyan",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gunung Menyan - 16810"
    },
    {
      "id": "651",
      "postcode": "16810",
      "village": "Gunung Picung",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gunung Picung - 16810"
    },
    {
      "id": "652",
      "postcode": "16810",
      "village": "Gunung Sari",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gunung Sari - 16810"
    },
    {
      "id": "653",
      "postcode": "16810",
      "village": "Gunung Sari",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gunung Sari - 16810"
    },
    {
      "id": "654",
      "postcode": "16810",
      "village": "Hambalang",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Hambalang - 16810"
    },
    {
      "id": "655",
      "postcode": "16810",
      "village": "Kadumangu",
      "regency": "Babakan Madang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kadumangu - 16810"
    },
    {
      "id": "656",
      "postcode": "16810",
      "village": "Karang Asem Barat",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Karang Asem Barat - 16810"
    },
    {
      "id": "657",
      "postcode": "16810",
      "village": "Karang Asem Timur",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Karang Asem Timur - 16810"
    },
    {
      "id": "658",
      "postcode": "16810",
      "village": "Karang Tengah",
      "regency": "Babakan Madang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Karang Tengah - 16810"
    },
    {
      "id": "659",
      "postcode": "16810",
      "village": "Leuwinutug",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Leuwinutug - 16810"
    },
    {
      "id": "660",
      "postcode": "16810",
      "village": "Pamijahan",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pamijahan - 16810"
    },
    {
      "id": "661",
      "postcode": "16810",
      "village": "Pasarean",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasarean - 16810"
    },
    {
      "id": "662",
      "postcode": "16810",
      "village": "Pasir Mukti",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Mukti - 16810"
    },
    {
      "id": "663",
      "postcode": "16810",
      "village": "Purwabakti",
      "regency": "Pamijahan",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Purwabakti - 16810"
    },
    {
      "id": "664",
      "postcode": "16810",
      "village": "Puspanegara",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Puspanegara - 16810"
    },
    {
      "id": "665",
      "postcode": "16810",
      "village": "Puspasari",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Puspasari - 16810"
    },
    {
      "id": "666",
      "postcode": "16810",
      "village": "Sanja",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sanja - 16810"
    },
    {
      "id": "667",
      "postcode": "16810",
      "village": "Sentul",
      "regency": "Babakan Madang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sentul - 16810"
    },
    {
      "id": "668",
      "postcode": "16810",
      "village": "Sukahati",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukahati - 16810"
    },
    {
      "id": "669",
      "postcode": "16810",
      "village": "Sumur Batu",
      "regency": "Babakan Madang",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sumur Batu - 16810"
    },
    {
      "id": "670",
      "postcode": "16810",
      "village": "Tajur",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tajur - 16810"
    },
    {
      "id": "671",
      "postcode": "16810",
      "village": "Tangkil",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tangkil - 16810"
    },
    {
      "id": "672",
      "postcode": "16810",
      "village": "Tarikolot",
      "regency": "Citeureup",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tarikolot - 16810"
    },
    {
      "id": "673",
      "postcode": "16820",
      "village": "Cileungsi",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cileungsi - 16820"
    },
    {
      "id": "674",
      "postcode": "16820",
      "village": "Cileungsi Kidul",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cileungsi Kidul - 16820"
    },
    {
      "id": "675",
      "postcode": "16820",
      "village": "Cipenjo",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cipenjo - 16820"
    },
    {
      "id": "676",
      "postcode": "16820",
      "village": "Cipeucang",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cipeucang - 16820"
    },
    {
      "id": "677",
      "postcode": "16820",
      "village": "Dayeuh",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Dayeuh - 16820"
    },
    {
      "id": "678",
      "postcode": "16820",
      "village": "Gandoang",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gandoang - 16820"
    },
    {
      "id": "679",
      "postcode": "16820",
      "village": "Jatisari",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jatisari - 16820"
    },
    {
      "id": "680",
      "postcode": "16820",
      "village": "Limus Nunggal",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Limus Nunggal - 16820"
    },
    {
      "id": "681",
      "postcode": "16820",
      "village": "Mampir",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Mampir - 16820"
    },
    {
      "id": "682",
      "postcode": "16820",
      "village": "Mekarsari",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Mekarsari - 16820"
    },
    {
      "id": "683",
      "postcode": "16820",
      "village": "Pasir Angin",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Angin - 16820"
    },
    {
      "id": "684",
      "postcode": "16820",
      "village": "Setu Sari",
      "regency": "Cileungsi",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Setu Sari - 16820"
    },
    {
      "id": "685",
      "postcode": "16830",
      "village": "Balekambang",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Balekambang - 16830"
    },
    {
      "id": "686",
      "postcode": "16830",
      "village": "Bendungan",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bendungan - 16830"
    },
    {
      "id": "687",
      "postcode": "16830",
      "village": "Cibadak",
      "regency": "Sukamakmur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibadak - 16830"
    },
    {
      "id": "688",
      "postcode": "16830",
      "village": "Cibodas",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibodas - 16830"
    },
    {
      "id": "689",
      "postcode": "16830",
      "village": "Jonggol",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Jonggol - 16830"
    },
    {
      "id": "690",
      "postcode": "16830",
      "village": "Pabuaran",
      "regency": "Sukamakmur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pabuaran - 16830"
    },
    {
      "id": "691",
      "postcode": "16830",
      "village": "Singajaya",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Singajaya - 16830"
    },
    {
      "id": "692",
      "postcode": "16830",
      "village": "Singasari",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Singasari - 16830"
    },
    {
      "id": "693",
      "postcode": "16830",
      "village": "Sirnagalih",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sirnagalih - 16830"
    },
    {
      "id": "694",
      "postcode": "16830",
      "village": "Sirnajaya",
      "regency": "Sukamakmur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sirnajaya - 16830"
    },
    {
      "id": "695",
      "postcode": "16830",
      "village": "Sukadamai",
      "regency": "Sukamakmur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukadamai - 16830"
    },
    {
      "id": "696",
      "postcode": "16830",
      "village": "Sukagalih",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukagalih - 16830"
    },
    {
      "id": "697",
      "postcode": "16830",
      "village": "Sukaharja",
      "regency": "Sukamakmur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukaharja - 16830"
    },
    {
      "id": "698",
      "postcode": "16830",
      "village": "Sukajaya",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukajaya - 16830"
    },
    {
      "id": "699",
      "postcode": "16830",
      "village": "Sukamaju",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamaju - 16830"
    },
    {
      "id": "700",
      "postcode": "16830",
      "village": "Sukamakmur",
      "regency": "Sukamakmur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamakmur - 16830"
    },
    {
      "id": "701",
      "postcode": "16830",
      "village": "Sukamanah",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamanah - 16830"
    },
    {
      "id": "702",
      "postcode": "16830",
      "village": "Sukamulya",
      "regency": "Sukamakmur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukamulya - 16830"
    },
    {
      "id": "703",
      "postcode": "16830",
      "village": "Sukanegara",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukanegara - 16830"
    },
    {
      "id": "704",
      "postcode": "16830",
      "village": "Sukaresmi",
      "regency": "Sukamakmur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukaresmi - 16830"
    },
    {
      "id": "705",
      "postcode": "16830",
      "village": "Sukasirna",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukasirna - 16830"
    },
    {
      "id": "706",
      "postcode": "16830",
      "village": "Sukawangi",
      "regency": "Sukamakmur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukawangi - 16830"
    },
    {
      "id": "707",
      "postcode": "16830",
      "village": "Wargajaya",
      "regency": "Sukamakmur",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Wargajaya - 16830"
    },
    {
      "id": "708",
      "postcode": "16830",
      "village": "Weninggalih",
      "regency": "Jonggol",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Weninggalih - 16830"
    },
    {
      "id": "709",
      "postcode": "16840",
      "village": "Antajaya",
      "regency": "Tanjungsari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Antajaya - 16840"
    },
    {
      "id": "710",
      "postcode": "16840",
      "village": "Babakan Raden",
      "regency": "Cariu",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Babakan Raden - 16840"
    },
    {
      "id": "711",
      "postcode": "16840",
      "village": "Bantar Kuning",
      "regency": "Cariu",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bantar Kuning - 16840"
    },
    {
      "id": "712",
      "postcode": "16840",
      "village": "Buanajaya",
      "regency": "Tanjungsari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Buanajaya - 16840"
    },
    {
      "id": "713",
      "postcode": "16840",
      "village": "Cariu",
      "regency": "Cariu",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cariu - 16840"
    },
    {
      "id": "714",
      "postcode": "16840",
      "village": "Cibadak",
      "regency": "Tanjungsari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibadak - 16840"
    },
    {
      "id": "715",
      "postcode": "16840",
      "village": "Cibatu Tiga",
      "regency": "Cariu",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibatu Tiga - 16840"
    },
    {
      "id": "716",
      "postcode": "16840",
      "village": "Cikutamahi",
      "regency": "Cariu",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cikutamahi - 16840"
    },
    {
      "id": "717",
      "postcode": "16840",
      "village": "Karya Mekar",
      "regency": "Cariu",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Karya Mekar - 16840"
    },
    {
      "id": "718",
      "postcode": "16840",
      "village": "Kuta Mekar",
      "regency": "Cariu",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kuta Mekar - 16840"
    },
    {
      "id": "719",
      "postcode": "16840",
      "village": "Mekarwangi",
      "regency": "Cariu",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Mekarwangi - 16840"
    },
    {
      "id": "720",
      "postcode": "16840",
      "village": "Pasir Tanjung",
      "regency": "Tanjungsari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pasir Tanjung - 16840"
    },
    {
      "id": "721",
      "postcode": "16840",
      "village": "Selawangi",
      "regency": "Tanjungsari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Selawangi - 16840"
    },
    {
      "id": "722",
      "postcode": "16840",
      "village": "Sirnarasa",
      "regency": "Tanjungsari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sirnarasa - 16840"
    },
    {
      "id": "723",
      "postcode": "16840",
      "village": "Sirnasari",
      "regency": "Tanjungsari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sirnasari - 16840"
    },
    {
      "id": "724",
      "postcode": "16840",
      "village": "Sukajadi",
      "regency": "Cariu",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukajadi - 16840"
    },
    {
      "id": "725",
      "postcode": "16840",
      "village": "Sukarasa",
      "regency": "Tanjungsari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukarasa - 16840"
    },
    {
      "id": "726",
      "postcode": "16840",
      "village": "Tanjungrasa",
      "regency": "Tanjungsari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tanjungrasa - 16840"
    },
    {
      "id": "727",
      "postcode": "16840",
      "village": "Tanjungsari",
      "regency": "Tanjungsari",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tanjungsari - 16840"
    },
    {
      "id": "728",
      "postcode": "16840",
      "village": "Tegal Panjang",
      "regency": "Cariu",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tegal Panjang - 16840"
    },
    {
      "id": "729",
      "postcode": "16911",
      "village": "Cibinong",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cibinong - 16911"
    },
    {
      "id": "730",
      "postcode": "16912",
      "village": "Nanggewer",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Nanggewer - 16912"
    },
    {
      "id": "731",
      "postcode": "16912",
      "village": "Nanggewer Mekar",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Nanggewer Mekar - 16912"
    },
    {
      "id": "732",
      "postcode": "16913",
      "village": "Karadenan",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Karadenan - 16913"
    },
    {
      "id": "733",
      "postcode": "16913",
      "village": "Sukahati",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Sukahati - 16913"
    },
    {
      "id": "734",
      "postcode": "16914",
      "village": "Harapan Jaya",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Harapan Jaya - 16914"
    },
    {
      "id": "735",
      "postcode": "16914",
      "village": "Pondok Rajeg",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pondok Rajeg - 16914"
    },
    {
      "id": "736",
      "postcode": "16914",
      "village": "Tengah",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tengah - 16914"
    },
    {
      "id": "737",
      "postcode": "16915",
      "village": "Pakansari",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pakansari - 16915"
    },
    {
      "id": "738",
      "postcode": "16916",
      "village": "Pabuaran",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pabuaran - 16916"
    },
    {
      "id": "739",
      "postcode": "16917",
      "village": "Cirimekar",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cirimekar - 16917"
    },
    {
      "id": "740",
      "postcode": "16918",
      "village": "Ciriung",
      "regency": "Cibinong",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciriung - 16918"
    },
    {
      "id": "741",
      "postcode": "16920",
      "village": "Bojong Baru",
      "regency": "Bojonggede",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong Baru - 16920"
    },
    {
      "id": "742",
      "postcode": "16920",
      "village": "Cimanggis",
      "regency": "Bojonggede",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cimanggis - 16920"
    },
    {
      "id": "743",
      "postcode": "16920",
      "village": "Ragajaya",
      "regency": "Bojonggede",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ragajaya - 16920"
    },
    {
      "id": "744",
      "postcode": "16920",
      "village": "Rawa Panjang",
      "regency": "Bojonggede",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Rawa Panjang - 16920"
    },
    {
      "id": "745",
      "postcode": "16920",
      "village": "Susukan",
      "regency": "Bojonggede",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Susukan - 16920"
    },
    {
      "id": "746",
      "postcode": "16920",
      "village": "Waringin Jaya",
      "regency": "Bojonggede",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Waringin Jaya - 16920"
    },
    {
      "id": "747",
      "postcode": "16921",
      "village": "Pabuaran",
      "regency": "Bojonggede",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Pabuaran - 16921"
    },
    {
      "id": "748",
      "postcode": "16922",
      "village": "Bojong Gede",
      "regency": "Bojonggede",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong Gede - 16922"
    },
    {
      "id": "749",
      "postcode": "16923",
      "village": "Kedung Waringin",
      "regency": "Bojonggede",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Kedung Waringin - 16923"
    },
    {
      "id": "750",
      "postcode": "16960",
      "village": "Karanggan",
      "regency": "Gunung Putri",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Karanggan - 16960"
    },
    {
      "id": "751",
      "postcode": "16961",
      "village": "Gunung Putri",
      "regency": "Gunung Putri",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Gunung Putri - 16961"
    },
    {
      "id": "752",
      "postcode": "16962",
      "village": "Tlajung Udik",
      "regency": "Gunung Putri",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Tlajung Udik - 16962"
    },
    {
      "id": "753",
      "postcode": "16963",
      "village": "Bojong Nangka",
      "regency": "Gunung Putri",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong Nangka - 16963"
    },
    {
      "id": "754",
      "postcode": "16964",
      "village": "Cicadas",
      "regency": "Gunung Putri",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cicadas - 16964"
    },
    {
      "id": "755",
      "postcode": "16965",
      "village": "Wanaherang",
      "regency": "Gunung Putri",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Wanaherang - 16965"
    },
    {
      "id": "756",
      "postcode": "16966",
      "village": "Cikeas Udik",
      "regency": "Gunung Putri",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Cikeas Udik - 16966"
    },
    {
      "id": "757",
      "postcode": "16967",
      "village": "Nagrag",
      "regency": "Gunung Putri",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Nagrag - 16967"
    },
    {
      "id": "758",
      "postcode": "16968",
      "village": "Ciangsana",
      "regency": "Gunung Putri",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Ciangsana - 16968"
    },
    {
      "id": "759",
      "postcode": "16969",
      "village": "Bojong Kulur",
      "regency": "Gunung Putri",
      "state": "Kab.",
      "district": "Bogor",
      "province": "Jawa Barat",
      "string": "Bojong Kulur - 16969"
    },
    {
      "id": "760",
      "postcode": "16411",
      "village": "Mekarjaya",
      "regency": "Sukmajaya",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Mekarjaya - 16411"
    },
    {
      "id": "761",
      "postcode": "16412",
      "village": "Sukmajaya",
      "regency": "Sukmajaya",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Sukmajaya - 16412"
    },
    {
      "id": "762",
      "postcode": "16412",
      "village": "Tirtajaya",
      "regency": "Sukmajaya",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Tirtajaya - 16412"
    },
    {
      "id": "763",
      "postcode": "16413",
      "village": "Jatimulya",
      "regency": "Cilodong",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Jatimulya - 16413"
    },
    {
      "id": "764",
      "postcode": "16413",
      "village": "Kalimulya",
      "regency": "Cilodong",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Kalimulya - 16413"
    },
    {
      "id": "765",
      "postcode": "16414",
      "village": "Cilodong",
      "regency": "Cilodong",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Cilodong - 16414"
    },
    {
      "id": "766",
      "postcode": "16414",
      "village": "Kalibaru",
      "regency": "Cilodong",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Kalibaru - 16414"
    },
    {
      "id": "767",
      "postcode": "16415",
      "village": "Sukamaju",
      "regency": "Cilodong",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Sukamaju - 16415"
    },
    {
      "id": "768",
      "postcode": "16416",
      "village": "Cisalak",
      "regency": "Sukmajaya",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Cisalak - 16416"
    },
    {
      "id": "769",
      "postcode": "16417",
      "village": "Abadijaya",
      "regency": "Sukmajaya",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Abadijaya - 16417"
    },
    {
      "id": "770",
      "postcode": "16418",
      "village": "Baktijaya",
      "regency": "Sukmajaya",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Baktijaya - 16418"
    },
    {
      "id": "771",
      "postcode": "16421",
      "village": "Beji",
      "regency": "Beji",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Beji - 16421"
    },
    {
      "id": "772",
      "postcode": "16422",
      "village": "Beji Timur",
      "regency": "Beji",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Beji Timur - 16422"
    },
    {
      "id": "773",
      "postcode": "16423",
      "village": "Kemiri Muka",
      "regency": "Beji",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Kemiri Muka - 16423"
    },
    {
      "id": "774",
      "postcode": "16424",
      "village": "Pondok Cina",
      "regency": "Beji",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Pondok Cina - 16424"
    },
    {
      "id": "775",
      "postcode": "16425",
      "village": "Kukusan",
      "regency": "Beji",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Kukusan - 16425"
    },
    {
      "id": "776",
      "postcode": "16426",
      "village": "Tanah Baru",
      "regency": "Beji",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Tanah Baru - 16426"
    },
    {
      "id": "777",
      "postcode": "16431",
      "village": "Depok",
      "regency": "Pancoran Mas",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Depok - 16431"
    },
    {
      "id": "778",
      "postcode": "16432",
      "village": "Depok Jaya",
      "regency": "Pancoran Mas",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Depok Jaya - 16432"
    },
    {
      "id": "779",
      "postcode": "16433",
      "village": "Mampang",
      "regency": "Pancoran Mas",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Mampang - 16433"
    },
    {
      "id": "780",
      "postcode": "16434",
      "village": "Rangkapanjaya Baru",
      "regency": "Pancoran Mas",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Rangkapanjaya Baru - 16434"
    },
    {
      "id": "781",
      "postcode": "16435",
      "village": "Rangkapanjaya",
      "regency": "Pancoran Mas",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Rangkapanjaya - 16435"
    },
    {
      "id": "782",
      "postcode": "16436",
      "village": "Bojong Pondok Terong",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Bojong Pondok Terong - 16436"
    },
    {
      "id": "783",
      "postcode": "16436",
      "village": "Pancoran Mas",
      "regency": "Pancoran Mas",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Pancoran Mas - 16436"
    },
    {
      "id": "784",
      "postcode": "16442",
      "village": "Cipayung",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Cipayung - 16442"
    },
    {
      "id": "785",
      "postcode": "16437",
      "village": "Cipayung Jaya",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Cipayung Jaya - 16437"
    },
    {
      "id": "786",
      "postcode": "16438",
      "village": "Pondokjaya",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Pondokjaya - 16438"
    },
    {
      "id": "787",
      "postcode": "16439",
      "village": "Ratu Jaya",
      "regency": "Cipayung",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Ratu Jaya - 16439"
    },
    {
      "id": "788",
      "postcode": "16451",
      "village": "Jatijajar",
      "regency": "Tapos",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Jatijajar - 16451"
    },
    {
      "id": "789",
      "postcode": "16451",
      "village": "Pasir Gunung Selatan",
      "regency": "Cimanggis",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Pasir Gunung Selatan - 16451"
    },
    {
      "id": "790",
      "postcode": "16451",
      "village": "Tugu",
      "regency": "Cimanggis",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Tugu - 16451"
    },
    {
      "id": "791",
      "postcode": "16452",
      "village": "Cisalak Pasar",
      "regency": "Cimanggis",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Cisalak Pasar - 16452"
    },
    {
      "id": "792",
      "postcode": "16452",
      "village": "Mekarsari",
      "regency": "Cimanggis",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Mekarsari - 16452"
    },
    {
      "id": "793",
      "postcode": "16453",
      "village": "Curug",
      "regency": "Cimanggis",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Curug - 16453"
    },
    {
      "id": "794",
      "postcode": "16454",
      "village": "Harjamukti",
      "regency": "Cimanggis",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Harjamukti - 16454"
    },
    {
      "id": "795",
      "postcode": "16454",
      "village": "Sukatani",
      "regency": "Tapos",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Sukatani - 16454"
    },
    {
      "id": "796",
      "postcode": "16455",
      "village": "Sukamaju Baru",
      "regency": "Tapos",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Sukamaju Baru - 16455"
    },
    {
      "id": "797",
      "postcode": "16456",
      "village": "Leuwinanggung",
      "regency": "Tapos",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Leuwinanggung - 16456"
    },
    {
      "id": "798",
      "postcode": "16457",
      "village": "Tapos",
      "regency": "Tapos",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Tapos - 16457"
    },
    {
      "id": "799",
      "postcode": "16458",
      "village": "Cilangkap",
      "regency": "Tapos",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Cilangkap - 16458"
    },
    {
      "id": "800",
      "postcode": "16459",
      "village": "Cimpaeun",
      "regency": "Tapos",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Cimpaeun - 16459"
    },
    {
      "id": "801",
      "postcode": "16511",
      "village": "Sawangan (Lama)",
      "regency": "Sawangan",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Sawangan (Lama) - 16511"
    },
    {
      "id": "802",
      "postcode": "16511",
      "village": "Sawangan Baru",
      "regency": "Sawangan",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Sawangan Baru - 16511"
    },
    {
      "id": "803",
      "postcode": "16512",
      "village": "Gandul",
      "regency": "Cinere",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Gandul - 16512"
    },
    {
      "id": "804",
      "postcode": "16512",
      "village": "Grogol",
      "regency": "Limo",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Grogol - 16512"
    },
    {
      "id": "805",
      "postcode": "16512",
      "village": "Krukut",
      "regency": "Limo",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Krukut - 16512"
    },
    {
      "id": "806",
      "postcode": "16513",
      "village": "Pangkalan Jati",
      "regency": "Cinere",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Pangkalan Jati - 16513"
    },
    {
      "id": "807",
      "postcode": "16513",
      "village": "Pangkalan Jati Baru",
      "regency": "Cinere",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Pangkalan Jati Baru - 16513"
    },
    {
      "id": "808",
      "postcode": "16514",
      "village": "Cinere",
      "regency": "Cinere",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Cinere - 16514"
    },
    {
      "id": "809",
      "postcode": "16515",
      "village": "Limo",
      "regency": "Limo",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Limo - 16515"
    },
    {
      "id": "810",
      "postcode": "16515",
      "village": "Meruyung",
      "regency": "Limo",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Meruyung - 16515"
    },
    {
      "id": "811",
      "postcode": "16516",
      "village": "Bojong Sari (Lama)",
      "regency": "Bojongsari",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Bojong Sari (Lama) - 16516"
    },
    {
      "id": "812",
      "postcode": "16516",
      "village": "Bojong Sari Baru",
      "regency": "Bojongsari",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Bojong Sari Baru - 16516"
    },
    {
      "id": "813",
      "postcode": "16516",
      "village": "Cinangka",
      "regency": "Sawangan",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Cinangka - 16516"
    },
    {
      "id": "814",
      "postcode": "16516",
      "village": "Kedaung",
      "regency": "Sawangan",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Kedaung - 16516"
    },
    {
      "id": "815",
      "postcode": "16517",
      "village": "Curug",
      "regency": "Bojongsari",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Curug - 16517"
    },
    {
      "id": "816",
      "postcode": "16517",
      "village": "Pondok Petir",
      "regency": "Bojongsari",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Pondok Petir - 16517"
    },
    {
      "id": "817",
      "postcode": "16517",
      "village": "Serua",
      "regency": "Bojongsari",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Serua - 16517"
    },
    {
      "id": "818",
      "postcode": "16518",
      "village": "Duren Mekar",
      "regency": "Bojongsari",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Duren Mekar - 16518"
    },
    {
      "id": "819",
      "postcode": "16518",
      "village": "Duren Seribu",
      "regency": "Bojongsari",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Duren Seribu - 16518"
    },
    {
      "id": "820",
      "postcode": "16518",
      "village": "Pengasihan",
      "regency": "Sawangan",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Pengasihan - 16518"
    },
    {
      "id": "821",
      "postcode": "16519",
      "village": "Bedahan",
      "regency": "Sawangan",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Bedahan - 16519"
    },
    {
      "id": "822",
      "postcode": "16519",
      "village": "Pasir Putih",
      "regency": "Sawangan",
      "state": "Kota",
      "district": "Depok",
      "province": "Jawa Barat",
      "string": "Pasir Putih - 16519"
    },
    {
      "id": "823",
      "postcode": "15111",
      "village": "Suka Asih",
      "regency": "Tangerang",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Asih - 15111"
    },
    {
      "id": "824",
      "postcode": "15111",
      "village": "Sukarasa",
      "regency": "Tangerang",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sukarasa - 15111"
    },
    {
      "id": "825",
      "postcode": "15112",
      "village": "Koang Jaya",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Koang Jaya - 15112"
    },
    {
      "id": "826",
      "postcode": "15112",
      "village": "Nambo Jaya",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Nambo Jaya - 15112"
    },
    {
      "id": "827",
      "postcode": "15112",
      "village": "Pabuaran Tumpeng",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pabuaran Tumpeng - 15112"
    },
    {
      "id": "828",
      "postcode": "15112",
      "village": "Pasar Baru",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasar Baru - 15112"
    },
    {
      "id": "829",
      "postcode": "15113",
      "village": "Bugel",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bugel - 15113"
    },
    {
      "id": "830",
      "postcode": "15113",
      "village": "Gerendeng",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gerendeng - 15113"
    },
    {
      "id": "831",
      "postcode": "15113",
      "village": "Marga Sari",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Marga Sari - 15113"
    },
    {
      "id": "832",
      "postcode": "15113",
      "village": "Suka Jadi",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Jadi - 15113"
    },
    {
      "id": "833",
      "postcode": "15114",
      "village": "Cimone",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cimone - 15114"
    },
    {
      "id": "834",
      "postcode": "15114",
      "village": "Pabuaran",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pabuaran - 15114"
    },
    {
      "id": "835",
      "postcode": "15114",
      "village": "Sumur Pancing (Pacing)",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sumur Pancing (Pacing) - 15114"
    },
    {
      "id": "836",
      "postcode": "15115",
      "village": "Bojong Jaya",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bojong Jaya - 15115"
    },
    {
      "id": "837",
      "postcode": "15115",
      "village": "Karawaci",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karawaci - 15115"
    },
    {
      "id": "838",
      "postcode": "15116",
      "village": "Cimone Jaya",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cimone Jaya - 15116"
    },
    {
      "id": "839",
      "postcode": "15116",
      "village": "Karawaci Baru",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karawaci Baru - 15116"
    },
    {
      "id": "840",
      "postcode": "15116",
      "village": "Nusa Jaya",
      "regency": "Karawaci",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Nusa Jaya - 15116"
    },
    {
      "id": "841",
      "postcode": "15117",
      "village": "Cikokol",
      "regency": "Tangerang",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cikokol - 15117"
    },
    {
      "id": "842",
      "postcode": "15117",
      "village": "Kelapa Indah",
      "regency": "Tangerang",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kelapa Indah - 15117"
    },
    {
      "id": "843",
      "postcode": "15118",
      "village": "Babakan",
      "regency": "Tangerang",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Babakan - 15118"
    },
    {
      "id": "844",
      "postcode": "15118",
      "village": "Sukasari",
      "regency": "Tangerang",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sukasari - 15118"
    },
    {
      "id": "845",
      "postcode": "15119",
      "village": "Buaran Indah",
      "regency": "Tangerang",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Buaran Indah - 15119"
    },
    {
      "id": "846",
      "postcode": "15119",
      "village": "Tanah Tinggi",
      "regency": "Tangerang",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tanah Tinggi - 15119"
    },
    {
      "id": "847",
      "postcode": "15121",
      "village": "Batu Jaya",
      "regency": "Batuceper",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Batu Jaya - 15121"
    },
    {
      "id": "848",
      "postcode": "15121",
      "village": "Batu Sari",
      "regency": "Batuceper",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Batu Sari - 15121"
    },
    {
      "id": "849",
      "postcode": "15121",
      "village": "Karang Anyar",
      "regency": "Neglasari",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karang Anyar - 15121"
    },
    {
      "id": "850",
      "postcode": "15121",
      "village": "Karang Sari",
      "regency": "Neglasari",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karang Sari - 15121"
    },
    {
      "id": "851",
      "postcode": "15122",
      "village": "Batu Ceper",
      "regency": "Batuceper",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Batu Ceper - 15122"
    },
    {
      "id": "852",
      "postcode": "15122",
      "village": "Kebon Besar",
      "regency": "Batuceper",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kebon Besar - 15122"
    },
    {
      "id": "853",
      "postcode": "15122",
      "village": "Poris Gaga",
      "regency": "Batuceper",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Poris Gaga - 15122"
    },
    {
      "id": "854",
      "postcode": "15122",
      "village": "Poris Gaga Baru",
      "regency": "Batuceper",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Poris Gaga Baru - 15122"
    },
    {
      "id": "855",
      "postcode": "15122",
      "village": "Poris Jaya",
      "regency": "Batuceper",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Poris Jaya - 15122"
    },
    {
      "id": "856",
      "postcode": "15123",
      "village": "Belendung",
      "regency": "Benda",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Belendung - 15123"
    },
    {
      "id": "857",
      "postcode": "15124",
      "village": "Jurumudi",
      "regency": "Benda",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jurumudi - 15124"
    },
    {
      "id": "858",
      "postcode": "15124",
      "village": "Jurumudi Baru",
      "regency": "Benda",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jurumudi Baru - 15124"
    },
    {
      "id": "859",
      "postcode": "15125",
      "village": "Benda",
      "regency": "Benda",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Benda - 15125"
    },
    {
      "id": "860",
      "postcode": "15126",
      "village": "Pajang",
      "regency": "Benda",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pajang - 15126"
    },
    {
      "id": "861",
      "postcode": "15127",
      "village": "Selapajang Jaya",
      "regency": "Neglasari",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Selapajang Jaya - 15127"
    },
    {
      "id": "862",
      "postcode": "15128",
      "village": "Kedaung Baru",
      "regency": "Neglasari",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kedaung Baru - 15128"
    },
    {
      "id": "863",
      "postcode": "15128",
      "village": "Kedaung Wetan",
      "regency": "Neglasari",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kedaung Wetan - 15128"
    },
    {
      "id": "864",
      "postcode": "15129",
      "village": "Mekar Sari",
      "regency": "Neglasari",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mekar Sari - 15129"
    },
    {
      "id": "865",
      "postcode": "15129",
      "village": "Neglasari",
      "regency": "Neglasari",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Neglasari - 15129"
    },
    {
      "id": "866",
      "postcode": "15131",
      "village": "Periuk",
      "regency": "Periuk",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Periuk - 15131"
    },
    {
      "id": "867",
      "postcode": "15131",
      "village": "Periuk Jaya",
      "regency": "Periuk",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Periuk Jaya - 15131"
    },
    {
      "id": "868",
      "postcode": "15132",
      "village": "Gebang Raya",
      "regency": "Periuk",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gebang Raya - 15132"
    },
    {
      "id": "869",
      "postcode": "15132",
      "village": "Sangiang Jaya",
      "regency": "Periuk",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sangiang Jaya - 15132"
    },
    {
      "id": "870",
      "postcode": "15133",
      "village": "Alam Jaya",
      "regency": "Jatiuwung",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Alam Jaya - 15133"
    },
    {
      "id": "871",
      "postcode": "15133",
      "village": "Gembor",
      "regency": "Periuk",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gembor - 15133"
    },
    {
      "id": "872",
      "postcode": "15134",
      "village": "Jatiuwung",
      "regency": "Cibodas",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jatiuwung - 15134"
    },
    {
      "id": "873",
      "postcode": "15134",
      "village": "Keroncong",
      "regency": "Jatiuwung",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Keroncong - 15134"
    },
    {
      "id": "874",
      "postcode": "15135",
      "village": "Pasir Jaya",
      "regency": "Jatiuwung",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasir Jaya - 15135"
    },
    {
      "id": "875",
      "postcode": "15136",
      "village": "Jatake",
      "regency": "Jatiuwung",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jatake - 15136"
    },
    {
      "id": "876",
      "postcode": "15136",
      "village": "Manis Jaya",
      "regency": "Jatiuwung",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Manis Jaya - 15136"
    },
    {
      "id": "877",
      "postcode": "15137",
      "village": "Gandasari",
      "regency": "Jatiuwung",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gandasari - 15137"
    },
    {
      "id": "878",
      "postcode": "15138",
      "village": "Cibodas",
      "regency": "Cibodas",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cibodas - 15138"
    },
    {
      "id": "879",
      "postcode": "15138",
      "village": "Cibodas Baru",
      "regency": "Cibodas",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cibodas Baru - 15138"
    },
    {
      "id": "880",
      "postcode": "15138",
      "village": "Cibodas Sari",
      "regency": "Cibodas",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cibodas Sari - 15138"
    },
    {
      "id": "881",
      "postcode": "15138",
      "village": "Uwung Jaya",
      "regency": "Cibodas",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Uwung Jaya - 15138"
    },
    {
      "id": "882",
      "postcode": "15139",
      "village": "Panunggangan Barat",
      "regency": "Cibodas",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Panunggangan Barat - 15139"
    },
    {
      "id": "883",
      "postcode": "15141",
      "village": "Poris Plawad",
      "regency": "Cipondoh",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Poris Plawad - 15141"
    },
    {
      "id": "884",
      "postcode": "15141",
      "village": "Poris Plawad Indah",
      "regency": "Cipondoh",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Poris Plawad Indah - 15141"
    },
    {
      "id": "885",
      "postcode": "15141",
      "village": "Poris Plawad Utara",
      "regency": "Cipondoh",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Poris Plawad Utara - 15141"
    },
    {
      "id": "886",
      "postcode": "15142",
      "village": "Cipete",
      "regency": "Pinang (Penang)",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cipete - 15142"
    },
    {
      "id": "887",
      "postcode": "15142",
      "village": "Pakojan",
      "regency": "Pinang (Penang)",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pakojan - 15142"
    },
    {
      "id": "888",
      "postcode": "15143",
      "village": "Panunggangan",
      "regency": "Pinang (Penang)",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Panunggangan - 15143"
    },
    {
      "id": "889",
      "postcode": "15143",
      "village": "Panunggangan Timur",
      "regency": "Pinang (Penang)",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Panunggangan Timur - 15143"
    },
    {
      "id": "890",
      "postcode": "15143",
      "village": "Panunggangan Utara",
      "regency": "Pinang (Penang)",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Panunggangan Utara - 15143"
    },
    {
      "id": "891",
      "postcode": "15144",
      "village": "Kunciran",
      "regency": "Pinang (Penang)",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kunciran - 15144"
    },
    {
      "id": "892",
      "postcode": "15144",
      "village": "Kunciran Indah",
      "regency": "Pinang (Penang)",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kunciran Indah - 15144"
    },
    {
      "id": "893",
      "postcode": "15144",
      "village": "Kunciran Jaya",
      "regency": "Pinang (Penang)",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kunciran Jaya - 15144"
    },
    {
      "id": "894",
      "postcode": "15145",
      "village": "Nerogtog",
      "regency": "Pinang (Penang)",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Nerogtog - 15145"
    },
    {
      "id": "895",
      "postcode": "15145",
      "village": "Pinang",
      "regency": "Pinang (Penang)",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pinang - 15145"
    },
    {
      "id": "896",
      "postcode": "15145",
      "village": "Sudimara Pinang",
      "regency": "Pinang (Penang)",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sudimara Pinang - 15145"
    },
    {
      "id": "897",
      "postcode": "15146",
      "village": "Gondrong",
      "regency": "Cipondoh",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gondrong - 15146"
    },
    {
      "id": "898",
      "postcode": "15146",
      "village": "Kenanga",
      "regency": "Cipondoh",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kenanga - 15146"
    },
    {
      "id": "899",
      "postcode": "15147",
      "village": "Ketapang",
      "regency": "Cipondoh",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ketapang - 15147"
    },
    {
      "id": "900",
      "postcode": "15147",
      "village": "Petir",
      "regency": "Cipondoh",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Petir - 15147"
    },
    {
      "id": "901",
      "postcode": "15148",
      "village": "Cipondoh",
      "regency": "Cipondoh",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cipondoh - 15148"
    },
    {
      "id": "902",
      "postcode": "15148",
      "village": "Cipondoh Indah",
      "regency": "Cipondoh",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cipondoh Indah - 15148"
    },
    {
      "id": "903",
      "postcode": "15148",
      "village": "Cipondoh Makmur",
      "regency": "Cipondoh",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cipondoh Makmur - 15148"
    },
    {
      "id": "904",
      "postcode": "15151",
      "village": "Sudimara Barat",
      "regency": "Ciledug",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sudimara Barat - 15151"
    },
    {
      "id": "905",
      "postcode": "15151",
      "village": "Sudimara Jaya",
      "regency": "Ciledug",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sudimara Jaya - 15151"
    },
    {
      "id": "906",
      "postcode": "15151",
      "village": "Sudimara Selatan",
      "regency": "Ciledug",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sudimara Selatan - 15151"
    },
    {
      "id": "907",
      "postcode": "15151",
      "village": "Sudimara Timur",
      "regency": "Ciledug",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sudimara Timur - 15151"
    },
    {
      "id": "908",
      "postcode": "15152",
      "village": "Tajur",
      "regency": "Ciledug",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tajur - 15152"
    },
    {
      "id": "909",
      "postcode": "15153",
      "village": "Paninggilan",
      "regency": "Ciledug",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Paninggilan - 15153"
    },
    {
      "id": "910",
      "postcode": "15153",
      "village": "Paninggilan Utara",
      "regency": "Ciledug",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Paninggilan Utara - 15153"
    },
    {
      "id": "911",
      "postcode": "15153",
      "village": "Parung Serab",
      "regency": "Ciledug",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Parung Serab - 15153"
    },
    {
      "id": "912",
      "postcode": "15154",
      "village": "Gaga",
      "regency": "Larangan",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gaga - 15154"
    },
    {
      "id": "913",
      "postcode": "15154",
      "village": "Larangan Indah",
      "regency": "Larangan",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Larangan Indah - 15154"
    },
    {
      "id": "914",
      "postcode": "15154",
      "village": "Larangan Selatan",
      "regency": "Larangan",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Larangan Selatan - 15154"
    },
    {
      "id": "915",
      "postcode": "15154",
      "village": "Larangan Utara",
      "regency": "Larangan",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Larangan Utara - 15154"
    },
    {
      "id": "916",
      "postcode": "15155",
      "village": "Cipadu",
      "regency": "Larangan",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cipadu - 15155"
    },
    {
      "id": "917",
      "postcode": "15155",
      "village": "Cipadu Jaya",
      "regency": "Larangan",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cipadu Jaya - 15155"
    },
    {
      "id": "918",
      "postcode": "15156",
      "village": "Kreo",
      "regency": "Larangan",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kreo - 15156"
    },
    {
      "id": "919",
      "postcode": "15156",
      "village": "Kreo Selatan",
      "regency": "Larangan",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kreo Selatan - 15156"
    },
    {
      "id": "920",
      "postcode": "15157",
      "village": "Karang Mulya",
      "regency": "Karang Tengah",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karang Mulya - 15157"
    },
    {
      "id": "921",
      "postcode": "15157",
      "village": "Karang Tengah",
      "regency": "Karang Tengah",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karang Tengah - 15157"
    },
    {
      "id": "922",
      "postcode": "15157",
      "village": "Karang Tengah",
      "regency": "Pagedangan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karang Tengah - 15157"
    },
    {
      "id": "923",
      "postcode": "15157",
      "village": "Karang Timur",
      "regency": "Karang Tengah",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karang Timur - 15157"
    },
    {
      "id": "924",
      "postcode": "15158",
      "village": "Pondok Pucung",
      "regency": "Karang Tengah",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pondok Pucung - 15158"
    },
    {
      "id": "925",
      "postcode": "15159",
      "village": "Padurenan (Pedurenan)",
      "regency": "Karang Tengah",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Padurenan (Pedurenan) - 15159"
    },
    {
      "id": "926",
      "postcode": "15159",
      "village": "Parung Jaya",
      "regency": "Karang Tengah",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Parung Jaya - 15159"
    },
    {
      "id": "927",
      "postcode": "15159",
      "village": "Pondok Bahar",
      "regency": "Karang Tengah",
      "state": "Kota",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pondok Bahar - 15159"
    },
    {
      "id": "928",
      "postcode": "15211",
      "village": "Dadap",
      "regency": "Kosambi",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Dadap - 15211"
    },
    {
      "id": "929",
      "postcode": "15211",
      "village": "Jatimulya",
      "regency": "Kosambi",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jatimulya - 15211"
    },
    {
      "id": "930",
      "postcode": "15212",
      "village": "Belimbing",
      "regency": "Kosambi",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Belimbing - 15212"
    },
    {
      "id": "931",
      "postcode": "15212",
      "village": "Cengklong",
      "regency": "Kosambi",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cengklong - 15212"
    },
    {
      "id": "932",
      "postcode": "15213",
      "village": "Kosambi Barat",
      "regency": "Kosambi",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kosambi Barat - 15213"
    },
    {
      "id": "933",
      "postcode": "15213",
      "village": "Kosambi Timur",
      "regency": "Kosambi",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kosambi Timur - 15213"
    },
    {
      "id": "934",
      "postcode": "15214",
      "village": "Salembaran Jati",
      "regency": "Kosambi",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Salembaran Jati - 15214"
    },
    {
      "id": "935",
      "postcode": "15214",
      "village": "Salembaran Raya",
      "regency": "Kosambi",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Salembaran Raya - 15214"
    },
    {
      "id": "936",
      "postcode": "15215",
      "village": "Rawa Burung",
      "regency": "Kosambi",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Rawa Burung - 15215"
    },
    {
      "id": "937",
      "postcode": "15215",
      "village": "Rawa Rengas",
      "regency": "Kosambi",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Rawa Rengas - 15215"
    },
    {
      "id": "938",
      "postcode": "15330",
      "village": "Jatake",
      "regency": "Pagedangan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jatake - 15330"
    },
    {
      "id": "939",
      "postcode": "15330",
      "village": "Malang Nengah",
      "regency": "Pagedangan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Malang Nengah - 15330"
    },
    {
      "id": "940",
      "postcode": "15331",
      "village": "Lengkong Kulon",
      "regency": "Pagedangan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Lengkong Kulon - 15331"
    },
    {
      "id": "941",
      "postcode": "15332",
      "village": "Cihuni",
      "regency": "Pagedangan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cihuni - 15332"
    },
    {
      "id": "942",
      "postcode": "15334",
      "village": "Medang",
      "regency": "Pagedangan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Medang - 15334"
    },
    {
      "id": "943",
      "postcode": "15335",
      "village": "Cijantra",
      "regency": "Pagedangan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cijantra - 15335"
    },
    {
      "id": "944",
      "postcode": "15336",
      "village": "Cicalengka",
      "regency": "Pagedangan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cicalengka - 15336"
    },
    {
      "id": "945",
      "postcode": "15337",
      "village": "Kadu Sirung",
      "regency": "Pagedangan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kadu Sirung - 15337"
    },
    {
      "id": "946",
      "postcode": "15338",
      "village": "Situ Gadung",
      "regency": "Pagedangan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Situ Gadung - 15338"
    },
    {
      "id": "947",
      "postcode": "15339",
      "village": "Pagedangan",
      "regency": "Pagedangan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pagedangan - 15339"
    },
    {
      "id": "948",
      "postcode": "15340",
      "village": "Mekarwangi",
      "regency": "Cisauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mekarwangi - 15340"
    },
    {
      "id": "949",
      "postcode": "15341",
      "village": "Cisauk",
      "regency": "Cisauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cisauk - 15341"
    },
    {
      "id": "950",
      "postcode": "15342",
      "village": "Dangdang",
      "regency": "Cisauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Dangdang - 15342"
    },
    {
      "id": "951",
      "postcode": "15343",
      "village": "Suradita",
      "regency": "Cisauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suradita - 15343"
    },
    {
      "id": "952",
      "postcode": "15344",
      "village": "Cibogo",
      "regency": "Cisauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cibogo - 15344"
    },
    {
      "id": "953",
      "postcode": "15345",
      "village": "Sampora",
      "regency": "Cisauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sampora - 15345"
    },
    {
      "id": "954",
      "postcode": "15510",
      "village": "Babakan Asem",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Babakan Asem - 15510"
    },
    {
      "id": "955",
      "postcode": "15510",
      "village": "Bojong Renged",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bojong Renged - 15510"
    },
    {
      "id": "956",
      "postcode": "15510",
      "village": "Kampung Besar",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kampung Besar - 15510"
    },
    {
      "id": "957",
      "postcode": "15510",
      "village": "Kampung Melayu Barat",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kampung Melayu Barat - 15510"
    },
    {
      "id": "958",
      "postcode": "15510",
      "village": "Kampung Melayu Timur",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kampung Melayu Timur - 15510"
    },
    {
      "id": "959",
      "postcode": "15510",
      "village": "Kebon Cau",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kebon Cau - 15510"
    },
    {
      "id": "960",
      "postcode": "15510",
      "village": "Lemo",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Lemo - 15510"
    },
    {
      "id": "961",
      "postcode": "15510",
      "village": "Muara",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Muara - 15510"
    },
    {
      "id": "962",
      "postcode": "15510",
      "village": "Pangkalan",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pangkalan - 15510"
    },
    {
      "id": "963",
      "postcode": "15510",
      "village": "Tanjung Burung",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tanjung Burung - 15510"
    },
    {
      "id": "964",
      "postcode": "15510",
      "village": "Tanjung Pasir",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tanjung Pasir - 15510"
    },
    {
      "id": "965",
      "postcode": "15510",
      "village": "Tegal Angus",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tegal Angus - 15510"
    },
    {
      "id": "966",
      "postcode": "15510",
      "village": "Teluk Naga",
      "regency": "Teluknaga",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Teluk Naga - 15510"
    },
    {
      "id": "967",
      "postcode": "15520",
      "village": "Gempol Sari",
      "regency": "Sepatan Timur",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gempol Sari - 15520"
    },
    {
      "id": "968",
      "postcode": "15520",
      "village": "Jati Mulya",
      "regency": "Sepatan Timur",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jati Mulya - 15520"
    },
    {
      "id": "969",
      "postcode": "15520",
      "village": "Kampung Kelor",
      "regency": "Sepatan Timur",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kampung Kelor - 15520"
    },
    {
      "id": "970",
      "postcode": "15520",
      "village": "Karet",
      "regency": "Sepatan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karet - 15520"
    },
    {
      "id": "971",
      "postcode": "15520",
      "village": "Kayu Agung",
      "regency": "Sepatan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kayu Agung - 15520"
    },
    {
      "id": "972",
      "postcode": "15520",
      "village": "Kayu Bongkok",
      "regency": "Sepatan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kayu Bongkok - 15520"
    },
    {
      "id": "973",
      "postcode": "15520",
      "village": "Kedaung Barat",
      "regency": "Sepatan Timur",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kedaung Barat - 15520"
    },
    {
      "id": "974",
      "postcode": "15520",
      "village": "Lebak Wangi",
      "regency": "Sepatan Timur",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Lebak Wangi - 15520"
    },
    {
      "id": "975",
      "postcode": "15520",
      "village": "Mekar Jaya",
      "regency": "Sepatan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mekar Jaya - 15520"
    },
    {
      "id": "976",
      "postcode": "15520",
      "village": "Pisangan Jaya",
      "regency": "Sepatan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pisangan Jaya - 15520"
    },
    {
      "id": "977",
      "postcode": "15520",
      "village": "Pondok Jaya",
      "regency": "Sepatan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pondok Jaya - 15520"
    },
    {
      "id": "978",
      "postcode": "15520",
      "village": "Pondok Kelor",
      "regency": "Sepatan Timur",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pondok Kelor - 15520"
    },
    {
      "id": "979",
      "postcode": "15520",
      "village": "Sangiang",
      "regency": "Sepatan Timur",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sangiang - 15520"
    },
    {
      "id": "980",
      "postcode": "15520",
      "village": "Sarakan",
      "regency": "Sepatan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sarakan - 15520"
    },
    {
      "id": "981",
      "postcode": "15520",
      "village": "Sepatan",
      "regency": "Sepatan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sepatan - 15520"
    },
    {
      "id": "982",
      "postcode": "15520",
      "village": "Tanah Merah",
      "regency": "Sepatan Timur",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tanah Merah - 15520"
    },
    {
      "id": "983",
      "postcode": "15530",
      "village": "Banyu Asih",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Banyu Asih - 15530"
    },
    {
      "id": "984",
      "postcode": "15530",
      "village": "Buaran Jati",
      "regency": "Sukadiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Buaran Jati - 15530"
    },
    {
      "id": "985",
      "postcode": "15530",
      "village": "Gintung",
      "regency": "Sukadiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gintung - 15530"
    },
    {
      "id": "986",
      "postcode": "15530",
      "village": "Gunung Sari",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gunung Sari - 15530"
    },
    {
      "id": "987",
      "postcode": "15530",
      "village": "Jati Waringin",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jati Waringin - 15530"
    },
    {
      "id": "988",
      "postcode": "15530",
      "village": "Karang Anyar",
      "regency": "Kemiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karang Anyar - 15530"
    },
    {
      "id": "989",
      "postcode": "15530",
      "village": "Karang Serang",
      "regency": "Sukadiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karang Serang - 15530"
    },
    {
      "id": "990",
      "postcode": "15530",
      "village": "Kedung Dalem",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kedung Dalem - 15530"
    },
    {
      "id": "991",
      "postcode": "15530",
      "village": "Kemiri",
      "regency": "Kemiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kemiri - 15530"
    },
    {
      "id": "992",
      "postcode": "15530",
      "village": "Ketapang",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ketapang - 15530"
    },
    {
      "id": "993",
      "postcode": "15530",
      "village": "Kjlebet (Kelebet)",
      "regency": "Kemiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kjlebet (Kelebet) - 15530"
    },
    {
      "id": "994",
      "postcode": "15530",
      "village": "Kosambi",
      "regency": "Sukadiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kosambi - 15530"
    },
    {
      "id": "995",
      "postcode": "15530",
      "village": "Legok Sula Maju",
      "regency": "Kemiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Legok Sula Maju - 15530"
    },
    {
      "id": "996",
      "postcode": "15530",
      "village": "Lontar",
      "regency": "Kemiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Lontar - 15530"
    },
    {
      "id": "997",
      "postcode": "15530",
      "village": "Marga Mulya",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Marga Mulya - 15530"
    },
    {
      "id": "998",
      "postcode": "15530",
      "village": "Mauk Barat",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mauk Barat - 15530"
    },
    {
      "id": "999",
      "postcode": "15530",
      "village": "Mauk Timur",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mauk Timur - 15530"
    },
    {
      "id": "1000",
      "postcode": "15530",
      "village": "Mekar Kondang",
      "regency": "Sukadiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mekar Kondang - 15530"
    },
    {
      "id": "1001",
      "postcode": "15530",
      "village": "Patra Manggala",
      "regency": "Kemiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Patra Manggala - 15530"
    },
    {
      "id": "1002",
      "postcode": "15530",
      "village": "Pekayon",
      "regency": "Sukadiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pekayon - 15530"
    },
    {
      "id": "1003",
      "postcode": "15530",
      "village": "Ranca Labuh",
      "regency": "Kemiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ranca Labuh - 15530"
    },
    {
      "id": "1004",
      "postcode": "15530",
      "village": "Rawa Kidang",
      "regency": "Sukadiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Rawa Kidang - 15530"
    },
    {
      "id": "1005",
      "postcode": "15530",
      "village": "Sasak",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sasak - 15530"
    },
    {
      "id": "1006",
      "postcode": "15530",
      "village": "Sukadiri",
      "regency": "Sukadiri",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sukadiri - 15530"
    },
    {
      "id": "1007",
      "postcode": "15530",
      "village": "Tanjung Anom",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tanjung Anom - 15530"
    },
    {
      "id": "1008",
      "postcode": "15530",
      "village": "Tegal Kunir Kidul",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tegal Kunir Kidul - 15530"
    },
    {
      "id": "1009",
      "postcode": "15530",
      "village": "Tegal Kunir Lor",
      "regency": "Mauk",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tegal Kunir Lor - 15530"
    },
    {
      "id": "1010",
      "postcode": "15540",
      "village": "Badak Anom",
      "regency": "Sindang Jaya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Badak Anom - 15540"
    },
    {
      "id": "1011",
      "postcode": "15540",
      "village": "Daon",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Daon - 15540"
    },
    {
      "id": "1012",
      "postcode": "15540",
      "village": "Jambu Karya",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jambu Karya - 15540"
    },
    {
      "id": "1013",
      "postcode": "15540",
      "village": "Lembang Sari",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Lembang Sari - 15540"
    },
    {
      "id": "1014",
      "postcode": "15540",
      "village": "Mekarsari",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mekarsari - 15540"
    },
    {
      "id": "1015",
      "postcode": "15540",
      "village": "Pangarengan",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pangarengan - 15540"
    },
    {
      "id": "1016",
      "postcode": "15540",
      "village": "Rajeg",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Rajeg - 15540"
    },
    {
      "id": "1017",
      "postcode": "15540",
      "village": "Rajegmulya",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Rajegmulya - 15540"
    },
    {
      "id": "1018",
      "postcode": "15540",
      "village": "Ranca Bango",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ranca Bango - 15540"
    },
    {
      "id": "1019",
      "postcode": "15540",
      "village": "Suka Manah",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Manah - 15540"
    },
    {
      "id": "1020",
      "postcode": "15540",
      "village": "Suka Sari",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Sari - 15540"
    },
    {
      "id": "1021",
      "postcode": "15540",
      "village": "Suka Tani",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Tani - 15540"
    },
    {
      "id": "1022",
      "postcode": "15540",
      "village": "Tanjakan",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tanjakan - 15540"
    },
    {
      "id": "1023",
      "postcode": "15540",
      "village": "Tanjakan Mekar",
      "regency": "Rajeg",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tanjakan Mekar - 15540"
    },
    {
      "id": "1024",
      "postcode": "15550",
      "village": "Bakung",
      "regency": "Kronjo",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bakung - 15550"
    },
    {
      "id": "1025",
      "postcode": "15550",
      "village": "Blukbuk",
      "regency": "Kronjo",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Blukbuk - 15550"
    },
    {
      "id": "1026",
      "postcode": "15550",
      "village": "Cijeruk",
      "regency": "Mekar Baru",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cijeruk - 15550"
    },
    {
      "id": "1027",
      "postcode": "15550",
      "village": "Cirumpak",
      "regency": "Kronjo",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cirumpak - 15550"
    },
    {
      "id": "1028",
      "postcode": "15550",
      "village": "Ganda Ria",
      "regency": "Mekar Baru",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ganda Ria - 15550"
    },
    {
      "id": "1029",
      "postcode": "15550",
      "village": "Jenggot",
      "regency": "Mekar Baru",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jenggot - 15550"
    },
    {
      "id": "1030",
      "postcode": "15550",
      "village": "Kedaung",
      "regency": "Mekar Baru",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kedaung - 15550"
    },
    {
      "id": "1031",
      "postcode": "15550",
      "village": "Klutuk",
      "regency": "Mekar Baru",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Klutuk - 15550"
    },
    {
      "id": "1032",
      "postcode": "15550",
      "village": "Kosambi Dalam",
      "regency": "Mekar Baru",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kosambi Dalam - 15550"
    },
    {
      "id": "1033",
      "postcode": "15550",
      "village": "Kronjo",
      "regency": "Kronjo",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kronjo - 15550"
    },
    {
      "id": "1034",
      "postcode": "15550",
      "village": "Mekar Baru",
      "regency": "Mekar Baru",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mekar Baru - 15550"
    },
    {
      "id": "1035",
      "postcode": "15550",
      "village": "Muncung",
      "regency": "Kronjo",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Muncung - 15550"
    },
    {
      "id": "1036",
      "postcode": "15550",
      "village": "Pagedangan Ilir",
      "regency": "Kronjo",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pagedangan Ilir - 15550"
    },
    {
      "id": "1037",
      "postcode": "15550",
      "village": "Pagedangan Udik",
      "regency": "Kronjo",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pagedangan Udik - 15550"
    },
    {
      "id": "1038",
      "postcode": "15550",
      "village": "Pagenjahan",
      "regency": "Kronjo",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pagenjahan - 15550"
    },
    {
      "id": "1039",
      "postcode": "15550",
      "village": "Pasilian",
      "regency": "Kronjo",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasilian - 15550"
    },
    {
      "id": "1040",
      "postcode": "15550",
      "village": "Pasir",
      "regency": "Kronjo",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasir - 15550"
    },
    {
      "id": "1041",
      "postcode": "15550",
      "village": "Waliwis",
      "regency": "Mekar Baru",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Waliwis - 15550"
    },
    {
      "id": "1042",
      "postcode": "15560",
      "village": "Gelam Jaya",
      "regency": "Pasar Kemis",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gelam Jaya - 15560"
    },
    {
      "id": "1043",
      "postcode": "15560",
      "village": "Kuta Baru",
      "regency": "Pasar Kemis",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kuta Baru - 15560"
    },
    {
      "id": "1044",
      "postcode": "15560",
      "village": "Kuta Bumi",
      "regency": "Pasar Kemis",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kuta Bumi - 15560"
    },
    {
      "id": "1045",
      "postcode": "15560",
      "village": "Kuta Jaya",
      "regency": "Pasar Kemis",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kuta Jaya - 15560"
    },
    {
      "id": "1046",
      "postcode": "15560",
      "village": "Pangadegan",
      "regency": "Pasar Kemis",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pangadegan - 15560"
    },
    {
      "id": "1047",
      "postcode": "15560",
      "village": "Pasar Kemis",
      "regency": "Pasar Kemis",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasar Kemis - 15560"
    },
    {
      "id": "1048",
      "postcode": "15560",
      "village": "Sindang Asih",
      "regency": "Sindang Jaya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sindang Asih - 15560"
    },
    {
      "id": "1049",
      "postcode": "15560",
      "village": "Sindang Jaya",
      "regency": "Sindang Jaya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sindang Jaya - 15560"
    },
    {
      "id": "1050",
      "postcode": "15560",
      "village": "Sindang Panon",
      "regency": "Sindang Jaya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sindang Panon - 15560"
    },
    {
      "id": "1051",
      "postcode": "15560",
      "village": "Sindang Sari",
      "regency": "Pasar Kemis",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sindang Sari - 15560"
    },
    {
      "id": "1052",
      "postcode": "15560",
      "village": "Sindang Sono",
      "regency": "Sindang Jaya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sindang Sono - 15560"
    },
    {
      "id": "1053",
      "postcode": "15560",
      "village": "Suka Harja",
      "regency": "Sindang Jaya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Harja - 15560"
    },
    {
      "id": "1054",
      "postcode": "15560",
      "village": "Sukaasih",
      "regency": "Pasar Kemis",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sukaasih - 15560"
    },
    {
      "id": "1055",
      "postcode": "15560",
      "village": "Sukamantri",
      "regency": "Pasar Kemis",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sukamantri - 15560"
    },
    {
      "id": "1056",
      "postcode": "15560",
      "village": "Wana Kerta",
      "regency": "Sindang Jaya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Wana Kerta - 15560"
    },
    {
      "id": "1057",
      "postcode": "15570",
      "village": "Buaran Bambu",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Buaran Bambu - 15570"
    },
    {
      "id": "1058",
      "postcode": "15570",
      "village": "Buaran Mangga",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Buaran Mangga - 15570"
    },
    {
      "id": "1059",
      "postcode": "15570",
      "village": "Bunisari (Bonasari)",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bunisari (Bonasari) - 15570"
    },
    {
      "id": "1060",
      "postcode": "15570",
      "village": "Gaga",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gaga - 15570"
    },
    {
      "id": "1061",
      "postcode": "15570",
      "village": "Kalibaru",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kalibaru - 15570"
    },
    {
      "id": "1062",
      "postcode": "15570",
      "village": "Kiara Payung",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kiara Payung - 15570"
    },
    {
      "id": "1063",
      "postcode": "15570",
      "village": "Kohod",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kohod - 15570"
    },
    {
      "id": "1064",
      "postcode": "15570",
      "village": "Kramat",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kramat - 15570"
    },
    {
      "id": "1065",
      "postcode": "15570",
      "village": "Laksana",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Laksana - 15570"
    },
    {
      "id": "1066",
      "postcode": "15570",
      "village": "Paku Alam",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Paku Alam - 15570"
    },
    {
      "id": "1067",
      "postcode": "15570",
      "village": "Pakuhaji",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pakuhaji - 15570"
    },
    {
      "id": "1068",
      "postcode": "15570",
      "village": "Rawa Boni",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Rawa Boni - 15570"
    },
    {
      "id": "1069",
      "postcode": "15570",
      "village": "Sukawali",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sukawali - 15570"
    },
    {
      "id": "1070",
      "postcode": "15570",
      "village": "Surya Bahari",
      "regency": "Pakuhaji",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Surya Bahari - 15570"
    },
    {
      "id": "1071",
      "postcode": "15610",
      "village": "Balaraja",
      "regency": "Balaraja",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Balaraja - 15610"
    },
    {
      "id": "1072",
      "postcode": "15610",
      "village": "Benda",
      "regency": "Sukamulya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Benda - 15610"
    },
    {
      "id": "1073",
      "postcode": "15610",
      "village": "Bunar",
      "regency": "Sukamulya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bunar - 15610"
    },
    {
      "id": "1074",
      "postcode": "15610",
      "village": "Buni Ayu",
      "regency": "Sukamulya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Buni Ayu - 15610"
    },
    {
      "id": "1075",
      "postcode": "15610",
      "village": "Cangkudu",
      "regency": "Balaraja",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cangkudu - 15610"
    },
    {
      "id": "1076",
      "postcode": "15610",
      "village": "Cikande",
      "regency": "Jayanti",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cikande - 15610"
    },
    {
      "id": "1077",
      "postcode": "15610",
      "village": "Dang Deur",
      "regency": "Jayanti",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Dang Deur - 15610"
    },
    {
      "id": "1078",
      "postcode": "15610",
      "village": "Gembong",
      "regency": "Balaraja",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gembong - 15610"
    },
    {
      "id": "1079",
      "postcode": "15610",
      "village": "Jayanti",
      "regency": "Jayanti",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jayanti - 15610"
    },
    {
      "id": "1080",
      "postcode": "15610",
      "village": "Kali Asin",
      "regency": "Sukamulya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kali Asin - 15610"
    },
    {
      "id": "1081",
      "postcode": "15610",
      "village": "Kubang",
      "regency": "Sukamulya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kubang - 15610"
    },
    {
      "id": "1082",
      "postcode": "15610",
      "village": "Merak",
      "regency": "Sukamulya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Merak - 15610"
    },
    {
      "id": "1083",
      "postcode": "15610",
      "village": "Pabuaran",
      "regency": "Jayanti",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pabuaran - 15610"
    },
    {
      "id": "1084",
      "postcode": "15610",
      "village": "Pangkat",
      "regency": "Jayanti",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pangkat - 15610"
    },
    {
      "id": "1085",
      "postcode": "15610",
      "village": "Parahu",
      "regency": "Sukamulya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Parahu - 15610"
    },
    {
      "id": "1086",
      "postcode": "15610",
      "village": "Pasir Gintung",
      "regency": "Jayanti",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasir Gintung - 15610"
    },
    {
      "id": "1087",
      "postcode": "15610",
      "village": "Pasir Muncang",
      "regency": "Jayanti",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasir Muncang - 15610"
    },
    {
      "id": "1088",
      "postcode": "15610",
      "village": "Saga",
      "regency": "Balaraja",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Saga - 15610"
    },
    {
      "id": "1089",
      "postcode": "15610",
      "village": "Sentul",
      "regency": "Balaraja",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sentul - 15610"
    },
    {
      "id": "1090",
      "postcode": "15610",
      "village": "Sentul Jaya",
      "regency": "Balaraja",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sentul Jaya - 15610"
    },
    {
      "id": "1091",
      "postcode": "15610",
      "village": "Suka Mulya",
      "regency": "Sukamulya",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Mulya - 15610"
    },
    {
      "id": "1092",
      "postcode": "15610",
      "village": "Suka Murni",
      "regency": "Balaraja",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Murni - 15610"
    },
    {
      "id": "1093",
      "postcode": "15610",
      "village": "Sumur Bandung",
      "regency": "Jayanti",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sumur Bandung - 15610"
    },
    {
      "id": "1094",
      "postcode": "15610",
      "village": "Talagasari",
      "regency": "Balaraja",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Talagasari - 15610"
    },
    {
      "id": "1095",
      "postcode": "15610",
      "village": "Tobat",
      "regency": "Balaraja",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tobat - 15610"
    },
    {
      "id": "1096",
      "postcode": "15620",
      "village": "Cibetok",
      "regency": "Gunung Kaler",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cibetok - 15620"
    },
    {
      "id": "1097",
      "postcode": "15620",
      "village": "Cipaeh",
      "regency": "Gunung Kaler",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cipaeh - 15620"
    },
    {
      "id": "1098",
      "postcode": "15620",
      "village": "Gunung Kaler",
      "regency": "Gunung Kaler",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Gunung Kaler - 15620"
    },
    {
      "id": "1099",
      "postcode": "15620",
      "village": "Jengkol",
      "regency": "Kresek",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jengkol - 15620"
    },
    {
      "id": "1100",
      "postcode": "15620",
      "village": "Kanda Wati",
      "regency": "Gunung Kaler",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kanda Wati - 15620"
    },
    {
      "id": "1101",
      "postcode": "15620",
      "village": "Kedung",
      "regency": "Gunung Kaler",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kedung - 15620"
    },
    {
      "id": "1102",
      "postcode": "15620",
      "village": "Kemuning",
      "regency": "Kresek",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kemuning - 15620"
    },
    {
      "id": "1103",
      "postcode": "15620",
      "village": "Koper",
      "regency": "Kresek",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Koper - 15620"
    },
    {
      "id": "1104",
      "postcode": "15620",
      "village": "Kresek",
      "regency": "Kresek",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kresek - 15620"
    },
    {
      "id": "1105",
      "postcode": "15620",
      "village": "Onyam",
      "regency": "Gunung Kaler",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Onyam - 15620"
    },
    {
      "id": "1106",
      "postcode": "15620",
      "village": "Pasir Ampo",
      "regency": "Kresek",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasir Ampo - 15620"
    },
    {
      "id": "1107",
      "postcode": "15620",
      "village": "Patra Sana",
      "regency": "Kresek",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Patra Sana - 15620"
    },
    {
      "id": "1108",
      "postcode": "15620",
      "village": "Ranca Gede",
      "regency": "Gunung Kaler",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ranca Gede - 15620"
    },
    {
      "id": "1109",
      "postcode": "15620",
      "village": "Ranca Ilat",
      "regency": "Kresek",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ranca Ilat - 15620"
    },
    {
      "id": "1110",
      "postcode": "15620",
      "village": "Renged",
      "regency": "Kresek",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Renged - 15620"
    },
    {
      "id": "1111",
      "postcode": "15620",
      "village": "Sidoko",
      "regency": "Gunung Kaler",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sidoko - 15620"
    },
    {
      "id": "1112",
      "postcode": "15620",
      "village": "Talok",
      "regency": "Kresek",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Talok - 15620"
    },
    {
      "id": "1113",
      "postcode": "15620",
      "village": "Tamiang",
      "regency": "Gunung Kaler",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tamiang - 15620"
    },
    {
      "id": "1114",
      "postcode": "15710",
      "village": "Bitung Jaya",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bitung Jaya - 15710"
    },
    {
      "id": "1115",
      "postcode": "15710",
      "village": "Bojong",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bojong - 15710"
    },
    {
      "id": "1116",
      "postcode": "15710",
      "village": "Budi Mulya",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Budi Mulya - 15710"
    },
    {
      "id": "1117",
      "postcode": "15710",
      "village": "Bunder",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bunder - 15710"
    },
    {
      "id": "1118",
      "postcode": "15710",
      "village": "Ciakar",
      "regency": "Panongan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ciakar - 15710"
    },
    {
      "id": "1119",
      "postcode": "15710",
      "village": "Cibadak",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cibadak - 15710"
    },
    {
      "id": "1120",
      "postcode": "15710",
      "village": "Cikupa",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cikupa - 15710"
    },
    {
      "id": "1121",
      "postcode": "15710",
      "village": "Dukuh",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Dukuh - 15710"
    },
    {
      "id": "1122",
      "postcode": "15710",
      "village": "Mekar Bakti",
      "regency": "Panongan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mekar Bakti - 15710"
    },
    {
      "id": "1123",
      "postcode": "15710",
      "village": "Mekar Jaya",
      "regency": "Panongan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mekar Jaya - 15710"
    },
    {
      "id": "1124",
      "postcode": "15710",
      "village": "Panongan",
      "regency": "Panongan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Panongan - 15710"
    },
    {
      "id": "1125",
      "postcode": "15710",
      "village": "Pasir Gadung",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasir Gadung - 15710"
    },
    {
      "id": "1126",
      "postcode": "15710",
      "village": "Pasir Jaya",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasir Jaya - 15710"
    },
    {
      "id": "1127",
      "postcode": "15710",
      "village": "Peusar",
      "regency": "Panongan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Peusar - 15710"
    },
    {
      "id": "1128",
      "postcode": "15710",
      "village": "Ranca Iyuh",
      "regency": "Panongan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ranca Iyuh - 15710"
    },
    {
      "id": "1129",
      "postcode": "15710",
      "village": "Ranca Kalapa",
      "regency": "Panongan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ranca Kalapa - 15710"
    },
    {
      "id": "1130",
      "postcode": "15710",
      "village": "Serdang Kulon",
      "regency": "Panongan",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Serdang Kulon - 15710"
    },
    {
      "id": "1131",
      "postcode": "15710",
      "village": "Suka Damai",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Damai - 15710"
    },
    {
      "id": "1132",
      "postcode": "15710",
      "village": "Suka Mulya",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Mulya - 15710"
    },
    {
      "id": "1133",
      "postcode": "15710",
      "village": "Suka Nagara",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Nagara - 15710"
    },
    {
      "id": "1134",
      "postcode": "15710",
      "village": "Talaga",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Talaga - 15710"
    },
    {
      "id": "1135",
      "postcode": "15710",
      "village": "Talaga Sari",
      "regency": "Cikupa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Talaga Sari - 15710"
    },
    {
      "id": "1136",
      "postcode": "15720",
      "village": "Ancol Pasir",
      "regency": "Jambe",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ancol Pasir - 15720"
    },
    {
      "id": "1137",
      "postcode": "15720",
      "village": "Bantar Panjang",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bantar Panjang - 15720"
    },
    {
      "id": "1138",
      "postcode": "15720",
      "village": "Cileles",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cileles - 15720"
    },
    {
      "id": "1139",
      "postcode": "15720",
      "village": "Cisereh",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cisereh - 15720"
    },
    {
      "id": "1140",
      "postcode": "15720",
      "village": "Daru",
      "regency": "Jambe",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Daru - 15720"
    },
    {
      "id": "1141",
      "postcode": "15720",
      "village": "Jambe",
      "regency": "Jambe",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jambe - 15720"
    },
    {
      "id": "1142",
      "postcode": "15720",
      "village": "Kadu Agung",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kadu Agung - 15720"
    },
    {
      "id": "1143",
      "postcode": "15720",
      "village": "Kutruk",
      "regency": "Jambe",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kutruk - 15720"
    },
    {
      "id": "1144",
      "postcode": "15720",
      "village": "Marga Sari",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Marga Sari - 15720"
    },
    {
      "id": "1145",
      "postcode": "15720",
      "village": "Mata Gara",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mata Gara - 15720"
    },
    {
      "id": "1146",
      "postcode": "15720",
      "village": "Mekarsari",
      "regency": "Jambe",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Mekarsari - 15720"
    },
    {
      "id": "1147",
      "postcode": "15720",
      "village": "Pasir Barat",
      "regency": "Jambe",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasir Barat - 15720"
    },
    {
      "id": "1148",
      "postcode": "15720",
      "village": "Pasir Bolang",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasir Bolang - 15720"
    },
    {
      "id": "1149",
      "postcode": "15720",
      "village": "Pasir Nangka",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasir Nangka - 15720"
    },
    {
      "id": "1150",
      "postcode": "15720",
      "village": "Pematang",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pematang - 15720"
    },
    {
      "id": "1151",
      "postcode": "15720",
      "village": "Pete",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pete - 15720"
    },
    {
      "id": "1152",
      "postcode": "15720",
      "village": "Rancabuaya",
      "regency": "Jambe",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Rancabuaya - 15720"
    },
    {
      "id": "1153",
      "postcode": "15720",
      "village": "Sodong",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sodong - 15720"
    },
    {
      "id": "1154",
      "postcode": "15720",
      "village": "Suka Manah",
      "regency": "Jambe",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Manah - 15720"
    },
    {
      "id": "1155",
      "postcode": "15720",
      "village": "Taban",
      "regency": "Jambe",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Taban - 15720"
    },
    {
      "id": "1156",
      "postcode": "15720",
      "village": "Tapos",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tapos - 15720"
    },
    {
      "id": "1157",
      "postcode": "15720",
      "village": "Tegalsari",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tegalsari - 15720"
    },
    {
      "id": "1158",
      "postcode": "15720",
      "village": "Tigaraksa",
      "regency": "Tigaraksa",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tigaraksa - 15720"
    },
    {
      "id": "1159",
      "postcode": "15720",
      "village": "Tipar Jaya (Tiparraya)",
      "regency": "Jambe",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Tipar Jaya (Tiparraya) - 15720"
    },
    {
      "id": "1160",
      "postcode": "15730",
      "village": "Bojongloa",
      "regency": "Cisoka",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bojongloa - 15730"
    },
    {
      "id": "1161",
      "postcode": "15730",
      "village": "Carenang",
      "regency": "Cisoka",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Carenang - 15730"
    },
    {
      "id": "1162",
      "postcode": "15730",
      "village": "Caringin",
      "regency": "Cisoka",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Caringin - 15730"
    },
    {
      "id": "1163",
      "postcode": "15730",
      "village": "Cempaka",
      "regency": "Cisoka",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cempaka - 15730"
    },
    {
      "id": "1164",
      "postcode": "15730",
      "village": "Cibugel",
      "regency": "Cisoka",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cibugel - 15730"
    },
    {
      "id": "1165",
      "postcode": "15730",
      "village": "Cikareo",
      "regency": "Solear",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cikareo - 15730"
    },
    {
      "id": "1166",
      "postcode": "15730",
      "village": "Cikasungka",
      "regency": "Solear",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cikasungka - 15730"
    },
    {
      "id": "1167",
      "postcode": "15730",
      "village": "Cikuya",
      "regency": "Solear",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cikuya - 15730"
    },
    {
      "id": "1168",
      "postcode": "15730",
      "village": "Cireundeu",
      "regency": "Solear",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cireundeu - 15730"
    },
    {
      "id": "1169",
      "postcode": "15730",
      "village": "Cisoka",
      "regency": "Cisoka",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cisoka - 15730"
    },
    {
      "id": "1170",
      "postcode": "15730",
      "village": "Jeung Jing",
      "regency": "Cisoka",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Jeung Jing - 15730"
    },
    {
      "id": "1171",
      "postcode": "15730",
      "village": "Karangharja",
      "regency": "Cisoka",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Karangharja - 15730"
    },
    {
      "id": "1172",
      "postcode": "15730",
      "village": "Munjul",
      "regency": "Solear",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Munjul - 15730"
    },
    {
      "id": "1173",
      "postcode": "15730",
      "village": "Pasanggrahan",
      "regency": "Solear",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pasanggrahan - 15730"
    },
    {
      "id": "1174",
      "postcode": "15730",
      "village": "Selapajang",
      "regency": "Cisoka",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Selapajang - 15730"
    },
    {
      "id": "1175",
      "postcode": "15730",
      "village": "Solear",
      "regency": "Solear",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Solear - 15730"
    },
    {
      "id": "1176",
      "postcode": "15730",
      "village": "Sukatani",
      "regency": "Cisoka",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Sukatani - 15730"
    },
    {
      "id": "1177",
      "postcode": "15810",
      "village": "Bencongan",
      "regency": "Kelapa Dua",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bencongan - 15810"
    },
    {
      "id": "1178",
      "postcode": "15810",
      "village": "Bencongan Indah",
      "regency": "Kelapa Dua",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bencongan Indah - 15810"
    },
    {
      "id": "1179",
      "postcode": "15810",
      "village": "Binong",
      "regency": "Curug",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Binong - 15810"
    },
    {
      "id": "1180",
      "postcode": "15810",
      "village": "Bojong Nangka",
      "regency": "Kelapa Dua",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bojong Nangka - 15810"
    },
    {
      "id": "1181",
      "postcode": "15810",
      "village": "Cukang Galih",
      "regency": "Curug",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cukang Galih - 15810"
    },
    {
      "id": "1182",
      "postcode": "15810",
      "village": "Curug Kulon",
      "regency": "Curug",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Curug Kulon - 15810"
    },
    {
      "id": "1183",
      "postcode": "15810",
      "village": "Curug Sangerang (Curug Sangereng)",
      "regency": "Kelapa Dua",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Curug Sangerang (Curug Sangereng) - 15810"
    },
    {
      "id": "1184",
      "postcode": "15810",
      "village": "Curug Wetan",
      "regency": "Curug",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Curug Wetan - 15810"
    },
    {
      "id": "1185",
      "postcode": "15810",
      "village": "Kadu",
      "regency": "Curug",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kadu - 15810"
    },
    {
      "id": "1186",
      "postcode": "15810",
      "village": "Kadu Jaya",
      "regency": "Curug",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kadu Jaya - 15810"
    },
    {
      "id": "1187",
      "postcode": "15810",
      "village": "Kelapa Dua",
      "regency": "Kelapa Dua",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kelapa Dua - 15810"
    },
    {
      "id": "1188",
      "postcode": "15810",
      "village": "Pakulonan Barat",
      "regency": "Kelapa Dua",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pakulonan Barat - 15810"
    },
    {
      "id": "1189",
      "postcode": "15810",
      "village": "Suka Bakti",
      "regency": "Curug",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Suka Bakti - 15810"
    },
    {
      "id": "1190",
      "postcode": "15820",
      "village": "Babakan",
      "regency": "Legok",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Babakan - 15820"
    },
    {
      "id": "1191",
      "postcode": "15820",
      "village": "Babat",
      "regency": "Legok",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Babat - 15820"
    },
    {
      "id": "1192",
      "postcode": "15820",
      "village": "Bojong Kamal",
      "regency": "Legok",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Bojong Kamal - 15820"
    },
    {
      "id": "1193",
      "postcode": "15820",
      "village": "Caringin",
      "regency": "Legok",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Caringin - 15820"
    },
    {
      "id": "1194",
      "postcode": "15820",
      "village": "Ciangir",
      "regency": "Legok",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Ciangir - 15820"
    },
    {
      "id": "1195",
      "postcode": "15820",
      "village": "Cirarab",
      "regency": "Legok",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Cirarab - 15820"
    },
    {
      "id": "1196",
      "postcode": "15820",
      "village": "Kamuning (Kemuning)",
      "regency": "Legok",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Kamuning (Kemuning) - 15820"
    },
    {
      "id": "1197",
      "postcode": "15820",
      "village": "Legok",
      "regency": "Legok",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Legok - 15820"
    },
    {
      "id": "1198",
      "postcode": "15820",
      "village": "Pala Sari",
      "regency": "Legok",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Pala Sari - 15820"
    },
    {
      "id": "1199",
      "postcode": "15820",
      "village": "Rancagong",
      "regency": "Legok",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Rancagong - 15820"
    },
    {
      "id": "1200",
      "postcode": "15820",
      "village": "Serdang Wetan",
      "regency": "Legok",
      "state": "Kab.",
      "district": "Tangerang",
      "province": "Banten",
      "string": "Serdang Wetan - 15820"
    },
    {
      "id": "1201",
      "postcode": "15220",
      "village": "Pondok Jaya",
      "regency": "Pondok Aren",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Jaya - 15220"
    },
    {
      "id": "1202",
      "postcode": "15221",
      "village": "Pondok Betung",
      "regency": "Pondok Aren",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Betung - 15221"
    },
    {
      "id": "1203",
      "postcode": "15222",
      "village": "Jurang Mangu Timur",
      "regency": "Pondok Aren",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Jurang Mangu Timur - 15222"
    },
    {
      "id": "1204",
      "postcode": "15223",
      "village": "Jurang Mangu Barat",
      "regency": "Pondok Aren",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Jurang Mangu Barat - 15223"
    },
    {
      "id": "1205",
      "postcode": "15224",
      "village": "Pondok Aren",
      "regency": "Pondok Aren",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Aren - 15224"
    },
    {
      "id": "1206",
      "postcode": "15225",
      "village": "Pondok Karya",
      "regency": "Pondok Aren",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Karya - 15225"
    },
    {
      "id": "1207",
      "postcode": "15226",
      "village": "Pondok Kacang Barat",
      "regency": "Pondok Aren",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Kacang Barat - 15226"
    },
    {
      "id": "1208",
      "postcode": "15226",
      "village": "Pondok Kacang Timur",
      "regency": "Pondok Aren",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Kacang Timur - 15226"
    },
    {
      "id": "1209",
      "postcode": "15227",
      "village": "Perigi Lama",
      "regency": "Pondok Aren",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Perigi Lama - 15227"
    },
    {
      "id": "1210",
      "postcode": "15228",
      "village": "Perigi Baru",
      "regency": "Pondok Aren",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Perigi Baru - 15228"
    },
    {
      "id": "1211",
      "postcode": "15229",
      "village": "Pondok Pucung",
      "regency": "Pondok Aren",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Pucung - 15229"
    },
    {
      "id": "1212",
      "postcode": "15310",
      "village": "Buaran",
      "regency": "Serpong",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Buaran - 15310"
    },
    {
      "id": "1213",
      "postcode": "15310",
      "village": "Ciater",
      "regency": "Serpong",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Ciater - 15310"
    },
    {
      "id": "1214",
      "postcode": "15310",
      "village": "Cilenggang",
      "regency": "Serpong",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Cilenggang - 15310"
    },
    {
      "id": "1215",
      "postcode": "15310",
      "village": "Rawa Mekar Jaya",
      "regency": "Serpong",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Rawa Mekar Jaya - 15310"
    },
    {
      "id": "1216",
      "postcode": "15311",
      "village": "Serpong",
      "regency": "Serpong",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Serpong - 15311"
    },
    {
      "id": "1217",
      "postcode": "15312",
      "village": "Kranggan",
      "regency": "Setu",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Kranggan - 15312"
    },
    {
      "id": "1218",
      "postcode": "15313",
      "village": "Kademangan",
      "regency": "Setu",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Kademangan - 15313"
    },
    {
      "id": "1219",
      "postcode": "15314",
      "village": "Muncul",
      "regency": "Setu",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Muncul - 15314"
    },
    {
      "id": "1220",
      "postcode": "15314",
      "village": "Setu",
      "regency": "Setu",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Setu - 15314"
    },
    {
      "id": "1221",
      "postcode": "15315",
      "village": "Babakan",
      "regency": "Setu",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Babakan - 15315"
    },
    {
      "id": "1222",
      "postcode": "15315",
      "village": "Bakti Jaya",
      "regency": "Setu",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Bakti Jaya - 15315"
    },
    {
      "id": "1223",
      "postcode": "15318",
      "village": "Rawa Buntu",
      "regency": "Serpong",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Rawa Buntu - 15318"
    },
    {
      "id": "1224",
      "postcode": "15320",
      "village": "Lengkong Karya",
      "regency": "Serpong Utara",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Lengkong Karya - 15320"
    },
    {
      "id": "1225",
      "postcode": "15320",
      "village": "Pakualam",
      "regency": "Serpong Utara",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pakualam - 15320"
    },
    {
      "id": "1226",
      "postcode": "15321",
      "village": "Lengkong Gudang",
      "regency": "Serpong",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Lengkong Gudang - 15321"
    },
    {
      "id": "1227",
      "postcode": "15321",
      "village": "Lengkong Gudang Timur",
      "regency": "Serpong",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Lengkong Gudang Timur - 15321"
    },
    {
      "id": "1228",
      "postcode": "15322",
      "village": "Lengkong Wetan",
      "regency": "Serpong",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Lengkong Wetan - 15322"
    },
    {
      "id": "1229",
      "postcode": "15323",
      "village": "Jelupang",
      "regency": "Serpong Utara",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Jelupang - 15323"
    },
    {
      "id": "1230",
      "postcode": "15324",
      "village": "Paku Jaya",
      "regency": "Serpong Utara",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Paku Jaya - 15324"
    },
    {
      "id": "1231",
      "postcode": "15325",
      "village": "Pakulonan",
      "regency": "Serpong Utara",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pakulonan - 15325"
    },
    {
      "id": "1232",
      "postcode": "15326",
      "village": "Pondok Jagung",
      "regency": "Serpong Utara",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Jagung - 15326"
    },
    {
      "id": "1233",
      "postcode": "15326",
      "village": "Pondok Jagung Timur",
      "regency": "Serpong Utara",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Jagung Timur - 15326"
    },
    {
      "id": "1234",
      "postcode": "15411",
      "village": "Cipayung",
      "regency": "Ciputat",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Cipayung - 15411"
    },
    {
      "id": "1235",
      "postcode": "15411",
      "village": "Ciputat",
      "regency": "Ciputat",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Ciputat - 15411"
    },
    {
      "id": "1236",
      "postcode": "15412",
      "village": "Cempaka Putih",
      "regency": "Ciputat Timur",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Cempaka Putih - 15412"
    },
    {
      "id": "1237",
      "postcode": "15412",
      "village": "Pondok Ranji",
      "regency": "Ciputat Timur",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Ranji - 15412"
    },
    {
      "id": "1238",
      "postcode": "15412",
      "village": "Rempoa",
      "regency": "Ciputat Timur",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Rempoa - 15412"
    },
    {
      "id": "1239",
      "postcode": "15412",
      "village": "Rengas",
      "regency": "Ciputat Timur",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Rengas - 15412"
    },
    {
      "id": "1240",
      "postcode": "15413",
      "village": "Sawah Baru",
      "regency": "Ciputat",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Sawah Baru - 15413"
    },
    {
      "id": "1241",
      "postcode": "15413",
      "village": "Sawah Lama",
      "regency": "Ciputat",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Sawah Lama - 15413"
    },
    {
      "id": "1242",
      "postcode": "15414",
      "village": "Jombang",
      "regency": "Ciputat",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Jombang - 15414"
    },
    {
      "id": "1243",
      "postcode": "15414",
      "village": "Sarua (Serua)",
      "regency": "Ciputat",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Sarua (Serua) - 15414"
    },
    {
      "id": "1244",
      "postcode": "15414",
      "village": "Sarua Indah",
      "regency": "Ciputat",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Sarua Indah - 15414"
    },
    {
      "id": "1245",
      "postcode": "15415",
      "village": "Bambu Apus",
      "regency": "Pamulang",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Bambu Apus - 15415"
    },
    {
      "id": "1246",
      "postcode": "15415",
      "village": "Kedaung",
      "regency": "Pamulang",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Kedaung - 15415"
    },
    {
      "id": "1247",
      "postcode": "15416",
      "village": "Pondok Benda",
      "regency": "Pamulang",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Benda - 15416"
    },
    {
      "id": "1248",
      "postcode": "15417",
      "village": "Pamulang Barat",
      "regency": "Pamulang",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pamulang Barat - 15417"
    },
    {
      "id": "1249",
      "postcode": "15417",
      "village": "Pamulang Timur",
      "regency": "Pamulang",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pamulang Timur - 15417"
    },
    {
      "id": "1250",
      "postcode": "15418",
      "village": "Benda Baru",
      "regency": "Pamulang",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Benda Baru - 15418"
    },
    {
      "id": "1251",
      "postcode": "15418",
      "village": "Pondok Cabe Ilir",
      "regency": "Pamulang",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Cabe Ilir - 15418"
    },
    {
      "id": "1252",
      "postcode": "15418",
      "village": "Pondok Cabe Udik",
      "regency": "Pamulang",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pondok Cabe Udik - 15418"
    },
    {
      "id": "1253",
      "postcode": "15419",
      "village": "Cireundeu",
      "regency": "Ciputat Timur",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Cireundeu - 15419"
    },
    {
      "id": "1254",
      "postcode": "15419",
      "village": "Pisangan",
      "regency": "Ciputat Timur",
      "state": "Kota",
      "district": "Tangerang Selatan",
      "province": "Banten",
      "string": "Pisangan - 15419"
    },
    {
      "id": "1255",
      "postcode": "17111",
      "village": "Aren Jaya",
      "regency": "Bekasi Timur",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Aren Jaya - 17111"
    },
    {
      "id": "1256",
      "postcode": "17111",
      "village": "Duren Jaya",
      "regency": "Bekasi Timur",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Duren Jaya - 17111"
    },
    {
      "id": "1257",
      "postcode": "17112",
      "village": "Bekasi Jaya",
      "regency": "Bekasi Timur",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Bekasi Jaya - 17112"
    },
    {
      "id": "1258",
      "postcode": "17113",
      "village": "Margahayu",
      "regency": "Bekasi Timur",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Margahayu - 17113"
    },
    {
      "id": "1259",
      "postcode": "17114",
      "village": "Sepanjang Jaya",
      "regency": "Rawalumbu",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sepanjang Jaya - 17114"
    },
    {
      "id": "1260",
      "postcode": "17115",
      "village": "Pengasinan",
      "regency": "Rawalumbu",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pengasinan - 17115"
    },
    {
      "id": "1261",
      "postcode": "17116",
      "village": "Bojong Rawalumbu",
      "regency": "Rawalumbu",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Bojong Rawalumbu - 17116"
    },
    {
      "id": "1262",
      "postcode": "17117",
      "village": "Bojong Menteng",
      "regency": "Rawalumbu",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Bojong Menteng - 17117"
    },
    {
      "id": "1263",
      "postcode": "17121",
      "village": "Teluk Pucung",
      "regency": "Bekasi Utara",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Teluk Pucung - 17121"
    },
    {
      "id": "1264",
      "postcode": "17122",
      "village": "Perwira",
      "regency": "Bekasi Utara",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Perwira - 17122"
    },
    {
      "id": "1265",
      "postcode": "17123",
      "village": "Harapan Baru",
      "regency": "Bekasi Utara",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Harapan Baru - 17123"
    },
    {
      "id": "1266",
      "postcode": "17124",
      "village": "Harapan Jaya",
      "regency": "Bekasi Utara",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Harapan Jaya - 17124"
    },
    {
      "id": "1267",
      "postcode": "17125",
      "village": "Kali Abang Tengah",
      "regency": "Bekasi Utara",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kali Abang Tengah - 17125"
    },
    {
      "id": "1268",
      "postcode": "17131",
      "village": "Pejuang",
      "regency": "Medan Satria",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pejuang - 17131"
    },
    {
      "id": "1269",
      "postcode": "17132",
      "village": "Medan Satria",
      "regency": "Medan Satria",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Medan Satria - 17132"
    },
    {
      "id": "1270",
      "postcode": "17133",
      "village": "Kali Baru",
      "regency": "Medan Satria",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kali Baru - 17133"
    },
    {
      "id": "1271",
      "postcode": "17133",
      "village": "Kota Baru",
      "regency": "Bekasi Barat",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kota Baru - 17133"
    },
    {
      "id": "1272",
      "postcode": "17134",
      "village": "Bintara",
      "regency": "Bekasi Barat",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Bintara - 17134"
    },
    {
      "id": "1273",
      "postcode": "17135",
      "village": "Kranji",
      "regency": "Bekasi Barat",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kranji - 17135"
    },
    {
      "id": "1274",
      "postcode": "17136",
      "village": "Bintara Jaya",
      "regency": "Bekasi Barat",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Bintara Jaya - 17136"
    },
    {
      "id": "1275",
      "postcode": "17141",
      "village": "Margajaya",
      "regency": "Bekasi Selatan",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Margajaya - 17141"
    },
    {
      "id": "1276",
      "postcode": "17142",
      "village": "Marga Mulya",
      "regency": "Bekasi Utara",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Marga Mulya - 17142"
    },
    {
      "id": "1277",
      "postcode": "17143",
      "village": "Harapan Mulya",
      "regency": "Medan Satria",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Harapan Mulya - 17143"
    },
    {
      "id": "1278",
      "postcode": "17144",
      "village": "Kayuringin Jaya",
      "regency": "Bekasi Selatan",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kayuringin Jaya - 17144"
    },
    {
      "id": "1279",
      "postcode": "17145",
      "village": "Jaka Sampurna",
      "regency": "Bekasi Barat",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jaka Sampurna - 17145"
    },
    {
      "id": "1280",
      "postcode": "17146",
      "village": "Jaka Mulya",
      "regency": "Bekasi Selatan",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jaka Mulya - 17146"
    },
    {
      "id": "1281",
      "postcode": "17147",
      "village": "Jaka Setia",
      "regency": "Bekasi Selatan",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jaka Setia - 17147"
    },
    {
      "id": "1282",
      "postcode": "17148",
      "village": "Pekayon Jaya",
      "regency": "Bekasi Selatan",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pekayon Jaya - 17148"
    },
    {
      "id": "1283",
      "postcode": "17151",
      "village": "Bantar Gebang",
      "regency": "Bantar Gebang",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Bantar Gebang - 17151"
    },
    {
      "id": "1284",
      "postcode": "17152",
      "village": "Cikiwul",
      "regency": "Bantar Gebang",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cikiwul - 17152"
    },
    {
      "id": "1285",
      "postcode": "17153",
      "village": "Ciketing Udik",
      "regency": "Bantar Gebang",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Ciketing Udik - 17153"
    },
    {
      "id": "1286",
      "postcode": "17154",
      "village": "Sumur Batu",
      "regency": "Bantar Gebang",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sumur Batu - 17154"
    },
    {
      "id": "1287",
      "postcode": "17155",
      "village": "Cimuning",
      "regency": "Mustika Jaya",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cimuning - 17155"
    },
    {
      "id": "1288",
      "postcode": "17156",
      "village": "Pedurenan (Padurenan)",
      "regency": "Mustika Jaya",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pedurenan (Padurenan) - 17156"
    },
    {
      "id": "1289",
      "postcode": "17157",
      "village": "Mustika Sari",
      "regency": "Mustika Jaya",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Mustika Sari - 17157"
    },
    {
      "id": "1290",
      "postcode": "17158",
      "village": "Mustika Jaya",
      "regency": "Mustika Jaya",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Mustika Jaya - 17158"
    },
    {
      "id": "1291",
      "postcode": "17211",
      "village": "Segara Makmur",
      "regency": "Tarumajaya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Segara Makmur - 17211"
    },
    {
      "id": "1292",
      "postcode": "17212",
      "village": "Pantai Makmur",
      "regency": "Tarumajaya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pantai Makmur - 17212"
    },
    {
      "id": "1293",
      "postcode": "17213",
      "village": "Setia Mulya",
      "regency": "Tarumajaya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Setia Mulya - 17213"
    },
    {
      "id": "1294",
      "postcode": "17214",
      "village": "Pusaka Rakyat",
      "regency": "Tarumajaya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pusaka Rakyat - 17214"
    },
    {
      "id": "1295",
      "postcode": "17215",
      "village": "Setia Asih",
      "regency": "Tarumajaya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Setia Asih - 17215"
    },
    {
      "id": "1296",
      "postcode": "17216",
      "village": "Pahlawan Setia",
      "regency": "Tarumajaya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pahlawan Setia - 17216"
    },
    {
      "id": "1297",
      "postcode": "17217",
      "village": "Samudra Jaya",
      "regency": "Tarumajaya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Samudra Jaya - 17217"
    },
    {
      "id": "1298",
      "postcode": "17218",
      "village": "Segara Jaya",
      "regency": "Tarumajaya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Segara Jaya - 17218"
    },
    {
      "id": "1299",
      "postcode": "17320",
      "village": "Burangkeng",
      "regency": "Setu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Burangkeng - 17320"
    },
    {
      "id": "1300",
      "postcode": "17320",
      "village": "Cibening",
      "regency": "Setu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cibening - 17320"
    },
    {
      "id": "1301",
      "postcode": "17320",
      "village": "Cijengkol",
      "regency": "Setu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cijengkol - 17320"
    },
    {
      "id": "1302",
      "postcode": "17320",
      "village": "Cikarageman",
      "regency": "Setu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cikarageman - 17320"
    },
    {
      "id": "1303",
      "postcode": "17320",
      "village": "Cileduk",
      "regency": "Setu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cileduk - 17320"
    },
    {
      "id": "1304",
      "postcode": "17320",
      "village": "Kertarahayu",
      "regency": "Setu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kertarahayu - 17320"
    },
    {
      "id": "1305",
      "postcode": "17320",
      "village": "Lubangbuaya",
      "regency": "Setu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Lubangbuaya - 17320"
    },
    {
      "id": "1306",
      "postcode": "17320",
      "village": "Muktijaya",
      "regency": "Setu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Muktijaya - 17320"
    },
    {
      "id": "1307",
      "postcode": "17320",
      "village": "Ragemanunggal",
      "regency": "Setu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Ragemanunggal - 17320"
    },
    {
      "id": "1308",
      "postcode": "17320",
      "village": "Taman Rahayu",
      "regency": "Setu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Taman Rahayu - 17320"
    },
    {
      "id": "1309",
      "postcode": "17320",
      "village": "Taman Sari",
      "regency": "Setu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Taman Sari - 17320"
    },
    {
      "id": "1310",
      "postcode": "17330",
      "village": "Cilangkara",
      "regency": "Serang Baru",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cilangkara - 17330"
    },
    {
      "id": "1311",
      "postcode": "17330",
      "village": "Jaya Sampurna",
      "regency": "Serang Baru",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jaya Sampurna - 17330"
    },
    {
      "id": "1312",
      "postcode": "17330",
      "village": "Jayamulya",
      "regency": "Serang Baru",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jayamulya - 17330"
    },
    {
      "id": "1313",
      "postcode": "17330",
      "village": "Nagacipta",
      "regency": "Serang Baru",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Nagacipta - 17330"
    },
    {
      "id": "1314",
      "postcode": "17330",
      "village": "Nagasari",
      "regency": "Serang Baru",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Nagasari - 17330"
    },
    {
      "id": "1315",
      "postcode": "17330",
      "village": "Sirnajaya",
      "regency": "Serang Baru",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sirnajaya - 17330"
    },
    {
      "id": "1316",
      "postcode": "17330",
      "village": "Sukaragam",
      "regency": "Serang Baru",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukaragam - 17330"
    },
    {
      "id": "1317",
      "postcode": "17330",
      "village": "Sukasari",
      "regency": "Serang Baru",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukasari - 17330"
    },
    {
      "id": "1318",
      "postcode": "17340",
      "village": "Cibarusahjaya",
      "regency": "Cibarusah",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cibarusahjaya - 17340"
    },
    {
      "id": "1319",
      "postcode": "17340",
      "village": "Cibarusahkota",
      "regency": "Cibarusah",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cibarusahkota - 17340"
    },
    {
      "id": "1320",
      "postcode": "17340",
      "village": "Ridogalih",
      "regency": "Cibarusah",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Ridogalih - 17340"
    },
    {
      "id": "1321",
      "postcode": "17340",
      "village": "Ridomanah",
      "regency": "Cibarusah",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Ridomanah - 17340"
    },
    {
      "id": "1322",
      "postcode": "17340",
      "village": "Sindangmulya",
      "regency": "Cibarusah",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sindangmulya - 17340"
    },
    {
      "id": "1323",
      "postcode": "17340",
      "village": "Sirnajati",
      "regency": "Cibarusah",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sirnajati - 17340"
    },
    {
      "id": "1324",
      "postcode": "17340",
      "village": "Wibawamulya",
      "regency": "Cibarusah",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Wibawamulya - 17340"
    },
    {
      "id": "1325",
      "postcode": "17350",
      "village": "Karangindah",
      "regency": "Bojongmangu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karangindah - 17350"
    },
    {
      "id": "1326",
      "postcode": "17350",
      "village": "Medalkrisna",
      "regency": "Bojongmangu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Medalkrisna - 17350"
    },
    {
      "id": "1327",
      "postcode": "17350",
      "village": "Sukabungah",
      "regency": "Bojongmangu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukabungah - 17350"
    },
    {
      "id": "1328",
      "postcode": "17350",
      "village": "Sukamukti",
      "regency": "Bojongmangu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukamukti - 17350"
    },
    {
      "id": "1329",
      "postcode": "17352",
      "village": "Bojongmanggu",
      "regency": "Bojongmangu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Bojongmanggu - 17352"
    },
    {
      "id": "1330",
      "postcode": "17356",
      "village": "Karangmulya",
      "regency": "Bojongmangu",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karangmulya - 17356"
    },
    {
      "id": "1331",
      "postcode": "17411",
      "village": "Jaticempaka",
      "regency": "Pondok Gede",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jaticempaka - 17411"
    },
    {
      "id": "1332",
      "postcode": "17411",
      "village": "Jatiwaringin",
      "regency": "Pondok Gede",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatiwaringin - 17411"
    },
    {
      "id": "1333",
      "postcode": "17412",
      "village": "Jatibening",
      "regency": "Pondok Gede",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatibening - 17412"
    },
    {
      "id": "1334",
      "postcode": "17412",
      "village": "Jatibening Baru",
      "regency": "Pondok Gede",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatibening Baru - 17412"
    },
    {
      "id": "1335",
      "postcode": "17413",
      "village": "Jatimakmur",
      "regency": "Pondok Gede",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatimakmur - 17413"
    },
    {
      "id": "1336",
      "postcode": "17414",
      "village": "Jati Rahayu",
      "regency": "Pondok Melati",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jati Rahayu - 17414"
    },
    {
      "id": "1337",
      "postcode": "17414",
      "village": "Jatimelati",
      "regency": "Pondok Melati",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatimelati - 17414"
    },
    {
      "id": "1338",
      "postcode": "17415",
      "village": "Jatiwarna",
      "regency": "Pondok Melati",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatiwarna - 17415"
    },
    {
      "id": "1339",
      "postcode": "17421",
      "village": "Jatikramat",
      "regency": "Jatiasih",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatikramat - 17421"
    },
    {
      "id": "1340",
      "postcode": "17422",
      "village": "Jati Mekar",
      "regency": "Jatiasih",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jati Mekar - 17422"
    },
    {
      "id": "1341",
      "postcode": "17423",
      "village": "Jatiasih",
      "regency": "Jatiasih",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatiasih - 17423"
    },
    {
      "id": "1342",
      "postcode": "17424",
      "village": "Jatirasa",
      "regency": "Jatiasih",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatirasa - 17424"
    },
    {
      "id": "1343",
      "postcode": "17425",
      "village": "Jatiluhur",
      "regency": "Jatiasih",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatiluhur - 17425"
    },
    {
      "id": "1344",
      "postcode": "17426",
      "village": "Jatisari",
      "regency": "Jatiasih",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatisari - 17426"
    },
    {
      "id": "1345",
      "postcode": "17431",
      "village": "Jatimurni",
      "regency": "Pondok Melati",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatimurni - 17431"
    },
    {
      "id": "1346",
      "postcode": "17432",
      "village": "Jatiranggon",
      "regency": "Jati Sampurna",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatiranggon - 17432"
    },
    {
      "id": "1347",
      "postcode": "17433",
      "village": "Jatiraden",
      "regency": "Jati Sampurna",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatiraden - 17433"
    },
    {
      "id": "1348",
      "postcode": "17433",
      "village": "Jatisampurna",
      "regency": "Jati Sampurna",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatisampurna - 17433"
    },
    {
      "id": "1349",
      "postcode": "17434",
      "village": "Jatirangga",
      "regency": "Jati Sampurna",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatirangga - 17434"
    },
    {
      "id": "1350",
      "postcode": "17435",
      "village": "Jatikarya",
      "regency": "Jati Sampurna",
      "state": "Kota",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatikarya - 17435"
    },
    {
      "id": "1351",
      "postcode": "17510",
      "village": "Jalenjaya (Jejalenjaya)",
      "regency": "Tambun Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jalenjaya (Jejalenjaya) - 17510"
    },
    {
      "id": "1352",
      "postcode": "17510",
      "village": "Jatimulya",
      "regency": "Tambun Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatimulya - 17510"
    },
    {
      "id": "1353",
      "postcode": "17510",
      "village": "Karangsatria",
      "regency": "Tambun Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karangsatria - 17510"
    },
    {
      "id": "1354",
      "postcode": "17510",
      "village": "Lambangjaya",
      "regency": "Tambun Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Lambangjaya - 17510"
    },
    {
      "id": "1355",
      "postcode": "17510",
      "village": "Lambangsari",
      "regency": "Tambun Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Lambangsari - 17510"
    },
    {
      "id": "1356",
      "postcode": "17510",
      "village": "Mangunjaya",
      "regency": "Tambun Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Mangunjaya - 17510"
    },
    {
      "id": "1357",
      "postcode": "17510",
      "village": "Mekarsari",
      "regency": "Tambun Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Mekarsari - 17510"
    },
    {
      "id": "1358",
      "postcode": "17510",
      "village": "Satriajaya",
      "regency": "Tambun Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Satriajaya - 17510"
    },
    {
      "id": "1359",
      "postcode": "17510",
      "village": "Satriamekar",
      "regency": "Tambun Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Satriamekar - 17510"
    },
    {
      "id": "1360",
      "postcode": "17510",
      "village": "Setiadarma",
      "regency": "Tambun Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Setiadarma - 17510"
    },
    {
      "id": "1361",
      "postcode": "17510",
      "village": "Setiamekar",
      "regency": "Tambun Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Setiamekar - 17510"
    },
    {
      "id": "1362",
      "postcode": "17510",
      "village": "Sriamur",
      "regency": "Tambun Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sriamur - 17510"
    },
    {
      "id": "1363",
      "postcode": "17510",
      "village": "Srijaya",
      "regency": "Tambun Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Srijaya - 17510"
    },
    {
      "id": "1364",
      "postcode": "17510",
      "village": "Srimahi",
      "regency": "Tambun Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Srimahi - 17510"
    },
    {
      "id": "1365",
      "postcode": "17510",
      "village": "Srimukti",
      "regency": "Tambun Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Srimukti - 17510"
    },
    {
      "id": "1366",
      "postcode": "17510",
      "village": "Sumber Jaya",
      "regency": "Tambun Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sumber Jaya - 17510"
    },
    {
      "id": "1367",
      "postcode": "17510",
      "village": "Tambun",
      "regency": "Tambun Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Tambun - 17510"
    },
    {
      "id": "1368",
      "postcode": "17510",
      "village": "Tridaya Sakti",
      "regency": "Tambun Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Tridaya Sakti - 17510"
    },
    {
      "id": "1369",
      "postcode": "17520",
      "village": "Cibuntu",
      "regency": "Cibitung",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cibuntu - 17520"
    },
    {
      "id": "1370",
      "postcode": "17520",
      "village": "Kerta Mukti",
      "regency": "Cibitung",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kerta Mukti - 17520"
    },
    {
      "id": "1371",
      "postcode": "17520",
      "village": "Muktiwari",
      "regency": "Cibitung",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Muktiwari - 17520"
    },
    {
      "id": "1372",
      "postcode": "17520",
      "village": "Sarimukti",
      "regency": "Cibitung",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sarimukti - 17520"
    },
    {
      "id": "1373",
      "postcode": "17520",
      "village": "Sukajaya",
      "regency": "Cibitung",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukajaya - 17520"
    },
    {
      "id": "1374",
      "postcode": "17520",
      "village": "Wanajaya",
      "regency": "Cibitung",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Wanajaya - 17520"
    },
    {
      "id": "1375",
      "postcode": "17520",
      "village": "Wanasari",
      "regency": "Cibitung",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Wanasari - 17520"
    },
    {
      "id": "1376",
      "postcode": "17530",
      "village": "Ciantra",
      "regency": "Cikarang Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Ciantra - 17530"
    },
    {
      "id": "1377",
      "postcode": "17530",
      "village": "Cibatu",
      "regency": "Cikarang Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cibatu - 17530"
    },
    {
      "id": "1378",
      "postcode": "17530",
      "village": "Cicau",
      "regency": "Cikarang Pusat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cicau - 17530"
    },
    {
      "id": "1379",
      "postcode": "17530",
      "village": "Cikarang Kota",
      "regency": "Cikarang Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cikarang Kota - 17530"
    },
    {
      "id": "1380",
      "postcode": "17530",
      "village": "Cikedokan",
      "regency": "Cikarang Barat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cikedokan - 17530"
    },
    {
      "id": "1381",
      "postcode": "17530",
      "village": "Cipayung",
      "regency": "Cikarang Timur",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Cipayung - 17530"
    },
    {
      "id": "1382",
      "postcode": "17530",
      "village": "Danau Indah",
      "regency": "Cikarang Barat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Danau Indah - 17530"
    },
    {
      "id": "1383",
      "postcode": "17530",
      "village": "Gandamekar",
      "regency": "Cikarang Barat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Gandamekar - 17530"
    },
    {
      "id": "1384",
      "postcode": "17530",
      "village": "Gandasari",
      "regency": "Cikarang Barat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Gandasari - 17530"
    },
    {
      "id": "1385",
      "postcode": "17530",
      "village": "Harja Mekar",
      "regency": "Cikarang Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Harja Mekar - 17530"
    },
    {
      "id": "1386",
      "postcode": "17530",
      "village": "Hegarmanah",
      "regency": "Cikarang Timur",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Hegarmanah - 17530"
    },
    {
      "id": "1387",
      "postcode": "17530",
      "village": "Hegarmukti",
      "regency": "Cikarang Pusat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Hegarmukti - 17530"
    },
    {
      "id": "1388",
      "postcode": "17530",
      "village": "Jatibaru",
      "regency": "Cikarang Timur",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatibaru - 17530"
    },
    {
      "id": "1389",
      "postcode": "17530",
      "village": "Jatireja",
      "regency": "Cikarang Timur",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatireja - 17530"
    },
    {
      "id": "1390",
      "postcode": "17530",
      "village": "Jatiwangi",
      "regency": "Cikarang Barat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jatiwangi - 17530"
    },
    {
      "id": "1391",
      "postcode": "17530",
      "village": "Jayamukti",
      "regency": "Cikarang Pusat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jayamukti - 17530"
    },
    {
      "id": "1392",
      "postcode": "17530",
      "village": "Kali Jaya",
      "regency": "Cikarang Barat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kali Jaya - 17530"
    },
    {
      "id": "1393",
      "postcode": "17530",
      "village": "Karang Bahagia",
      "regency": "Karangbahagia",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karang Bahagia - 17530"
    },
    {
      "id": "1394",
      "postcode": "17530",
      "village": "Karang Baru",
      "regency": "Cikarang Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karang Baru - 17530"
    },
    {
      "id": "1395",
      "postcode": "17530",
      "village": "Karang Rahayu",
      "regency": "Karangbahagia",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karang Rahayu - 17530"
    },
    {
      "id": "1396",
      "postcode": "17530",
      "village": "Karang Sentosa",
      "regency": "Karangbahagia",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karang Sentosa - 17530"
    },
    {
      "id": "1397",
      "postcode": "17530",
      "village": "Karang Setra",
      "regency": "Karangbahagia",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karang Setra - 17530"
    },
    {
      "id": "1398",
      "postcode": "17530",
      "village": "Karang Setu",
      "regency": "Karangbahagia",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karang Setu - 17530"
    },
    {
      "id": "1399",
      "postcode": "17530",
      "village": "Karanganyar",
      "regency": "Karangbahagia",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karanganyar - 17530"
    },
    {
      "id": "1400",
      "postcode": "17530",
      "village": "Karangasih",
      "regency": "Cikarang Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karangasih - 17530"
    },
    {
      "id": "1401",
      "postcode": "17530",
      "village": "Karangmukti",
      "regency": "Karangbahagia",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karangmukti - 17530"
    },
    {
      "id": "1402",
      "postcode": "17530",
      "village": "Karangraharja",
      "regency": "Cikarang Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karangraharja - 17530"
    },
    {
      "id": "1403",
      "postcode": "17530",
      "village": "Karangsari",
      "regency": "Cikarang Timur",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karangsari - 17530"
    },
    {
      "id": "1404",
      "postcode": "17530",
      "village": "Labansari",
      "regency": "Cikarang Timur",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Labansari - 17530"
    },
    {
      "id": "1405",
      "postcode": "17530",
      "village": "Mekar Wangi",
      "regency": "Cikarang Barat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Mekar Wangi - 17530"
    },
    {
      "id": "1406",
      "postcode": "17530",
      "village": "Mekarmukti",
      "regency": "Cikarang Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Mekarmukti - 17530"
    },
    {
      "id": "1407",
      "postcode": "17530",
      "village": "Pasir Gombong",
      "regency": "Cikarang Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pasir Gombong - 17530"
    },
    {
      "id": "1408",
      "postcode": "17530",
      "village": "Pasirpanji",
      "regency": "Cikarang Pusat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pasirpanji - 17530"
    },
    {
      "id": "1409",
      "postcode": "17530",
      "village": "Pasirsari",
      "regency": "Cikarang Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pasirsari - 17530"
    },
    {
      "id": "1410",
      "postcode": "17530",
      "village": "Pasirtanjung",
      "regency": "Cikarang Pusat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pasirtanjung - 17530"
    },
    {
      "id": "1411",
      "postcode": "17530",
      "village": "Serang",
      "regency": "Cikarang Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Serang - 17530"
    },
    {
      "id": "1412",
      "postcode": "17530",
      "village": "Sertajaya",
      "regency": "Cikarang Timur",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sertajaya - 17530"
    },
    {
      "id": "1413",
      "postcode": "17530",
      "village": "Simpangan",
      "regency": "Cikarang Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Simpangan - 17530"
    },
    {
      "id": "1414",
      "postcode": "17530",
      "village": "Sukadami",
      "regency": "Cikarang Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukadami - 17530"
    },
    {
      "id": "1415",
      "postcode": "17530",
      "village": "Sukadanau",
      "regency": "Cikarang Barat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukadanau - 17530"
    },
    {
      "id": "1416",
      "postcode": "17530",
      "village": "Sukamahi",
      "regency": "Cikarang Pusat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukamahi - 17530"
    },
    {
      "id": "1417",
      "postcode": "17530",
      "village": "Sukaraya",
      "regency": "Karangbahagia",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukaraya - 17530"
    },
    {
      "id": "1418",
      "postcode": "17530",
      "village": "Sukaresmi",
      "regency": "Cikarang Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukaresmi - 17530"
    },
    {
      "id": "1419",
      "postcode": "17530",
      "village": "Sukasejati",
      "regency": "Cikarang Selatan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukasejati - 17530"
    },
    {
      "id": "1420",
      "postcode": "17530",
      "village": "Tanjungbaru",
      "regency": "Cikarang Timur",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Tanjungbaru - 17530"
    },
    {
      "id": "1421",
      "postcode": "17530",
      "village": "Tanjungsari",
      "regency": "Cikarang Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Tanjungsari - 17530"
    },
    {
      "id": "1422",
      "postcode": "17530",
      "village": "Telaga Asih",
      "regency": "Cikarang Barat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Telaga Asih - 17530"
    },
    {
      "id": "1423",
      "postcode": "17530",
      "village": "Telaga Murni",
      "regency": "Cikarang Barat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Telaga Murni - 17530"
    },
    {
      "id": "1424",
      "postcode": "17530",
      "village": "Telajung",
      "regency": "Cikarang Barat",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Telajung - 17530"
    },
    {
      "id": "1425",
      "postcode": "17530",
      "village": "Waluya",
      "regency": "Cikarang Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Waluya - 17530"
    },
    {
      "id": "1426",
      "postcode": "17530",
      "village": "Wangunharja",
      "regency": "Cikarang Utara",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Wangunharja - 17530"
    },
    {
      "id": "1427",
      "postcode": "17540",
      "village": "Bojongsari",
      "regency": "Kedung Waringin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Bojongsari - 17540"
    },
    {
      "id": "1428",
      "postcode": "17540",
      "village": "Karang Harum",
      "regency": "Kedung Waringin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karang Harum - 17540"
    },
    {
      "id": "1429",
      "postcode": "17540",
      "village": "Karang Mekar",
      "regency": "Kedung Waringin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karang Mekar - 17540"
    },
    {
      "id": "1430",
      "postcode": "17540",
      "village": "Karang Sambung",
      "regency": "Kedung Waringin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karang Sambung - 17540"
    },
    {
      "id": "1431",
      "postcode": "17540",
      "village": "Kedungwaringin",
      "regency": "Kedung Waringin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kedungwaringin - 17540"
    },
    {
      "id": "1432",
      "postcode": "17540",
      "village": "Mekar Jaya",
      "regency": "Kedung Waringin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Mekar Jaya - 17540"
    },
    {
      "id": "1433",
      "postcode": "17540",
      "village": "Waringin Jaya",
      "regency": "Kedung Waringin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Waringin Jaya - 17540"
    },
    {
      "id": "1434",
      "postcode": "17610",
      "village": "Babelan Kota",
      "regency": "Babelan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Babelan Kota - 17610"
    },
    {
      "id": "1435",
      "postcode": "17610",
      "village": "Bahagia",
      "regency": "Babelan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Bahagia - 17610"
    },
    {
      "id": "1436",
      "postcode": "17610",
      "village": "Buni Bakti",
      "regency": "Babelan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Buni Bakti - 17610"
    },
    {
      "id": "1437",
      "postcode": "17610",
      "village": "Hurip Jaya",
      "regency": "Babelan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Hurip Jaya - 17610"
    },
    {
      "id": "1438",
      "postcode": "17610",
      "village": "Kebalen",
      "regency": "Babelan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kebalen - 17610"
    },
    {
      "id": "1439",
      "postcode": "17610",
      "village": "Kedung Pengawas",
      "regency": "Babelan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kedung Pengawas - 17610"
    },
    {
      "id": "1440",
      "postcode": "17610",
      "village": "Kedungjaya",
      "regency": "Babelan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kedungjaya - 17610"
    },
    {
      "id": "1441",
      "postcode": "17610",
      "village": "Muara Bakti",
      "regency": "Babelan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Muara Bakti - 17610"
    },
    {
      "id": "1442",
      "postcode": "17610",
      "village": "Pantai Hurip",
      "regency": "Babelan",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pantai Hurip - 17610"
    },
    {
      "id": "1443",
      "postcode": "17620",
      "village": "Sukabakti",
      "regency": "Tambelang",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukabakti - 17620"
    },
    {
      "id": "1444",
      "postcode": "17620",
      "village": "Sukabudi",
      "regency": "Sukawangi",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukabudi - 17620"
    },
    {
      "id": "1445",
      "postcode": "17620",
      "village": "Sukadaya",
      "regency": "Sukawangi",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukadaya - 17620"
    },
    {
      "id": "1446",
      "postcode": "17620",
      "village": "Sukakerta",
      "regency": "Sukawangi",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukakerta - 17620"
    },
    {
      "id": "1447",
      "postcode": "17620",
      "village": "Sukamaju",
      "regency": "Tambelang",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukamaju - 17620"
    },
    {
      "id": "1448",
      "postcode": "17620",
      "village": "Sukamantri",
      "regency": "Tambelang",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukamantri - 17620"
    },
    {
      "id": "1449",
      "postcode": "17620",
      "village": "Sukamekar",
      "regency": "Sukawangi",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukamekar - 17620"
    },
    {
      "id": "1450",
      "postcode": "17620",
      "village": "Sukarahayu",
      "regency": "Tambelang",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukarahayu - 17620"
    },
    {
      "id": "1451",
      "postcode": "17620",
      "village": "Sukaraja",
      "regency": "Tambelang",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukaraja - 17620"
    },
    {
      "id": "1452",
      "postcode": "17620",
      "village": "Sukarapih",
      "regency": "Tambelang",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukarapih - 17620"
    },
    {
      "id": "1453",
      "postcode": "17620",
      "village": "Sukaringin",
      "regency": "Sukawangi",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukaringin - 17620"
    },
    {
      "id": "1454",
      "postcode": "17620",
      "village": "Sukatenang",
      "regency": "Sukawangi",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukatenang - 17620"
    },
    {
      "id": "1455",
      "postcode": "17620",
      "village": "Sukawangi",
      "regency": "Sukawangi",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukawangi - 17620"
    },
    {
      "id": "1456",
      "postcode": "17620",
      "village": "Sukawijaya",
      "regency": "Tambelang",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukawijaya - 17620"
    },
    {
      "id": "1457",
      "postcode": "17630",
      "village": "Banjar Sari",
      "regency": "Sukatani",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Banjar Sari - 17630"
    },
    {
      "id": "1458",
      "postcode": "17630",
      "village": "Suka Asih",
      "regency": "Sukatani",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Suka Asih - 17630"
    },
    {
      "id": "1459",
      "postcode": "17630",
      "village": "Sukadarma",
      "regency": "Sukatani",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukadarma - 17630"
    },
    {
      "id": "1460",
      "postcode": "17630",
      "village": "Sukahurip",
      "regency": "Sukatani",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukahurip - 17630"
    },
    {
      "id": "1461",
      "postcode": "17630",
      "village": "Sukaindah",
      "regency": "Sukakarya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukaindah - 17630"
    },
    {
      "id": "1462",
      "postcode": "17630",
      "village": "Sukajadi",
      "regency": "Sukakarya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukajadi - 17630"
    },
    {
      "id": "1463",
      "postcode": "17630",
      "village": "Sukakarya",
      "regency": "Sukakarya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukakarya - 17630"
    },
    {
      "id": "1464",
      "postcode": "17630",
      "village": "Sukakersa",
      "regency": "Sukakarya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukakersa - 17630"
    },
    {
      "id": "1465",
      "postcode": "17630",
      "village": "Sukalaksana",
      "regency": "Sukakarya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukalaksana - 17630"
    },
    {
      "id": "1466",
      "postcode": "17630",
      "village": "Sukamakmur",
      "regency": "Sukakarya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukamakmur - 17630"
    },
    {
      "id": "1467",
      "postcode": "17630",
      "village": "Sukamanah",
      "regency": "Sukatani",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukamanah - 17630"
    },
    {
      "id": "1468",
      "postcode": "17630",
      "village": "Sukamulya",
      "regency": "Sukatani",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukamulya - 17630"
    },
    {
      "id": "1469",
      "postcode": "17630",
      "village": "Sukamurni",
      "regency": "Sukakarya",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukamurni - 17630"
    },
    {
      "id": "1470",
      "postcode": "17630",
      "village": "Sukarukun",
      "regency": "Sukatani",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sukarukun - 17630"
    },
    {
      "id": "1471",
      "postcode": "17710",
      "village": "Bantarjaya",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Bantarjaya - 17710"
    },
    {
      "id": "1472",
      "postcode": "17710",
      "village": "Bantarsari",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Bantarsari - 17710"
    },
    {
      "id": "1473",
      "postcode": "17710",
      "village": "Karang Harja",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karang Harja - 17710"
    },
    {
      "id": "1474",
      "postcode": "17710",
      "village": "Karanghaur",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karanghaur - 17710"
    },
    {
      "id": "1475",
      "postcode": "17710",
      "village": "Karangjaya",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karangjaya - 17710"
    },
    {
      "id": "1476",
      "postcode": "17710",
      "village": "Karangpatri",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karangpatri - 17710"
    },
    {
      "id": "1477",
      "postcode": "17710",
      "village": "Karangreja",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karangreja - 17710"
    },
    {
      "id": "1478",
      "postcode": "17710",
      "village": "Karangsegar",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Karangsegar - 17710"
    },
    {
      "id": "1479",
      "postcode": "17710",
      "village": "Kertajaya",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kertajaya - 17710"
    },
    {
      "id": "1480",
      "postcode": "17710",
      "village": "Kertasari",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Kertasari - 17710"
    },
    {
      "id": "1481",
      "postcode": "17710",
      "village": "Sumberreja",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sumberreja - 17710"
    },
    {
      "id": "1482",
      "postcode": "17710",
      "village": "Sumbersari",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sumbersari - 17710"
    },
    {
      "id": "1483",
      "postcode": "17710",
      "village": "Sumberurip",
      "regency": "Pebayuran",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sumberurip - 17710"
    },
    {
      "id": "1484",
      "postcode": "17720",
      "village": "Jaya Bakti",
      "regency": "Cabangbungin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jaya Bakti - 17720"
    },
    {
      "id": "1485",
      "postcode": "17720",
      "village": "Jaya Laksana",
      "regency": "Cabangbungin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jaya Laksana - 17720"
    },
    {
      "id": "1486",
      "postcode": "17720",
      "village": "Lenggah Jaya",
      "regency": "Cabangbungin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Lenggah Jaya - 17720"
    },
    {
      "id": "1487",
      "postcode": "17720",
      "village": "Lenggah Sari",
      "regency": "Cabangbungin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Lenggah Sari - 17720"
    },
    {
      "id": "1488",
      "postcode": "17720",
      "village": "Setia Jaya",
      "regency": "Cabangbungin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Setia Jaya - 17720"
    },
    {
      "id": "1489",
      "postcode": "17720",
      "village": "Setialaksana",
      "regency": "Cabangbungin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Setialaksana - 17720"
    },
    {
      "id": "1490",
      "postcode": "17720",
      "village": "Sindang Jaya",
      "regency": "Cabangbungin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sindang Jaya - 17720"
    },
    {
      "id": "1491",
      "postcode": "17720",
      "village": "Sindangsari",
      "regency": "Cabangbungin",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Sindangsari - 17720"
    },
    {
      "id": "1492",
      "postcode": "17730",
      "village": "Jayasakti",
      "regency": "Muara Gembong",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Jayasakti - 17730"
    },
    {
      "id": "1493",
      "postcode": "17730",
      "village": "Pantai Bahagia",
      "regency": "Muara Gembong",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pantai Bahagia - 17730"
    },
    {
      "id": "1494",
      "postcode": "17730",
      "village": "Pantai Bakti",
      "regency": "Muara Gembong",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pantai Bakti - 17730"
    },
    {
      "id": "1495",
      "postcode": "17730",
      "village": "Pantai Harapan Jaya",
      "regency": "Muara Gembong",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pantai Harapan Jaya - 17730"
    },
    {
      "id": "1496",
      "postcode": "17730",
      "village": "Pantai Mekar",
      "regency": "Muara Gembong",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pantai Mekar - 17730"
    },
    {
      "id": "1497",
      "postcode": "17730",
      "village": "Pantai Sederhana",
      "regency": "Muara Gembong",
      "state": "Kab.",
      "district": "Bekasi",
      "province": "Jawa Barat",
      "string": "Pantai Sederhana - 17730"
    },
    {
      "id": 1497,
      "postcode": 80351,
      "village": "Abianbase",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1498,
      "postcode": 80351,
      "village": "Baha",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1499,
      "postcode": 80351,
      "village": "Buduk",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1500,
      "postcode": 80351,
      "village": "Cemagi",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1501,
      "postcode": 80351,
      "village": "Gulingan",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1502,
      "postcode": 80351,
      "village": "Kapal",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1503,
      "postcode": 80351,
      "village": "Kekeran",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1504,
      "postcode": 80351,
      "village": "Kuwum",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1505,
      "postcode": 80351,
      "village": "Lukluk",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1506,
      "postcode": 80351,
      "village": "Mengwi",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1507,
      "postcode": 80351,
      "village": "Mengwitani",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1508,
      "postcode": 80351,
      "village": "Munggu",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1509,
      "postcode": 80351,
      "village": "Penarungan",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1510,
      "postcode": 80351,
      "village": "Pererenan",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1511,
      "postcode": 80351,
      "village": "Sading",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1512,
      "postcode": 80351,
      "village": "Sembung",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1513,
      "postcode": 80351,
      "village": "Sempidi",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1514,
      "postcode": 80351,
      "village": "Sobangan",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1515,
      "postcode": 80351,
      "village": "Tumbak Bayuh",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1516,
      "postcode": 80351,
      "village": "Werdi Bhuwana",
      "regency": "Mengwi",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1517,
      "postcode": 80352,
      "village": "Abiansemal",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1518,
      "postcode": 80352,
      "village": "Angantaka",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1519,
      "postcode": 80352,
      "village": "Ayunan",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1520,
      "postcode": 80352,
      "village": "Blahkiuh",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1521,
      "postcode": 80352,
      "village": "Bongkasa",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1522,
      "postcode": 80352,
      "village": "Bongkasa Pertiwi",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1523,
      "postcode": 80352,
      "village": "Darmasaba",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1524,
      "postcode": 80352,
      "village": "Dauh Yeh Cani",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1525,
      "postcode": 80352,
      "village": "Jagapati",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1526,
      "postcode": 80352,
      "village": "Mambal",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1527,
      "postcode": 80352,
      "village": "Mekar Bhuwana",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1528,
      "postcode": 80352,
      "village": "Punggul",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1529,
      "postcode": 80352,
      "village": "Sangeh",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1530,
      "postcode": 80352,
      "village": "Sedang",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1531,
      "postcode": 80352,
      "village": "Selat",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1532,
      "postcode": 80352,
      "village": "Sibang Gede",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1533,
      "postcode": 80352,
      "village": "Sibang Kaja",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1534,
      "postcode": 80352,
      "village": "Taman",
      "regency": "Abiansemal",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1535,
      "postcode": 80353,
      "village": "Belok",
      "regency": "Petang",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1536,
      "postcode": 80353,
      "village": "Carangsari",
      "regency": "Petang",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1537,
      "postcode": 80353,
      "village": "Getasan",
      "regency": "Petang",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1538,
      "postcode": 80353,
      "village": "Pangsan",
      "regency": "Petang",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1539,
      "postcode": 80353,
      "village": "Pelaga",
      "regency": "Petang",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1540,
      "postcode": 80353,
      "village": "Petang",
      "regency": "Petang",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1541,
      "postcode": 80353,
      "village": "Sulangai",
      "regency": "Petang",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1542,
      "postcode": 80361,
      "village": "Benoa",
      "regency": "Kuta Selatan",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1543,
      "postcode": 80361,
      "village": "Canggu",
      "regency": "Kuta Utara",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1544,
      "postcode": 80361,
      "village": "Dalung",
      "regency": "Kuta Utara",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1545,
      "postcode": 80361,
      "village": "Jimbaran",
      "regency": "Kuta Selatan",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1546,
      "postcode": 80361,
      "village": "Kedonganan",
      "regency": "Kuta",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1547,
      "postcode": 80361,
      "village": "Kerobokan",
      "regency": "Kuta Utara",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1548,
      "postcode": 80361,
      "village": "Kerobokan Kaja",
      "regency": "Kuta Utara",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1549,
      "postcode": 80361,
      "village": "Kerobokan Kelod",
      "regency": "Kuta Utara",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1550,
      "postcode": 80361,
      "village": "Kuta",
      "regency": "Kuta",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1551,
      "postcode": 80361,
      "village": "Kutuh",
      "regency": "Kuta Selatan",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1552,
      "postcode": 80361,
      "village": "Legian",
      "regency": "Kuta",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1553,
      "postcode": 80361,
      "village": "Pecatu",
      "regency": "Kuta Selatan",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1554,
      "postcode": 80361,
      "village": "Seminyak",
      "regency": "Kuta",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1555,
      "postcode": 80361,
      "village": "Tanjung Benoa",
      "regency": "Kuta Selatan",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1556,
      "postcode": 80361,
      "village": "Tibubeneng",
      "regency": "Kuta Utara",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1557,
      "postcode": 80361,
      "village": "Tuban",
      "regency": "Kuta",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1558,
      "postcode": 80361,
      "village": "Ungasan",
      "regency": "Kuta Selatan",
      "state": "Kab.",
      "district": "Badung",
      "province": "Bali"
    },
    {
      "id": 1559,
      "postcode": 80611,
      "village": "Kubu",
      "regency": "Bangli",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1560,
      "postcode": 80611,
      "village": "Landih",
      "regency": "Bangli",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1561,
      "postcode": 80612,
      "village": "Cempaga",
      "regency": "Bangli",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1562,
      "postcode": 80613,
      "village": "Kawan",
      "regency": "Bangli",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1563,
      "postcode": 80614,
      "village": "Bebalang",
      "regency": "Bangli",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1564,
      "postcode": 80614,
      "village": "Bunutin",
      "regency": "Bangli",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1565,
      "postcode": 80614,
      "village": "Kayubihi",
      "regency": "Bangli",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1566,
      "postcode": 80614,
      "village": "Pengotan",
      "regency": "Bangli",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1567,
      "postcode": 80614,
      "village": "Taman Bali",
      "regency": "Bangli",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1568,
      "postcode": 80652,
      "village": "Abang Songan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1569,
      "postcode": 80652,
      "village": "Abuan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1570,
      "postcode": 80652,
      "village": "Awan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1571,
      "postcode": 80652,
      "village": "Bantang",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1572,
      "postcode": 80652,
      "village": "Banua",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1573,
      "postcode": 80652,
      "village": "Batu Dinding",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1574,
      "postcode": 80652,
      "village": "Batukaang",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1575,
      "postcode": 80652,
      "village": "Batur Selatan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1576,
      "postcode": 80652,
      "village": "Batur Tengah",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1577,
      "postcode": 80652,
      "village": "Batur Utara",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1578,
      "postcode": 80652,
      "village": "Bayungcerik",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1579,
      "postcode": 80652,
      "village": "Bayunggede",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1580,
      "postcode": 80652,
      "village": "Belancan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1581,
      "postcode": 80652,
      "village": "Belandingan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1582,
      "postcode": 80652,
      "village": "Belanga",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1583,
      "postcode": 80652,
      "village": "Belantih",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1584,
      "postcode": 80652,
      "village": "Binyan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1585,
      "postcode": 80652,
      "village": "Bonyoh",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1586,
      "postcode": 80652,
      "village": "Buahan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1587,
      "postcode": 80652,
      "village": "Bunutin",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1588,
      "postcode": 80652,
      "village": "Catur",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1589,
      "postcode": 80652,
      "village": "Daup",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1590,
      "postcode": 80652,
      "village": "Dausa",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1591,
      "postcode": 80652,
      "village": "Gunungbau",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1592,
      "postcode": 80652,
      "village": "Katung",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1593,
      "postcode": 80652,
      "village": "Kedisan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1594,
      "postcode": 80652,
      "village": "Kintamani",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1595,
      "postcode": 80652,
      "village": "Kutuh",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1596,
      "postcode": 80652,
      "village": "Langgahan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1597,
      "postcode": 80652,
      "village": "Lembean",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1598,
      "postcode": 80652,
      "village": "Mangguh",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1599,
      "postcode": 80652,
      "village": "Manikliyu",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1600,
      "postcode": 80652,
      "village": "Mengani",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1601,
      "postcode": 80652,
      "village": "Pengejaran",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1602,
      "postcode": 80652,
      "village": "Pinggan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1603,
      "postcode": 80652,
      "village": "Satra",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1604,
      "postcode": 80652,
      "village": "Sekaan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1605,
      "postcode": 80652,
      "village": "Sekardadi",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1606,
      "postcode": 80652,
      "village": "Selulung",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1607,
      "postcode": 80652,
      "village": "Serahi",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1608,
      "postcode": 80652,
      "village": "Siyakin",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1609,
      "postcode": 80652,
      "village": "Songan A",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1610,
      "postcode": 80652,
      "village": "Songan B",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1611,
      "postcode": 80652,
      "village": "Subaya",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1612,
      "postcode": 80652,
      "village": "Sukawana",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1613,
      "postcode": 80652,
      "village": "Suter",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1614,
      "postcode": 80652,
      "village": "Terunyan",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1615,
      "postcode": 80652,
      "village": "Ulian",
      "regency": "Kintamani",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1616,
      "postcode": 80661,
      "village": "Abuan",
      "regency": "Susut",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1617,
      "postcode": 80661,
      "village": "Apuan",
      "regency": "Susut",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1618,
      "postcode": 80661,
      "village": "Demulih",
      "regency": "Susut",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1619,
      "postcode": 80661,
      "village": "Pengiangan",
      "regency": "Susut",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1620,
      "postcode": 80661,
      "village": "Penglumbaran",
      "regency": "Susut",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1621,
      "postcode": 80661,
      "village": "Selat",
      "regency": "Susut",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1622,
      "postcode": 80661,
      "village": "Sulahan",
      "regency": "Susut",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1623,
      "postcode": 80661,
      "village": "Susut",
      "regency": "Susut",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1624,
      "postcode": 80661,
      "village": "Tiga",
      "regency": "Susut",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1625,
      "postcode": 80671,
      "village": "Bangbang",
      "regency": "Tembuku",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1626,
      "postcode": 80671,
      "village": "Jehem",
      "regency": "Tembuku",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1627,
      "postcode": 80671,
      "village": "Peninjoan",
      "regency": "Tembuku",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1628,
      "postcode": 80671,
      "village": "Tembuku",
      "regency": "Tembuku",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1629,
      "postcode": 80671,
      "village": "Undisan",
      "regency": "Tembuku",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1630,
      "postcode": 80671,
      "village": "Yangapi",
      "regency": "Tembuku",
      "state": "Kab.",
      "district": "Bangli",
      "province": "Bali"
    },
    {
      "id": 1631,
      "postcode": 81111,
      "village": "Kampung Singaraja",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1632,
      "postcode": 81112,
      "village": "Astina",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1633,
      "postcode": 81112,
      "village": "Kendran",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1634,
      "postcode": 81113,
      "village": "Banjar Bali",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1635,
      "postcode": 81113,
      "village": "Banjar Jawa",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1636,
      "postcode": 81114,
      "village": "Kampung Baru",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1637,
      "postcode": 81114,
      "village": "Kampung Kajanan",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1638,
      "postcode": 81115,
      "village": "Kampung Anyar",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1639,
      "postcode": 81115,
      "village": "Kampung Bugis",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1640,
      "postcode": 81116,
      "village": "Banyuasri",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1641,
      "postcode": 81116,
      "village": "Kaliuntu",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1642,
      "postcode": 81117,
      "village": "Banjar Tegal",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1643,
      "postcode": 81118,
      "village": "Paket Agung",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1644,
      "postcode": 81119,
      "village": "Alasangker",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1645,
      "postcode": 81119,
      "village": "Anturan",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1646,
      "postcode": 81119,
      "village": "Banyuning",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1647,
      "postcode": 81119,
      "village": "Beratan",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1648,
      "postcode": 81119,
      "village": "Bhakti Seraga (Baktiseraga)",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1649,
      "postcode": 81119,
      "village": "Jinengdalem",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1650,
      "postcode": 81119,
      "village": "Kalibukbuk",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1651,
      "postcode": 81119,
      "village": "Liligundi",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1652,
      "postcode": 81119,
      "village": "Nagasepaha",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1653,
      "postcode": 81119,
      "village": "Pemaron",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1654,
      "postcode": 81119,
      "village": "Penarukan",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1655,
      "postcode": 81119,
      "village": "Penglatan",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1656,
      "postcode": 81119,
      "village": "Petandakan",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1657,
      "postcode": 81119,
      "village": "Poh Bergong",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1658,
      "postcode": 81119,
      "village": "Sari Mekar",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1659,
      "postcode": 81119,
      "village": "Tukadmungga",
      "regency": "Buleleng",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1660,
      "postcode": 81152,
      "village": "Banjar",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1661,
      "postcode": 81152,
      "village": "Banjar Tegeha",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1662,
      "postcode": 81152,
      "village": "Banyuatis",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1663,
      "postcode": 81152,
      "village": "Banyusri",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1664,
      "postcode": 81152,
      "village": "Cempaga",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1665,
      "postcode": 81152,
      "village": "Dencarik",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1666,
      "postcode": 81152,
      "village": "Gesing",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1667,
      "postcode": 81152,
      "village": "Gobleg",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1668,
      "postcode": 81152,
      "village": "Kaliasem",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1669,
      "postcode": 81152,
      "village": "Kayuputih",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1670,
      "postcode": 81152,
      "village": "Munduk",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1671,
      "postcode": 81152,
      "village": "Pedawa",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1672,
      "postcode": 81152,
      "village": "Sidetapa",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1673,
      "postcode": 81152,
      "village": "Tampekan",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1674,
      "postcode": 81152,
      "village": "Temukus",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1675,
      "postcode": 81152,
      "village": "Tigawasa",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1676,
      "postcode": 81152,
      "village": "Tirtasari",
      "regency": "Banjar",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1677,
      "postcode": 81153,
      "village": "Banjar Asem",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1678,
      "postcode": 81153,
      "village": "Bestala",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1679,
      "postcode": 81153,
      "village": "Bubunan",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1680,
      "postcode": 81153,
      "village": "Gunungsari",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1681,
      "postcode": 81153,
      "village": "Joanyar",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1682,
      "postcode": 81153,
      "village": "Kalianget",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1683,
      "postcode": 81153,
      "village": "Kalisada",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1684,
      "postcode": 81153,
      "village": "Lokapaksa",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1685,
      "postcode": 81153,
      "village": "Mayong",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1686,
      "postcode": 81153,
      "village": "Munduk Bestala",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1687,
      "postcode": 81153,
      "village": "Pangkungparuk",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1688,
      "postcode": 81153,
      "village": "Patemoh (Patemon)",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1689,
      "postcode": 81153,
      "village": "Pengastulan",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1690,
      "postcode": 81153,
      "village": "Rangdu",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1691,
      "postcode": 81153,
      "village": "Ringdikit",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1692,
      "postcode": 81153,
      "village": "Seririt",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1693,
      "postcode": 81153,
      "village": "Sulanyah",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1694,
      "postcode": 81153,
      "village": "Tangguwisia",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1695,
      "postcode": 81153,
      "village": "Ularan",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1696,
      "postcode": 81153,
      "village": "Umeanyar",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1697,
      "postcode": 81153,
      "village": "Unggahan",
      "regency": "Seririt",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1698,
      "postcode": 81154,
      "village": "Bengkel",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1699,
      "postcode": 81154,
      "village": "Bongancina",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1700,
      "postcode": 81154,
      "village": "Busungbiu",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1701,
      "postcode": 81154,
      "village": "Kedia (Kedis)",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1702,
      "postcode": 81154,
      "village": "Kekeran",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1703,
      "postcode": 81154,
      "village": "Pelapuan",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1704,
      "postcode": 81154,
      "village": "Pucaksari",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1705,
      "postcode": 81154,
      "village": "Sepang",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1706,
      "postcode": 81154,
      "village": "Sepang Kelod",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1707,
      "postcode": 81154,
      "village": "Subuk",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1708,
      "postcode": 81154,
      "village": "Telaga",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1709,
      "postcode": 81154,
      "village": "Tinggarsari",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1710,
      "postcode": 81154,
      "village": "Tista",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1711,
      "postcode": 81154,
      "village": "Titab",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1712,
      "postcode": 81154,
      "village": "Umejero",
      "regency": "Busungbiu",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1713,
      "postcode": 81155,
      "village": "Banyupoh",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1714,
      "postcode": 81155,
      "village": "Celukan Bawang",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1715,
      "postcode": 81155,
      "village": "Gerokgak",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1716,
      "postcode": 81155,
      "village": "Musi",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1717,
      "postcode": 81155,
      "village": "Patas",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1718,
      "postcode": 81155,
      "village": "Pejarakan",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1719,
      "postcode": 81155,
      "village": "Pemuteran",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1720,
      "postcode": 81155,
      "village": "Pengulon",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1721,
      "postcode": 81155,
      "village": "Penyabangan",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1722,
      "postcode": 81155,
      "village": "Sanggalangit",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1723,
      "postcode": 81155,
      "village": "Sumber Klampok",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1724,
      "postcode": 81155,
      "village": "Sumberkima",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1725,
      "postcode": 81155,
      "village": "Tinga Tinga",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1726,
      "postcode": 81155,
      "village": "Tukad Sumaga",
      "regency": "Gerokgak",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1727,
      "postcode": 81161,
      "village": "Ambengan",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1728,
      "postcode": 81161,
      "village": "Gitgit",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1729,
      "postcode": 81161,
      "village": "Kayuputih",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1730,
      "postcode": 81161,
      "village": "Padangbulia",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1731,
      "postcode": 81161,
      "village": "Pancasari",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1732,
      "postcode": 81161,
      "village": "Panji",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1733,
      "postcode": 81161,
      "village": "Panji Anom",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1734,
      "postcode": 81161,
      "village": "Pegadungan",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1735,
      "postcode": 81161,
      "village": "Pegayaman",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1736,
      "postcode": 81161,
      "village": "Sambangan",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1737,
      "postcode": 81161,
      "village": "Selat",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1738,
      "postcode": 81161,
      "village": "Silangjana",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1739,
      "postcode": 81161,
      "village": "Sukasada",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1740,
      "postcode": 81161,
      "village": "Tegal Linggah (Tegalinggah)",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1741,
      "postcode": 81161,
      "village": "Wanagiri",
      "regency": "Sukasada",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1742,
      "postcode": 81171,
      "village": "Bebetin",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1743,
      "postcode": 81171,
      "village": "Bungkulan",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1744,
      "postcode": 81171,
      "village": "Galungan",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1745,
      "postcode": 81171,
      "village": "Giri Emas",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1746,
      "postcode": 81171,
      "village": "Jagaraga",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1747,
      "postcode": 81171,
      "village": "Kerobokan",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1748,
      "postcode": 81171,
      "village": "Lemukih",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1749,
      "postcode": 81171,
      "village": "Menyali",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1750,
      "postcode": 81171,
      "village": "Sangsit",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1751,
      "postcode": 81171,
      "village": "Sawan",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1752,
      "postcode": 81171,
      "village": "Sekumpul",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1753,
      "postcode": 81171,
      "village": "Sinabun",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1754,
      "postcode": 81171,
      "village": "Sudaji",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1755,
      "postcode": 81171,
      "village": "Suwug",
      "regency": "Sawan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1756,
      "postcode": 81172,
      "village": "Bengkala",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1757,
      "postcode": 81172,
      "village": "Bila",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1758,
      "postcode": 81172,
      "village": "Bontihing",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1759,
      "postcode": 81172,
      "village": "Bukti",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1760,
      "postcode": 81172,
      "village": "Bulian",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1761,
      "postcode": 81172,
      "village": "Depeha",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1762,
      "postcode": 81172,
      "village": "Kubutambahan",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1763,
      "postcode": 81172,
      "village": "Mengening",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1764,
      "postcode": 81172,
      "village": "Pakisan",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1765,
      "postcode": 81172,
      "village": "Tajun",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1766,
      "postcode": 81172,
      "village": "Tambakan",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1767,
      "postcode": 81172,
      "village": "Tamblang",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1768,
      "postcode": 81172,
      "village": "Tunjung",
      "regency": "Kubutambahan",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1769,
      "postcode": 81173,
      "village": "Bondalem",
      "regency": "Tejakula",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1770,
      "postcode": 81173,
      "village": "Julah",
      "regency": "Tejakula",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1771,
      "postcode": 81173,
      "village": "Les",
      "regency": "Tejakula",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1772,
      "postcode": 81173,
      "village": "Madenan",
      "regency": "Tejakula",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1773,
      "postcode": 81173,
      "village": "Pacung",
      "regency": "Tejakula",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1774,
      "postcode": 81173,
      "village": "Penuktukan",
      "regency": "Tejakula",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1775,
      "postcode": 81173,
      "village": "Sambirenteng",
      "regency": "Tejakula",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1776,
      "postcode": 81173,
      "village": "Sembiran",
      "regency": "Tejakula",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1777,
      "postcode": 81173,
      "village": "Tejakula",
      "regency": "Tejakula",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1778,
      "postcode": 81173,
      "village": "Tembok",
      "regency": "Tejakula",
      "state": "Kab.",
      "district": "Buleleng",
      "province": "Bali"
    },
    {
      "id": 1779,
      "postcode": 80111,
      "village": "Dauh Puri Kaja",
      "regency": "Denpasar Utara",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1780,
      "postcode": 80112,
      "village": "Dauh Puri Kangin",
      "regency": "Denpasar Barat",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1781,
      "postcode": 80113,
      "village": "Dauh Puri",
      "regency": "Denpasar Barat",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1782,
      "postcode": 80113,
      "village": "Dauh Puri Kauh",
      "regency": "Denpasar Barat",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1783,
      "postcode": 80114,
      "village": "Dauh Puri Klod/Kelod",
      "regency": "Denpasar Barat",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1784,
      "postcode": 80115,
      "village": "Peguyangan",
      "regency": "Denpasar Utara",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1785,
      "postcode": 80115,
      "village": "Peguyangan Kaja",
      "regency": "Denpasar Utara",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1786,
      "postcode": 80115,
      "village": "Peguyangan Kangin",
      "regency": "Denpasar Utara",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1787,
      "postcode": 80116,
      "village": "Ubung",
      "regency": "Denpasar Utara",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1788,
      "postcode": 80116,
      "village": "Ubung Kaja",
      "regency": "Denpasar Utara",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1789,
      "postcode": 80117,
      "village": "Padangsambian",
      "regency": "Denpasar Barat",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1790,
      "postcode": 80117,
      "village": "Padangsambian Kaja",
      "regency": "Denpasar Barat",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1791,
      "postcode": 80117,
      "village": "Padangsambian Klod/Kelod",
      "regency": "Denpasar Barat",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1792,
      "postcode": 80118,
      "village": "Pemecutan Kaja",
      "regency": "Denpasar Utara",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1793,
      "postcode": 80119,
      "village": "Pemecutan",
      "regency": "Denpasar Barat",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1794,
      "postcode": 80119,
      "village": "Pemecutan Klod/Kelod",
      "regency": "Denpasar Barat",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1795,
      "postcode": 80119,
      "village": "Tegal Harum",
      "regency": "Denpasar Barat",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1796,
      "postcode": 80119,
      "village": "Tegal Kertha",
      "regency": "Denpasar Barat",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1797,
      "postcode": 80221,
      "village": "Pemogan",
      "regency": "Denpasar Selatan",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1798,
      "postcode": 80222,
      "village": "Pedungan",
      "regency": "Denpasar Selatan",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1799,
      "postcode": 80223,
      "village": "Sesetan",
      "regency": "Denpasar Selatan",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1800,
      "postcode": 80224,
      "village": "Sidakarya",
      "regency": "Denpasar Selatan",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1801,
      "postcode": 80225,
      "village": "Panjer",
      "regency": "Denpasar Selatan",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1802,
      "postcode": 80226,
      "village": "Renon",
      "regency": "Denpasar Selatan",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1803,
      "postcode": 80227,
      "village": "Sanur Kaja",
      "regency": "Denpasar Selatan",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1804,
      "postcode": 80227,
      "village": "Sanur Kauh",
      "regency": "Denpasar Selatan",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1805,
      "postcode": 80228,
      "village": "Sanur",
      "regency": "Denpasar Selatan",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1806,
      "postcode": 80229,
      "village": "Serangan",
      "regency": "Denpasar Selatan",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1807,
      "postcode": 80231,
      "village": "Dangin Puri Kaja",
      "regency": "Denpasar Utara",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1808,
      "postcode": 80231,
      "village": "Dangin Puri Kauh",
      "regency": "Denpasar Utara",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1809,
      "postcode": 80232,
      "village": "Dangin Puri",
      "regency": "Denpasar Timur",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1810,
      "postcode": 80233,
      "village": "Dangin Puri Kangin",
      "regency": "Denpasar Utara",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1811,
      "postcode": 80234,
      "village": "Dangin Puri Klod",
      "regency": "Denpasar Timur",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1812,
      "postcode": 80235,
      "village": "Sumerta",
      "regency": "Denpasar Timur",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1813,
      "postcode": 80236,
      "village": "Sumerta Kaja",
      "regency": "Denpasar Timur",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1814,
      "postcode": 80236,
      "village": "Sumerta Kauh",
      "regency": "Denpasar Timur",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1815,
      "postcode": 80237,
      "village": "Kesiman",
      "regency": "Denpasar Timur",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1816,
      "postcode": 80237,
      "village": "Kesiman Kertalangu",
      "regency": "Denpasar Timur",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1817,
      "postcode": 80237,
      "village": "Kesiman Petilan",
      "regency": "Denpasar Timur",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1818,
      "postcode": 80238,
      "village": "Penatih",
      "regency": "Denpasar Timur",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1819,
      "postcode": 80238,
      "village": "Penatih Dangin Puri",
      "regency": "Denpasar Timur",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1820,
      "postcode": 80239,
      "village": "Sumerta Kelod/Klod",
      "regency": "Denpasar Timur",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1821,
      "postcode": 80239,
      "village": "Tonja",
      "regency": "Denpasar Utara",
      "state": "Kota",
      "district": "Denpasar",
      "province": "Bali"
    },
    {
      "id": 1822,
      "postcode": 80511,
      "village": "Gianyar",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1823,
      "postcode": 80512,
      "village": "Samplangan",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1824,
      "postcode": 80513,
      "village": "Beng",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1825,
      "postcode": 80515,
      "village": "Abianbase",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1826,
      "postcode": 80515,
      "village": "Bakbakan",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1827,
      "postcode": 80515,
      "village": "Bitera",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1828,
      "postcode": 80515,
      "village": "Lebih",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1829,
      "postcode": 80515,
      "village": "Petak",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1830,
      "postcode": 80515,
      "village": "Petak Kaja",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1831,
      "postcode": 80515,
      "village": "Serongga",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1832,
      "postcode": 80515,
      "village": "Siangan",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1833,
      "postcode": 80515,
      "village": "Sidan",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1834,
      "postcode": 80515,
      "village": "Sumita",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1835,
      "postcode": 80515,
      "village": "Suwat",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1836,
      "postcode": 80515,
      "village": "Tegal Tugu",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1837,
      "postcode": 80515,
      "village": "Temesi",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1838,
      "postcode": 80515,
      "village": "Tulikup",
      "regency": "Gianyar",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1839,
      "postcode": 80552,
      "village": "Manukaya",
      "regency": "Tampak Siring",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1840,
      "postcode": 80552,
      "village": "Pejeng",
      "regency": "Tampak Siring",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1841,
      "postcode": 80552,
      "village": "Pejeng Kaja",
      "regency": "Tampak Siring",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1842,
      "postcode": 80552,
      "village": "Pejeng Kangin",
      "regency": "Tampak Siring",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1843,
      "postcode": 80552,
      "village": "Pejeng Kawan",
      "regency": "Tampak Siring",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1844,
      "postcode": 80552,
      "village": "Pejeng Klod (Kelod)",
      "regency": "Tampak Siring",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1845,
      "postcode": 80552,
      "village": "Sanding",
      "regency": "Tampak Siring",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1846,
      "postcode": 80552,
      "village": "Tampaksiring",
      "regency": "Tampak Siring",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1847,
      "postcode": 80561,
      "village": "Kedisan",
      "regency": "Tegallalang",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1848,
      "postcode": 80561,
      "village": "Keliki",
      "regency": "Tegallalang",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1849,
      "postcode": 80561,
      "village": "Kenderan",
      "regency": "Tegallalang",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1850,
      "postcode": 80561,
      "village": "Pupuan",
      "regency": "Tegallalang",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1851,
      "postcode": 80561,
      "village": "Sebatu",
      "regency": "Tegallalang",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1852,
      "postcode": 80561,
      "village": "Taro",
      "regency": "Tegallalang",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1853,
      "postcode": 80561,
      "village": "Tegallalang",
      "regency": "Tegallalang",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1854,
      "postcode": 80571,
      "village": "Kedewatan",
      "regency": "Ubud",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1855,
      "postcode": 80571,
      "village": "Lodtunduh",
      "regency": "Ubud",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1856,
      "postcode": 80571,
      "village": "Mas",
      "regency": "Ubud",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1857,
      "postcode": 80571,
      "village": "Peliatan",
      "regency": "Ubud",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1858,
      "postcode": 80571,
      "village": "Petulu",
      "regency": "Ubud",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1859,
      "postcode": 80571,
      "village": "Sayan",
      "regency": "Ubud",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1860,
      "postcode": 80571,
      "village": "Singakerta (Singekerta)",
      "regency": "Ubud",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1861,
      "postcode": 80571,
      "village": "Ubud",
      "regency": "Ubud",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1862,
      "postcode": 80572,
      "village": "Bresela (Beresela)",
      "regency": "Payangan",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1863,
      "postcode": 80572,
      "village": "Buahan",
      "regency": "Payangan",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1864,
      "postcode": 80572,
      "village": "Buahan Kaja",
      "regency": "Payangan",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1865,
      "postcode": 80572,
      "village": "Bukian",
      "regency": "Payangan",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1866,
      "postcode": 80572,
      "village": "Kelusa",
      "regency": "Payangan",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1867,
      "postcode": 80572,
      "village": "Kerta",
      "regency": "Payangan",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1868,
      "postcode": 80572,
      "village": "Melinggih",
      "regency": "Payangan",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1869,
      "postcode": 80572,
      "village": "Melinggih Kelod",
      "regency": "Payangan",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1870,
      "postcode": 80572,
      "village": "Puhu",
      "regency": "Payangan",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1871,
      "postcode": 80581,
      "village": "Bedulu",
      "regency": "Belah Batuh (Blahbatuh)",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1872,
      "postcode": 80581,
      "village": "Belega",
      "regency": "Belah Batuh (Blahbatuh)",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1873,
      "postcode": 80581,
      "village": "Blahbatuh",
      "regency": "Belah Batuh (Blahbatuh)",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1874,
      "postcode": 80581,
      "village": "Bona",
      "regency": "Belah Batuh (Blahbatuh)",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1875,
      "postcode": 80581,
      "village": "Buruan",
      "regency": "Belah Batuh (Blahbatuh)",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1876,
      "postcode": 80581,
      "village": "Keramas",
      "regency": "Belah Batuh (Blahbatuh)",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1877,
      "postcode": 80581,
      "village": "Medahan",
      "regency": "Belah Batuh (Blahbatuh)",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1878,
      "postcode": 80581,
      "village": "Pering",
      "regency": "Belah Batuh (Blahbatuh)",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1879,
      "postcode": 80581,
      "village": "Saba",
      "regency": "Belah Batuh (Blahbatuh)",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1880,
      "postcode": 80582,
      "village": "Batuan",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1881,
      "postcode": 80582,
      "village": "Batuan Kaler",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1882,
      "postcode": 80582,
      "village": "Batubulan",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1883,
      "postcode": 80582,
      "village": "Batubulan Kangin",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1884,
      "postcode": 80582,
      "village": "Celuk",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1885,
      "postcode": 80582,
      "village": "Guwang",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1886,
      "postcode": 80582,
      "village": "Kemenuh",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1887,
      "postcode": 80582,
      "village": "Ketewel",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1888,
      "postcode": 80582,
      "village": "Singapadu",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1889,
      "postcode": 80582,
      "village": "Singapadu Kaler",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1890,
      "postcode": 80582,
      "village": "Singapadu Tengah",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1891,
      "postcode": 80582,
      "village": "Sukawati",
      "regency": "Sukawati",
      "state": "Kab.",
      "district": "Gianyar",
      "province": "Bali"
    },
    {
      "id": 1892,
      "postcode": 82211,
      "village": "Pendem",
      "regency": "Jembrana",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1893,
      "postcode": 82212,
      "village": "Baler Bale Agung",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1894,
      "postcode": 82213,
      "village": "Banjar Tengah",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1895,
      "postcode": 82214,
      "village": "Lelateng",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1896,
      "postcode": 82215,
      "village": "Loloan Barat",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1897,
      "postcode": 82216,
      "village": "Loloan Timur",
      "regency": "Jembrana",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1898,
      "postcode": 82218,
      "village": "Air Kuning",
      "regency": "Jembrana",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1899,
      "postcode": 82218,
      "village": "Baluk",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1900,
      "postcode": 82218,
      "village": "Banyubiru",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1901,
      "postcode": 82218,
      "village": "Batuagung",
      "regency": "Jembrana",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1902,
      "postcode": 82218,
      "village": "Berangbang",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1903,
      "postcode": 82218,
      "village": "Budeng",
      "regency": "Jembrana",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1904,
      "postcode": 82218,
      "village": "Cupel",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1905,
      "postcode": 82218,
      "village": "Dangin Tukadaya",
      "regency": "Jembrana",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1906,
      "postcode": 82218,
      "village": "Dauhwaru",
      "regency": "Jembrana",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1907,
      "postcode": 82218,
      "village": "Kaliakah",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1908,
      "postcode": 82218,
      "village": "Pengambengan",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1909,
      "postcode": 82218,
      "village": "Perancak",
      "regency": "Jembrana",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1910,
      "postcode": 82218,
      "village": "Sangkaragung",
      "regency": "Jembrana",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1911,
      "postcode": 82218,
      "village": "Tegal Badeng Barat",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1912,
      "postcode": 82218,
      "village": "Tegal Badeng Timur",
      "regency": "Negara",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1913,
      "postcode": 82218,
      "village": "Yeh Kuning",
      "regency": "Jembrana",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1914,
      "postcode": 82252,
      "village": "Blimbingsari (Belimbingsari)",
      "regency": "Melaya",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1915,
      "postcode": 82252,
      "village": "Candikusuma",
      "regency": "Melaya",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1916,
      "postcode": 82252,
      "village": "Ekasari",
      "regency": "Melaya",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1917,
      "postcode": 82252,
      "village": "Gilimanuk",
      "regency": "Melaya",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1918,
      "postcode": 82252,
      "village": "Manistutu",
      "regency": "Melaya",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1919,
      "postcode": 82252,
      "village": "Melaya",
      "regency": "Melaya",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1920,
      "postcode": 82252,
      "village": "Nusa Sari",
      "regency": "Melaya",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1921,
      "postcode": 82252,
      "village": "Tukadaya",
      "regency": "Melaya",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1922,
      "postcode": 82252,
      "village": "Tuwed",
      "regency": "Melaya",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1923,
      "postcode": 82252,
      "village": "Warnasari",
      "regency": "Melaya",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1924,
      "postcode": 82261,
      "village": "Delod Berawah",
      "regency": "Mendoyo",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1925,
      "postcode": 82261,
      "village": "Mendoyo Dangin Tukad",
      "regency": "Mendoyo",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1926,
      "postcode": 82261,
      "village": "Mendoyo Dauh Tukad",
      "regency": "Mendoyo",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1927,
      "postcode": 82261,
      "village": "Penyaringan",
      "regency": "Mendoyo",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1928,
      "postcode": 82261,
      "village": "Pergung",
      "regency": "Mendoyo",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1929,
      "postcode": 82261,
      "village": "Pohsanten",
      "regency": "Mendoyo",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1930,
      "postcode": 82261,
      "village": "Tegal Cangkring",
      "regency": "Mendoyo",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1931,
      "postcode": 82261,
      "village": "Yeh Embang",
      "regency": "Mendoyo",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1932,
      "postcode": 82261,
      "village": "Yeh Embang Kangin",
      "regency": "Mendoyo",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1933,
      "postcode": 82261,
      "village": "Yeh Embang Kauh",
      "regency": "Mendoyo",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1934,
      "postcode": 82261,
      "village": "Yeh Sumbul",
      "regency": "Mendoyo",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1935,
      "postcode": 82262,
      "village": "Asahduren",
      "regency": "Pekutatan",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1936,
      "postcode": 82262,
      "village": "Gumbrih",
      "regency": "Pekutatan",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1937,
      "postcode": 82262,
      "village": "Manggissari",
      "regency": "Pekutatan",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1938,
      "postcode": 82262,
      "village": "Medewi",
      "regency": "Pekutatan",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1939,
      "postcode": 82262,
      "village": "Pangyangan",
      "regency": "Pekutatan",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1940,
      "postcode": 82262,
      "village": "Pekutatan",
      "regency": "Pekutatan",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1941,
      "postcode": 82262,
      "village": "Pengeragoan (Pengragoan)",
      "regency": "Pekutatan",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1942,
      "postcode": 82262,
      "village": "Pulukan",
      "regency": "Pekutatan",
      "state": "Kab.",
      "district": "Jembrana",
      "province": "Bali"
    },
    {
      "id": 1943,
      "postcode": 80811,
      "village": "Bugbug",
      "regency": "Karang Asem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1944,
      "postcode": 80811,
      "village": "Bukit",
      "regency": "Karang Asem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1945,
      "postcode": 80811,
      "village": "Karangasem",
      "regency": "Karang Asem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1946,
      "postcode": 80811,
      "village": "Padang Kerta",
      "regency": "Karang Asem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1947,
      "postcode": 80811,
      "village": "Pertima",
      "regency": "Karang Asem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1948,
      "postcode": 80811,
      "village": "Seraya Barat",
      "regency": "Karang Asem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1949,
      "postcode": 80811,
      "village": "Seraya Tengah",
      "regency": "Karang Asem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1950,
      "postcode": 80811,
      "village": "Seraya Timur",
      "regency": "Karang Asem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1951,
      "postcode": 80811,
      "village": "Tegallinggah",
      "regency": "Karang Asem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1952,
      "postcode": 80811,
      "village": "Tumbu",
      "regency": "Karang Asem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1953,
      "postcode": 80813,
      "village": "Subagan",
      "regency": "Karang Asem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1954,
      "postcode": 80852,
      "village": "Ababi",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1955,
      "postcode": 80852,
      "village": "Abang",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1956,
      "postcode": 80852,
      "village": "Bunutan",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1957,
      "postcode": 80852,
      "village": "Culik",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1958,
      "postcode": 80852,
      "village": "Datah",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1959,
      "postcode": 80852,
      "village": "Kerta Mandala",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1960,
      "postcode": 80852,
      "village": "Kesimpar",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1961,
      "postcode": 80852,
      "village": "Laba Sari",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1962,
      "postcode": 80852,
      "village": "Nawakerti",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1963,
      "postcode": 80852,
      "village": "Pidpid",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1964,
      "postcode": 80852,
      "village": "Purwakerti",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1965,
      "postcode": 80852,
      "village": "Tista",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1966,
      "postcode": 80852,
      "village": "Tiyingtali",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1967,
      "postcode": 80852,
      "village": "Tri Buana",
      "regency": "Abang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1968,
      "postcode": 80853,
      "village": "Ban",
      "regency": "Kubu",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1969,
      "postcode": 80853,
      "village": "Batu Ringgit",
      "regency": "Kubu",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1970,
      "postcode": 80853,
      "village": "Dukuh",
      "regency": "Kubu",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1971,
      "postcode": 80853,
      "village": "Kubu",
      "regency": "Kubu",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1972,
      "postcode": 80853,
      "village": "Sukadana",
      "regency": "Kubu",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1973,
      "postcode": 80853,
      "village": "Tianyar Barat",
      "regency": "Kubu",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1974,
      "postcode": 80853,
      "village": "Tianyar Tengah",
      "regency": "Kubu",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1975,
      "postcode": 80853,
      "village": "Tianyar Timur",
      "regency": "Kubu",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1976,
      "postcode": 80853,
      "village": "Tulamben",
      "regency": "Kubu",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1977,
      "postcode": 80861,
      "village": "Bebandem",
      "regency": "Bebandem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1978,
      "postcode": 80861,
      "village": "Buana Giri",
      "regency": "Bebandem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1979,
      "postcode": 80861,
      "village": "Budakeling (Bude Keling)",
      "regency": "Bebandem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1980,
      "postcode": 80861,
      "village": "Bungaya (Bungaya Kauh)",
      "regency": "Bebandem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1981,
      "postcode": 80861,
      "village": "Bungaya Kangin",
      "regency": "Bebandem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1982,
      "postcode": 80861,
      "village": "Jungutan",
      "regency": "Bebandem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1983,
      "postcode": 80861,
      "village": "Macang",
      "regency": "Bebandem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1984,
      "postcode": 80861,
      "village": "Sibetan",
      "regency": "Bebandem",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1985,
      "postcode": 80862,
      "village": "Amerta Bhuana",
      "regency": "Selat",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1986,
      "postcode": 80862,
      "village": "Duda",
      "regency": "Selat",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1987,
      "postcode": 80862,
      "village": "Duda Timur",
      "regency": "Selat",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1988,
      "postcode": 80862,
      "village": "Duda Utara",
      "regency": "Selat",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1989,
      "postcode": 80862,
      "village": "Muncan",
      "regency": "Selat",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1990,
      "postcode": 80862,
      "village": "Pering Sari",
      "regency": "Selat",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1991,
      "postcode": 80862,
      "village": "Sebudi",
      "regency": "Selat",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1992,
      "postcode": 80862,
      "village": "Selat",
      "regency": "Selat",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1993,
      "postcode": 80863,
      "village": "Besakih",
      "regency": "Rendang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1994,
      "postcode": 80863,
      "village": "Menanga",
      "regency": "Rendang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1995,
      "postcode": 80863,
      "village": "Nongan",
      "regency": "Rendang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1996,
      "postcode": 80863,
      "village": "Pempatan",
      "regency": "Rendang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1997,
      "postcode": 80863,
      "village": "Pesaban",
      "regency": "Rendang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1998,
      "postcode": 80863,
      "village": "Rendang",
      "regency": "Rendang",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 1999,
      "postcode": 80864,
      "village": "Kertha Buana",
      "regency": "Sidemen",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2000,
      "postcode": 80864,
      "village": "Lokasari",
      "regency": "Sidemen",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2001,
      "postcode": 80864,
      "village": "Sangkan Gunung",
      "regency": "Sidemen",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2002,
      "postcode": 80864,
      "village": "Sidemen",
      "regency": "Sidemen",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2003,
      "postcode": 80864,
      "village": "Sindu Wati",
      "regency": "Sidemen",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2004,
      "postcode": 80864,
      "village": "Talibeng",
      "regency": "Sidemen",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2005,
      "postcode": 80864,
      "village": "Tangkup",
      "regency": "Sidemen",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2006,
      "postcode": 80864,
      "village": "Telaga Tawang",
      "regency": "Sidemen",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2007,
      "postcode": 80864,
      "village": "Tri Eka Buana",
      "regency": "Sidemen",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2008,
      "postcode": 80864,
      "village": "Wisma Kerta",
      "regency": "Sidemen",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2009,
      "postcode": 80871,
      "village": "Antiga",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2010,
      "postcode": 80871,
      "village": "Antiga Kelod",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2011,
      "postcode": 80871,
      "village": "Gegelang",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2012,
      "postcode": 80871,
      "village": "Manggis",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2013,
      "postcode": 80871,
      "village": "Ngis",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2014,
      "postcode": 80871,
      "village": "Nyuh Tebel",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2015,
      "postcode": 80871,
      "village": "Padangbai",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2016,
      "postcode": 80871,
      "village": "Pesedahan",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2017,
      "postcode": 80871,
      "village": "Selumbung",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2018,
      "postcode": 80871,
      "village": "Sengkidu",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2019,
      "postcode": 80871,
      "village": "Tenganan",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2020,
      "postcode": 80871,
      "village": "Ulakan",
      "regency": "Manggis",
      "state": "Kab.",
      "district": "Karangasem",
      "province": "Bali"
    },
    {
      "id": 2021,
      "postcode": 80711,
      "village": "Semarapura Tengah",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2022,
      "postcode": 80712,
      "village": "Semarapura Kaja",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2023,
      "postcode": 80713,
      "village": "Semarapura Kauh",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2024,
      "postcode": 80716,
      "village": "Akah",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2025,
      "postcode": 80716,
      "village": "Gelgel",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2026,
      "postcode": 80716,
      "village": "Jumpai",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2027,
      "postcode": 80716,
      "village": "Kamasan",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2028,
      "postcode": 80716,
      "village": "Kampung Gelgel",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2029,
      "postcode": 80716,
      "village": "Manduang",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2030,
      "postcode": 80716,
      "village": "Satra",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2031,
      "postcode": 80716,
      "village": "Selat",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2032,
      "postcode": 80716,
      "village": "Selisihan",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2033,
      "postcode": 80716,
      "village": "Semarapura Kangin",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2034,
      "postcode": 80716,
      "village": "Semarapura Klod Kangin",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2035,
      "postcode": 80716,
      "village": "Semarapura Klod/Kelod",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2036,
      "postcode": 80716,
      "village": "Tangkas",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2037,
      "postcode": 80716,
      "village": "Tegak",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2038,
      "postcode": 80716,
      "village": "Tojan",
      "regency": "Klungkung",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2039,
      "postcode": 80752,
      "village": "Aan",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2040,
      "postcode": 80752,
      "village": "Bakas",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2041,
      "postcode": 80752,
      "village": "Banjarangkan",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2042,
      "postcode": 80752,
      "village": "Bungbungan",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2043,
      "postcode": 80752,
      "village": "Getakan",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2044,
      "postcode": 80752,
      "village": "Negari",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2045,
      "postcode": 80752,
      "village": "Nyalian",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2046,
      "postcode": 80752,
      "village": "Nyanglan",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2047,
      "postcode": 80752,
      "village": "Takmung",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2048,
      "postcode": 80752,
      "village": "Tihingan",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2049,
      "postcode": 80752,
      "village": "Timuhun",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2050,
      "postcode": 80752,
      "village": "Tohpati",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2051,
      "postcode": 80752,
      "village": "Tusan",
      "regency": "Banjarangkan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2052,
      "postcode": 80761,
      "village": "Besan",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2053,
      "postcode": 80761,
      "village": "Dawan Kaler",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2054,
      "postcode": 80761,
      "village": "Dawan Klod",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2055,
      "postcode": 80761,
      "village": "Gunaksa",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2056,
      "postcode": 80761,
      "village": "Kampung Kusamba",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2057,
      "postcode": 80761,
      "village": "Kusamba",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2058,
      "postcode": 80761,
      "village": "Paksebali",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2059,
      "postcode": 80761,
      "village": "Pesinggahan",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2060,
      "postcode": 80761,
      "village": "Pikat",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2061,
      "postcode": 80761,
      "village": "Sampalan Klod",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2062,
      "postcode": 80761,
      "village": "Sampalan Tengah",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2063,
      "postcode": 80761,
      "village": "Sulang",
      "regency": "Dawan",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2064,
      "postcode": 80771,
      "village": "Batukandik",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2065,
      "postcode": 80771,
      "village": "Batumadeg",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2066,
      "postcode": 80771,
      "village": "Batununggul",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2067,
      "postcode": 80771,
      "village": "Bunga Mekar",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2068,
      "postcode": 80771,
      "village": "Jungutbatu",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2069,
      "postcode": 80771,
      "village": "Kampung Toyapakeh",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2070,
      "postcode": 80771,
      "village": "Klumpu",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2071,
      "postcode": 80771,
      "village": "Kutampi",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2072,
      "postcode": 80771,
      "village": "Kutampi Kaler",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2073,
      "postcode": 80771,
      "village": "Lembongan",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2074,
      "postcode": 80771,
      "village": "Ped",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2075,
      "postcode": 80771,
      "village": "Pejukutan",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2076,
      "postcode": 80771,
      "village": "Sakti",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2077,
      "postcode": 80771,
      "village": "Sekartaji",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2078,
      "postcode": 80771,
      "village": "Suana",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2079,
      "postcode": 80771,
      "village": "Tanglad",
      "regency": "Nusapenida",
      "state": "Kab.",
      "district": "Klungkung",
      "province": "Bali"
    },
    {
      "id": 2080,
      "postcode": 82111,
      "village": "Dauh Peken",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2081,
      "postcode": 82111,
      "village": "Wanasari",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2082,
      "postcode": 82112,
      "village": "Bongan (Boongan)",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2083,
      "postcode": 82113,
      "village": "Delod Peken",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2084,
      "postcode": 82114,
      "village": "Dejan Peken (Dajan Peken)",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2085,
      "postcode": 82115,
      "village": "Buahan",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2086,
      "postcode": 82115,
      "village": "Denbantas",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2087,
      "postcode": 82115,
      "village": "Gubug",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2088,
      "postcode": 82115,
      "village": "Sesandan",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2089,
      "postcode": 82115,
      "village": "Subamia",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2090,
      "postcode": 82115,
      "village": "Sudimara",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2091,
      "postcode": 82115,
      "village": "Tunjuk",
      "regency": "Tabanan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2092,
      "postcode": 82121,
      "village": "Abian Tuwung",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2093,
      "postcode": 82121,
      "village": "Belalang",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2094,
      "postcode": 82121,
      "village": "Bengkel",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2095,
      "postcode": 82121,
      "village": "Beraban",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2096,
      "postcode": 82121,
      "village": "Buwit",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2097,
      "postcode": 82121,
      "village": "Cepaka",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2098,
      "postcode": 82121,
      "village": "Kaba-Kaba",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2099,
      "postcode": 82121,
      "village": "Kediri",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2100,
      "postcode": 82121,
      "village": "Nyambu",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2101,
      "postcode": 82121,
      "village": "Nyitdah",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2102,
      "postcode": 82121,
      "village": "Pandak Bandung",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2103,
      "postcode": 82121,
      "village": "Pandak Gede",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2104,
      "postcode": 82121,
      "village": "Pangkung Tibah",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2105,
      "postcode": 82121,
      "village": "Pejaten",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2106,
      "postcode": 82123,
      "village": "Banjar Anyar",
      "regency": "Kediri",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2107,
      "postcode": 82152,
      "village": "Babahan",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2108,
      "postcode": 82152,
      "village": "Biaung",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2109,
      "postcode": 82152,
      "village": "Buruan",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2110,
      "postcode": 82152,
      "village": "Jatiluwih",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2111,
      "postcode": 82152,
      "village": "Jegu",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2112,
      "postcode": 82152,
      "village": "Mengeste",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2113,
      "postcode": 82152,
      "village": "Penatahan",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2114,
      "postcode": 82152,
      "village": "Penebel",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2115,
      "postcode": 82152,
      "village": "Pesagi",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2116,
      "postcode": 82152,
      "village": "Pitra",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2117,
      "postcode": 82152,
      "village": "Rejasa",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2118,
      "postcode": 82152,
      "village": "Riang Gede",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2119,
      "postcode": 82152,
      "village": "Sangketan",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2120,
      "postcode": 82152,
      "village": "Senganan",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2121,
      "postcode": 82152,
      "village": "Tajen",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2122,
      "postcode": 82152,
      "village": "Tegalinggah",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2123,
      "postcode": 82152,
      "village": "Tengkudak",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2124,
      "postcode": 82152,
      "village": "Wongaya Gede",
      "regency": "Penebel",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2125,
      "postcode": 82161,
      "village": "Batuaji",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2126,
      "postcode": 82161,
      "village": "Baturiti",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2127,
      "postcode": 82161,
      "village": "Belumbang",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2128,
      "postcode": 82161,
      "village": "Kelating",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2129,
      "postcode": 82161,
      "village": "Kerambitan",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2130,
      "postcode": 82161,
      "village": "Kesiut",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2131,
      "postcode": 82161,
      "village": "Kukuh",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2132,
      "postcode": 82161,
      "village": "Meliling",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2133,
      "postcode": 82161,
      "village": "Pangkung Karung",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2134,
      "postcode": 82161,
      "village": "Penarukan",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2135,
      "postcode": 82161,
      "village": "Samsam",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2136,
      "postcode": 82161,
      "village": "Sembung Gede",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2137,
      "postcode": 82161,
      "village": "Tibu Biu (Tibubiyu)",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2138,
      "postcode": 82161,
      "village": "Timpag",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2139,
      "postcode": 82161,
      "village": "Tista",
      "regency": "Kerambitan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2140,
      "postcode": 82162,
      "village": "Angkah",
      "regency": "Selemadeg / Salemadeg Barat",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2141,
      "postcode": 82162,
      "village": "Antap",
      "regency": "Selemadeg",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2142,
      "postcode": 82162,
      "village": "Antosari",
      "regency": "Selemadeg / Salemadeg Barat",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2143,
      "postcode": 82162,
      "village": "Bajera",
      "regency": "Selemadeg",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2144,
      "postcode": 82162,
      "village": "Bajera Utara",
      "regency": "Selemadeg",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2145,
      "postcode": 82162,
      "village": "Bantas",
      "regency": "Selemadeg / Salamadeg Timur",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2146,
      "postcode": 82162,
      "village": "Bengkel Sari",
      "regency": "Selemadeg / Salemadeg Barat",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2147,
      "postcode": 82162,
      "village": "Beraban",
      "regency": "Selemadeg / Salamadeg Timur",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2148,
      "postcode": 82162,
      "village": "Berembeng",
      "regency": "Selemadeg",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2149,
      "postcode": 82162,
      "village": "Dalang",
      "regency": "Selemadeg / Salamadeg Timur",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2150,
      "postcode": 82162,
      "village": "Gadung Sari",
      "regency": "Selemadeg / Salamadeg Timur",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2151,
      "postcode": 82162,
      "village": "Gadungan",
      "regency": "Selemadeg / Salamadeg Timur",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2152,
      "postcode": 82162,
      "village": "Gunung Salak",
      "regency": "Selemadeg / Salamadeg Timur",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2153,
      "postcode": 82162,
      "village": "Lalang Linggah",
      "regency": "Selemadeg / Salemadeg Barat",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2154,
      "postcode": 82162,
      "village": "Lumbung",
      "regency": "Selemadeg / Salemadeg Barat",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2155,
      "postcode": 82162,
      "village": "Lumbung Kauh",
      "regency": "Selemadeg / Salemadeg Barat",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2156,
      "postcode": 82162,
      "village": "Mambang",
      "regency": "Selemadeg / Salamadeg Timur",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2157,
      "postcode": 82162,
      "village": "Manikyang",
      "regency": "Selemadeg",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2158,
      "postcode": 82162,
      "village": "Megati",
      "regency": "Selemadeg / Salamadeg Timur",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2159,
      "postcode": 82162,
      "village": "Mundeh",
      "regency": "Selemadeg / Salemadeg Barat",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2160,
      "postcode": 82162,
      "village": "Mundeh Kangin",
      "regency": "Selemadeg / Salemadeg Barat",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2161,
      "postcode": 82162,
      "village": "Mundeh Kauh",
      "regency": "Selemadeg / Salemadeg Barat",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2162,
      "postcode": 82162,
      "village": "Pupuan Sawah",
      "regency": "Selemadeg",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2163,
      "postcode": 82162,
      "village": "Selabih",
      "regency": "Selemadeg / Salemadeg Barat",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2164,
      "postcode": 82162,
      "village": "Selemadeg",
      "regency": "Selemadeg",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2165,
      "postcode": 82162,
      "village": "Serampingan",
      "regency": "Selemadeg",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2166,
      "postcode": 82162,
      "village": "Tangguntiti",
      "regency": "Selemadeg / Salamadeg Timur",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2167,
      "postcode": 82162,
      "village": "Tegal Mengkeb",
      "regency": "Selemadeg / Salamadeg Timur",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2168,
      "postcode": 82162,
      "village": "Tiying Gading",
      "regency": "Selemadeg / Salemadeg Barat",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2169,
      "postcode": 82162,
      "village": "Wanagiri",
      "regency": "Selemadeg",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2170,
      "postcode": 82162,
      "village": "Wanagiri Kauh",
      "regency": "Selemadeg",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2171,
      "postcode": 82163,
      "village": "Bantiran",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2172,
      "postcode": 82163,
      "village": "Batungsel",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2173,
      "postcode": 82163,
      "village": "Belatungan",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2174,
      "postcode": 82163,
      "village": "Belimbing",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2175,
      "postcode": 82163,
      "village": "Jelijih Punggang",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2176,
      "postcode": 82163,
      "village": "Karya Sari",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2177,
      "postcode": 82163,
      "village": "Kebon Padangan",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2178,
      "postcode": 82163,
      "village": "Munduk Temu",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2179,
      "postcode": 82163,
      "village": "Padangan",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2180,
      "postcode": 82163,
      "village": "Pajahan",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2181,
      "postcode": 82163,
      "village": "Pujungan",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2182,
      "postcode": 82163,
      "village": "Pupuan",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2183,
      "postcode": 82163,
      "village": "Sanda",
      "regency": "Pupuan",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2184,
      "postcode": 82181,
      "village": "Batannyuh",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2185,
      "postcode": 82181,
      "village": "Beringkit",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2186,
      "postcode": 82181,
      "village": "Caubelayu",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2187,
      "postcode": 82181,
      "village": "Geluntung",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2188,
      "postcode": 82181,
      "village": "Kukuh",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2189,
      "postcode": 82181,
      "village": "Kuwum",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2190,
      "postcode": 82181,
      "village": "Marga",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2191,
      "postcode": 82181,
      "village": "Marga Dajan Puri",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2192,
      "postcode": 82181,
      "village": "Marga Dauh Puri",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2193,
      "postcode": 82181,
      "village": "Payangan",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2194,
      "postcode": 82181,
      "village": "Peken Belayu",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2195,
      "postcode": 82181,
      "village": "Petiga",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2196,
      "postcode": 82181,
      "village": "Selanbawak",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2197,
      "postcode": 82181,
      "village": "Tegaljadi",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2198,
      "postcode": 82181,
      "village": "Tua",
      "regency": "Marga",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2199,
      "postcode": 82191,
      "village": "Angseri",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2200,
      "postcode": 82191,
      "village": "Antapan",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2201,
      "postcode": 82191,
      "village": "Apuan",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2202,
      "postcode": 82191,
      "village": "Bangli",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2203,
      "postcode": 82191,
      "village": "Batunya",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2204,
      "postcode": 82191,
      "village": "Baturiti",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2205,
      "postcode": 82191,
      "village": "Candikuning",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2206,
      "postcode": 82191,
      "village": "Luwus",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2207,
      "postcode": 82191,
      "village": "Mekarsari",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2208,
      "postcode": 82191,
      "village": "Perean",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2209,
      "postcode": 82191,
      "village": "Perean Kangin",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    },
    {
      "id": 2210,
      "postcode": 82191,
      "village": "Perean Tengah",
      "regency": "Baturiti",
      "state": "Kab.",
      "district": "Tabanan",
      "province": "Bali"
    }
  ]
