export const RimauS = {
  vehicle_id: process.env.VUE_APP_ID_VEHICLE_RIMAU,
  name: "RIMAU S",
  nameLowercase: "rimau_s",
  slug: "rimau_s",
  folder: "cpx",
  description: "Meets the needs of the aspiring urban professional. ",
  image: "img/shared/bikes/rimau_s/bike-rimau_s-inside.png",
  images: {
    hero: 'img/shared/bikes/rimau_s/bg-banner-rimau_s-new.jpg',
    logo: 'img/shared/bikes/rimau_s/logotype-rimau_s-black.png',
    tab_1: "img/shared/bikes/rimau_s/tab1.jpg",
    tab_2: "img/shared/bikes/rimau_s/tab2.jpg",
    tab_3: "img/shared/bikes/rimau_s/tab3.jpg",
    product: "img/shared/bikes/rimau_s/banner-rimau_s-product.png",
    product_row_1: "img/shared/bikes/rimau_s/banner-rimau-product-row-1.png",
    product_row_2: 'img/shared/bikes/rimau_s/banner-rimau-product-row-2.png',
    product_row_3: 'img/shared/bikes/rimau_s/banner-rimau-product-row-3.png',
  },
  imageNavbar: "img/shared/bikes/rimau_s/rimau_s-menu-navbar.png",
  motor: "Electric Motor - Super Soco EEC Power 4000W",
  autonomy:
    "Autonomy 140 Km (at 45 Km/h with 75 Kg driver, 2 batteries)",
  speed: "95",
  topSpeed: "95",
  maxDistance: "200",
  maximumRange: "200",
  maxDistanceUnit: "km",
  battery: "2",
  recharge:
    "Recharge 3.5 h at 60V15A (fast charge with single battery)",
  count: 7,
  price: "Rp40.880.000",
  subPrice: null,
  batterySubscription: null,
  dailyPrice: 45000,
  stock: 1,
  colorOptions: ["#FFFFFF", "#000000"],
  colorOptionsDes: [
    {
      hex: '#FFFFFF', name: 'Arctic White'
    },
    {
      hex: '#000000', name: 'Jet Black'
    }
  ],
  productTagLine: {
    en: {
      title: 'Effortless commutes',
      desctiption: 'Designed for professionals, artists, and adventurers living an urban lifestyle. Experience effortless commuting with RIMAU.'
    },
    id: {
      title: 'Seru berkendara membelah kota',
      desctiption: 'Dirancang untuk para pengendara urban dan petualang. Nikmati pengalaman berkendara nyaman dan bertenaga bersama RIMAU.'
    }
  },
  quotes: null,
  quotesForm: null,
  assignQuotes: null,
  productInspirationDesign: null,
  performanceAndSafetyDesc: {
    en: "<p>Designed for sustainable mobility with style.</p> <p>Full-LED smart lighting system (front and rear) helps you see and be seen anywhere, even in the bad weather. Combined Brake System (CBS) features are standard for RIMAU units to reduce the risk of accidents.</p>",
    id: "<p>RIMAU dirancang untuk memberikan pengalaman mobilitas yang berkelanjutan tanpa melupakan gaya.</p> <p>Dengan sistem pencahayaan full LED di bagian depan dan belakang, dapatkan visibilitas yang lebih baik dalam berbagai kondisi cuaca. Dibekali sistem pengereman CBS, dapatkan keamanan ekstra saat berkendara.</p>"
  },
  convenienceDesc: {
    en: "<p>We understand that commuting can be a very tiring journey. Our sculpted seat provides exceptional comfort for you and your passenger.</p><p>RIMAU is also equipped with a one-push reverse/creep button, fast & easy changing tyre system, fast-charging, and intuitive battery operation for everyday ease of use.</p>",
    id: "<p>Perjalanan berangkat dan pulang kerja merupakan proses yang melelahkan. Inilah alasan kami merancang jok RIMAU sedemikian rupa untuk memberikan kenyamanan maksimum bagimu maupun penumpang di jok belakang.</p><p>Rimau juga dilengkapi one push reverse button untuk memudahkanmu saat harus mundur dan single-sided swingarm yang memungkinkan proses penggantian ban belakang dengan sangat cepat.</p>"
  },
  intelegencyDesc: {
    en: "<P>Our powertrain management system enables seamless switching between two batteries as they get depleted for maximum efficiency and control.</p><p>Stay connected at all times with your vehicle through our localised cloud to app integration. Monitor your vehicle condition and battery capacity from the comfort of your home or office.</p>",
    id: "<P>Sistem powertrain management memungkinkan RIMAU untuk beralih dari satu baterai ke baterai lainnya saat dayanya habis untuk efisiensi dan kendali maksimum.</p><p>Tetap terhubung kapan pun dengan RIMAU melalui aplikasi Charged yang terintegrasi. Kamu bisa memantau kondisi motor dan kapasitas bateraimu dari mana pun, kapan pun.</p>"
  },
  isRelease: true,
  typeProduct: "edition",
  specificationDesc: {
    en: "*Data collected from real world testing on Indonesian roads, with a single rider of 70kg, not exceeding speeds of 50km/h. Your results may vary depending on riding conditions.",
    id: "*Berdasarkan hasil uji di jalanan Indonesia dengan satu pengendara, bobot 70 kg dan kecepatan berkendara tidak lebih dari 50 km/jam. Hasil mungkin bervariasi, tergantung kondisi berkendara."
  },
  specification: {
    keys: {
      en: [
        {
          description: "L*W*H",
          details: "1975*720*1132 mm",
        },
        {
          description: "Wheelbase",
          details: "1365 mm",
        },
        {
          description: "Min. Ground Clearance",
          details: "145 mm",
        },
        {
          description: "Seat Height",
          details: "760 mm",
        },
        {
          description: "Net Weight",
          details: "135 kg",
        },
        {
          description: "Max Load",
          details: "150 kg (rider excluded)",
        },
      ],
      id: [
        {
          description: "P*L*T",
          details: "1975*720*1132 mm",
        },
        {
          description: "Jarak Sumbu Roda",
          details: "1365 mm",
        },
        {
          description: "Min. Ground Clearance",
          details: "145 mm",
        },
        {
          description: "Tinggi Tempat Duduk",
          details: "760 mm",
        },
        {
          description: "Bobot Bersih",
          details: "135 kg",
        },
        {
          description: "Kapasitas Beban Maksimum",
          details: "150 kg (tanpa pengendara)",
        },
      ],
    },
    powertrain: {
      en: [
        // {
        //   description: "Range",
        //   details: "200 km",
        // },
        {
          description: "Charge time (per battery)",
          details: "4 Hours",
        },
        {
          description: "Torque (peak)",
          details: "171 N.m",
        },
        // {
        //   description: "Top Speed",
        //   details: "95 Km/h",
        // },
      ],
      id: [
        // {
        //   description: "Jangkauan",
        //   details: "200 km",
        // },
        {
          description: "Waktu pengisian daya (per baterai)",
          details: "4 Jam",
        },
        {
          description: "Torsi (puncaknya)",
          details: "171 N.m",
        },
        // {
        //   description: "Kecepatan Tertinggi",
        //   details: "95 Km/h",
        // },
      ],
    },
    other: {
      en: [
        {
          description: "Brakes (F)",
          details: "CBS, Disc, 240mm",
        },
        {
          description: "Brakes (R)",
          details: "CBS, Disc, 180mm",
        },
        {
          description: "Front Suspension Travel",
          details: "80 mm",
        },
        {
          description: "Rear Suspension Travel",
          details: "60 mm",
        },
        {
          description: "Front Tyre Size",
          details: "100/80-16",
        },
        {
          description: "Rear Tyre Size",
          details: "110/80-14",
        },
        {
          description: "Lighting",
          details: "F/R LED",
        },
        {
          description: "Battery",
          details: "Dual, Removable",
        },
      ],
      id: [
        {
          description: "Rem (Depan)",
          details: "CBS, Cakram, 240mm",
        },
        {
          description: "Rem (Belakang)",
          details: "CBS, Cakram, 180mm",
        },
        {
          description: "Travel Suspensi Depan",
          details: "80 mm",
        },
        {
          description: "Travel Suspensi Belakang",
          details: "60 mm",
        },
        {
          description: "Ukuran Ban Depan",
          details: "100/80-16",
        },
        {
          description: "Ukuran Ban Belakang",
          details: "110/80-14",
        },
        {
          description: "Pencahayaan",
          details: "LED (Depan, Belakang)",
        },
        {
          description: "Baterai",
          details: "Ganda, Dapat Dilepas Pasang",
        },
      ],
    }
  },
  priceDescription: {
    en: 'Price refer to OTR Jakarta, includes VAT, spare parts warranty and all on the road costs. Terms and conditions applied.',
    id: 'Harga mengacu pada OTR Jakarta, sudah termasuk PPN, garansi suku cadang dan biaya on the road. Syarat dan ketentuan berlaku.',
  },
  actualPrice: null,
  text: {
    otr: {
      en: 'OTR Jakarta Price',
      id: 'Harga OTR Jakarta'
    },
    includeInOtr: "(Unit, Battery, & Charger)",
    includeInTheOtrPrice: {
      en: 'Included in the OTR price:',
      id: 'Sudah termasuk dalam harga OTR:',
    },
    includeInTheOtrDescription: {
      en: [
        {
          name: 'Unit with 1 battery',
          description: 'Rimau S has 2 batteries capacity'
        },
        {
          name: 'Standard Charger',
          description: 'Standard Charger, 10% to 100% in 5 hours time'
        },
        {
          name: 'Fast Charger (Optional)',
          description: '10% - 100% in 3 hours time* Additional cost Rp 3.600.000'
        }
      ],
      id: [
        {
          name: 'Unit dengan 1 baterai',
          description: 'Rimau S memiliki 2 baterai berkapasitas'
        },
        {
          name: 'Pengisi Daya Standar',
          description: 'Pengisi Daya Standar, 10% hingga 100% dalam waktu 5 jam'
        },
        {
          name: 'Pengisi Daya Cepat (Opsional)',
          description: '10% - 100% dalam waktu 3 jam* Biaya tambahan Rp 3.600.000'
        }
      ]
    }
  }
}