import { BeRubyAPI } from "../../../clients/BERubyAPI";
import Jwt from "jsonwebtoken";

const getVehiclePriceApi = (token, subscription_id) => {
  let responseFetch = BeRubyAPI
    .get('/v2/embedded_forms/get_subscription_prices?key_id=' + subscription_id,
      {
        headers: {
          'Authorization': token !== null ? 'Bearer ' + token : '',
        }
      }
    )
    .then(res => {
      let decryptData = Jwt.verify(res.data.data.payload, process.env.VUE_APP_END_TO_END_SECRET_KEY);
      return {
        data: decryptData,
        meta: res.data.meta,
      }
    }).catch((err) => {
      return err.response.data
    });

  return responseFetch;
}

export { getVehiclePriceApi };