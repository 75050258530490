<template>
  <v-container
    pa-0
    fluid
    fill-height
    :style="
      dark
        ? 'width: 100%;background: linear-gradient(180deg, rgba(15,15,15,1) 0%, rgba(36,36,36,1) 100%);'
        : 'width: 100%;background: #FFF;'
    "
  >
    <v-card flat class="pa-2" style="height: 100%; width: 100%" :dark="dark">
      <v-layout :class="!isMobileDevice ? 'form-signup' : 'form-signup-mobile'">
        <v-row>
          <v-icon
            v-if="SMSsent"
            style="color: black !important; padding: 1rem; margin-bottom: 1rem"
            >mdi-chevron-left</v-icon
          >
          <v-spacer></v-spacer>
        </v-row>
        <v-row align="center" justify="space-between" class="mb-2">
          <v-btn class="md:hidden" @click="profileFormDialogClosed" right icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-col cols="12" md="11">
            <span
              :class="
                !isMobileDevice
                  ? 'login-title font-montserrat text-2xl text-charged-grey-100 font-bold tracking-tight'
                  : 'login-title-mobile font-montserrat'
              "
              v-if="!SMSsent"
              >{{
                getLanguage == "id"
                  ? "Lengkapi Akun Anda"
                  : "Complete your account"
              }}</span
            >
          </v-col>
          <v-col cols="1" class="px-0 hidden md:table">
            <v-btn @click="profileFormDialogClosed" right icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <span style="font-size: 18px" class="font-weight-bold" v-if="SMSsent">{{
          lang[getLanguage].THE_CODE
        }}</span>
        <span class="subheading" v-if="SMSsent">{{
          lang[getLanguage].SMS_SENT + this.phoneNo
        }}</span>
        <span
          class="font-weight-medium text-charged-grey-400 text-base font-work-sans font-normal"
          >{{ lang[getLanguage].LABEL_FULL_NAME_KTP }}</span
        >
        <v-text-field
          class="custom-combobox-d color-400"
          style="width: 100%"
          outlined
          dense
          filled
          v-model="name"
          :disabled="disabledName"
        >
        </v-text-field>
        <span
          class="font-weight-medium text-charged-grey-400 text-base font-work-sans font-normal"
          >{{ lang[getLanguage].SUBSCRIBE_EMAIL_ADDRESS }}</span
        >
        <v-form v-model="valid" ref="form">
          <v-text-field
            class="custom-combobox-d color-400"
            style="width: 100%"
            outlined
            dense
            filled
            v-model="email"
            :rules="emailRules"
            required
            @input="change1"
            @change="change1"
            :disabled="disabledEmail"
          >
          </v-text-field>
        </v-form>
        <span
          class="font-weight-medium text-charged-grey-400 text-base font-work-sans font-normal"
          >{{ lang[getLanguage].LABEL_CUSTOMER_TYPE }}</span
        >
        <v-combobox
          v-if="customerTypeValue !== 'mitra_driver_gojek'"
          class="custom-combobox-d color-400"
          v-model="customerType"
          :items="customerTypeList"
          @change="onChangeCustomerType"
          dense
          :loading="loading"
          item-id="id"
          item-text="name"
          outlined
          :disabled="disabledCustomerType"
        ></v-combobox>
        <v-text-field
          v-if="customerTypeValue == 'mitra_driver_gojek'"
          class="custom-combobox-d color-400"
          style="width: 100%"
          outlined
          dense
          filled
          v-model="customerType"
          @change="onChangeCustomerType"
          :disabled="disabledCustomerType"
        >
        </v-text-field>
        <div v-if="customerTypeValue === 'driver'">
          <span
            class="font-weight-medium text-charged-grey-400 text-base font-work-sans font-normal"
          >
            Referral Code (Optional)
          </span>
          <v-combobox
            class="custom-combobox-d"
            v-model="referralCode"
            :items="referralCodeList"
            dense
            :loading="loading"
            item-id="id"
            item-text="name"
            outlined
          ></v-combobox>
        </div>
        <!-- <v-checkbox v-model="newsletter" class="mt-0">
          <template v-slot:label>
            <div class="text-xs md:text-base font-work-sans tracking-tight">
              {{
                getLanguage === "en"
                  ? "Subscribe to our newsletter to get early access to sales and promotions."
                  : "Berlangganan ke newsletter kami untuk mendapatkan akses awal pada penjualan dan promosi."
              }}
            </div>
          </template>
        </v-checkbox> -->
        <v-btn
          large
          style="width: 100%"
          @click="profileSubmit"
          :loading="loading"
          :disabled="!name || !email || !customerTypeValue"
          class="submit-button rounded-lg"
        >
          <!-- <v-icon left>mdi-arrow-right-bold</v-icon> -->
          {{ lang[getLanguage].SIGNUP_SUBMIT }}
        </v-btn>
        <span class="login-label-policy">
          {{
            getLanguage === "en"
              ? "By registering, you agree with our"
              : "Dengan masuk, Anda setuju dengan"
          }}
          <!-- <span
            style="cursor: pointer; text-decoration: underline; color: #0000d6"
            @click="openPolicy"
            >{{ lang[getLanguage].SALES_AGREEMENT }}</span
          > -->
          <span
            style="cursor: pointer; text-decoration: underline; color: #0000d6"
            @click="routerGo('/term-of-use')"
          >
            {{
              getLanguage == "en"
                ? "Terms and conditions"
                : "Syarat & ketentuan"
            }}
          </span>
          {{ lang[getLanguage].LABEL_AND }}
          <span
            style="cursor: pointer; text-decoration: underline; color: #0000d6"
            @click="routerGo('/privacy-policy')"
            >{{ lang[getLanguage].ACC_APP_PRIVACY
            }}{{
              getLanguage === "id" ? " & Cookie kami" : "our Cookies"
            }}</span
          >.
        </span>

        <v-layout fill-height></v-layout>
      </v-layout>
    </v-card>
    <v-dialog
      v-model="subscribeNowDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-toolbar :dark="dark" :color="dark ? 'grey darken-4' : '#FFF'">
        <!-- <v-btn
          @click="subscribeNowDialogClosed"
          icon
          style="margin-right: 20px"
        ></v-btn>
        <v-icon>mdi-arrow-left</v-icon> -->
        <!-- </v-btn> -->
        <v-icon>mdi-lock-outline</v-icon>
        <!-- <span style="margin-right: 5px">Secure Checkout by </span> -->
        <!-- <v-img
          src="img/icons/xendit_logo.png"
          max-width="64"
          max-height="24"
        ></v-img> -->
        <v-spacer></v-spacer>
        <v-img
          src="/img/logo/charged_logo.png"
          max-width="144"
          max-height="32"
        ></v-img>
        <v-spacer></v-spacer>
        <v-icon v-if="!isMobileDevice">mdi-phone</v-icon>
        <span v-if="!isMobileDevice"
          >{{ lang[getLanguage].LABEL_NEED_HELP }}?
          <a
            href="https://wa.me/6289604582129?text=Hi+Charged+Indonesia%2C+Saya+perlu+bantuan"
            target="_blank"
            >+62 896 04 582129</a
          ></span
        >
      </v-toolbar>
      <v-card
        :dark="dark"
        :style="dark ? 'background-color:#2a2a2a' : 'background-color:#FFF'"
      >
        <SubscribeNow
          ref="subscribe"
          :profile="profile"
          :index="index"
          :selectedBike="selectedBike"
          :isMobileDevice="isMobileDevice"
        ></SubscribeNow>
      </v-card>
    </v-dialog>

    <!-- ALERT -->
    <v-dialog v-model="alert" max-width="500">
      <v-card color="red" class="pb-0">
        <v-alert type="error">
          {{ textAlert }}
        </v-alert>
      </v-card>
    </v-dialog>

    <!-- DIALOG LOADER -->
    <v-dialog v-model="isLoad" hide-overlay persistent width="300">
      <v-card color="white" class="p-3">
        <v-card-text class="text-center">
          <v-progress-circular
            color="primary"
            :size="50"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text class="text-center text-charged-grey-100">
          Please stand by
          <!-- <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "@/store/index";
import SubscribeNow from "./Subscribe.vue";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
// import { addDoc, collection } from "@firebase/firestore";
// import { db } from "../../../main";
import { LoginUserApi, RegisterUserApi } from "../../../services/back-end-ruby";
export default {
  name: "signup",
  props: [
    // {
    //   dark: Boolean,
    //   windowWidth: Number,
    //   windowHeight: Number,
    //   drawer: Boolean,
    //   isMobileDevice: Boolean,
    //   index: Number,
    //   selectedBike: Object,
    // },
    "dark",
    "windowWidth",
    "windowHeight",
    "drawer",
    "isMobileDevice",
    "index",
    "selectedBike",
    "profileFormDialogClosed",
  ],
  data() {
    return {
      isLoad: false,
      loadingScreen: false,
      lang: this.$store.state.lang,
      alert: false,
      textAlert: null,
      loader: null,
      loading: false,
      phone: {
        code: "62",
        number: "",
      },
      phoneNoValid: false,
      valid: false,
      countryCode: "+62",
      phoneNo: "",
      appVerifier: "",
      otp: "",
      credential: "",
      verificationId: "",
      SMSsent: false,
      logoSrcDark: "",
      phoneNoFormatted: "",
      name: "",
      disabledName: false,
      email: "",
      disabledEmail: false,
      disabledCustomerType: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(v) ||
          "Email address must be valid",
      ],
      customerType: "",
      customerTypeValue: "",
      customerTypeList: ["Regular Customer", "Mitra Driver Online"],
      referralCode: "",
      // referralCodeList: [
      //   "BB1",
      //   "Jimmy1",
      //   "MN1",
      //   "MLY1",
      //   "DA1",
      //   "Joko1",
      //   "One1",
      // ],
      referralCodeList: ["MN1", "JK1", "SA1", "CH1", "DM1", "VG1"],
      newsletter: false,
      subscribeNowDialog: false,
      profile: {},
    };
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getPlatform() {
      return this.$store.state.device.platform;
    },
    getCustomerType() {
      return this.$store.getters.getCustomerType;
    },
  },
  components: {
    SubscribeNow,
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      // setTimeout(() => (this[l] = false), 3000)
    },
  },
  mounted() {
    /* this.currentUser = firebase.auth().currentUser
        if (this.currentUser && this.getUser.accType > 9) {
          console.log('####### Welcome App User #######')
          this.$router.push('/')
          return
        } else {
          this.$router.push('/')
        } */
  },
  created() {
    store.commit("ShowNav");
    // this.initReCaptcha();
    // this.phoneNo = this.countryCode;
    this.logoSrcDark = "./img/logo/charged_logo.png";
    this.emailRules = [
      (v) => !!v || this.lang[this.getLanguage].VALIDATION_EMAIL_REQ,
      (v) =>
        /.+@.+\..+/.test(v) ||
        this.lang[this.getLanguage].VALIDATION_EMAIL_VALID,
      (v) =>
        (v || "").indexOf(" ") < 0 ||
        this.lang[this.getLanguage].VALIDATION_EMAIL_VALID,
    ];
    setTimeout(() => {
      this.checkUser();
    }, 500);
  },
  methods: {
    async checkUser() {
      const getUser = this.$store.state.user;
      // console.log("getUser", getUser);
      if (getUser.fullName != "" && getUser.email != "") {
        this.name = getUser.fullName;
        this.email = getUser.email;
        this.disabledName = true;
        this.disabledEmail = true;
      }
      if (getUser.customerType != "") {
        if (getUser.customerType == "mitra_driver_gojek") {
          this.customerTypeValue = "mitra_driver_gojek";
        } else {
          this.customerTypeValue =
            getUser.customerType == "mitra" ? "driver" : "regular";
        }
        this.onChangeCustomerTypeValue();
        this.disabledCustomerType = true;
      }
      // if (
      //   this.customerTypeValue === "regular" &&
      //   getUser.fullName != "" &&
      //   getUser.email != ""
      // ) {
      //   // this.profileSubmit();
      // }
    },
    onChangeCustomerTypeValue() {
      if (this.customerTypeValue === "regular")
        this.customerType = "Regular Customer";
      if (this.customerTypeValue === "driver")
        this.customerType = "Mitra Driver Online";
      if (this.customerTypeValue === "mitra_driver_gojek")
        this.customerType = "Mitra Gojek";
    },
    onChangeCustomerType() {
      if (this.customerType === "Regular Customer")
        this.customerTypeValue = "regular";
      if (this.customerType === "Mitra Driver Online")
        this.customerTypeValue = "driver";
    },
    keyPressed(e) {
      this.phoneNoFormatted = e.formattedNumber;
      if (e.isValid) {
        this.phoneNoValid = true;
      } else {
        this.phoneNoValid = false;
      }
      // console.log(e.formattedNumber);
      /* if (e === 'click' && this.phone.number !== '' && this.phoneNoValid) {
            this.clickNext()
            return
          } */
      // if (e.keyCode === 13 && this.phoneNoValid) {
      //   // alert('Enter was pressed')
      //   this.clickNext();
      //   return;
      // }
    },
    change1() {
      // console.log(this.$refs.form.validate());
      if (this.$refs.form.validate()) {
        this.dataProvided = true;
      } else {
        this.dataProvided = false;
      }
    },
    async profileSubmit() {
      this.isLoad = true;
      // this.loadingScreen = true;
      console.log(
        "data:",
        //   this.name,
        //   this.email,
        //   this.customerType,
        //   this.referralCode,
        //   this.newsletter,
        this.index,
        this.selectedBike
      );
      // if (this.customerTypeValue === "driver")
      store.commit("SetLanguage", {
        language: "id",
      });
      const getUser = this.$store.state.user;
      this.profile = {
        uid: getUser.uid,
        name: this.name,
        email: this.email,
        phoneNumber: getUser.phone,
        customerType: this.customerTypeValue,
        referralCode: this.referralCode || "",
        newsletter: this.newsletter || "",
      };
      console.log("lang", this.getLanguage);

      // if (getUser.fullName == "" && getUser.email == "") {
      //   const usersRef = await addDoc(collection(db, "users"), this.profile);
      //   console.log("Done Create", usersRef);
      // }
      this.verifyAndLogin();
    },
    async verifyAndLogin() {
      let getDataUser = this.getUser;
      // console.log("THIS_DATA_USERS", getDataUser);
      let whoCustomerType = "";
      let howVehicleId = "";
      if (this.getCustomerType == "mitra_driver_gojek") {
        whoCustomerType = "Mitra Driver Gojek";
        howVehicleId = "";
      } else {
        whoCustomerType =
          this.customerTypeValue == "regular" ? "Non Mitra" : "Mitra";
        howVehicleId = getDataUser.bikeSubs?.vehicle_id;
      }
      await RegisterUserApi({
        phone_number: getDataUser.phoneNumber,
        email: this.email,
        full_name: this.name,
        customer_type: whoCustomerType,
        newsletter: this.newsletter,
        referral_code: this.referralCode ?? "",
        province: getDataUser.serviceCity[0].province,
        city:
          getDataUser.serviceCity[0].state +
          " " +
          getDataUser.serviceCity[0].district,
        district: getDataUser.serviceCity[0].regency,
        sub_district: getDataUser.serviceCity[0].village,
        vehicle_id: howVehicleId,
      }).then((res) => {
        // console.log("RES_RESGIST", res);
        store.commit("SetUserDetails", {
          ...getDataUser,
          fullName: this.name,
          email: this.email,
          phone: getDataUser.phoneNumber,
          customerType: this.customerTypeValue,
          isLoggedIn: true,
        });
        if (this.getCustomerType == "mitra_driver_gojek") {
          store.commit("setDataGojekInformasiPribadi", {
            ...this.$store.getters.getDataGojekInformasiPribadi,
            name: this.name,
            emailPribadi: this.email,
            phoneNumberPribadi: getDataUser.phoneNumber,
          });
        }
        if (
          res.meta.code == 422 &&
          res.meta.message[0] == "Phone number has already been taken"
        ) {
          LoginUserApi({ phone_number: getDataUser.phoneNumber }).then(
            (res) => {
              // console.log("RES_LOGIN", res);
              store.commit("SetUserDetails", {
                ...getDataUser,
                fullName: this.name,
                email: this.email,
                phone: getDataUser.phoneNumber,
                customerType: this.customerTypeValue,
                isLoggedIn: true,
                isLoggedIn2: true,
              });
              if (this.getCustomerType == "mitra_driver_gojek") {
                store.commit("setDataGojekInformasiPribadi", {
                  ...this.$store.getters.getDataGojekInformasiPribadi,
                  name: this.name,
                  emailGojek: this.email,
                  phoneNumberGojek: getDataUser.phoneNumber,
                });
              }
              this.$cookies.set("token_api", res.token);
              if (this.getCustomerType == "mitra_driver_gojek") {
                this.isLoad = false;
                // this.loadingScreen = false;
                this.$router.push("/form-subscribe-mitra-gojek");
              } else {
                this.validationInSignMitraGojek();
              }
            }
          );
        } else if (
          res.meta.code == 422 &&
          res.meta.message[0] == "Email has already been taken"
        ) {
          this.alert = true;
          this.textAlert = res.meta.message[0];
        } else {
          this.$cookies.set("token_api", res.data.token);
          if (this.getCustomerType == "mitra_driver_gojek") {
            this.isLoad = false;
            // this.loadingScreen = false;
            this.$router.push("/form-subscribe-mitra-gojek");
          } else {
            this.$router.push("/form-subscribe");
          }
        }

        // this.subscribeNowDialogClosed;
        // this.signupDialogClosed;
        // this.checkServiceAreaDialogClose;
      });
    },
    validationInSignMitraGojek() {
      if (this.$route.name == "sign-mitra-gojek") {
        this.alert = true;
        this.textAlert = "Maaf, akun anda bukan Mitra Gojek";
        this.$cookies.remove("token_api");
      } else {
        this.$router.push("/form-subscribe");
      }
    },
    clickNext() {
      // console.log("Platform:" + store.state.device.platform);
      if (!this.SMSsent) {
        if (store.state.device.platform === "NaN") {
          this.sendOtp();
        } else {
          this.sendOtpMobile();
        }
      } else {
        if (store.state.device.platform === "NaN") {
          this.verifyOtp();
        } else {
          this.verifyOtpMobile();
        }
      }
    },
    subscribeNowDialogClosed() {
      console.log("subscribe closed");
      this.$refs.subscribe.subscribeClosed();
      this.subscribeNowDialog = false;
    },
    changeLanguage() {
      // console.log(this.getLanguage)
      store.commit("SetLanguage", {
        language: this.getLanguage === "en" ? "id" : "en",
      });
      /* this.userData.language = lang
          var obj = {
            language: lang
          }
          this.saveSettingsData(obj) */
    },
    change0() {
      if (this.$refs.phoneInput.phoneNoIsValid()) {
        this.phoneNoValid = true;
      } else {
        this.phoneNoValid = false;
      }
    },
    routerGo(route) {
      window.open(route, "_blank");
    },
    openPolicy() {
      window.open("/pdf/sales_agreement.pdf", "_blank");
    },
  }, // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAK0lEQVQYV2NkgIK3f///h7GFmRkZQWwwAZKACSDzGdElkE2iQBKvnfhcCwAOtC/AmRbyYwAAAABJRU5ErkJggg==
};
</script>
<style>
::placeholder {
  color: #656565;
}
.myDiv {
  background: url("/img/app/card_bg.jpg") no-repeat;
}
.vue-phone-number-input {
  width: 100%;
  padding: 0;
  margin: 0;
}
.charged-logo-mini {
  margin-top: -200px;
}
.login-title {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 2rem;
}
.login-title {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 2rem;
}
.login-title-mobile {
  font-weight: 700;
  font-size: 20px;
  padding: 2rem 0;
}
.phone-mobile {
  padding: 1rem 0;
}
.form-signup {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: flex-start;
  width: 100%;
}
.form-signup-mobile {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: flex-start;
  width: 100%;
}
.submit-button {
  color: #fff !important;
  background-color: #6b4693 !important;
  text-transform: none !important;
}
.login-label {
  font-weight: 400;
  font-size: 16px;
}
.login-label-policy {
  font-weight: 400;
  font-size: 14px;
  margin-top: 2rem;
}

.custom-combobox-d.color-400.v-input--is-disabled
  .v-input__control
  > .v-input__slot
  input {
  color: #535456;
  /* color: black !important; */
}
</style>
