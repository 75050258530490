import { BeRubyAPI } from "../../../clients/BERubyAPI";
import Jwt from "jsonwebtoken";

const postOrderApi = (token, { subscription_checkbox, subscription_id }) => {

  let data = {
    subscription_checkbox,
  };

  let body = {
    body_params: data,
    timestamp: new Date()
  };

  let encryptData = Jwt.sign(body, process.env.VUE_APP_END_TO_END_SECRET_KEY);

  let responseFetch = BeRubyAPI.post('/v2/embedded_forms/order',
    { key_id: subscription_id, payload: encryptData },
    {
      headers: {
        'Authorization': token !== null ? 'Bearer ' + token : '',
      }
    })
    .then((res) => {
      let decryptData = Jwt.verify(res.data.data.payload, process.env.VUE_APP_END_TO_END_SECRET_KEY);
      return {
        data: { subscription: decryptData },
        meta: res.data.meta,
      }
    })
    .catch((err) => { return err.response.data });

  return responseFetch;
};

export { postOrderApi };