export const Maleo = {
  vehicle_id: process.env.VUE_APP_ID_VEHICLE_MALEO,
  name: "MALEO",
  nameLowercase: "maleo",
  slug: "maleo",
  folder: "vs2",
  description:
    "The multi-functional delivery vehicle adding value for your business.",
  image: "img/shared/bikes/maleo/bike-maleo.webp",
  imageNavbar: "img/navbar/maleo-front-view.svg",
  motor: "Engine Single Swingarm 3000W",
  autonomy:
    "Autonomy 75 Km (at 45 Km/h with 75 Kg driver, 2 batteries)",
  speed: "70",
  topSpeed: "70",
  maxDistance: "125",
  maximumRange: "125",
  maxDistanceUnit: "km",
  battery: "1",
  recharge: "Recharge 3.5 h at 60V15A (fast charge)",
  count: 8,
  price: "Rp 20.880.000",
  subPrice: "Rp 1.200.000",
  batterySubscription: "Rp 350.000",
  dailyPrice: 40000,
  stock: 1,
  colorOptions: ["#FFFFFF", "#000000"],
  productTagLine: {
    en: {
      title: 'Agile and iconic city runner',
      desctiption: 'Do anything, go anywhere, anytime with the MALEO.'
    },
    id: {
      title: 'Penjelajah kota yang lincah',
      desctiption: 'Pergi ke mana pun, lakukan apa yang kamu mau bersama MALEO.'
    }
  },
  performanceAndSafetyDesc: {
    en: "<p>Lightweight maneuverability meets usability with MALEO. Whether you like to do delivery or ferry your friends, MALEO can do it all at the same time. Full-LED smart lighting system (front and rear) helps you see and be seen anywhere, even in the bad weather.</p><p>Combined Brake System (CBS) features are standard for MALEO units to reduce the risk of accidents.</p>",
    id: "<p>Dapatkan kebebasan manuver yang lincah bersama MALEO. Mau untuk digunakan sehari-hari, bisa. Mau digunakan untuk mencari penghasilan tambahan, juga bisa. Dengan sistem pencahayaan full LED di bagian depan dan belakang, dapatkan visibilitas yang lebih baik dalam berbagai kondisi cuaca.</p><p>Dibekali sistem pengereman CBS, dapatkan keamanan ekstra saat berkendara.</p>"
  },
  convenienceDesc: {
    en: "<p>Rear rack allows you to move any package and your passenger at the same time.</p><p>MALEO is also equipped with a one-push reverse/creep button, fast & easy changing tyre system, fast-charging, easy onboard or offboard charging with easy battery access for everyday convenience, and hub driven maintenance-free drive train that moves you ahead of traffic.</p>",
    id: "<p>Tambahan rack di belakang memberikanmu fleksibilitas untuk mengangkut bawaan, paket, atau penumpang secara bersamaan.</p><p>MALEO juga dilengkapi dengan one push reverse button untuk memudahkan saat mundur, sistem single-sided swingarm untuk penggantian ban belakang yang cepat, fast charger dan akses mudah untuk mengisi daya baterai.</p>"
  },
  intelegencyDesc: {
    en: "<P>Your smart apps and vehicle are connected seamlessly behind the scene, allowing you to utilise our BMOS Central Control System with information such as Vehicle Inspection, Location, AntiTheft System, Vehicle Record, and Warning.</p><p>To further assist you in keeping your connectivity, MALEO is equipped with USB Charging port to keep your mobile device powered up.</p>",
    id: "<P>Hubungkan motormu dengan aplikasi pintar kami untuk mengakses BMOS Central Control System. Kamu bisa mengecek kondisi motor dan baterai, lokasi motor, sistem AntiTheft, record kendaraan, dan fitur peringatan.</p><p>MALEO juga dilengkapi slot USB khusus untuk pengecasan baterai ponsel agar kamu bisa terus terhubung.</p>"
  },
  isRelease: true,
  typeProduct: "standart",
  specificationDesc: {
    en: "*Data collected from real world testing on Indonesian roads, with a single rider of 70kg, not exceeding speeds of 50km/h. Your results may vary depending on riding conditions.",
    id: "*Berdasarkan hasil uji di jalanan Indonesia dengan satu pengendara, bobot 70 kg dan kecepatan berkendara tidak lebih dari 50 km/jam. Hasil mungkin bervariasi, tergantung kondisi berkendara."
  },
  specification: {
    keys: {
      en: [
        {
          description: "L*W*H",
          details: "1800*570*1050 mm",
        },
        {
          description: "Wheelbase",
          details: "1350 mm",
        },
        {
          description: "Min. Ground Clearance",
          details: "145 mm",
        },
        // {
        //   description: "Seat Height",
        //   details: "-",
        // },
        {
          description: "Net Weight",
          details: "84 kg",
        },
        {
          description: "Max Load",
          details: "100 kg",
        },
      ],
      id: [
        {
          description: "P*L*T",
          details: "1800*570*1050 mm",
        },
        {
          description: "Jarak Sumbu Roda",
          details: "1350 mm",
        },
        {
          description: "Min. Ground Clearance",
          details: "145 mm",
        },
        // {
        //   description: "Seat Height",
        //   details: "-",
        // },
        {
          description: "Bobot Bersih",
          details: "84 kg",
        },
        {
          description: "Kapasitas Beban Maksimum",
          details: "100 kg",
        },
      ],
    },
    powertrain: {
      en: [
        {
          description: "Range",
          details: "125 km",
        },
        {
          description: "Charge time (per battery)",
          details: "3 Hours (per battery 10-100%)",
        },
        {
          description: "Torque (peak)",
          details: "80 N.m",
        },
        {
          description: "Top Speed",
          details: "70 Km/h",
        },
      ],
      id: [
        {
          description: "Jangkauan",
          details: "125 km",
        },
        {
          description: "Waktu pengisian daya (per baterai)",
          details: "3 Jam (per baterai 10-100%)",
        },
        {
          description: "Torsi (puncaknya)",
          details: "80 N.m",
        },
        {
          description: "Kecepatan Tertinggi",
          details: "70 Km/h",
        },
      ],
    },
    other: {
      en: [
        {
          description: "Brakes (F)",
          details: "CBS, Disc",
        },
        {
          description: "Brakes (R)",
          details: "CBS, Disc",
        },
        // {
        //   description: "Front Suspension Travel",
        //   details: "Not Available",
        // },
        // {
        //   description: "Rear Suspension Travel",
        //   details: "Not Available",
        // },
        {
          description: "Front Tyre Size",
          details: "90/80-16",
        },
        {
          description: "Rear Tyre Size",
          details: "90/80-14",
        },
        {
          description: "Lighting",
          details: "F/R LED",
        },
        {
          description: "Battery",
          details: "Single, Removable",
        },
      ],
      id: [
        {
          description: "Rem (Depan)",
          details: "CBS, Cakram",
        },
        {
          description: "Rem (Belakang)",
          details: "CBS, Cakram",
        },
        // {
        //   description: "Front Suspension Travel",
        //   details: "Not Available",
        // },
        // {
        //   description: "Rear Suspension Travel",
        //   details: "Not Available",
        // },
        {
          description: "Ukuran Ban Depan",
          details: "90/80-16",
        },
        {
          description: "Ukuran Ban Belakang",
          details: "90/80-14",
        },
        {
          description: "Pencahayaan",
          details: "LED (Depan, Belakang)",
        },
        {
          description: "Baterai",
          details: "Single, Dapat Dilepas Pasang",
        },
      ],
    }
  },
  text: {
    otr: {
      en: 'OTR Jakarta Price',
      id: 'Harga OTR Jakarta'
    },
    includeInOtr: "(Unit & Charger)",
  }
}