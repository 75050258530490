<template>
  <v-menu offset-y dark>
    <template v-slot:activator="{ on }">
      <v-btn
        text
        v-on="on"
        :class="color + '--text '"
        style="
          text-transform: none !important;
          background-color: unset;
          box-shadow: none;
        "
      >
        {{ text }}
        <v-icon :color="color" right> {{ icon }} </v-icon>
      </v-btn>
    </template>
    <v-list v-for="(item, index) in items" :key="index">
      <v-list-item>
        <v-list-item-title
          @click="executeFunction(item.actionParam)"
          style="cursor: pointer"
          >{{ item.name }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "MenuLinkComponent",
  props: {
    text: String,
    color: {
      type: String,
      default: "black",
    },
    icon: String,
    items: [],
  },
  methods: {
    executeFunction(actionParam) {
      this.$emit("actions", actionParam);
    },
  },
};
</script>
