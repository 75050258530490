import { getProfileApi } from "../../../../services/back-end-ruby";

const dataAuthGojekModul = {
  state: () => ({
    isLogin: false,
  }),
  mutations: {
    setDataAuthGojek(state, payload) {
      state.isLogin = payload;
    },
  },
  actions: {
    async setDataAuthGojek({ commit }, token) {
      commit("setDataAuthGojek", await getProfileApi(token).then(res => {
        // console.log(res);
        if (res.meta.code == 200 && res.data.user.customer_type == 'mitra_driver_gojek') {
          return true;
        } else {
          return false;
        }
      }))
    }
  },
  getters: {
    getDataAuthGojek(state) {
      return state.isLogin;
    },
  }
}

export default dataAuthGojekModul;