import Vue from "vue";
import Vuex from "vuex";
import { LANG } from "./language";
import { PROVINCE } from "./province";
import { SERVICE_CITIES } from "./service_cities_v02";
import { CITIES } from "./cities";
import { REGENCIES } from "./regencies";
// import PACKAGES from '../../public/promotion'
import AuthModule from "./AuthModule";
import myProfile from "./MyProfileModule";
import customizationModul from "./form-subscribe/customization";
import domicileModule from "./form-subscribe/domicile";
import privateInfoModule from "./form-subscribe/private-info";
import vehicleModule from "./form-subscribe/vehicle";
import invoiceModul from "./form-subscribe/invoice";
import byUrlModul from "./ByUrlModule";
import dataGojekKostumisasiModul from "./form-subscribe/data/gojek/kostumisasi";
import dataGojekInformasiPribadiModul from "./form-subscribe/data/gojek/informasi-pribadi";
import dataGojekDomisiliModul from "./form-subscribe/data/gojek/domisili";
import dataGojekStepDoneModul from "./form-subscribe/data/gojek/stepDone";
import dataGojekPlanSubsModul from "./form-subscribe/data/gojek/plan_subs";
import dataAuthGojekModul from "./form-subscribe/data/gojek/userGojek";

import vehiclesStoreModel from "./vehicles_store";
import dataPlanSubsModul from "./form-subscribe/data/non-gojek/plan-subs";
import dataInformasiPribadiModul from "./form-subscribe/data/non-gojek/informasi-pribadi";
import dataAuthModul from "./form-subscribe/data/non-gojek/user";
import dataStepDoneModul from "./form-subscribe/data/non-gojek/form-step";
import dataDomisiliModul from "./form-subscribe/data/non-gojek/domisili";
import dataKostumisasiModul from "./form-subscribe/data/non-gojek/kostumisasi";

Vue.use(Vuex);

const state = {
  navigation: {
    show: true,
    showHome: true,
    showApp: false,
    dark: false, // set and get
    theme: "#b7e3e4", // Sky Blue
  },
  device: {
    platform: "NaN",
  },
  user: {
    dark:
      localStorage.getItem("darkTheme") === null
        ? false
        : JSON.parse(localStorage.getItem("darkTheme")), // set and get
    uid: "",
    docId: "",
    email: "",
    displayName: "",
    aboutMe: "",
    fullName: "",
    address_1: "",
    address_2: "",
    customerType: "",
    city: "",
    zip: "",
    dob: "",
    sex: 0,
    gravatar: false,
    avatar: "",
    hasCoinbase: false,
    hasWeb3InjectedBrowser: false,
    accounts: [],
    walletProvider:
      localStorage.getItem("provider") === null
        ? ""
        : localStorage.getItem("provider"),
    mmConnected: false,
    mmInstalled: false,
    mmCount: 0,
    newCount: 10,
    networkChainID: "",
    binanceConnected: false,
    binanceInstalled: false,
    binanceCount: 0,
    walletConnected: false,
    isDAppReady: false,
    isLoggedIn: false,
    isVerified: false,
    idVerified: false,
    phoneVerified: false,
    phone: null,
    phoneNumber: null,
    accLevel: 0,
    accType: 0,
    accStatus: 0,
    memberSince: 0,
    language:
      localStorage.getItem("language") === null
        ? "id"
        : localStorage.getItem("language"),
    favorites: [],
    resume: [],
    myapplications: [],
    likesBlog:
      localStorage.getItem("likesBlog") === null
        ? []
        : JSON.parse(localStorage.getItem("likesBlog")),
    welcome: null,
    showWelcomeSession: false,
    showWelcome:
      localStorage.getItem("welcome") === null
        ? false
        : JSON.parse(localStorage.getItem("welcome")),
    serviceCity: [],
    bikeSubs: null,
  },
  latestBNB: 0,
  latestETH: 0,
  latestMATIC: 0,
  latestKCS: 0,
  paymentAddress: "",
  key: "d9a922bc-2b12-4f50-9c1f-afF89sc21x7a",
  loading: false,
  error: null,
  lang: LANG,
  cities: CITIES,
  service_cities: SERVICE_CITIES,
  province: PROVINCE,
  regencies: REGENCIES,
  // packages: PACKAGES
};

const mutations = {
  ShowNav(state) {
    state.navigation.show = true;
  },
  HideNav(state) {
    state.navigation.show = false;
  },
  ShowNavHome(state) {
    state.navigation.showHome = true;
    state.navigation.showApp = false;
  },
  ShowNavApp(state) {
    state.navigation.showHome = false;
    state.navigation.showApp = true;
  },
  SetTheme(state, payload) {
    state.user.dark = payload.dark;
    localStorage.setItem("darkTheme", payload.dark);
  },
  SetLikesBlog(state, payload) {
    state.user.likesBlog.push(payload);
    localStorage.setItem("likesBlog", JSON.stringify(state.user.likesBlog));
  },
  RemoveLike(state, payload) {
    // payload === index
    state.user.likesBlog.splice(payload, 1);
    localStorage.setItem("likesBlog", JSON.stringify(state.user.likesBlog));
  },
  SetServiceCity(state, payload) {
    state.user.serviceCity = [];
    state.user.serviceCity.push(payload);
    //localStorage.setItem('serviceCity', JSON.stringify(state.user.serviceCity))
  },
  SetLatestPriceBNB(state, payload) {
    state.latestBNB = payload.price;
  },
  SetLatestPriceETH(state, payload) {
    state.latestETH = payload.price;
  },
  SetLatestPriceMATIC(state, payload) {
    state.latestMATIC = payload.price;
  },
  SetLatestPriceKCS(state, payload) {
    state.latestKCS = payload.price;
  },
  setAvatar(state, payload) {
    state.user.gravatar = false;
    state.user.avatar = payload;
  },
  setWelcomeSession(state, payload) {
    state.user.showWelcomeSession = payload;
  },
  setWelcome(state, payload) {
    state.user.showWelcome = payload;
    localStorage.setItem("welcome", payload);
  },
  setUserTierLevel(state, payload) {
    // console.log('setUserTierLevel in Store')
    // console.log(payload)
    state.user.accLevel = payload;
  },
  SetLanguage(state, payload) {
    state.user.language = payload.language ?? 'en';
    localStorage.setItem("language", payload.language);
  },
  SetUserDetails(state, payload) {
    // console.log('SetUserDetails in Store')
    // console.log(payload)
    state.user.uid = payload.uid;
    state.user.docId = payload.docId;
    state.user.language = payload.language ?? 'en';
    state.user.displayName = payload.displayName;
    state.user.aboutMe = payload.aboutMe;
    state.user.fullName = payload.fullName;
    state.user.dob = payload.dob;
    state.user.sex = payload.sex;
    state.user.address_1 = payload.address_1;
    state.user.address_2 = payload.address_2;
    state.user.customerType = payload.customerType;
    state.user.city = payload.city;
    state.user.zip = payload.zip;
    state.user.email = payload.email;
    state.user.gravatar = payload.gravatar;
    state.user.avatar = payload.avatar;
    state.user.isLoggedIn = payload.isLoggedIn;
    state.user.isVerified = payload.isVerified;
    state.user.idVerified = payload.idVerified;
    state.user.phoneVerified = payload.phoneVerified;
    state.user.phone = payload.phone;
    state.user.accLevel = payload.accLevel;
    state.user.accType = payload.accType;
    state.user.accStatus = payload.accStatus;
    state.user.memberSince = payload.memberSince;
    (state.user.favorites = payload.favorites),
      (state.user.myapplications = payload.myapplications);
  },
  SetConnectedUserFalse(state, payload) {
    state.user.welcome = payload.welcome;
  },
  SetMetaMaskChanges(state, payload) {
    state.user.accounts = payload.accounts;
    state.user.mmConnected = payload.mmConnected;
    state.user.walletProvider = payload.walletProvider;
    localStorage.setItem("provider", payload.walletProvider);
    state.user.isLoggedIn = payload.isLoggedIn;
  },
  SetBinanceChanges(state, payload) {
    state.user.accounts = payload.accounts;
    state.user.binanceConnected = payload.binanceConnected;
    state.user.walletProvider = payload.walletProvider;
    localStorage.setItem("provider", payload.walletProvider);
    state.user.isLoggedIn = payload.isLoggedIn;
  },
  SetWalletConnectChanges(state, payload) {
    state.user.accounts = payload.accounts;
    state.user.walletConnected = payload.mmConnected;
    state.user.walletProvider = payload.walletProvider;
    localStorage.setItem("provider", payload.walletProvider);
    state.user.isLoggedIn = payload.isLoggedIn;
  },
  SetMetaMaskInstalled(state, payload) {
    state.user.mmInstalled = payload.mmInstalled;
  },
  SetBinanceInstalled(state, payload) {
    state.user.binanceInstalled = payload.binanceInstalled;
  },
  SetNetworkChainID(state, payload) {
    state.user.networkChainID = payload.networkChainID;
  },
  SetCount(state, payload) {
    state.user.mmCount = payload.mmCount;
  },
  SetNewCount(state, payload) {
    state.user.newCount = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  clearError(state) {
    state.error = null;
  },
  SetBikeSubs(state, payload) {
    state.user.bikeSubs = payload;
  },
  setVerifyNumberPhone(state, payload) {
    state.user.phoneNumber = payload.phoneNumber;
    state.user.phoneVerified = payload.phoneVerified;
  },
  setCustomerType(state, payload) {
    state.user.customerType = payload;
  }
};

const actions = {
  clearError({ commit }) {
    commit("clearError");
  },
};

const getters = {
  getSessionUser() {
    return state.user;
  },
  getDevice() {
    return state.device;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  getCustomerType(state) {
    return state.user.customerType;
  }
};

// This is store!!!.
const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth: AuthModule,
    myProfile,
    customizationModul,
    domicileModule,
    privateInfoModule,
    vehicleModule,
    invoiceModul,
    byUrlModul,
    dataGojekKostumisasiModul,
    dataGojekInformasiPribadiModul,
    dataGojekDomisiliModul,
    dataGojekStepDoneModul,
    dataGojekPlanSubsModul,
    dataAuthGojekModul,
    vehiclesStoreModel,
    dataPlanSubsModul,
    dataInformasiPribadiModul,
    dataAuthModul,
    dataStepDoneModul,
    dataDomisiliModul,
    dataKostumisasiModul,
  },
});

export default store;
