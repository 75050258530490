<template>
  <v-sheet :color="dark ? 'grey darken-4' : '#FFF'" :dark="dark" tile>
    <v-layout
      v-show="$vuetify.breakpoint.lgAndUp"
      column
      align-center
      :style="
        windowWidth < 770
          ? 'margin-right:5px;margin-left:5px'
          : 'margin-right:5%;margin-left:5%'
      "
    >
      <p class="mt-4"></p>
      <p class="mt-4"></p>
      <!-- <div
        class="montserrat text-center font-weight-bold"
        style="
          color: #e8e8e8;
          font-size: 36px;
          margin-right: 20px;
          margin-left: 20px;
        "
      >
        {{ lang[getLanguage].BIKE_HOME_TITLE }}
      </div>
      <div
        class="work-sans text-center"
        style="
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.02em;
          color: #c1c2c4;
        "
      >
        {{ lang[getLanguage].BIKE_HOME_DESCRIPTION }}
      </div> -->

      <div class="text-center d-flex flex-column">
        <span
          clas="mb-4 font-montserrat"
          style="
            font-family: Montserrat;
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 45px;
            letter-spacing: -0.03em;
            color: #4f3379;
          "
          >{{ bikes[bikesCarousel].name }}</span
        >
        <!-- <Link
          v-if="bikes[bikesCarousel].isRelease"
          :text="lang[getLanguage].BIKE_HOME_SCHEDULE"
          actionParam="/testride"
          @actions="routerGo"
          :iconPosition="'right'"
          :color="'primary'"
          :icon="'mdi-chevron-right'"
          class="my-0 py-0 font-medium font-work-sans text-base custom-icon"
          :textTransform="'uppercase'"
          :textDecoration="'underline'"
          style="letter-spacing: -0.32px; font-size: 16px"
        >
        </Link> -->
      </div>

      <v-carousel
        v-model="bikesCarousel"
        hide-delimiter-background
        hide-delimiters
        style="width: 100%"
        :height="windowWidth < 770 ? 320 : 'auto'"
      >
        <v-carousel-item v-for="(item, i) in bikes" :key="i" class="mt-12">
          <v-row
            class="justify-center align-center"
            style="cursor: pointer"
            @click="routerGo('/bike/' + bikes[bikesCarousel].slug)"
          >
            <v-col cols="12" md="5" class="align-center">
              <v-img
                :src="item.image"
                max-height="360px"
                contain
                class="align-end"
              >
              </v-img>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="align-center px-auto"
              style="padding-left: 100px"
            >
              <v-row class="my-2">
                <v-card-text
                  class="py-0 bike-property-title"
                  style="letter-spacing: -0.32px"
                  >{{ lang[getLanguage].TOP_SPEED }}</v-card-text
                >
                <v-card-title class="py-0 bike-property-value"
                  >{{ bikes[bikesCarousel].speed }}
                  <small class="pa-2 bike-property-unit">{{
                    lang[getLanguage].SPEED_UNIT
                  }}</small></v-card-title
                >
              </v-row>
              <v-row class="my-2">
                <v-card-text class="py-0 mt-5">
                  <span class="bike-property-title">
                    {{ lang[getLanguage].MAX_RANGE }}
                  </span>
                  <span
                    v-if="item.battery > 1"
                    style="
                      font-family: 'Work Sans';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      letter-spacing: -0.02em;
                      color: #858688;
                    "
                  >
                    ({{
                      item.slug == "rimau_s"
                        ? parseInt(item.battery) / 2
                        : item.battery
                    }}
                    {{ getLanguage == "en" ? "BATTERIES" : "BATERAI" }})
                  </span>
                </v-card-text>
                <v-card-title class="py-0 bike-property-value">
                  {{
                    item.slug == "rimau_s"
                      ? parseInt(bikes[bikesCarousel].maxDistance) / 2
                      : bikes[bikesCarousel].maxDistance
                  }}
                  <small class="pa-2 bike-property-unit">{{
                    bikes[bikesCarousel].maxDistanceUnit
                  }}</small></v-card-title
                >
              </v-row>
              <v-chip
                v-if="
                  bikes[bikesCarousel].isRelease &&
                  bikes[bikesCarousel].subPrice
                "
                class="py-0 my-2"
                style="
                  font-family: 'Work Sans';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 22px;
                  color: #6b4693;
                  letter-spacing: -0.02em;
                "
                >{{ lang[getLanguage].AVAILABLE_SUBS }}</v-chip
              >
            </v-col>
          </v-row>
          <!-- <v-img
            v-if="item.slug == 'rimau_s'"
            class="mx-auto mt-5"
            src="/img/logo/TKDN-Color.png"
            max-width="152"
          /> -->
          <!-- <v-layout column align-center>
            
          </v-layout> -->
        </v-carousel-item>
      </v-carousel>

      <!-- Price in footer carousel -->
      <div
        v-if="bikes[bikesCarousel].isRelease"
        class="work-sans text-center my-10"
        style="
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.02em;
          font-weight: 400;
          color: #535456;
        "
      >
        <!-- {{ lang[getLanguage].BIKE_ITEM_DESCRIPTION }} -->
        <div
          class="font-work-sans"
          style="
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #535456;
          "
        >
          {{ bikes[bikesCarousel].text?.otr?.[getLanguage] }}
          {{ " " }}
          {{ bikes[bikesCarousel].text?.includeInOtr }}
        </div>
        <span
          class="montserrat"
          style="
            font-size: 28px;
            line-height: 35px;
            letter-spacing: -0.7px;
            font-weight: 700;
            color: #262626;
          "
          >{{ bikes[bikesCarousel].price }}
        </span>
        <span
          v-if="bikes[bikesCarousel]?.actualPrice"
          class="text-decoration-line-through text-gray-500"
        >
          {{ bikes[bikesCarousel]?.actualPrice }}
        </span>
        <div
          v-if="bikes[bikesCarousel].batterySubscription"
          style="
            margin-top: 10px;
            font-weight: 400;
            font-size: 16px;
            color: #535456;
          "
        >
          + {{ lang[getLanguage].BATTERY_SUBCRIPTION }} :
          {{ bikes[bikesCarousel].batterySubscription }}/{{
            lang[getLanguage].MONTH
          }}/{{ lang[getLanguage].BATTERIES }}
          <!-- ({{ lang[getLanguage].INCLUDE_CHARGER }}) -->
        </div>
      </div>
      <div v-else class="text-center">
        <h1
          class="text-4xl text-charged-grey-100 text-montserrate font-bold mb-2"
        >
          {{ getLanguage == "en" ? "Soon to be released!" : "Segera hadir!" }}
        </h1>
        <p class="text-charged-grey-300">
          {{
            getLanguage == "en"
              ? "Discover more about this product, we're set to launch soon. Stay Tuned!"
              : "Temukan lebih lanjut tentang produk ini, kami akan segera meluncurkannya. Nantikan!"
          }}
        </p>
      </div>

      <!-- Button in footer carousel -->
      <v-row
        v-if="bikes[bikesCarousel].isRelease"
        class="justify-center"
        :style="windowWidth < 770 ? 'margin-left:5%;margin-right:5%' : ''"
      >
        <v-col
          cols="12"
          :sm="windowWidth < 770 ? 12 : 5"
          style="margin-top: 5px"
          :md="bikes[bikesCarousel].subPrice ? 5 : 12"
        >
          <a
            :href="
              getLanguage === 'en'
                ? 'https://api.whatsapp.com/send?phone=6282221188028&text=Hi%2C%20I%27m%20interested%20in%20buying%20' +
                  bikes[bikesCarousel].name +
                  '%20electric%20motorcycle.%20How%20can%20I%20proceed%20%3F'
                : 'https://api.whatsapp.com/send?phone=6282221188028&text=Halo%2C%20saya%20tertarik%20untuk%20membeli%20motor%20' +
                  bikes[bikesCarousel].name +
                  '.%20Bagaimana%20caranya%20%3F'
            "
            target="_blank"
            style="text-decoration: none"
          >
            <v-btn
              class="work-sans font-weight-normal px-16"
              color="primary"
              large
              :style="
                windowWidth < 770
                  ? 'width:100%;color:#FFF;text-transform: none !important; box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12); border-radius: 8px; font-size: 16px; font-weight: 500; line-height: 24px; letter-spacing: -0.32px;'
                  : 'width:100%;color:#FFF;text-transform: none !important; box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12); border-radius: 8px; font-size: 16px; font-weight: 500; line-height: 24px; letter-spacing: -0.32px;'
              "
            >
              {{ lang[getLanguage].BUY_NOW + bikes[bikesCarousel].price }}
            </v-btn>
          </a>
        </v-col>
        <v-col
          v-if="bikes[bikesCarousel].subPrice"
          cols="auto"
          sm="auto"
          class="text-center align-self-center px-3"
        >
          {{ lang[getLanguage].OR }}
        </v-col>

        <v-col
          v-if="bikes[bikesCarousel].subPrice"
          class="text-center"
          cols="12"
          :sm="windowWidth < 770 ? 12 : 5"
          style="margin-top: 5px"
        >
          <!-- <img :src="bikes[bikesCarousel].promoLebaranSvg" width="286" height="30" style="margin-bottom: -7px" /> -->

          <v-btn
            color="#FFF"
            large
            class="work-sans px-16"
            :style="
              windowWidth < 770
                ? 'width:100%;color:#6B4693;text-transform: none !important; border: 1px solid #6B4693; border-radius: 8px; font-size: 16px; font-weight: 500; line-height: 24px; letter-spacing: -0.32px;'
                : 'width:100%;color:#6B4693;text-transform: none !important; border: 1px solid #6B4693; border-radius: 8px; font-size: 16px; font-weight: 500; line-height: 24px; letter-spacing: -0.32px;'
            "
            @click="subscribeNowClicked"
          >
            {{ lang[getLanguage].SUB_NOW + bikes[bikesCarousel].subPrice }}
          </v-btn>
        </v-col>
      </v-row>
      <div v-else class="mt-10">
        <v-btn
          outlined
          color="primary"
          class="capitalize text-base rounded-lg w-96"
          style="height: 48px"
          @click="routerGo('/bike/' + bikes[bikesCarousel].slug)"
        >
          Discover More
        </v-btn>
      </div>

      <p class="mt-4"></p>
      <p class="mt-4"></p>
    </v-layout>

    <v-layout
      v-show="$vuetify.breakpoint.mdAndDown"
      column
      align-center
      :style="
        windowWidth < 770
          ? 'margin-right:5px;margin-left:5px'
          : 'margin-right:5%;margin-left:5%'
      "
    >
      <p class="mt-4"></p>
      <p class="mt-4"></p>
      <div class="text-center d-flex flex-column">
        <span
          class="mb-3 montserrat"
          style="
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 45px;
            letter-spacing: -0.03em;
            color: #4f3379;
          "
          >{{ bikes[bikesCarousel].name }}</span
        >
        <!-- <Link
          v-if="bikes[bikesCarousel].isRelease"
          :text="lang[getLanguage].BIKE_HOME_SCHEDULE"
          actionParam="/testride"
          @actions="routerGo"
          :iconPosition="'right'"
          :color="'primary'"
          :icon="'mdi-chevron-right'"
          class="my-0 py-0 text-base custom-icon-bike"
          :textTransform="'uppercase'"
          :textDecoration="'underline'"
          style="letter-spacing: -0.32px; font-size: 16px"
        /> -->
      </div>

      <v-carousel
        v-model="bikesCarousel"
        hide-delimiters
        hide-delimiter-background
        :show-arrows="false"
        style="width: 100%"
        height="100%"
      >
        <v-carousel-item v-for="(item, i) in bikes" :key="i" class="test">
          <v-layout
            column
            align-center
            style="cursor: pointer"
            @click="routerGo('/bike/' + bikes[bikesCarousel].slug)"
          >
            <v-img
              :src="item.image"
              class="align-end"
              max-height="272px"
              contain
            >
            </v-img>
          </v-layout>
        </v-carousel-item>
      </v-carousel>

      <div class="px-4 w-80 mx-auto my-10">
        <div class="text-red-700 flex justify-between items-center gap-x-2">
          <v-icon
            @click="
              bikesCarousel > 0
                ? bikesCarousel--
                : (bikesCarousel = bikes.length - 1)
            "
            large
            style="color: #262626 !important"
            >mdi-chevron-left</v-icon
          >
          <div class="flex gap-x-1.5">
            <div
              v-for="(item, i) in bikes"
              :key="item.name"
              :class="
                bikesCarousel == i
                  ? 'w-1/3 h-2 rounded-lg bg-charged-grey-300 transform duration-300 ease-in-out'
                  : 'w-1/3 h-2 rounded-lg bg-charged-grey-600 transform duration-300 ease-in-out'
              "
            ></div>
            <!-- <div
              :class="
                bikesCarousel == 1
                  ? 'w-1/3 h-2 rounded-lg bg-charged-grey-300 transform duration-300 ease-in-out'
                  : 'w-1/3 h-2 rounded-lg bg-charged-grey-600 transform duration-300 ease-in-out'
              "
            ></div>
            <div
              :class="
                bikesCarousel == 2
                  ? 'w-1/3 h-2 rounded-lg bg-charged-grey-300 transform duration-300 ease-in-out'
                  : 'w-1/3 h-2 rounded-lg bg-charged-grey-600 transform duration-300 ease-in-out'
              "
            ></div> -->
          </div>
          <v-icon
            @click="
              bikesCarousel < bikes.length - 1
                ? bikesCarousel++
                : (bikesCarousel = 0)
            "
            large
            style="color: #262626 !important"
            >mdi-chevron-right</v-icon
          >
        </div>
      </div>

      <div class="bikeTitle text-center font-weight-bold">
        <v-col
          cols="12"
          md="4"
          class="align-center px-auto justify-center mb-5"
          style="padding-top: 0 !important; padding-bottom: 0 !important"
        >
          <v-row class="my-2">
            <v-card-text
              class="py-0 bike-property-title"
              style="letter-spacing: -0.32px !important"
              >{{ lang[getLanguage].TOP_SPEED }}</v-card-text
            >
            <div
              class="text-center justify-center py-0 flex items-center gap-x-2"
            >
              <span
                class="py-0 text-1xl font-montserrat font-bold"
                style="letter-spacing: -0.7px"
              >
                {{ bikes[bikesCarousel].speed }}
              </span>
              <small
                class="pa-1 text-base font-normal font-work-sans text-charged-grey-400"
                style="letter-spacing: -0.32px"
                >{{ lang[getLanguage].SPEED_UNIT }}
              </small>
            </div>
          </v-row>
          <v-row class="mb-2 mt-0">
            <v-card-text class="py-0 mt-5">
              <span
                class="bike-property-title"
                style="letter-spacing: -0.32px !important"
              >
                {{ lang[getLanguage].MAX_RANGE }}
              </span>
              <span
                v-if="bikes[bikesCarousel].battery > 1"
                class="font-work-sans text-xs font-normal text-charged-grey-400 ml-1"
                style="letter-spacing: -0.24px"
              >
                ({{
                  bikes[bikesCarousel].slug == "rimau_s"
                    ? parseInt(bikes[bikesCarousel].battery) / 2
                    : bikes[bikesCarousel].battery
                }}
                {{ getLanguage == "en" ? "BATTERIES" : "BATERAI" }})
              </span>
            </v-card-text>
            <div class="text-center justify-center flex items-center gap-x-2">
              <span
                class="py-0 text-1xl font-bold font-montserrat"
                style="letter-spacing: -0.7px"
              >
                {{
                  bikes[bikesCarousel].slug == "rimau_s"
                    ? parseInt(bikes[bikesCarousel].maxDistance) / 2
                    : bikes[bikesCarousel].maxDistance
                }}
              </span>
              <small
                class="pa-1 text-base font-normal font-work-sans text-charged-grey-400"
                style="letter-spacing: -0.32px"
                >{{ bikes[bikesCarousel].maxDistanceUnit }}
              </small>
            </div>
          </v-row>
          <v-chip
            v-if="
              bikes[bikesCarousel].isRelease && bikes[bikesCarousel].subPrice
            "
            class="py-0 my-2"
            style="
              font-family: 'Work Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              color: #6b4693;
              letter-spacing: -0.02em;
              padding: 16px 24px;
              height: 42px;
            "
            >{{ lang[getLanguage].AVAILABLE_SUBS }}</v-chip
          >
        </v-col>
      </div>

      <!-- <div
        class="work-sans text-center"
        style="
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.02em;
          color: #858688;
        "
      >
        {{ lang[getLanguage].BIKE_ITEM_DESCRIPTION }}
      </div> -->
      <!-- Price in footer carousel -->
      <div
        v-if="bikes[bikesCarousel].isRelease"
        class="work-sans text-center"
        style="
          font-size: 18px;
          line-height: 28px;
          letter-spacing: -0.02em;
          font-weight: 400;
          color: #535456;
        "
      >
        <!-- {{ lang[getLanguage].BIKE_ITEM_DESCRIPTION }} -->
        <div class="mt-5">
          <div
            class="font-work-sans"
            style="
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.02em;
              text-align: center;
              color: #535456;
            "
          >
            {{ bikes[bikesCarousel].text?.otr?.[getLanguage] }}
            {{ " " }}
            {{ bikes[bikesCarousel].text?.includeInOtr }}
          </div>
          <!-- <span
            class="text-lg font-normal font-work-sans"
            style="letter-spacing: -0.36px"
          >
            {{ lang[getLanguage].OTR }}
          </span> -->
          <span
            class="font-work-sans"
            style="
              font-size: 20px;
              line-height: 28px;
              letter-spacing: -0.4px;
              font-weight: 600;
              color: #262626;
            "
          >
            {{ bikes[bikesCarousel].price }}
          </span>
          <span
            v-if="bikes[bikesCarousel]?.actualPrice"
            class="text-decoration-line-through text-gray-500"
          >
            {{ bikes[bikesCarousel]?.actualPrice }}
          </span>
        </div>

        <div
          v-if="bikes[bikesCarousel].batterySubscription"
          class="mt-4"
          style="
            /* margin-top: 10px; */
            font-weight: 400;
            font-size: 16px;
            color: #535456;
          "
        >
          + {{ lang[getLanguage].BATTERY_SUBCRIPTION }} :
          {{ bikes[bikesCarousel].batterySubscription }}/{{
            lang[getLanguage].MONTH
          }}/{{ lang[getLanguage].BATTERIES }}
          <!-- <span class="inline-block">
            ({{ lang[getLanguage].INCLUDE_CHARGER }})
          </span> -->
        </div>
      </div>
      <div v-else class="text-center">
        <h1 class="text-2xl text-charged-grey-100 text-montserrate font-bold">
          {{ getLanguage == "en" ? "Soon to be released!" : "Segera hadir!" }}
        </h1>
        <p class="text-charged-grey-300">
          {{
            getLanguage == "en"
              ? "Discover more about this product, we're set to launch soon. Stay Tuned!"
              : "Temukan lebih lanjut tentang produk ini, kami akan segera meluncurkannya. Nantikan!"
          }}
        </p>
      </div>

      <!-- Button in footer carousel -->
      <div
        v-if="bikes[bikesCarousel].isRelease"
        class="px-4 flex flex-wrap justify-center gap-y-2.5 mt-8"
      >
        <div class="w-full">
          <a
            :href="
              getLanguage === 'en'
                ? 'https://api.whatsapp.com/send?phone=6282221188028&text=Hi%2C%20I%27m%20interested%20in%20buying%20' +
                  bikes[bikesCarousel].name +
                  '%20electric%20motorcycle.%20How%20can%20I%20proceed%20%3F'
                : 'https://api.whatsapp.com/send?phone=6282221188028&text=Halo%2C%20saya%20tertarik%20untuk%20membeli%20motor%20' +
                  bikes[bikesCarousel].name +
                  '.%20Bagaimana%20caranya%20%3F'
            "
            target="_blank"
            style="text-decoration: none"
          >
            <v-btn
              class="work-sans font-weight-normal px-16 text-base"
              color="primary"
              large
              :style="
                getLanguage === 'en'
                  ? 'width:100%;color:#FFF;text-transform: none !important; box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12); border-radius: 8px; letter-spacing: -0.32px; font-size: 16px;'
                  : 'font-size: 16px;width:100%;color:#FFF;text-transform: none !important; box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12); border-radius: 8px; letter-spacing: -0.32px;'
              "
            >
              {{ lang[getLanguage].BUY_NOW + bikes[bikesCarousel].price }}
            </v-btn>
          </a>
        </div>
        <div
          v-if="bikes[bikesCarousel].subPrice"
          class="w-full text-center font-work-sans text-base font-normal"
          style="letter-spacing: -0.32px"
        >
          {{ lang[getLanguage].OR }}
        </div>
        <div v-if="bikes[bikesCarousel].subPrice" class="w-full">
          <v-btn
            color="#FFF"
            large
            class="work-sans px-16 text-base"
            :style="
              getLanguage === 'en'
                ? 'width:100%;color:#6B4693;text-transform: none !important; border: 1px solid #6B4693; border-radius: 8px; letter-spacing: -0.32px; font-size: 16px;'
                : 'font-size: 16px;width:100%;color:#6B4693;text-transform: none !important; border: 1px solid #6B4693; border-radius: 8px; letter-spacing: -0.32px;'
            "
            @click="subscribeNowClicked"
          >
            {{ lang[getLanguage].SUB_NOW + bikes[bikesCarousel].subPrice }}
          </v-btn>
        </div>
      </div>
      <div v-else>
        <v-btn
          outlined
          color="primary"
          class="capitalize text-base rounded-lg w-full"
          @click="routerGo('/bike/' + bikes[bikesCarousel].slug)"
        >
          Discover More
        </v-btn>
      </div>

      <p class="mt-4"></p>
      <p class="mt-4"></p>
    </v-layout>

    <!-- bali area -->
    <!-- <v-dialog v-model="checkServiceAreaDialog" persistent max-width="500">
      <v-card>
        <v-layout>
          <v-spacer></v-spacer>
          <v-btn @click="checkServiceAreaDialog = false" icon> -->
    <!-- commented   -->
    <!-- this.$router.go(-1) -->
    <!-- <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>
        <v-layout style="margin-top: -10px">
          <v-spacer></v-spacer>
          <v-icon large color="grey">mdi-map-marker</v-icon>
          <v-spacer></v-spacer>
        </v-layout>
        <v-card-title class="title" style="margin-top: -10px"
          >What is Your City of Residence?</v-card-title
        >
        <v-card-text class="subheading font-weight-mediu"
          >In order to make sure Charged can serve you, please select or type
          your residential city.</v-card-text
        >

        <v-row
          :style="
            windowWidth < 770
              ? 'margin-left:5%;margin-right:5%;margin-top:5px;margin-bottom:10px'
              : 'margin-left:5%;margin-right:5%;margin-top:5px;margin-bottom:10px'
          "
        >
          <v-combobox
            v-model="customerCity"
            :items="getServiceCities"
            dense
            :loading="loading"
            item-id="id"
            item-text="city_village"
            label="Your residential city"
            placeholder="Select or search for your city..."
            outlined
          ></v-combobox>
        </v-row> -->
    <!-- commented   -->
    <!-- <v-card-text class="subheading">If you cannot find your city in the list it means that Charged cannot yet serve customers in that area.</v-card-text> -->
    <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="checkServiceAreaDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#6B4693"
            @click="subscribeNowServiceAreaOk"
            :disabled="customerCity === '' || customerCity === null"
            :style="
              windowWidth < 770
                ? 'width:100%;color:#FFF;text-transform: none !important;'
                : 'width:200px;color:#FFF;text-transform: none !important;'
            "
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <DialogCheckServiceAreVue
      ref="dialogCheckServiceArea"
      :bikeName="bikes[bikesCarousel].name"
      :dark="dark"
      :drawer="drawer"
      :isMobileDevice="isMobileDevice"
      :windowWidth="windowWidth"
    ></DialogCheckServiceAreVue>
  </v-sheet>
</template>

<script>
import store from "@/store/index";
// import Link from "../../components/navbar/LinkComponent.vue";
// import SignupNow from "./comp/Subscribe";
// import SignupNow from "./comp/Signup";
// import ProfileForm from "./comp/ProfileForm.vue";
// import SubscribeNow from "./comp/Subscribe";
import { contact, createContact } from "@/services/ContactApiCaller";
import DialogCheckServiceAreVue from "./components/DialogCheckServiceAre.vue";
export default {
  name: "about",
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    drawer: Boolean,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getProvince() {
      if (this.$store.state.user.language == "en") {
        return [
          ...this.$store.state.province,
          {
            name: "Not available in this list",
            stage: "province",
          },
        ];
      }
      if (this.$store.state.user.language == "id") {
        return [
          ...this.$store.state.province,
          {
            name: "Tidak tersedia dalam daftar ini",
            stage: "province",
          },
        ];
      }
      return this.$store.state.province;
    },
    getCities() {
      if (this.$store.state.user.language == "en") {
        let provList = this.$store.state.province.map((prov) => {
          return {
            name: "Not available in this list",
            province: prov.name,
            stage: "city",
          };
        });

        return [
          ...this.$store.state.cities,
          ...provList.map((provItem) => {
            return provItem;
          }),
        ];
      }
      if (this.$store.state.user.language == "id") {
        let provList = this.$store.state.province.map((prov) => {
          return {
            name: "Tidak tersedia dalam daftar ini",
            province: prov.name,
            stage: "city",
          };
        });

        return [
          ...this.$store.state.cities,
          ...provList.map((provItem) => {
            return provItem;
          }),
        ];
      }
      return this.$store.state.cities;
    },
    getRegencies() {
      return this.$store.state.regencies;
    },
    getServiceCities() {
      return this.$store.state.service_cities;
    },
  },
  data() {
    return {
      lang: this.$store.state.lang,
      loading: false,
      signupDialog: false,
      profileFormDialog: false,
      subscribeNowDialog: false,
      checkServiceAreaDialog: false,
      captureEmailDialog: false,
      bikesCarousel: 0,
      bikes: [],
      logoSrcDark: "",
      email: "",
      customerCity: "",
      province: "",
      provinceNotAvailable: false,
      city: "",
      cityNotAvailable: false,
      regency: "",
      customerArea: "",
      valid: false,
      dataProvided: false,
      resetEmaiSent: false,
      emailSaved: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(v) ||
          "Email address must be valid",
      ],
    };
  },
  watch: {},
  created() {
    this.getBikes();
    this.logoSrcDark = "./img/logo/charged_logo.png";
  },
  components: {
    // SignupNow,
    // ProfileForm,
    // SubscribeNow,
    // Link,
    DialogCheckServiceAreVue,
  },
  methods: {
    openDialogServiceArea() {
      this.$refs.dialogCheckServiceArea.checkServiceAreaDialogOpen();
      // console.log(this.$refs.dialogCheckServiceArea.checkServiceAreaDialogOpen);
    },
    checkServiceAreaDialogClose() {
      this.checkServiceAreaDialog = false;
    },
    getBikes() {
      // this.bikes = this.$store.getters.getListVehicle;
      this.bikes = this.$store.getters.vehicles_store;
      // console.log("THIS_BIKES", this.bikes);
    },
    onChangeProvince() {
      console.log("PROVINCE_CHANGE", this.province);
      this.province == null ? (this.province = "") : false;
      this.city = "";
      if (
        (this.province.name &&
          this.province.name == "Not available in this list") ||
        this.province.name == "Tidak tersedia dalam daftar ini"
      ) {
        this.provinceNotAvailable = true;
      } else {
        this.provinceNotAvailable = false;
      }
    },
    onChangeCity() {
      this.city == null ? (this.city = "") : false;
      this.regency = "";
      if (
        (this.city.name && this.city.name == "Not available in this list") ||
        this.city.name == "Tidak tersedia dalam daftar ini"
      ) {
        this.cityNotAvailable = true;
      } else {
        this.cityNotAvailable = false;
      }
    },
    onChangeRegency() {
      this.regency == null ? (this.regency = "") : false;
      this.customerCity = "";
    },
    subscribeNowClicked() {
      this.openDialogServiceArea();
      // this.subscribeNowDialog = true;
      // this.checkServiceAreaDialog = true;
      this.province = "";
      this.customerCity = "";
      this.city = "";
      this.regency = "";
      this.provinceNotAvailable = false;
      this.cityNotAvailable = false;
    },
    subscribeNowServiceAreaOk() {
      setTimeout(() => {
        console.log(this.customerCity);
        if (typeof this.customerCity.id !== "undefined") {
          console.log("Is Object");
          this.subscribeNowDialog = true;
          // write object to store
          store.commit("SetServiceCity", this.customerCity);
        } else {
          console.log("Is NOT Object");
          this.emailSaved = false;
          this.captureEmailDialog = true;
        }
      }, 500);
    },
    registerLeadEmail() {
      this.loading = true;
      contact.email_id = this.email;
      contact.email_ids.push({
        parent: name[0],
        parentfield: "email_ids",
        parenttype: "Contact",
        idx: 1,
        docstatus: 0,
        email_id: this.email,
        is_primary: 1,
        doctype: "Contact Email",
      });
      createContact();
      this.checkServiceAreaDialog = false;
      this.emailSaved = true;
    },
    subscribeNowDialogClosed() {
      this.$refs.subscribe.subscribeClosed();
      this.subscribeNowDialog = false;
    },
    change(text) {
      // this.alert = false
      console.log("on change");
      console.log(text);

      if (typeof this.customerCity.id !== "undefined") {
        console.log("Is Object");
        this.subscribeNowDialog = true;
        // write object to store
        store.commit("SetServiceCity", this.customerCity);
      } else {
        console.log("Is NOT Object");
        this.emailSaved = false;
        this.captureEmailDialog = true;
      }
    },
    signupDialogOpen() {
      setTimeout(() => {
        console.log("CustomerCity", this.customerCity);
        if (
          typeof this.customerCity.id !== "undefined" &&
          !this.cityNotAvailable &&
          !this.provinceNotAvailable
        ) {
          console.log("signup modal open");
          console.log(
            "BikeSubs",
            this.bikesCarousel,
            this.bikes[this.bikesCarousel]
          );

          const getUser = this.$store.state.user;
          console.log("getUser", getUser);
          const getuserPhoneNumber = getUser.phone;
          console.log("getuserPhoneNumber", getuserPhoneNumber);
          // getuserPhoneNumber
          //   ? (this.profileFormDialog = true)
          //   : (this.signupDialog = true);
          // getuserPhoneNumber
          //   ? (this.signupDialog = true)
          //   : (this.signupDialog = true);

          if (getuserPhoneNumber) {
            this.$router.push("/form-subscribe");
          } else {
            this.signupDialog = true;
          }
          // this.subscribeNowDialog = true;
          // this.profileFormDialog = true;
          // write object to store
          store.commit("SetServiceCity", this.customerCity);
          store.commit("SetBikeSubs", this.bikes[this.bikesCarousel]);
        } else {
          console.log("Is NOT Object");
          this.emailSaved = false;
          this.captureEmailDialog = true;
        }
      }, 500);
    },
    // registerLeadEmail() {
    //   this.loading = true;
    //   contact.email_id = this.email;
    //   contact.email_ids.push({
    //     parent: name[0],
    //     parentfield: "email_ids",
    //     parenttype: "Contact",
    //     idx: 1,
    //     docstatus: 0,
    //     email_id: this.email,
    //     is_primary: 1,
    //     doctype: "Contact Email",
    //   });
    //   createContact();
    //   this.checkServiceAreaDialog = false;
    //   this.emailSaved = true;
    // },
    signupDialogClosed() {
      this.signupDialog = false;
    },
    profileFormDialogClosed() {
      let getDataUser = this.getUser;
      if (!getDataUser.isLoggedIn) {
        this.$cookies.remove("token_api");
      }

      this.profileFormDialog = false;
    },
    // subscribeNowDialogClosed() {
    //   this.$refs.subscribe.subscribeClosed();
    //   this.subscribeNowDialog = false;
    // },
    // change(text) {
    //   // this.alert = false
    //   console.log("on change");
    //   console.log(text);

    //   if (typeof this.customerCity.id !== "undefined") {
    //     console.log("on change");
    //     console.log("this.customerCity.id ==  undefined");
    //   } else {
    //     console.log("Is Object");
    //     // this.customerCity = text
    //   }
    // },
    change1() {
      console.log(this.$refs.form.validate());
      if (this.$refs.form.validate()) {
        this.dataProvided = true;
      } else {
        this.dataProvided = false;
      }
    },
    routerGo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.bikeTitle {
  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 700;
}

.aboutBgLight1 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1)),
    url("/img/home/about_dark.png") no-repeat;
  background-size: cover;
}

.aboutBgLight2 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1)),
    url("/img/home/about_dark_02.png") no-repeat;
  background-size: cover;
}

.v-btn .v-btn__content .v-icon {
  color: black;
}

.theme--dark.v-btn.v-btn--icon {
  color: #3b007c;
}

.v-btn--round.theme--dark {
  background-color: rgba(255, 255, 255, 0.65) !important;
}

.v-btn--round.theme--dark:hover {
  background-color: rgba(107, 70, 147, 0.8) !important;
}

.v-icon.mdi-chevron-left {
  color: #ffff !important;
}

.v-icon.mdi-chevron-right {
  color: #ffff !important;
}

.bike-property-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #858688;
}

.bike-property-value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.03em;
  color: #262626;
}

.bike-property-unit {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #858688;
}

.form-city {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.cities-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.input-label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #858688;
}

.no-location-submit-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #262626;
}

.no-location-submit-description {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #535456;
  text-align: justify;
  text-align-last: center;
}

.font-custom .v-btn__content span {
  font-family: Work sans !important;
  font-weight: 600 !important;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: -0.32px;
}

.custom-icon .v-icon.v-icon--right {
  font-size: 20px;
}

.custom-icon-bike .v-icon.v-icon--right {
  font-size: 20px;
  margin-top: 1px !important;
}

.custom-combobox-d.v-input--is-disabled .v-input__control > .v-input__slot {
  border: none !important;
}

.custom-combobox-d .v-input__control > .v-input__slot {
  min-height: 40px !important;
  border-radius: 8px;
  border: none !important;
}

.custom-combobox-d.v-input--is-disabled
  .v-input__control
  > .v-input__slot
  fieldset {
  background: #f5f4f6 !important;
  color: #858688;
}

.color-option-container {
  color: white;
  position: absolute;
  bottom: 170px;

  display: flex;
}

.color-palette {
  width: 24px;
  height: 24px;
  border: 2px solid #e8e8e8;
  margin-right: 16px;
  border-radius: 50%;
}
</style>
