<template>
  <v-layout>
    <v-dialog
      v-model="checkServiceAreaDialog"
      persistent
      scrollable
      max-width="1000"
      :fullscreen="windowWidth < 770 || fullScreen"
    >
      <div :class="signupDialog || profileFormDialog ? 'hidden' : ''">
        <v-card
          :class="captureEmailDialog ? 'w-0 hidden' : 'h-screen md:h-full'"
        >
          <v-layout>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!btnCloseCustom"
              @click="checkServiceAreaDialogClose"
              icon
            >
              <!-- this.$router.go(-1) -->
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <slot name="btnCloseCustom"></slot>
          </v-layout>
          <v-layout style="margin-top: -10px; margin-bottom: 1rem">
            <v-spacer></v-spacer>
            <v-icon large color="grey">mdi-map-marker</v-icon>
            <v-spacer></v-spacer>
          </v-layout>
          <v-card-text
            class="font-work-sans text-center font-semibold text-xl md:text-1xl text-charged-grey-100"
            style="margin-top: -10px; letter-spacing: -0.4px; line-height: 28px"
          >
            {{ lang[getLanguage].PRODUCT_LOCATION_TITLE }}
          </v-card-text>
          <v-card-text class="subheading font-weight-mediu text-center pt-0">
            {{ lang[getLanguage].PRODUCT_LOCATION_DESCRIPTION }}
          </v-card-text>
          <!-- <v-row
          style="
            margin-left: 5%;
            margin-right: 5%;
            margin-top: 5px;
            margin-bottom: 10px;
          "
          v-if="isMobileDevice"
        >
          <v-col>
            <v-combobox
              v-model="province"
              :items="getProvince"
              @change="onChangeProvince"
              dense
              :loading="loading"
              item-id="id"
              item-text="name"
              placeholder="Select Provinsi"
              outlined
              style="padding: 0 1rem; width: 25rem"
            ></v-combobox>
            <v-combobox
              :disabled="!province || provinceNotAvailable"
              v-model="city"
              :items="getCities.filter((res) => res.province == province.name)"
              @change="onChangeCity"
              dense
              :loading="loading"
              item-id="id"
              item-text="name"
              placeholder="Select Kota"
              outlined
              style="padding: 1rem; width: 25rem"
            ></v-combobox>
            <v-combobox
              :disabled="!city || cityNotAvailable"
              v-model="regency"
              :items="getRegencies.filter((res) => res.city == city.name)"
              @change="onChangeRegency"
              dense
              :loading="loading"
              item-id="id"
              item-text="name"
              placeholder="Select Kecamatan"
              outlined
              style="padding: 0 1rem; width: 25rem"
            ></v-combobox>
            <v-combobox
              :disabled="!regency"
              v-model="customerCity"
              :items="
                getServiceCities.filter((res) => res.regency == regency.name)
              "
              dense
              :loading="loading"
              item-id="id"
              item-text="village"
              placeholder="Select Kelurahan"
              outlined
              style="padding: 0 1rem; width: 25rem"
            ></v-combobox>
          </v-col>
          <v-btn
            color="#6B4693"
            @click="signupDialogOpen"
            :disabled="
              (customerCity === '' &&
                !cityNotAvailable &&
                !provinceNotAvailable) ||
              (customerCity === null &&
                !cityNotAvailable &&
                !provinceNotAvailable) ||
              (city === '' && !cityNotAvailable && !provinceNotAvailable) ||
              (city === null && !cityNotAvailable && !provinceNotAvailable) ||
              (regency === '' && !cityNotAvailable && !provinceNotAvailable) ||
              (regency === null && !cityNotAvailable && !provinceNotAvailable)
            "
            style="
              margin-bottom: 1rem;
              width: 100%;
              color: #fff !important;
              text-transform: none !important;
            "
          >
            {{ lang[getLanguage].PRODUCT_LOCATION_SUBMIT }}
          </v-btn>
        </v-row> -->
          <!-- versi desktop -->
          <div class="container mt-5 md:m-0 md:mx-auto pt-1">
            <v-row class="px-5 md:px-12 md:mt-0 pb-8" justify="center">
              <!-- <v-col class="cities-container">
              <v-select
                v-model="province"
                :items="getProvince"
                @change="onChangeProvince"
                dense
                return-object
                item-text="name"
                :loading="loading"
                placeholder="Select Provinsi"
                outlined
                style="padding: 0 1rem; width: 25rem"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.name
                  }}<small
                    v-if="data.item.name == 'Bali'"
                    style="color: red; margin-left: 0.5rem"
                    >PRE-ORDER</small
                  >
                </template>
                <template slot="selection" slot-scope="data">
                  {{ data.item.name
                  }}<small
                    v-if="data.item.name == 'Bali'"
                    style="color: red; margin-left: 0.5rem"
                    >PRE-ORDER</small
                  >
                </template>
              </v-select>
  
  
              <v-combobox
                v-model="province"
                :items="getProvince"
                @change="onChangeProvince"
                dense
                item-text="name"
                :loading="loading"
                placeholder="Select Provinsi"
                outlined
                class="rounded-lg"
                style="padding: 1rem; width: 25rem"
              ></v-combobox>
              <v-combobox
                :disabled="!province || provinceNotAvailable"
                v-model="city"
                :items="getCities.filter((res) => res.province == province.name)"
                @change="onChangeCity"
                dense
                :loading="loading"
                item-id="id"
                item-text="name"
                placeholder="Select Kota"
                outlined
                class="rounded-lg custom-combobox-d"
                style="padding: 1rem; width: 25rem"
              ></v-combobox>
              <v-combobox
                :disabled="!city || cityNotAvailable"
                v-model="regency"
                :items="getRegencies.filter((res) => res.city == city.name)"
                @change="onChangeRegency"
                dense
                :loading="loading"
                item-id="id"
                item-text="name"
                placeholder="Select Kecamatan"
                outlined
                class="rounded-lg custom-combobox-d"
                style="padding: 0 1rem; width: 25rem"
              ></v-combobox>
              <v-combobox
                :disabled="!regency"
                v-model="customerCity"
                :items="
                  getServiceCities.filter((res) => res.regency == regency.name)
                "
                dense
                :loading="loading"
                item-id="id"
                item-text="village"
                placeholder="Select Kelurahan"
                outlined
                class="rounded-lg custom-combobox-d"
                style="padding: 0 1rem; width: 25rem"
              ></v-combobox>
            </v-col> -->
              <v-col class="p-0 md:p-3" cols="12" md="3">
                <span
                  class="text-charged-grey-400 text-base font-work-sans font-normal"
                >
                  Provinsi*
                </span>
                <v-combobox
                  v-model="province"
                  :items="getProvince"
                  @change="onChangeProvince"
                  dense
                  item-text="name"
                  :loading="loading"
                  placeholder="Select Provinsi"
                  outlined
                  class="rounded-lg custom-combobox-d"
                ></v-combobox>
              </v-col>
              <v-col class="p-0 md:p-3" cols="12" md="3">
                <span
                  class="text-charged-grey-400 text-base font-work-sans font-normal"
                >
                  Kota*
                </span>
                <v-combobox
                  :disabled="!province || provinceNotAvailable"
                  v-model="city"
                  :items="
                    getCities.filter((res) => res.province == province.name)
                  "
                  @change="onChangeCity"
                  dense
                  :loading="loading"
                  item-id="id"
                  item-text="name"
                  placeholder="Select Kota"
                  outlined
                  class="rounded-lg custom-combobox-d"
                ></v-combobox>
              </v-col>
              <v-col class="p-0 md:p-3" cols="12" md="3">
                <span
                  class="text-charged-grey-400 text-base font-work-sans font-normal"
                >
                  Kecamatan*
                </span>
                <v-combobox
                  :disabled="!city || cityNotAvailable"
                  v-model="regency"
                  :items="getRegencies.filter((res) => res.city == city.name)"
                  @change="onChangeRegency"
                  dense
                  :loading="loading"
                  item-id="id"
                  item-text="name"
                  placeholder="Select Kecamatan"
                  outlined
                  class="rounded-lg custom-combobox-d"
                ></v-combobox>
              </v-col>
              <v-col class="p-0 md:p-3" cols="12" md="3">
                <span
                  class="text-charged-grey-400 text-base font-work-sans font-normal"
                >
                  Kelurahan*
                </span>
                <v-combobox
                  :disabled="!regency"
                  v-model="customerCity"
                  :items="
                    getServiceCities.filter(
                      (res) =>
                        res.regency == regency.name &&
                        regency.city == `${res.state + ' ' + res.district}`
                    )
                  "
                  dense
                  :loading="loading"
                  item-id="id"
                  item-text="village"
                  placeholder="Select Kelurahan"
                  outlined
                  class="rounded-lg custom-combobox-d"
                ></v-combobox>
              </v-col>
              <v-btn
                class="block md:inline-block w-full md:w-32 md:text-center rounded-lg"
                color="#6B4693"
                @click="signupDialogOpen"
                :disabled="
                  (customerCity === '' &&
                    !cityNotAvailable &&
                    !provinceNotAvailable) ||
                  (customerCity === null &&
                    !cityNotAvailable &&
                    !provinceNotAvailable) ||
                  (city === '' && !cityNotAvailable && !provinceNotAvailable) ||
                  (city === null &&
                    !cityNotAvailable &&
                    !provinceNotAvailable) ||
                  (regency === '' &&
                    !cityNotAvailable &&
                    !provinceNotAvailable) ||
                  (regency === null &&
                    !cityNotAvailable &&
                    !provinceNotAvailable) ||
                  (province === '' &&
                    !cityNotAvailable &&
                    !provinceNotAvailable) ||
                  (province === null &&
                    !cityNotAvailable &&
                    !provinceNotAvailable)
                "
                style="
                  margin-bottom: 1rem;
                  color: #fff !important;
                  text-transform: none !important;
                  height: 48px;
                "
              >
                {{ lang[getLanguage].PRODUCT_LOCATION_SUBMIT }}
              </v-btn>
            </v-row>
          </div>

          <!-- <v-card-text class="subheading">If you cannot find your city in the list it means that Charged cannot yet serve customers in that area.</v-card-text> -->
          <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#6B4693"
            @click="subscribeNowServiceAreaOk"
            :disabled="customerCity === '' || customerCity === null"
            :style="
              windowWidth < 770
                ? 'width:100%;color:#FFF;text-transform: none !important;'
                : 'width:200px;color:#FFF;text-transform: none !important;'
            "
          >
            Enter
          </v-btn>
        </v-card-actions> -->
        </v-card>

        <v-card
          :class="
            captureEmailDialog ? 'w-full h-screen md:h-full' : 'w-0 hidden'
          "
        >
          <v-layout>
            <v-btn @click="captureEmailDialog = false" icon>
              <!-- this.$router.go(-1) -->
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                () => (
                  (checkServiceAreaDialog = false), (captureEmailDialog = false)
                )
              "
              icon
            >
              <!-- this.$router.go(-1) -->
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-layout>
          <div v-if="emailSaved" class="mx-1">
            <v-layout>
              <v-spacer></v-spacer>
              <v-icon color="green">mdi-check-circle</v-icon>
              <v-spacer></v-spacer>
            </v-layout>

            <v-card-text
              class="no-location-submit-title"
              style="margin-top: -10px; padding-bottom: 0; margin-top: 1rem"
            >
              {{
                lang[getLanguage].PRODUCT_NO_LOCATION_SUBMIT_TITLE
              }}</v-card-text
            >

            <!-- style="text-align: justify; text-align-last: center" -->
            <v-card-text class="no-location-submit-description pb-15">{{
              lang[getLanguage].PRODUCT_NO_LOCATION_SUBMIT_DESCRIPTION
            }}</v-card-text>
          </div>

          <div v-if="!emailSaved">
            <v-card-text
              class="font-work-sans text-center text-xl md:text-1xl text-charged-grey-100 font-semibold"
              style="
                margin-top: -10px;
                padding-bottom: 0;
                margin-top: 1rem;
                letter-spacing: -0.4px;
              "
            >
              {{ lang[getLanguage].PRODUCT_NO_LOCATION_TITLE }}</v-card-text
            >

            <v-card-text class="subheading text-center md:px-20">{{
              lang[getLanguage].PRODUCT_NO_LOCATION_DESCRIPTION
            }}</v-card-text>
            <div class="md:px-20 mt-5">
              <v-row class="m-0 pt-5 md:border-t-2" justify="center">
                <v-col cols="12" md="4" class="md:pb-0 p-0 md:p-3">
                  <div class="mx-5">
                    <span class="input-label">{{
                      lang[getLanguage].PRODUCT_NO_LOCATION_AREA_LABEL
                    }}</span>
                    <v-text-field
                      class="rounded-lg custom-combobox-d"
                      outlined
                      dense
                      filled
                      v-model="customerArea"
                      :placeholder="
                        lang[getLanguage].PRODUCT_NO_LOCATION_AREA_PLACEHOLDER
                      "
                    >
                    </v-text-field>
                  </div>
                </v-col>

                <v-col cols="12" md="8" class="md:pb-0 p-0 md:p-3">
                  <div class="mx-5 mb-2">
                    <span class="input-label">{{
                      lang[getLanguage].EMAIL_ADDRESS
                    }}</span>
                    <v-form v-model="valid" ref="form" style="width: 100%">
                      <v-text-field
                        class="rounded-lg custom-combobox-d"
                        outlined
                        dense
                        filled
                        v-model="email"
                        type="email"
                        :rules="emailRules"
                        :placeholder="
                          lang[getLanguage].PRODUCT_NO_LOCATION_EMAIL
                        "
                        required
                        @input="change1"
                        @change="change1"
                      >
                      </v-text-field>
                    </v-form>
                  </div>
                </v-col>
                <!-- <v-card-text class="subheading">If you cannot find your city in the list it means that Charged cannot yet serve customers in that area.</v-card-text> -->
                <v-card-actions class="mx-3 w-full md:w-1/3">
                  <!-- <v-btn color="grey darken-1" text @click="captureEmailDialog = false">{{ emailSaved ? 'Close' : 'Cancel' }}</v-btn> -->
                  <v-btn
                    color="#6B4693"
                    @click="registerLeadEmail"
                    :disabled="!dataProvided || customerArea == ''"
                    class="mb-15 w-full block md:inline-block"
                    :style="
                      windowWidth < 770
                        ? 'width:100%;color:#FFF;text-transform: none !important; height: 48px !important; letter-spacing: -0.32px;'
                        : 'width:100%;color:#FFF;text-transform: none !important; height: 48px !important; letter-spacing: -0.32px;'
                    "
                  >
                    {{ lang[getLanguage].PRODUCT_NO_LOCATION_EMAIL_SUBMIT }}
                  </v-btn>
                </v-card-actions>
              </v-row>
            </div>
          </div>
        </v-card>
      </div>
    </v-dialog>

    <v-dialog :v-model="false" persistent max-width="500">
      <v-card>
        <v-layout>
          <v-spacer></v-spacer>
          <v-btn @click="captureEmailDialog = false" icon>
            <!-- this.$router.go(-1) -->
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>
        <!-- <v-layout style="margin-top: -10px">
          <v-spacer></v-spacer>
          <v-icon large color="grey">mdi-emoticon-sad-outline</v-icon>
          <v-spacer></v-spacer>
        </v-layout>
        <v-card-title class="title" style="margin-top: -10px"
          >No service in {{ customerCity }}
        </v-card-title> -->

        <!-- <v-card-text class="subheading"
          >Unfortunately Charged cannot yet serve customers in
          {{ customerCity }}. In order to keep you updated on when Charged will
          be able to serve in your area, please leave us your email address.
        </v-card-text>

        <v-row
          :style="
            windowWidth < 770
              ? 'margin-left:5%;margin-right:5%;margin-top:5px;margin-bottom:10px'
              : 'margin-left:5%;margin-right:5%;margin-top:5px;margin-bottom:10px'
          "
        >
          <v-form v-model="valid" ref="form" style="width: 100%">
            <v-text-field
              style="width: 100%"
              v-if="!emailSaved"
              v-model="email"
              outlined
              color="purple darken-4"
              type="email"
              :rules="emailRules"
              label="Email"
              append-icon="mdi-email-outline"
              required
              @input="change1"
              @change="change1"
            ></v-text-field>
          </v-form>

          <v-layout v-if="emailSaved">
            <v-icon color="green">mdi-check-circle-outline</v-icon>
            <span>Thank you for leaving us your email address!</span>
          </v-layout>
        </v-row> -->

        <!-- <v-card-text class="subheading">If you cannot find your city in the list it means that Charged cannot yet serve customers in that area.</v-card-text> -->
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="captureEmailDialog = false"
            >{{ emailSaved ? "Close" : "Cancel" }}
          </v-btn>
          <v-btn
            v-if="!emailSaved"
            color="#6B4693"
            @click="registerLeadEmail"
            :disabled="!dataProvided"
            :style="
              windowWidth < 770
                ? 'width:100%;color:#FFF;text-transform: none !important;'
                : 'width:200px;color:#FFF;text-transform: none !important;'
            "
          >
            Send
          </v-btn>
        </v-card-actions> -->
        <div v-if="emailSaved" class="mx-1">
          <v-layout>
            <v-spacer></v-spacer>
            <v-icon color="green">mdi-check-circle</v-icon>
            <v-spacer></v-spacer>
          </v-layout>

          <v-card-text
            class="no-location-submit-title"
            style="margin-top: -10px; padding-bottom: 0; margin-top: 1rem"
          >
            {{
              lang[getLanguage].PRODUCT_NO_LOCATION_SUBMIT_TITLE
            }}</v-card-text
          >

          <!-- style="text-align: justify; text-align-last: center" -->
          <v-card-text class="no-location-submit-description pb-15">{{
            lang[getLanguage].PRODUCT_NO_LOCATION_SUBMIT_DESCRIPTION
          }}</v-card-text>
        </div>

        <div v-if="!emailSaved">
          <v-card-text
            class="title text-center"
            style="margin-top: -10px; padding-bottom: 0; margin-top: 1rem"
          >
            {{ lang[getLanguage].PRODUCT_NO_LOCATION_TITLE }}</v-card-text
          >

          <v-card-text class="subheading text-center">{{
            lang[getLanguage].PRODUCT_NO_LOCATION_DESCRIPTION
          }}</v-card-text>

          <div class="mx-5">
            <span class="input-label">{{
              lang[getLanguage].PRODUCT_NO_LOCATION_AREA_LABEL
            }}</span>
            <v-text-field
              outlined
              dense
              filled
              v-model="customerArea"
              :placeholder="
                lang[getLanguage].PRODUCT_NO_LOCATION_AREA_PLACEHOLDER
              "
            >
            </v-text-field>
          </div>

          <div class="mx-5 mb-2">
            <span class="input-label">{{
              lang[getLanguage].EMAIL_ADDRESS
            }}</span>
            <v-form v-model="valid" ref="form" style="width: 100%">
              <v-text-field
                outlined
                dense
                filled
                v-model="email"
                type="email"
                :rules="emailRules"
                :placeholder="lang[getLanguage].PRODUCT_NO_LOCATION_EMAIL"
                required
                @input="change1"
                @change="change1"
              >
              </v-text-field>
            </v-form>
          </div>

          <!-- <v-card-text class="subheading">If you cannot find your city in the list it means that Charged cannot yet serve customers in that area.</v-card-text> -->
          <v-card-actions class="mx-3">
            <!-- <v-btn color="grey darken-1" text @click="captureEmailDialog = false">{{ emailSaved ? 'Close' : 'Cancel' }}</v-btn> -->
            <v-btn
              color="#6B4693"
              @click="registerLeadEmail"
              :disabled="!dataProvided || customerArea == ''"
              class="mb-15"
              :style="
                windowWidth < 770
                  ? 'width:100%;color:#FFF;text-transform: none !important;'
                  : 'width:100%;color:#FFF;text-transform: none !important;'
              "
            >
              {{ lang[getLanguage].PRODUCT_NO_LOCATION_EMAIL_SUBMIT }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <!-- <v-dialog
      v-model="signupDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-toolbar :dark="dark" :color="dark ? 'grey darken-4' : '#FFF'">
        <v-btn @click="signupDialogClosed" icon style="margin-right: 20px">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-img
          src="/img/logo/charged_logo.png"
          max-width="144"
          max-height="32"
        ></v-img>
        <v-spacer></v-spacer>
        <v-icon v-if="!isMobileDevice">mdi-phone</v-icon>
        <span v-if="!isMobileDevice"
          >{{ lang[getLanguage].LABEL_NEED_HELP }}?
          <a
            href="https://wa.me/6289604582129?text=Hi+Charged+Indonesia%2C+Saya+perlu+bantuan"
            target="_blank"
            >+62 896 04 582129</a
          ></span
        >
      </v-toolbar>
      <v-card
        :dark="dark"
        :style="dark ? 'background-color:#2a2a2a' : 'background-color:#FFF'"
      >
        <SignupNow
          ref="signup"
          :index="bikesCarousel"
          :isMobileDevice="isMobileDevice"
          :selectedBike="bikes[bikesCarousel]"
        ></SignupNow>
      </v-card>
    </v-dialog> -->

    <v-dialog
      v-model="signupDialog"
      persistent
      :fullscreen="windowWidth < 770"
      max-width="450"
    >
      <v-card
        :dark="dark"
        :style="dark ? 'background-color:#2a2a2a;' : 'background-color:#FFF;'"
      >
        <SignupNow
          ref="signup"
          :signupDialogClosed="signupDialogClosed"
          :checkServiceAreaDialogClose="checkServiceAreaDialogClose"
          :index="bikesCarousel"
          :windowWidth="windowWidth"
          :isMobileDevice="isMobileDevice"
          :selectedBike="bikes[bikesCarousel]"
          :btnCloseCustom="btnCloseCustom"
        >
        </SignupNow>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import store from "@/store/index";
import SignupNow from "../comp/Signup";
// import ProfileForm from "../comp/ProfileForm.vue";
// import SubscribeNow from "../comp/Subscribe";
import { contact, createContact } from "@/services/ContactApiCaller";
export default {
  name: "DialogCheckServiceArea",
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    drawer: Boolean,
    bikeName: String,
    fullScreen: Boolean,
    btnCloseCustom: Boolean,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getProvince() {
      if (this.$store.state.user.language == "en") {
        return [
          ...this.$store.state.province,
          {
            name: "Not available in this list",
            stage: "province",
          },
        ];
      }
      if (this.$store.state.user.language == "id") {
        return [
          ...this.$store.state.province,
          {
            name: "Tidak tersedia dalam daftar ini",
            stage: "province",
          },
        ];
      }
      return this.$store.state.province;
    },
    getCities() {
      if (this.$store.state.user.language == "en") {
        let provList = this.$store.state.province.map((prov) => {
          return {
            name: "Not available in this list",
            province: prov.name,
            stage: "city",
          };
        });

        return [
          ...this.$store.state.cities,
          ...provList.map((provItem) => {
            return provItem;
          }),
        ];
      }
      if (this.$store.state.user.language == "id") {
        let provList = this.$store.state.province.map((prov) => {
          return {
            name: "Tidak tersedia dalam daftar ini",
            province: prov.name,
            stage: "city",
          };
        });

        return [
          ...this.$store.state.cities,
          ...provList.map((provItem) => {
            return provItem;
          }),
        ];
      }
      return this.$store.state.cities;
    },
    getRegencies() {
      return this.$store.state.regencies;
    },
    getServiceCities() {
      return this.$store.state.service_cities;
    },
    getCustomerType() {
      return this.$store.getters.getCustomerType;
    },
  },
  data() {
    return {
      lang: this.$store.state.lang,
      loading: false,
      signupDialog: false,
      profileFormDialog: false,
      subscribeNowDialog: false,
      checkServiceAreaDialog: false,
      captureEmailDialog: false,
      bikesCarousel: 0,
      bikes: [],
      logoSrcDark: "",
      email: "",
      customerCity: "",
      province: "",
      provinceNotAvailable: false,
      city: "",
      cityNotAvailable: false,
      regency: "",
      customerArea: "",
      valid: false,
      dataProvided: false,
      resetEmaiSent: false,
      emailSaved: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(v) ||
          "Email address must be valid",
      ],
    };
  },
  created() {
    this.getBikes();
    this.logoSrcDark = "./img/logo/charged_logo.png";
    // console.log("THIS_DIALOG_SERVICE", this.checkServiceAreaDialog);
    // console.log("THIS_BIKE_NAME", this.bikeName);
  },
  methods: {
    // signupDialogOpen() {
    //   this.signupDialog = true;
    // },
    checkServiceAreaDialogOpen() {
      this.checkServiceAreaDialog = true;
    },
    checkServiceAreaDialogClose() {
      this.checkServiceAreaDialog = false;
    },
    getBikes() {
      this.bikes = this.$store.getters.getListVehicle;
    },
    onChangeProvince() {
      // console.log("PROVINCE_CHANGE", this.province);
      this.province == null ? (this.province = "") : false;
      this.city = "";
      if (
        (this.province.name &&
          this.province.name == "Not available in this list") ||
        this.province.name == "Tidak tersedia dalam daftar ini"
      ) {
        this.provinceNotAvailable = true;
      } else {
        this.provinceNotAvailable = false;
      }
    },
    onChangeCity() {
      this.city == null ? (this.city = "") : false;
      this.regency = "";
      if (
        (this.city.name && this.city.name == "Not available in this list") ||
        this.city.name == "Tidak tersedia dalam daftar ini"
      ) {
        this.cityNotAvailable = true;
      } else {
        this.cityNotAvailable = false;
      }
    },
    onChangeRegency() {
      this.regency == null ? (this.regency = "") : false;
      this.customerCity = "";
    },
    subscribeNowClicked() {
      // this.subscribeNowDialog = true;
      this.checkServiceAreaDialog = true;
      this.province = "";
      this.customerCity = "";
      this.city = "";
      this.regency = "";
      this.provinceNotAvailable = false;
      this.cityNotAvailable = false;
    },
    subscribeNowServiceAreaOk() {
      setTimeout(() => {
        // console.log(this.customerCity);
        if (typeof this.customerCity.id !== "undefined") {
          // console.log("Is Object");
          this.subscribeNowDialog = true;
          // write object to store
          store.commit("SetServiceCity", this.customerCity);
        } else {
          // console.log("Is NOT Object");
          this.emailSaved = false;
          this.captureEmailDialog = true;
        }
      }, 500);
    },
    registerLeadEmail() {
      this.loading = true;
      contact.email_id = this.email;
      contact.email_ids.push({
        parent: name[0],
        parentfield: "email_ids",
        parenttype: "Contact",
        idx: 1,
        docstatus: 0,
        email_id: this.email,
        is_primary: 1,
        doctype: "Contact Email",
      });
      createContact();
      this.checkServiceAreaDialog = false;
      this.emailSaved = true;
    },
    subscribeNowDialogClosed() {
      this.$refs.subscribe.subscribeClosed();
      this.subscribeNowDialog = false;
    },
    change(text) {
      // this.alert = false
      // console.log("on change");
      console.log(text);

      if (typeof this.customerCity.id !== "undefined") {
        // console.log("Is Object");
        this.subscribeNowDialog = true;
        // write object to store
        store.commit("SetServiceCity", this.customerCity);
      } else {
        // console.log("Is NOT Object");
        this.emailSaved = false;
        this.captureEmailDialog = true;
      }
    },
    signupDialogOpen() {
      setTimeout(() => {
        // console.log("CustomerCity", this.customerCity);
        if (
          typeof this.customerCity.id !== "undefined" &&
          !this.cityNotAvailable &&
          !this.provinceNotAvailable
        ) {
          // console.log("signup modal open");
          // console.log(
          //   "BikeSubs",
          //   this.bikesCarousel,
          //   this.bikes[this.bikesCarousel]
          // );

          const getUser = this.$store.state.user;
          // console.log("getUser", getUser);
          const getuserPhoneNumber = getUser.phone;
          // console.log("getuserPhoneNumber", getuserPhoneNumber);
          // getuserPhoneNumber
          //   ? (this.profileFormDialog = true)
          //   : (this.signupDialog = true);
          // getuserPhoneNumber
          //   ? (this.signupDialog = true)
          //   : (this.signupDialog = true);

          if (getuserPhoneNumber) {
            // console.log("MITRA_GOJEK", this.getCustomerType);
            if (this.getCustomerType == "mitra_driver_gojek") {
              this.$router.push("/form-subscribe-mitra-gojek");
            } else {
              this.$router.push("/form-subscribe");
            }
          } else {
            this.signupDialog = true;
          }
          // this.subscribeNowDialog = true;
          // this.profileFormDialog = true;
          // write object to store
          // let selectedBikeName = this.bikes[this.bikesCarousel].name;
          // console.log("SERVICE_CITY", this.customerCity);
          store.commit("SetServiceCity", this.customerCity);
          // store.commit("SetBikeSubs", this.bikes);
          if (this.bikeName) {
            store.commit(
              "SetBikeSubs",
              this.bikes.find(
                (bike) => bike.name == this.bikeName.toUpperCase()
              )
            );
          }
        } else {
          console.log("Is NOT Object");
          this.emailSaved = false;
          this.captureEmailDialog = true;
        }
      }, 500);
    },
    // registerLeadEmail() {
    //   this.loading = true;
    //   contact.email_id = this.email;
    //   contact.email_ids.push({
    //     parent: name[0],
    //     parentfield: "email_ids",
    //     parenttype: "Contact",
    //     idx: 1,
    //     docstatus: 0,
    //     email_id: this.email,
    //     is_primary: 1,
    //     doctype: "Contact Email",
    //   });
    //   createContact();
    //   this.checkServiceAreaDialog = false;
    //   this.emailSaved = true;
    // },
    signupDialogClosed() {
      this.signupDialog = false;
    },
    profileFormDialogClosed() {
      let getDataUser = this.getUser;
      if (!getDataUser.isLoggedIn) {
        this.$cookies.remove("token_api");
      }

      this.profileFormDialog = false;
    },
    // subscribeNowDialogClosed() {
    //   this.$refs.subscribe.subscribeClosed();
    //   this.subscribeNowDialog = false;
    // },
    // change(text) {
    //   // this.alert = false
    //   console.log("on change");
    //   console.log(text);

    //   if (typeof this.customerCity.id !== "undefined") {
    //     console.log("on change");
    //     console.log("this.customerCity.id ==  undefined");
    //   } else {
    //     console.log("Is Object");
    //     // this.customerCity = text
    //   }
    // },
    change1() {
      // console.log(this.$refs.form.validate());
      if (this.$refs.form.validate()) {
        this.dataProvided = true;
      } else {
        this.dataProvided = false;
      }
    },
    routerGo(route) {
      this.$router.push(route);
    },
  },
  components: {
    SignupNow,
    // ProfileForm,
    // SubscribeNow,
    // Link,
    // DialogCheckServiceAreVue,
  },
};
</script>