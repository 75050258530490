const myProfile = {
  state: () => ({
    id_subscribe: '',
    profile: "",
    vehicle_id: null,
  }),
  mutations: {
    setProfile(state, payload) {
      // `state` is the local module state
      state.profile = payload
    },
    setIdSubs(state, payload) {
      state.id_subscribe = payload;
    },
    setVehicleId(state, payload) {
      state.vehicle_id = payload;
    }
  },
  actions: {},
  getters: {
    getProfile(state) {
      return state.profile;
    },
    getIdSubs(state) {
      return state.id_subscribe;
    },
    getVehicleId(state) {
      return state.vehicle_id;
    }
  }
}

export default myProfile;